import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AduraService } from '@app/shared/services/adura.service';
import { AduraResultItem } from '@app/shared/classes/adura/adura-result-item';
import { SeismoInfo } from '@app/shared/classes/adura/seismoInfo';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-adura-massenfreigabe-entry]',
  templateUrl: './adura-massenfreigabe-entry.component.html',
  styleUrls: ['./adura-massenfreigabe-entry.component.css']
})
export class AduraMassenfreigabeEntryComponent implements OnInit, OnDestroy {
  @Input() entry: AduraResultItem;
  seismoInfoList: SeismoInfo[];

  @Input() toggle: Observable<boolean>;
  private toggleSubject: Subscription;
  isSelected = false;

  @Output() toggleMeldungEmitter = new EventEmitter<AduraResultItem>();
  @Output() toggleSteckbriefEmitter = new EventEmitter<AduraResultItem>();

  @Input() error: Observable<string []>;
  private errorSubject: Subscription;
  hasError = false;

  @Input() success: Observable<string []>;
  private successSubject: Subscription;
  hasSuccess = false;
  hasSaveSuccess = false;

  constructor(private aduraService: AduraService) {
  }

  ngOnInit(){
    this.toggleSubject = this.toggle.subscribe(
      (selected) => this.isSelected = selected
    );
    this.errorSubject = this.error.subscribe(
      (errors) =>  this.hasError = (errors.includes(this.entry.code))
    );
    this.successSubject = this.success.subscribe(
      (success) =>  this.hasSuccess = (success.includes(this.entry.code))
    );
    if (this.isMeldung() && this.entry.seismoPublikation) {
      this.updateSeismoInfoList();
    }
  }

  ngOnDestroy() {
    this.toggleSubject.unsubscribe();
    this.errorSubject.unsubscribe();
    this.successSubject.unsubscribe();
  }

  updateSeismoInfoList() {
    this.aduraService.getSeismoInfoAusgabeList(this.entry.code)
      .subscribe((seismoInfoList) => this.seismoInfoList = seismoInfoList);
  }

  getTypeLabel() {
    if (this.isMeldung()) {
      return 'adura.meldung.label';
    } else {
      return 'adura.steckbrief.label';
    }
  }

  isFreigegeben() {
    return this.entry.status === '20';
  }

  toggleSelected() {
    this.isSelected = !this.isSelected;

    if (this.isMeldung()) {
      this.toggleMeldungEmitter.emit(this.entry);
    } else {
      this.toggleSteckbriefEmitter.emit(this.entry);
    }
  }

  isMeldung(): boolean {
    return this.entry.type === 'notification';
  }

  get bereich() {
    if (this.isMeldung()) {
      return (this.entry && this.entry.bereichPrimaer) ? this.entry.bereichPrimaer : null;
    } else {
      return (this.entry && this.entry.bereiche) ? this.entry.bereiche.join(', ') : null;
    }
  }

  updateSeismoAusgabe(ausgabe: string): void {
    this.hasSaveSuccess = false;
    this.aduraService.setSeismoInfoAusgabe(this.entry.code, ausgabe).subscribe( (entry) => {
      this.entry.seismoInfo = entry.seismoInfo;
      this.entry.mutDate = entry.mutDate;
      this.hasSaveSuccess = true;
      this.updateSeismoInfoList();
    });
  }

  setRating(rating: number): void {
    this.hasSaveSuccess = false;
    this.aduraService.setMeldungRating(this.entry.code, rating).subscribe( (entry) => {
      this.entry.sterne = entry.sterne;
      this.hasSaveSuccess = true;
    });
  }
}
