<div #favoriteCreateModal class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button *ngIf="!isSending" type="button" class="close" (click)="close()" aria-label="Close"><span
          aria-hidden="true">&times;</span>
        </button>
        <h2 class="modal-title">{{ 'favorite.new' | translate }}</h2>
      </div>
      <form [formGroup]="favoriteCreateForm" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form-group">
            <p *ngIf="error" class="alert alert-danger" role="alert"> {{ errorMessage | translate }}</p>
            <label for="favoriteName">{{ 'global.name' | translate }}:</label>
            <input
              type="text"
              class="form-control"
              formControlName="name"
              id="favoriteName"
              autocomplete="off"
              [(ngModel)]="favName"
              required>
            <br/>
            <div class="form-element">
              <label>{{ 'favorite.categories.label' | translate }}</label><br/>
              <ng-select appendTo="body"
                         [multiple]="true"
                         [maxSelectedItems]="3"
                         dropdownPosition="bottom"
                         [readonly]="false"
                         [addTag]="true"
                         addTagText="{{'favorite.categories.add' | translate}}"
                         notFoundText="{{'favorite.categories.none' | translate}}"
                         placeholder="{{'favorite.categories.choose' | translate}}"
                         [items]="categories"
                         class="favorite-category-select"
                         formControlName="categories">
              </ng-select>
              <p role="alert" class="errors-text" *ngIf="(favoriteCreateForm.controls.categories?.invalid)">
                {{ 'favorite.categories.maxLength' | translate }}
              </p>
            </div>
            <label for="notification">{{ 'favorite.notification.label' | translate }}:</label>
            <select class="form-control" id="notification" formControlName="notification">
              <option *ngFor="let notification of notificationList" [selected]="notification === favNotification"
                      [value]="notification"
                      [attr.aria-label]="'favorite.notification.frequency.' + notification | translate">{{ 'favorite.notification.frequency.' + notification | translate }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <ng-container *ngIf="isSending; then showspinner else hidespinner"></ng-container>

          <ng-template #hidespinner>
            <button type="button" class="btn btn-secondary" (click)="close()">{{ 'global.cancel' | translate }}
            </button>
            <button type="submit" class="btn btn-primary" [disabled]="favoriteCreateForm.controls.categories?.invalid">
              {{ 'global.save' | translate }}
            </button>
          </ng-template>

          <ng-template #showspinner>
            <button type="submit" class="btn btn-primary" [disabled]="favoriteCreateForm.pristine || this.isSending">
              <i class="fa fa-spinner fa-pulse fa-fw"></i>
            </button>
          </ng-template>
        </div>
      </form>
    </div>
  </div>
</div>
