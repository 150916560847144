export interface TreiberJSON {
  id: number;
  bezeichnung: string;
}

export class Treiber {
  id: number;
  bezeichnung: string;

  static fromJSON(json: string | object): Treiber {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const land = Object.create(Treiber.prototype);
    return Object.assign(land, object);
  }

  toJSON(): TreiberJSON {
    return Object.assign({}, this);
  }
}

export interface TreiberAdminJSON {
  id?: number;
  bezeichnungDe: string;
  bezeichnungEn: string;
  bezeichnungFr: string;
  bezeichnungIt: string;
  deaktiviert: boolean;
  mutDate?: Date;
  mutUser?: string;
}

export class TreiberAdmin {
  id?: number;
  bezeichnungDe: string;
  bezeichnungEn: string;
  bezeichnungFr: string;
  bezeichnungIt: string;
  deaktiviert: boolean;
  mutDate?: Date;
  mutUser?: string;

  static fromJSON(json: string | object): TreiberAdmin {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const land = Object.create(TreiberAdmin.prototype);
    return Object.assign(land, object);
  }

  toJSON(): TreiberAdminJSON {
    return Object.assign({}, this);
  }
}
