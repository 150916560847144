import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-thema-show-all',
  template: '<a *ngIf="collapsed" class="thema-show-all-links" (click)="showAll()">{{ this.showAllText }}</a>' +
    '<ng-container *ngIf="!collapsed"><ng-content></ng-content></ng-container>'
})
export class ThemaShowAllComponent implements OnInit {

  showAllText: string;
  collapsed: boolean;

  constructor(private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.collapsed = true;
    this.showAllText = this.translateService.instant('global.show.all');
  }

  showAll() {
    this.collapsed = false;
  }
}

