import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from '@app/core/config/settings.service';
import { Comment } from '@app/shared/classes/comment';
import { CommentCreateComponent } from '@app/shared/comment/comment-create/comment-create.component';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html'
})
export class CommentComponent implements OnInit {

  @Input()
  comments: Comment[];
  @Input()
  refGuid: string;
  @Input()
  reference: string;
  @ViewChild(CommentCreateComponent) child: CommentCreateComponent;
  newCommentEnabled = false;

  constructor(private settingsService: SettingsService) {
  }

  ngOnInit(): void {
    const set = this.settingsService.settings().find(el => el.key === 'gui.comment.' + this.reference + '.enabled');
    if (set.value === '1') {
      this.newCommentEnabled = true;
    }
  }

  openCreateComment() {
    this.child.open();
  }

  updateCreateComment(comments) {
    this.comments = comments;
  }
}
