<div class="container admin">
  <!-- Falls die API-Abfrage einen Fehler liefert, wird der Fehlertext angezeigt -->
  <ng-template [ngIf]="setListError" [ngIfElse]="noError">
    <div class="row">
      <p style="font-weight: bold; color: red;">Fehler bei der Datenabfrage!</p>
    </div>
  </ng-template>
  <ng-template #noError>
    <app-adura-admin-stammdaten-publikation-new (reloadEntries)="reloadEntries()"></app-adura-admin-stammdaten-publikation-new>
    <div class="row admin">
      <hr>
      <p><b>Bestehende Publikationen</b></p>
    </div>
    <div class="row admin">
      <div class="col-md-4 clear-padding-left"><p class="admin"><b>Publikationstitel</b></p></div>
      <div class="col-md-2"><p class="admin"><b>Publikationsabkürzung</b></p></div>
      <div class="col-md-1"><p class="admin"><b>Deaktiviert</b></p></div>
      <div class="col-md-3"><p class="admin"><b>Letzte Änderung</b></p></div>
    </div>
    <div class="row admin" *ngFor="let set of publikation; let i = index"
         app-adura-admin-stammdaten-publikation-entry
         [set]="set"
         (reloadEntries)="reloadEntries()"
         (deleted)="onPublikationDeleted(i)">
    </div>
  </ng-template>
</div>
