<div class="item-content-name">
  <dl class="dl-horizontal">
    <dt class="dataset-label">{{ 'date.create' | translate }}</dt>
    <dd class="dataset-details">{{ thema.creationDate | date: 'dd.MM.yyyy' }}</dd>
    <dt class="dataset-label">{{ 'date.mod' | translate }}</dt>
    <dd class="dataset-details">{{ thema.modificationDate | date: 'dd.MM.yyyy' }}</dd>
    <dt class="dataset-label">{{ 'oberthema.plural' | translate }}</dt>
    <dd class="dataset-details">
      <ng-container *ngFor="let ob of this.thema.oberthemen">
        <ul class="themainfo-list">
          <li>
            <a href="/search?topic={{ ob.id }}" target="_blank">
              {{ ob.name }}
            </a>
          </li>
        </ul>
      </ng-container>
    </dd>
    <dt class="dataset-label">{{ 'domain.plural' | translate }}</dt>
    <dd class="dataset-details">
      <ng-container *ngFor="let dom of this.thema.domains">
        <ul class="themainfo-list">
          <li>
            <a href="/search?domain={{ dom.id }}" target="_blank">
              {{ dom.name }}
            </a>
          </li>
        </ul>
      </ng-container>
    </dd>
    <ng-template [appHasRole]="['ROLE_BLV']">
      <dt class="dataset-label">{{ 'actanova.link' | translate }}</dt>
      <dd class="dataset-details">
        <a class="actanova-link" href="{{ this.thema.dossierLink }}" target="_blank">{{ 'subject.awisa' | translate }}</a>
      </dd>
    </ng-template>
  </dl>
</div>
