import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AduraService } from '@app/shared/services/adura.service';
import { TranslateService } from '@ngx-translate/core';
import { Steckbrief } from '@app/shared/classes/adura/steckbrief';
import { Beurteilung, BeurteilungType } from '@app/shared/classes/adura/beurteilung';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-adura-beurteilung-edit',
  templateUrl: './adura-beurteilung-edit.component.html'
})
export class AduraBeurteilungEditComponent implements AfterViewInit, OnInit {
  @ViewChild('beurteilungEditModal', { static: false }) beurteilungEditModal: ElementRef;
  @Output() updateAddBeurteilung = new EventEmitter<Beurteilung>();
  @Input() steckbrief: Steckbrief;

  private elm: HTMLElement;
  beurteilungEditForm: UntypedFormGroup;

  beurteilungToEdit: Beurteilung;
  beurteilungType = Object.values(BeurteilungType);

  selectedDate: dayjs.Dayjs;
  date: string;
  datePickerConfig = {
    format: 'DD.MM.YYYY',
    locale: 'de'
  };

  tinyConfigEnterAllowed: any;

  remainingCharactersMap = {
    text: ''
  };

  maxCharsMap = {
    text: 4000
  };

  constructor(private aduraService: AduraService,
              private translateService: TranslateService,
              private router: Router,
              private formBuilder: UntypedFormBuilder) {
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.elm = this.beurteilungEditModal.nativeElement as HTMLElement;
  }

  ngOnInit(): void {
    this.tinyConfigEnterAllowed = {
      menubar: false,
      plugins: 'lists link',
      toolbar: 'bold italic bullist link',
      valid_elements: 'strong,em,p,a[href|target=_blank],ul,li,br',
      default_link_target: '_blank',
      target_list: false,
      entity_encoding: 'raw'
    };
    this.datePickerConfig.locale = this.translateService.currentLang;
  }

  private initForm() {
    this.beurteilungEditForm = this.formBuilder.group({
      type: ['', Validators.required],
      date: ['', Validators.required],
      text: ['', Validators.required]
    });
  }

  private updateForm() {
    const beurteilungDate = this.beurteilungToEdit.date ? dayjs(this.beurteilungToEdit.date) : null;
    this.beurteilungEditForm.reset({
      type: this.beurteilungToEdit.type,
      date: beurteilungDate,
      text: this.beurteilungToEdit.text
    });
  }

  onSave() {
    const formValues = this.beurteilungEditForm.value;
    this.beurteilungToEdit.type = formValues.type;
    this.beurteilungToEdit.date = new Date(formValues.date);
    this.beurteilungToEdit.text = formValues.text;
    this.updateAddBeurteilung.emit(this.beurteilungToEdit);
    this.close();
  }

  close() {
    this.beurteilungEditForm.reset({}, { emitEvent: false });
    this.elm.classList.remove('show');
    setTimeout(() => {
      this.elm.style.width = '0';
    }, 75);
  }

  open(beurteilung: Beurteilung): void {
    this.elm.classList.add('show');
    this.elm.style.width = '100vw';
    this.beurteilungToEdit = beurteilung;
    this.updateForm();
  }

  showSuccessAlert(translateString: string) {
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000
          });
        });
  }

  getDateFromDatePicker(event: any) {
    this.selectedDate = dayjs(event.target.value.split('.').reverse().join('-'));

  }

  getDate() {
    if (this.selectedDate) {
      this.date = this.selectedDate.format('YYYY-MM-DD');
    }
  }

  get dateForm() {
    return this.beurteilungEditForm.get('date');
  }

  updateRemainingCharacters(fieldName: string, event: any) {
    const editor = event.editor;
    const maxChars = this.maxCharsMap[fieldName];
    let editorText = this.beurteilungEditForm.get(fieldName).value;

    if (maxChars < editorText?.length) {
      editorText = editorText.slice(0, maxChars);
      this.beurteilungEditForm.get(fieldName).setValue(editorText);
      // https://stackoverflow.com/a/19836226
      editor.selection.select(editor.getBody(), true);
      editor.selection.collapse(false);
    }

    this.remainingCharactersMap[fieldName] = maxChars - (editorText?.length || 0);
  }
}
