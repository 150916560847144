import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { UrlService } from '@app/core/routing/navigation/url.service';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  previousUrl: string = null;
  currentUrl: string = null;

  constructor(private authenticationService: AuthenticationService,
              private translate: TranslateService,
              private router: Router,
              private urlService: UrlService) {
    translate.addLangs(['de', 'fr', 'it', 'en']);
    translate.setDefaultLang('de');

    const user = authenticationService.getUser();
    if (user) {
      translate.use(user.language);
    }
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.urlService.setPreviousUrl(this.previousUrl);
    });
  }
}
