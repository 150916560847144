<div class="row admin">
  <p class="admin"><b>Neue Einstellung</b></p>
</div>
<div class="row admin">
    <div class="col-md-2 clear-padding-left"><p class="admin clear-margin-top"><b>Key</b></p></div>
    <div class="col-md-4"><p class="admin clear-margin-top"><b>Value</b></p></div>
    <div class="col-md-4"><p class="admin clear-margin-top"><b>Description</b></p></div>
</div>
<div class="row admin">
  <form [formGroup]="newSettingForm" (ngSubmit)="onSubmit()">
    <div class="col-md-2 clear-padding-left">
      <input id="key_new" class="admin-settings" formControlName="key_new" maxlength="255">
    </div>

    <div class="col-md-4">
      <textarea id="value_new" class="admin-settings" formControlName="value_new"
                (keyup)="autogrow('value_new')" maxlength="4000"></textarea>
    </div>

    <div class="col-md-4">
      <textarea id="description_new" class="admin-settings" formControlName="description_new"
                (keyup)="autogrow('description_new')" maxlength="255"></textarea>
    </div>

    <div class="col-md-2">
      <button class="btn btn-sm admin-settings" type="submit" [disabled]="newSettingForm.invalid">
        <i class="fa fa-check"></i>
      </button>

      <button class="btn btn-sm admin-settings" type="button" title="Abbrechen" (click)="onReset()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </form>
</div>
