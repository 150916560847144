<div #weitereInfoEditModal class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="close()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h3 class="modal-title">{{ 'adura.furtherInformation.add' | translate }}</h3>
      </div>
      <form [formGroup]="weitereInfoEditForm">
        <div class="modal-body">
          <div class="form-group">
            <label for="info">{{ 'adura.furtherInformation.text' | translate }}:</label>
            <span class="length-counter-modal">
              {{ (255 - weitereInfoEditForm.get('info').value?.length) + ' / 255' }}
            </span>
            <input
              type="text"
              class="form-control"
              formControlName="info"
              id="info"
              maxlength="255"
              autocomplete="off">
          </div>
          <div class="form-group">
            <label for="link">{{ 'adura.furtherInformation.link' | translate }}:</label>
            <span class="length-counter-modal">
              {{ (255 - weitereInfoEditForm.get('link').value?.length) + ' / 255' }}
            </span>
            <input
              type="text"
              class="form-control"
              formControlName="link"
              id="link"
              maxlength="255"
              autocomplete="off">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" (click)="close()">
            {{ 'global.cancel' | translate }}
          </button>
          <button type="button" class="btn btn-sm btn-space btn-primary" (click)="onSave()"
                  [disabled]="weitereInfoEditForm.invalid" *ngIf="weitereInfoToEdit">
            {{ 'global.save' | translate }}
          </button>
          <button type="submit" class="btn btn-sm btn-space btn-primary"
                  [disabled]="weitereInfoEditForm.invalid" *ngIf="!weitereInfoToEdit">
            {{ 'global.add' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
