import {Tag} from './tag';
import {Comment} from './comment';

export interface LinkJSON {
  guid: string;
  name: string;
  description: string;
  descriptionDe: string;
  descriptionEn: string;
  descriptionFr: string;
  descriptionIt: string;
  url: string;
  dossierLink: string;
  creationDate: string;
  modificationDate: string;
  roles: string[];
  comments: Comment[];
}

export class Link {
  guid: string;
  name: string;
  description: string;
  descriptionDe: string;
  descriptionEn: string;
  descriptionFr: string;
  descriptionIt: string;
  url: string;
  tags: Tag[];
  dossierLink: string;
  creationDate: string;
  modificationDate: string;
  roles: string[];
  comments: Comment[];

  static fromJSON(json: string | object): Link {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const link = Object.create(Link.prototype);
    return Object.assign(link, json);
  }

  toJSON(): LinkJSON {
    return Object.assign({}, this);
  }
}

export interface LinkSave {
  name: string;
  url: string;
  descriptionDe: string;
  descriptionEn: string;
  descriptionFr: string;
  descriptionIt: string;
  tags: string[];
  roles: string[];
  einbringerGuid: string;
}
