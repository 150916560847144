<div class="container admin">
  <!-- Falls die API-Abfrage einen Fehler liefert, wird der Fehlertext angezeigt -->
  <ng-template [ngIf]="setListError" [ngIfElse]="noError">
    <div class="row">
      <p style="font-weight: bold; color: red;">Fehler bei der Datenabfrage!</p>
    </div>
  </ng-template>
  <ng-template #noError>
    <app-adura-admin-stammdaten-gefahr-new (reloadEntries)="reloadEntries()"></app-adura-admin-stammdaten-gefahr-new>
    <div class="row admin">
      <hr>
      <p><b>Bestehende Gefahren</b></p>
    </div>
    <div class="row admin">
      <div class="col-md-2 clear-padding-left"><p class="admin"><b>Bezeichnung EN</b></p></div>
      <div class="col-md-2"><p class="admin"><b>Bezeichnung DE</b></p></div>
      <div class="col-md-2"><p class="admin"><b>Bezeichnung FR</b></p></div>
      <div class="col-md-2"><p class="admin"><b>Bezeichnung IT</b></p></div>
      <div class="col-md-1"><p class="admin"><b>Deaktiviert</b></p></div>
      <div class="col-md-2"></div>
    </div>
    <div class="row admin" *ngFor="let set of gefahr; let i = index"
         app-adura-admin-stammdaten-gefahr-entry
         [set]="set"
         (reloadEntries)="reloadEntries()"
         (deleted)="onGefahrDeleted(i)">
    </div>
  </ng-template>
</div>
