import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AduraFacet } from '@app/shared/classes/adura/adura-facet';
import { AduraService } from '@app/shared/services/adura.service';

@Component({
  selector: 'app-adura-treiber-side',
  templateUrl: './adura-treiber-side.component.html'
})
export class AduraTreiberSideComponent implements OnChanges {
  @Input() treiber: AduraFacet[] = [];
  @Input() activeTreiber: string[] = [];
  @Output() treiberChanged = new EventEmitter<string[]>();
  @Output() searchTermChanged = new EventEmitter<string>();

  searchTerm: string;
  treiberCopy: AduraFacet[];
  treiberTop10: AduraFacet[];
  isShowMoreVal = false;

  constructor(private aduraService: AduraService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.copyTreiber();
    this.calcTopTreiber();
    this.clearInput();
  }

  changeTreiber(id: string) {
    if (this.searchTerm) {
      this.searchTermChanged.emit(this.searchTerm);
    }
    this.clearInput();

    const newActiveTreiber = this.activeTreiber.slice();

    const index = newActiveTreiber.indexOf(id);
    if (index > -1) {
      newActiveTreiber.splice(index, 1);
    } else {
      newActiveTreiber.push(id);
    }

    this.treiberChanged.emit(newActiveTreiber);
  }

  isTreiberActive(treiber: string) {
    return this.activeTreiber.find(res => res === treiber);
  }

  calcTopTreiber() {
    if (this.treiberCopy !== undefined && this.treiberCopy !== null) {
      this.treiberTop10 = this.treiberCopy.slice(0, 10);
    } else {
      this.treiberTop10 = [];
    }
  }

  get top10Length() {
    return (this.treiberTop10) ? this.treiberTop10.length : null;
  }

  isShowMoreTreiber() {
    return this.isShowMoreVal;
  }

  changeShowMoreTreiber() {
    this.isShowMoreVal = !this.isShowMoreVal;
  }

  treiberListToShow() {
    if (this.isShowMoreVal) {
      return this.treiberCopy;
    } else {
      return this.treiberTop10;
    }
  }

  copyTreiber() {
    this.treiberCopy = this.treiber;
  }

  searchTreiber(): void {

    if (this.searchTerm) {
      this.aduraService.filterFacetsBySearchTerm(this.searchTerm.toLowerCase(), this.treiber)
        .subscribe({
          next: result => this.treiberCopy = result.length > 0 ? result : [],
          error: error => console.log(error)
        });
    } else {
      this.copyTreiber();
    }
    this.calcTopTreiber();
  }

  clearInput(): void {
    this.searchTerm = null;
    this.searchTreiber();
  }
}
