<ng-container *ngIf="newDocumentEnabled; else disabledNewDoc">
  <header class="page-header">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <button appBackButton type="button" class="btn btn-sm btn-primary back-button"
                  title="{{ 'global.back' | translate }}"
                  [attr.aria-label]="'global.back' | translate">
            <i class="fa fa-arrow-left"></i>
          </button>
        </div>
      </div>
    </div>
  </header>

  <section class="container information">
    <h1>{{ 'document.new' | translate }}</h1>
    <form [formGroup]="documentForm" (ngSubmit)="createDocument()">
      <p *ngIf="error" class="alert alert-danger" role="alert"> {{ 'global.error' | translate}}</p>
      <div class="form-group">
        <div class="form-element">
          <label for="name">{{ 'global.name' | translate }}*:</label>
          <input
            type="text"
            class="form-control"
            id="name"
            [ngClass]="(nameForm.invalid && nameForm.dirty) ? 'errors-input':''"
            formControlName="name"
            maxlength="400"
            autocomplete="off">
        </div>
        <p role="alert" class="errors-text" *ngIf="(nameForm.invalid && nameForm.dirty)">
          {{ 'global.validation.name' | translate }}
        </p>
        <div class="form-element">
          <label for="language">{{ 'language.label' | translate }}*:</label>
          <select class="form-control" id="language"
                  formControlName="language">
            <ng-container *ngFor="let languageItem of this.languageList">
              <option [selected]="languageItem.selected" value="{{ languageItem.name }}"
                      [attr.aria-label]="languageItem.messageProperty | translate">{{ languageItem.messageProperty | translate }}</option>
            </ng-container>
          </select>
        </div>
        <div class="form-element">
          <label for="language">{{ 'document.upload' | translate }}*:</label><br/>
          <div class="col-sm-12 clear-padding-left">
            <ng-container *ngIf="fileForm.value">
              <app-upload-entry [filename]="fileForm.value.name" (deleteFile)="deleteFileUpload()"></app-upload-entry>
            </ng-container>
          </div>
          <div class="col-sm-12 clear-padding-left clear-padding-right" *ngIf="dropzoneConfig && !fileForm.value">
            <dropzone class="form-element"
                      [config]="dropzoneConfig"
                      (addedFile)="dropzoneFileAdded($event)"
                      (error)="dropzoneFileError($event)">
            </dropzone>
            <p role="alert" class="errors-text" *ngIf="(fileForm.invalid && fileForm.dirty && fileForm.errors.required)">
              {{ 'global.validation.file' | translate }}
            </p>
            <p role="alert" class="errors-text" *ngIf="(fileForm.invalid && fileForm.errors.max)">
              {{ 'global.validation.fileSize' | translate }}
            </p>
            <p role="alert" class="errors-text" *ngFor="let dzErr of dropzoneError" [innerText]="dzErr"></p>
          </div>
        </div>
        <div class="form-element">
          <label>{{ 'event.shortDescription' | translate }}:</label>
          <app-tabs class="event-desc-tabs">
            <app-tab *ngFor="let language of languageList" role="tab"
                     tabId="content" tabTitle="{{ language.name }}"
                     contentClass="tabmenu-content">
        <textarea
          class="form-control form-element"
          id="desc_{{ language.name }}"
          formControlName="desc_{{ language.name }}"
          maxlength="1530"
          autocomplete="off"
          rows="3"></textarea>
            </app-tab>
          </app-tabs>
        </div>
        <div class="form-element">
          <label for="changeComment">{{ 'document.commentBlv' | translate }}:</label>
          <input
            type="text"
            class="form-control"
            id="changeComment"
            formControlName="changeComment"
            maxlength="255"
            autocomplete="off">
        </div>
        <div class="form-element">
          <label>{{ 'tag.plural' | translate }}*:</label><br/>
          <ng-select appendTo="body"
                     [multiple]="true"
                     dropdownPosition="bottom"
                     [readonly]="false"
                     formControlName="tags">
            <ng-option *ngFor="let tag of tags" [value]="tag.guid">{{tag.name}}</ng-option>
          </ng-select>
          <p role="alert" class="errors-text" *ngIf="(tagsForm.invalid && tagsForm.dirty)">
            {{ 'global.validation.tags' | translate }}
          </p>
        </div>
        <div class="form-element">
          <label>{{ 'author.label' | translate }}*:</label><br/>
          <label class="new-document-author" [attr.aria-checked]="">
            <input type="radio"
                   value="{{this.user.guid}}"
                   formControlName="author"
                   role="radio">
            {{ 'author.me' | translate }}
          </label>
          <br/>
          <label class="new-document-author" [attr.aria-checked]="">
            <input type="radio"
                   value=""
                   formControlName="author"
                   role="radio">
            {{ 'author.other' | translate }}
          </label>
          <div *ngIf="isOtherAuthor()">
            <input type="text"
                   placeholder="Hans Müller (@blv.admin.ch)"
                   class="form-control"
                   [ngClass]="(documentForm.controls.fremdAuthor.invalid) ? 'errors-input':''"
                   formControlName="fremdAuthor" maxlength="255" autocomplete="off"/>
            <p role="alert" class="errors-text" *ngIf="documentForm.controls.fremdAuthor.invalid">
              {{ 'global.validation.otherAuthor' | translate }}
            </p>
          </div>
        </div>
        <div class="form-element">
          <label>{{ 'global.permission' | translate }}*:
            <div class="tooltip-awisa">
              <i class="fa fa-info-circle"></i>
              <span class="tooltiptext-awisa"><div [innerHTML]="html"></div></span>
            </div>
          </label><br/>
          <ng-container *ngFor="let role of roles">
            <input type="checkbox" class="filter-checkbox sr-only" id="{{role.name}}"
                   name="{{role.identifier}}"
                   (change)="chooseRole($event)"
                   role="checkbox" [checked]="isRoleActive(role.identifier)">
            <label class="filter-checkbox-label" for="{{role.name}}"
                   role="checkbox"
                   [attr.aria-checked]="(isRoleActive(role.identifier))?'true':'false'">
              {{ role.name }}
            </label>
          </ng-container>
          <p role="alert" class="errors-text" *ngIf="(rolesForm.invalid && rolesForm.errors.min)">
            {{ 'global.validation.role' | translate }}
          </p>
        </div>
        <div class="form-element">
          <label for="status">{{ 'document.status.label' | translate }}:</label>
          <select class="form-control" id="status" formControlName="status">
            <option *ngFor="let status of this.statusList" [value]="status"
                    [attr.aria-label]="'document.status.' + status | translate">{{ 'document.status.' + status | translate }}
            </option>
          </select>
        </div>
        <div class="form-element">
          <label for="documentDate">{{ 'date.event' | translate }}:</label><br/>
          <dp-date-picker aria-hidden="true" placeholder="{{datePickerConfig.format}}" theme="dp-material"
                          class="form-control event-datepicker"
                          [ngClass]="(docDateForm.invalid && docDateForm.touched) ? 'errors-input':''"
                          formControlName="documentDate"
                          [(ngModel)]="selectedDocumentDate" [config]="datePickerConfig"
                          (onSelect)="getDocumentDate()">
          </dp-date-picker>
          <input (keyup.enter)="getDocumentDateFromDatePicker($event)"
                 id="documentDate"
                 placeholder="{{datePickerConfig.format}}" type="text"
                 tabindex="-1" class="sr-only" [attr.aria-label]="'date.event' | translate">
          <p role="alert" class="errors-text" *ngIf="(docDateForm.invalid && docDateForm.touched)">
            {{ 'global.validation.date' | translate }}
          </p>
        </div>
        <div class="form-element">
          <ng-container *ngIf="isSending; then showspinner else hidespinner"></ng-container>

          <ng-template #hidespinner>
            <button type="submit" class="btn btn-primary">
              {{ 'global.create' | translate }}
            </button>
          </ng-template>

          <ng-template #showspinner>
            <button type="submit" class="btn btn-primary" [disabled]="isSending">
              <i class="fa fa-spinner fa-pulse fa-fw"></i>
            </button>
          </ng-template>
        </div>
      </div>
    </form>
  </section>
</ng-container>
<ng-template #disabledNewDoc>
  <div class="row">
    <div class="errors">
      <h1>{{ 'settings.disabledNewDoc' | translate }}</h1>
    </div>
  </div>
</ng-template>
