import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { AduraService } from '@app/shared/services/adura.service';
import { Meldung } from '@app/shared/classes/adura/meldung';
import { SeismoInfo } from '@app/shared/classes/adura/seismoInfo';

@Component({
  selector: 'app-adura-meldung',
  templateUrl: './adura-meldung-component.html'
})
export class AduraMeldungComponent implements OnInit {
  meldung: Meldung;
  seismoInfo: SeismoInfo;
  error = false;

  @Input() papierkorbView = false;

  constructor(private aduraService: AduraService,
              private authenticationService: AuthenticationService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    if (!this.papierkorbView) {
      const meldungCode = this.route.snapshot.paramMap.get('code');
      this.aduraService.getMeldung(meldungCode)
        .subscribe({
          next: meldung => {
            this.meldung = meldung;
            this.meldung.bereiche?.sort((a, b) =>
              a.bezeichnung.toUpperCase() > b.bezeichnung.toUpperCase() ? 1 : -1
            );
            if (this.meldung.seismoPublikation) {
              this.loadSeismoInfoList();
            }
          },
          error: error => {
            this.error = true;
            console.error(error);
          }
        });
    }
  }

  isEditable(): boolean {
    return (this.authenticationService.hasRole('AWISA_FRESIL') || this.meldung?.status?.id === 10);
  }

  get id() {
    return (this.meldung && this.meldung.id) ? this.meldung.id : null;
  }

  get titel() {
    return '<i class="fa fa-bullhorn"></i>&nbsp;&nbsp;' + this.meldung.titel;
  }

  get basisQuelle() {
    const basisQuelle = this.meldung.publikationen.find(it => it.type === 10);
    return (basisQuelle) ? basisQuelle : null;
  }

  get originalPublikationen() {
    const originalPublikation = this.meldung.publikationen.filter(it => it.type === 20);
    return (originalPublikation) ? originalPublikation : null;
  }

  get weitereInfos() {
    const weitereInfos = this.meldung.publikationen.filter(it => it.type === 30);
    return (weitereInfos) ? weitereInfos : null;
  }

  loadSeismoInfoList() {
    this.aduraService.getSeismoInfoAusgabeList(this.meldung.code).subscribe(seismoInfo => {
      this.seismoInfo = seismoInfo.find(it => it.selected);
    });
  }

  editMeldung() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/adura/notification/' + this.meldung.code + '/edit'])
    );

    window.open(url, '_blank');
  }

  initPapierkorbView(meldung: Meldung) {
    this.meldung = meldung;
  }
}
