import { Component, Input, OnInit } from '@angular/core';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';

@Component({
  selector: 'app-thema-img',
  template: '<img class="responsive" src="{{ this.imgData }}"/>'
})
export class ThemaImgComponent implements OnInit {

  @Input() imgId: string;
  imgData: string;

  constructor(private awisaFoService: AwisaFoService) {
  }

  ngOnInit(): void {
    this.awisaFoService.getThemaPicture(this.imgId)
      .subscribe({
        next: (result) => this.imgData = result,
        error: (error) => console.log(error)
      });
  }
}

