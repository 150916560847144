import {IdName} from './id-name';

export interface DomainJSON {
  id?: string;
  guid: string;
  name: string;
  themen: IdName[];
  oberthemen: IdName[];
}

export class Domain {
  id?: string;
  guid: string;
  name: string;
  count: number;
  themen: IdName[];
  oberthemen: IdName[];

  static fromJSON(json: string | object): Domain {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const domain = Object.create(Domain.prototype);
    return Object.assign(domain, json);
  }

  toJSON(): DomainJSON {
    return Object.assign({}, this);
  }
}
