import { Injectable } from '@angular/core';
import { Configuration } from '@app/core/config/configuration';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private loaded = false;
  private configuration: Configuration;

  public getConfig(): Configuration {
    return this.configuration;
  }

  // the return value (Promise) of this method is used as an APP_INITIALIZER,
  // so the application's initialization will not complete until the Promise resolves.
  public load(): Promise<Configuration> {
    if (this.loaded) {
      return Promise.resolve(this.configuration);
    }
    // TODO: Strategie betreffend Caching?
    return fetch(`config/config.json`)
      .then(response => response.json())
      .then(config => {
        this.configuration = config as Configuration;
        console.log(`got configuration: ${JSON.stringify(this.configuration)}`);
        this.loaded = true;
        return this.configuration;
      })
      .catch(error => {
        console.log(`error loading configuration: ${JSON.stringify(error)}`);
        return null;
      });
  }

  public isTestEnvironment(): boolean {
    return this.configuration?.stage === 'EN' || this.configuration?.stage === 'IN';
  }

  public getStage(): 'EN'|'IN'|'VP'|'PR' {
    const stage = this.configuration?.stage;
    if (stage === 'EN' || stage === 'IN' || stage === 'VP' || stage === 'PR') {
      return stage;
    }
    // Wenn wir uns nicht sicher sind, immer von PR ausgehen
    return 'PR';
  }
}
