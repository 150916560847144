import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AduraService } from '@app/shared/services/adura.service';
import { TranslateService } from '@ngx-translate/core';
import { Matrix } from '@app/shared/classes/adura/matrix';
import { Meldung } from '@app/shared/classes/adura/meldung';
import { Steckbrief } from '@app/shared/classes/adura/steckbrief';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-adura-matrix-edit',
  templateUrl: './adura-matrix-edit.component.html'
})
export class AduraMatrixEditComponent implements AfterViewInit, OnChanges {
  @ViewChild('matrixEditModal', {static: false}) matrixEditModal: ElementRef;
  @Output() updateAddMatrix = new EventEmitter<Matrix>();
  @Input() meldung: Meldung;
  @Input() steckbrief: Steckbrief;

  private elm: HTMLElement;
  matrixEditForm: UntypedFormGroup;

  matrixList: Matrix[];
  matrixToEdit: Matrix;

  constructor(private aduraService: AduraService,
              private translateService: TranslateService,
              private router: Router,
              private formBuilder: UntypedFormBuilder) {
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.elm = this.matrixEditModal.nativeElement as HTMLElement;
  }

  // @Input this.meldung ist erst innerhalb OnChanges nicht null
  ngOnChanges(changes: SimpleChanges): void {
    this.aduraService.listMatrix()
      .subscribe(
        (result) => {
          this.matrixList = result;

          this.meldung?.matrix?.forEach(activeMatrix =>
            this.matrixList.find(matrix => matrix.id === activeMatrix.id).disabled = true
          );

          this.steckbrief?.matrix?.forEach(activeMatrix =>
            this.matrixList.find(matrix => matrix.id === activeMatrix.id).disabled = true
          );

          // "Nicht spezifiziert" kann mehrmals angehängt werden
          this.matrixList.find(matrix => matrix.sortierung === '00').disabled = false;
      });
  }

  private initForm() {
    this.matrixEditForm = this.formBuilder.group({
      matrix: [[], Validators.required],
      ergaenzung: ['']
    });
  }

  private updateForm() {
    this.matrixEditForm.reset({
      matrix: this.matrixToEdit,
      ergaenzung: this.matrixToEdit.ergaenzung
    });
  }

  onSave() {
    const matrix = this.matrixToEdit || new Matrix();
    const formValues = this.matrixEditForm.value;
    matrix.id = formValues.matrix.id;
    matrix.bezeichnung = formValues.matrix.bezeichnung;
    matrix.sortierung = formValues.matrix.sortierung;
    matrix.ergaenzung = formValues.ergaenzung;
    if (matrix.sortierung !== '00') {
      this.matrixList.find( it => it.id === matrix.id ).disabled = true;
      this.refreshDisabledStatus();
    }
    this.updateAddMatrix.emit(matrix);
    this.close();
  }

  close() {
    this.matrixEditForm.reset({}, { emitEvent: false });
    this.elm.classList.remove('show');
    setTimeout(() => {
      this.elm.style.width = '0';
    }, 75);
  }

  open(matrix?: Matrix): void {
    this.elm.classList.add('show');
    this.elm.style.width = '100vw';
    this.matrixToEdit = matrix || new Matrix();
    if (matrix) {
      this.matrixList.find( it => it.id === matrix.id ).disabled = false;
      this.refreshDisabledStatus();
    }
    this.updateForm();
  }

  showSuccessAlert(translateString: string) {
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000
          });
        });
  }

  private refreshDisabledStatus(): void {
    // TODO Gibt es einen besseren Weg um den Disabled-Status im GUI zu aktualisieren?
    this.matrixList = [...this.matrixList];
  }
}
