import { Tag } from './tag';
import { Thema } from '@app/shared/classes/thema';
import { Oberthema } from '@app/shared/classes/oberthema';
import { Domain } from '@app/shared/classes/domain';
import { Comment } from '@app/shared/classes/comment';

export interface DocumentJSON {
  guid: string;
  name: string;
  description: string;
  author: string;
  language: string;
  format: string;
  versions: DocumentVersion[];
  translations: DocumentTranslation[];
  tags: Tag[];
  dossierLink: string;
  originalFileLink: string;
  originalGuid: string;
  comments: Comment[];
  docDate: string;
  creationDate: string;
  modificationDate: string;
  themen: Thema[];
  oberthemen: Oberthema[];
  domains: Domain[];
}

export class Document {
  guid: string;
  name: string;
  description: string;
  author: string;
  language: string;
  format: string;
  versions: DocumentVersion[];
  translations: DocumentTranslation[];
  tags: Tag[];
  dossierLink: string;
  originalFileLink: string;
  originalGuid: string;
  comments: Comment[];
  docDate: string;
  creationDate: string;
  modificationDate: string;
  themen: Thema[];
  oberthemen: Oberthema[];
  domains: Domain[];

  static fromJSON(json: string | object): Document {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const document = Object.create(Document.prototype);
    return Object.assign(document, json);
  }

  toJSON(): DocumentJSON {
    return Object.assign({}, this);
  }
}

export interface DocumentVersionJSON {
  guid: string;
  name: string;
  author: string;
  changeComment: string;
  format: string;
  size: number;
  status: string;
  creationDate: string;
  publishedFrom: string;
  publishedTo: string;
  canPreview: boolean;
}

export class DocumentVersion {
  guid: string;
  name: string;
  author: string;
  changeComment: string;
  format: string;
  size: number;
  status: string;
  creationDate: string;
  publishedFrom: string;
  publishedTo: string;
  canPreview: boolean;

  static fromJSON(json: string | object): DocumentVersion {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const document = Object.create(DocumentVersion.prototype);
    return Object.assign(document, json);
  }

  toJSON(): DocumentVersionJSON {
    return Object.assign({}, this);
  }
}

export interface DocumentSave {
  name: string;
  language: string;
  file: File;
  descriptionDe: string;
  descriptionEn: string;
  descriptionFr: string;
  descriptionIt: string;
  changeComment: string;
  tags: string;
  author: string;
  fremdAuthor: string;
  status: string;
  roles: string;
  documentDate: string;
}

export interface DocumentTranslationJSON {
  guid: string;
  name: string;
  language: string;
  changeComment: string;
}

export class DocumentTranslation {
  guid: string;
  name: string;
  language: string;
  changeComment: string;

  static fromJSON(json: string | object): DocumentTranslation {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const document = Object.create(DocumentTranslation.prototype);
    return Object.assign(document, json);
  }

  toJSON(): DocumentTranslationJSON {
    return Object.assign({}, this);
  }
}
