export interface LogJSON {
  parentCode: string;
  label: LogLabel;
  comment: string;
  timestamp: Date;
  person: string;
}

export class Log {
  parentCode: string;
  label: LogLabel;
  comment: string;
  timestamp: Date;
  person: string;

  static fromJSON(json: string | object): Log {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const log = Object.create(Log.prototype);
    return Object.assign(log, object);
  }

  toJSON(): LogJSON {
    return Object.assign({}, this);
  }
}

export interface LogLabelJSON {
  id: number;
  messageProperty: string;
}

export class LogLabel {
  id: number;
  messageProperty: string;

  static fromJSON(json: string | object): LogLabel {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const label = Object.create(LogLabel.prototype);
    return Object.assign(label, object);
  }

  toJSON(): LogLabelJSON {
    return Object.assign({}, this);
  }
}
