export interface AduraDocumentJSON {
  id?: number;
  name: string;
  extension: string;
  file: File;
  autorisierung: number;
  state: DocumentState;
}

export class AduraDocument {
  id?: number;
  name: string;
  extension: string;
  file: File;
  autorisierung: number;
  state: DocumentState;

  public constructor(init?: Partial<AduraDocument>) {

    console.log('init document');
    console.log(init);

    if (init) {
      Object.assign(this, init);
      this.state = DocumentState.Persisted;
    } else {
      this.state = DocumentState.Prototype;
      this.name = '';
    }
  }

  get notPersisted() {
    return this.state === DocumentState.Prototype || this.state === DocumentState.New;
  }

  get isNew() {
    return this.state === DocumentState.New;
  }

  get toDelete() {
    return this.state === DocumentState.Deleted;
  }

  static fromJSON(json: string | object): AduraDocument {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    return new AduraDocument(object);
  }

  toJSON(): AduraDocumentJSON {
    return Object.assign({}, this);
  }
}

export enum DocumentState {
  Prototype,
  New,
  Edited,
  Deleted,
  Persisted,
  Temporary
}
