export interface TaskJSON {
  createdAt: string;
  finishedAt: string;
  success: boolean;
  exception: string;
  discriminator: string;
}

export class Task {
  createdAt: string;
  finishedAt: string;
  success: boolean;
  exception: string;
  discriminator: string;

  static fromJSON(json: string | object): Task {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const task = Object.create(Task.prototype);
    return Object.assign(task, json);
  }

  toJSON(): TaskJSON {
    return Object.assign({}, this);
  }
}

export interface MonitoringJSON {
  job: string;
  status: string;
  lastSuccess: string;
  exception: string;
  inProgress: boolean;
}

export class Monitoring {
  job: string;
  status: string;
  lastSuccess: string;
  exception: string;
  inProgress: boolean;

  static fromJSON(json: string | object): Monitoring {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const task = Object.create(Monitoring.prototype);
    return Object.assign(task, json);
  }

  toJSON(): MonitoringJSON {
    return Object.assign({}, this);
  }
}
