import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { FavoriteCreateComponent } from '@app/search/favorite-create/favorite-create.component';
import { Document } from '@app/shared/classes/document';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';
import { saveAs } from 'file-saver';
import { DocumentPreviewModalComponent } from '@app/document/document-preview-modal/document-preview-modal.component';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html'
})
export class DocumentComponent implements OnInit {

  loading = false;
  loadingPreviewGuid: string;
  guid: string;
  doc: Document = new Document();

  structure: string[] = [];
  hideArchived = true;
  hideTranslations = true;

  searchTags: string[] = [];

  @ViewChild(FavoriteCreateComponent) child: FavoriteCreateComponent;
  @ViewChild(DocumentPreviewModalComponent) documentPreviewModalComponent: DocumentPreviewModalComponent;

  constructor(private route: ActivatedRoute,
              private awisaFoService: AwisaFoService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.guid = this.route.snapshot.paramMap.get('id');
    this.awisaFoService.getDocumentDetails(this.guid)
      .subscribe(
        (result) => {
          console.log(result);
          this.doc = result;
        });
  }

  getDocumentVersionPreview(guid: string, name: string) {
    this.loadingPreviewGuid = guid;

    this.awisaFoService.getDocumentVersionPreview(guid)
      .subscribe({
        next: (response) => {
          this.documentPreviewModalComponent.open(
            response, name
          );
          this.loadingPreviewGuid = null;
        },
        error: e => {
          console.log(e);
          this.loadingPreviewGuid = null;
        }
      });
  }

  getDocument(guid: string) {
    this.loading = true;

    this.awisaFoService.getDocument(guid)
      .subscribe({
        next: (response: HttpResponse<Blob>) => {
          const blob = new Blob([response.body], {type: response.headers.get('Content-Type')});
          const contentDisposition: string = response.headers.get('content-disposition');
          const r = /(?:filename=")(.+)(?:")/;
          const filename = r.exec(contentDisposition)[1];
          console.log(filename);
          saveAs(blob, filename);
          this.loading = false;
        },
        error: e => {
          console.log(e);
          this.loading = false;
        }
      });
  }

  get comments() {
    return (this.doc && this.doc.comments) ? this.doc.comments : null;
  }

  searchByDomain(domain: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/search/content'], {
        queryParams: {
          status: ['10', '20'],
          domain
        }
      })
    );

    window.open(url, '_blank');
  }

  searchByTopic(topic: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/search/content'], {
        queryParams: {
          status: ['10', '20'],
          topic
        }
      })
    );

    window.open(url, '_blank');
  }

  searchByDocDate(date: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/search/content'], {
        queryParams: {
          status: ['10', '20'],
          dateFrom: date,
          dateTo: date
        }
      })
    );

    window.open(url, '_blank');
  }

  toggleSearchTag(tag: string) {
    const index = this.searchTags.indexOf(tag);

    if (index >= 0) {
      this.searchTags.splice(index, 1);
    } else {
      this.searchTags.push(tag);
    }
  }

  isSearchTagActive(tag: string) {
    const index = this.searchTags.indexOf(tag);
    return index >= 0;
  }

  searchByTags() {
    const allDocTags = [];

    // Wenn nichts ausgewählt ist, mit allen Tags suchen
    if (this.searchTags.length === 0) {
      this.doc.tags?.forEach(tag => allDocTags.push(tag.guid));
    }

    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/search/content'], {
        queryParams: {
          status: ['10', '20'],
          tag: allDocTags.length > 0 ? allDocTags : this.searchTags
        }
      })
    );

    window.open(url, '_blank');
  }

  openCreateFavorite() {
    this.child.open();
  }

  toggleArchivedDocuments() {
    this.hideArchived = !this.hideArchived;
  }

  hasArchivedDocuments(): boolean {

    const documentVersion = this.doc.versions?.find(docVersion => {
      return docVersion.status === 'status.archiviert';
    });

    return documentVersion !== undefined;
  }

  toggleTranslations() {
    this.hideTranslations = !this.hideTranslations;
  }
}
