import { Component, Input } from '@angular/core';
import { Thema } from '@app/shared/classes/thema';

@Component({
  selector: 'app-thema-information-item',
  templateUrl: './thema-information-item.component.html'
})
export class ThemaInformationItemComponent {
  @Input()
  thema: Thema;
}
