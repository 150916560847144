import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rating-config',
  templateUrl: './rating-config.html',
  providers: [NgbRatingConfig] // add NgbRatingConfig to the component providers
})
export class RatingConfigComponent {
  @Output() ratingChanged = new EventEmitter<number>();
  @Input() initialRating: number;
  @Input() readOnly: boolean;

  isRating = false;

  constructor(config: NgbRatingConfig) {
    // customize default values of ratings used by this component tree
    config.max = 3;
  }

  onChange(rating: number): void {
    if (this.isRating) {
      this.ratingChanged.emit(rating);
    }
  }
}
