import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Facet } from '@app/shared/classes/facet';

@Component({
  selector: 'app-subject-side',
  templateUrl: './subject-side.component.html'
})
export class SubjectSideComponent implements OnChanges {

  @Input() oberthemen: Facet[] = [];
  @Input() activeOberthemen: string[] = [];
  @Output() oberthemenChanged = new EventEmitter<string[]>();

  oberthemenTop10: Facet[];
  activeOberthemaCollapseTablet = false;
  isShowMoreVal = false;

  ngOnChanges(changes: SimpleChanges) {
    this.calcTopOberthemen();
  }

  isOberthemaActive(guid: string) {
    return this.activeOberthemen.find(res => res === guid);
  }

  changeOberthema(guid: string) {
    const newActiveOberthemen = this.activeOberthemen.slice();

    const index = newActiveOberthemen.indexOf(guid);
    if (index > -1) {
      newActiveOberthemen.splice(index, 1);
    } else {
      newActiveOberthemen.push(guid);
    }

    this.oberthemenChanged.emit(newActiveOberthemen);
  }

  calcTopOberthemen() {
    if (this.oberthemen !== undefined && this.oberthemen !== null) {
      this.oberthemenTop10 = this.oberthemen.slice(0, 10);
    }
  }

  get top10Length() {
    return (this.oberthemenTop10) ? this.oberthemenTop10.length : null;
  }

  isShowMoreOberthemen() {
    return this.isShowMoreVal;
  }

  changeShowMoreOberthemen() {
    this.isShowMoreVal = !this.isShowMoreVal;
  }

  changeOberthemenCollapseForTablet() {
    this.activeOberthemaCollapseTablet = !this.activeOberthemaCollapseTablet;
  }

  oberthemenListToShow() {
    if (this.isShowMoreVal) {
      return this.oberthemen;
    } else {
      return this.oberthemenTop10;
    }
  }
}
