import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { PublikationDetail } from '@app/shared/classes/adura/publikation';

@Component({
  selector: 'app-meldung-weitere-info',
  templateUrl: './adura-meldung-weitere-info.component.html'
})
export class AduraMeldungWeitereInfoComponent implements AfterViewInit {
  @ViewChild('weitereInfoEditModal', {static: false}) weitereInfoEditModal: ElementRef;
  @Output() updateAddWeitereInfo = new EventEmitter<PublikationDetail>();

  private elm: HTMLElement;
  weitereInfoEditForm: UntypedFormGroup;

  weitereInfoToEdit: PublikationDetail;

  constructor(private translateService: TranslateService,
              private formBuilder: UntypedFormBuilder) {
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.elm = this.weitereInfoEditModal.nativeElement as HTMLElement;
  }

  private initForm() {
    this.weitereInfoEditForm = this.formBuilder.group({
      link: ['', Validators.required],
      info: ['', Validators.required]
    });
  }

  private updateForm() {
    this.weitereInfoEditForm.reset({
      link: this.weitereInfoToEdit.link,
      info: this.weitereInfoToEdit.info
    });
  }

  onSave() {
    const formValues = this.weitereInfoEditForm.value;
    this.weitereInfoToEdit.link = formValues.link;
    this.weitereInfoToEdit.info = formValues.info;
    this.weitereInfoToEdit.type = 30;
    this.updateAddWeitereInfo.emit(this.weitereInfoToEdit);
    this.close();
  }

  close() {
    this.weitereInfoEditForm.reset({}, { emitEvent: false });
    this.elm.classList.remove('show');
    setTimeout(() => {
      this.elm.style.width = '0';
    }, 75);
  }

  open(weitereInfo: PublikationDetail): void {
    this.elm.classList.add('show');
    this.elm.style.width = '100vw';

    if (weitereInfo) {
      this.weitereInfoToEdit = weitereInfo;
      this.updateForm();
    }
  }

  showSuccessAlert(translateString: string) {
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000
          });
        });
  }
}
