import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CheckComponent } from '@app/admin/check/check.component';
import { AdminSettingsEntryComponent } from '@app/admin/settings/admin-settings-entry/admin-settings-entry.component';
import { AdminSettingsNewComponent } from '@app/admin/settings/admin-settings-new/admin-settings-new.component';
import { AdminSettingsComponent } from '@app/admin/settings/admin-settings.component';
import { AppInitService, initializeApp } from '@app/app-init.service';
import { GlobalHttpInterceptor } from '@app/core/auth/global-http-interceptor.service';
import { HasRoleDirective } from '@app/core/auth/has-role.directive';
import { LanguageInterceptor } from '@app/core/i18n/language-interceptor.service';
import { CustomRouteReuseStrategy } from '@app/core/routing/custom-route-reuse-strategy';
import { BackButtonDirective } from '@app/core/routing/navigation/back-button.directive';
import { UrlService } from '@app/core/routing/navigation/url.service';
import { DisclaimerComponent } from '@app/disclaimer/disclaimer.component';
import { DocumentPreviewModalComponent } from '@app/document/document-preview-modal/document-preview-modal.component';
import { SubeventNewComponent } from '@app/event/event-new/subevent-new/subevent-new.component';
import { EventComponent } from '@app/event/event.component';
import { FavoriteEditModalComponent } from '@app/home/favorite/favorite-edit-modal/favorite-edit-modal.component';
import { ForbiddenComponent } from '@app/layouts/errors/forbidden/forbidden.component';
import { NotFoundComponent } from '@app/layouts/errors/not-found/not-found.component';
import { UnauthorizedComponent } from '@app/layouts/errors/unauthorized/unauthorized.component';
import { LinkComponent } from '@app/link/link.component';
import { CommentItemComponent } from '@app/search/comment-item/comment-item.component';
import { LanguageSideComponent } from '@app/search/language-side/language-side.component';
import { ThemaItemComponent } from '@app/search/thema-item/thema-item.component';
import { CollapseContentDirective } from '@app/shared/collapse-content.directive';
import { CollapseDirective } from '@app/shared/collapse.directive';
import { CommentComponent } from '@app/shared/comment/comment.component';
import { DropdownDirective } from '@app/shared/dropdown.directive';
import { LinkClipboardComponent } from '@app/shared/link-clipboard.component';
import { DateAgoPipe } from '@app/shared/pipes/date-ago.pipe';
import { DateMergePipe } from '@app/shared/pipes/date-merge.pipe';
import { FilesizePipe } from '@app/shared/pipes/filesize.pipe';
import { TextTruncatePipe } from '@app/shared/pipes/text-truncate.pipe';
import { TabComponent } from '@app/shared/tabs/tab.component';
import { TabsComponent } from '@app/shared/tabs/tabs.component';
import { ThemaImgComponent } from '@app/thema/thema-content-item/thema-img.component';
import { ThemaLinkComponent } from '@app/thema/thema-content-item/thema-link.component';
import { ThemaShowAllComponent } from '@app/thema/thema-content-item/thema-show-all.component';
import { ThemaComponent } from '@app/thema/thema.component';
import { ThemenwordTestComponent } from '@app/themenword-test/themenword-test.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DpDatePickerModule } from 'ng2-date-picker';
import { DynamicHooksComponent, provideDynamicHooks } from 'ngx-dynamic-hooks';
import { AdminComponent } from './admin/admin.component';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AccessTokenInterceptor } from './core/auth/access-token-interceptor.service';
import { DocumentNewComponent } from './document/document-new/document-new.component';
import { DocumentComponent } from './document/document.component';
import { EventNewComponent } from './event/event-new/event-new.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ChangesComponent } from './home/changes/changes.component';
import { DomainComponent } from './home/domain/domain.component';
import { FavoriteCategoryDeleteComponent } from './home/favorite/favorite-category-delete/favorite-category-delete.component';
import { FavoriteCategoryEditModalComponent } from './home/favorite/favorite-category-edit-modal/favorite-category-edit-modal.component';
import { FavoriteDeleteComponent } from './home/favorite/favorite-delete/favorite-delete.component';
import { FavoriteEditComponent } from './home/favorite/favorite-edit/favorite-edit.component';
import { FavoriteTileComponent } from './home/favorite/favorite-tile/favorite-tile.component';
import { FavoriteComponent } from './home/favorite/favorite.component';
import { HomeComponent } from './home/home.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { LinkNewComponent } from './link/link-new/link-new.component';
import { AuthorSideComponent } from './search/author-side/author-side.component';
import { ContentTypeSideComponent } from './search/content-type-side/content-type-side.component';
import { CreationDateSideComponent } from './search/creation-date-side/creation-date-side.component';
import { DateSideComponent } from './search/date-side/date-side.component';
import { DocItemComponent } from './search/doc-item/doc-item.component';
import { DomainSideComponent } from './search/domain-side/domain-side.component';
import { FavoriteCreateComponent } from './search/favorite-create/favorite-create.component';
import { FormatSideComponent } from './search/format-side/format-side.component';
import { ModDateSideComponent } from './search/mod-date-side/mod-date-side.component';
import { SearchComponent } from './search/search.component';
import { StatusSideComponent } from './search/status-side/status-side.component';
import { SubjectSideComponent } from './search/subject-side/subject-side.component';
import { TagSideComponent } from './search/tag-side/tag-side.component';
import { FilterSideComponent } from '@app/search/filter-side/filter-side.component';
import { CommentCreateComponent } from './shared/comment/comment-create/comment-create.component';
import { ThemaContentItemComponent } from './thema/thema-content-item/thema-content-item.component';
import { ThemaInformationItemComponent } from './thema/thema-information-item/thema-information-item.component';
import { AduraMeldungComponent } from '@app/adura/meldung/adura-meldung-component';
import { AduraMeldungEditComponent } from '@app/adura/meldung/adura-meldung-edit/adura-meldung-edit.component';
import { AduraTracelogComponent } from '@app/adura/shared/tracelog/adura-tracelog.component';
import { RatingConfigModule } from '@app/core/config/rating/rating-config.module';
import { AduraAdminComponent } from '@app/adura/admin/adura-admin.component';
import { AduraAdminStammdatenTreiberComponent } from '@app/adura/admin/stammdaten/treiber/adura-admin-stammdaten-treiber.component';
import { AduraAdminStammdatenTreiberNewComponent } from '@app/adura/admin/stammdaten/treiber/adura-admin-stammdaten-treiber-new/adura-admin-stammdaten-treiber-new.component';
import { AduraAdminStammdatenTreiberEntryComponent } from '@app/adura/admin/stammdaten/treiber/adura-admin-stammdaten-treiber-entry/adura-admin-stammdaten-treiber-entry.component';
import { AduraAdminStammdatenGefahrComponent } from '@app/adura/admin/stammdaten/gefahr/adura-admin-stammdaten-gefahr.component';
import { AduraAdminStammdatenGefahrEntryComponent } from '@app/adura/admin/stammdaten/gefahr/adura-admin-stammdaten-gefahr-entry/adura-admin-stammdaten-gefahr-entry.component';
import { AduraAdminStammdatenGefahrNewComponent } from '@app/adura/admin/stammdaten/gefahr/adura-admin-stammdaten-gefahr-new/adura-admin-stammdaten-gefahr-new.component';
import { AduraAdminStammdatenPublikationEntryComponent } from '@app/adura/admin/stammdaten/publikation/adura-admin-stammdaten-publikation-entry/adura-admin-stammdaten-publikation-entry.component';
import { AduraAdminStammdatenPublikationNewComponent } from '@app/adura/admin/stammdaten/publikation/adura-admin-stammdaten-publikation-new/adura-admin-stammdaten-publikation-new.component';
import { AduraAdminStammdatenPublikationComponent } from '@app/adura/admin/stammdaten/publikation/adura-admin-stammdaten-publikation.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { AduraGefahrCreateComponent } from '@app/adura/shared/gefahr-create/adura-gefahr-create.component';
import { AduraTreiberCreateComponent } from '@app/adura/shared/treiber-create/adura-treiber-create.component';
import { AduraMatrixEditComponent } from '@app/adura/shared/matrix/matrix-edit/adura-matrix-edit.component';
import { AduraMatrixEntryComponent } from '@app/adura/shared/matrix/matrix-entry/adura-matrix-entry.component';
import { AduraPublikationAddComponent } from '@app/adura/shared/publikation/publikation-add/adura-publikation-add.component';
import { AduraPublikationEntryComponent } from '@app/adura/shared/publikation/publikation-entry/adura-publikation-entry.component';
import { AduraSearchComponent } from '@app/adura/search/adura-search.component';
import { AduraMeldungItemComponent } from '@app/adura/search/meldung-item/adura-meldung-item.component';
import { AduraSteckbriefItemComponent } from '@app/adura/search/steckbrief-item/adura-steckbrief-item.component';
import { AduraGefahrSideComponent } from '@app/adura/search/gefahr-side/adura-gefahr-side.component';
import { AduraMatrixSideComponent } from '@app/adura/search/matrix-side/adura-matrix-side.component';
import { AduraTreiberSideComponent } from '@app/adura/search/treiber-side/adura-treiber-side.component';
import { AduraBereichSideComponent } from '@app/adura/search/bereich-side/adura-bereich-side.component';
import { AduraStatusSideComponent } from '@app/adura/search/status-side/adura-status-side.component';
import { AduraPrioritySideComponent } from '@app/adura/search/priority-side/adura-priority-side.component';
import { AduraDateSideComponent } from '@app/adura/search/date-side/adura-date-side.component';
import { AduraAktivitaetSideComponent } from '@app/adura/search/aktivitaet-date-side/adura-aktivitaet-side.component';
import { AduraSeismoInfoSideComponent } from '@app/adura/search/seismo-info-side/adura-seismo-info-side.component';
import { AduraIsPublicSideComponent } from '@app/adura/search/is-public-side/adura-is-public-side.component';
import { AduraBeurteilungSideComponent } from '@app/adura/search/beurteilung-side/adura-beurteilung-side.component';
import { AduraFilterSideComponent } from '@app/adura/search/filter-side/adura-filter-side.component';
import { AduraMeldungWeitereInfoComponent } from './adura/meldung/meldung-weitere-info/adura-meldung-weitere-info.component';
import { AduraSteckbriefEditComponent } from '@app/adura/steckbrief/adura-steckbrief-edit/adura-steckbrief-edit.component';
import { AduraSteckbriefComponent } from '@app/adura/steckbrief/adura-steckbrief-component';
import { AduraDocumentEntryComponent } from '@app/adura/shared/document/adura-document-entry.component';
import { AduraBeurteilungEditComponent } from '@app/adura/shared/beurteilung/beurteilung-edit/adura-beurteilung-edit.component';
import { AduraBeurteilungEntryComponent } from '@app/adura/shared/beurteilung/beurteilung-entry/adura-beurteilung-entry.component';
import { AduraAktivitaetEntryComponent } from '@app/adura/shared/aktivitaet/aktivitaet-entry/adura-aktivitaet-entry.component';
import { AduraAktivitaetEditComponent } from '@app/adura/shared/aktivitaet/aktivitaet-edit/adura-aktivitaet-edit.component';
import { AduraAdminPapierkorbComponent } from '@app/adura/admin/papierkorb/adura-admin-papierkorb.component';
import { AduraPapierkorbMeldungEditComponent } from '@app/adura/admin/papierkorb/papierkorb-meldung-edit/adura-papierkorb-meldung-edit.component';
import { AduraMassenfreigabeComponent } from '@app/adura/admin/massenfreigabe/adura-massenfreigabe.component';
import { AduraMassenfreigabeEntryComponent } from '@app/adura/admin/massenfreigabe/adura-massenfreigabe-entry/adura-massenfreigabe-entry.component';
import { AduraMassenfreigabeDateFilterComponent } from '@app/adura/admin/massenfreigabe/adura-massenfreigabe-filter/date-filter/adura-massenfreigabe-date-filter.component';
import { AduraMassenfreigabeStatusFilterComponent } from '@app/adura/admin/massenfreigabe/adura-massenfreigabe-filter/status-filter/adura-massenfreigabe-status-filter.component';
import { AduraMassenfreigabeSeismoInfoFilterComponent } from '@app/adura/admin/massenfreigabe/adura-massenfreigabe-filter/seismo-info-filter/adura-massenfreigabe-seismo-info-filter.component';
import { AduraMassenfreigabeTypeFilterComponent } from '@app/adura/admin/massenfreigabe/adura-massenfreigabe-filter/type-filter/adura-massenfreigabe-type-filter.component';
import { AduraPapierkorbSteckbriefEditComponent } from '@app/adura/admin/papierkorb/papierkorb-steckbrief-edit/adura-papierkorb-steckbrief-edit.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { QRCodeModule } from 'angularx-qrcode';
import { UploadEntryComponent } from '@app/shared/document/upload-entry.component';
import { AduraReportKennzahlenComponent } from '@app/adura/admin/report/adura-report-kennzahlen/adura-report-kennzahlen.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    ForbiddenComponent,
    NotFoundComponent,
    UnauthorizedComponent,
    HeaderComponent,
    MainLayoutComponent,
    HomeComponent,
    FavoriteComponent,
    DomainComponent,
    SearchComponent,
    FooterComponent,
    DomainSideComponent,
    DocumentComponent,
    DocumentPreviewModalComponent,
    DocItemComponent,
    FilesizePipe,
    TagSideComponent,
    SubjectSideComponent,
    AdminComponent,
    DropdownDirective,
    CollapseDirective,
    CollapseContentDirective,
    HasRoleDirective,
    TabsComponent,
    TabComponent,
    CommentComponent,
    ThemaItemComponent,
    ThemaComponent,
    AuthorSideComponent,
    ContentTypeSideComponent,
    DateSideComponent,
    LanguageSideComponent,
    TextTruncatePipe,
    CommentItemComponent,
    ThemaContentItemComponent,
    ThemaInformationItemComponent,
    FormatSideComponent,
    StatusSideComponent,
    LinkComponent,
    EventComponent,
    CommentCreateComponent,
    FavoriteCreateComponent,
    FavoriteEditComponent,
    FavoriteEditModalComponent,
    FavoriteDeleteComponent,
    LinkNewComponent,
    EventNewComponent,
    ThemenwordTestComponent,
    LinkClipboardComponent,
    DateMergePipe,
    DateAgoPipe,
    AdminSettingsComponent,
    AdminSettingsEntryComponent,
    AdminSettingsNewComponent,
    DocumentNewComponent,
    SubeventNewComponent,
    DocumentNewComponent,
    CheckComponent,
    ChangesComponent,
    DisclaimerComponent,
    ChangesComponent,
    BackButtonDirective,
    ThemaImgComponent,
    ThemaLinkComponent,
    ThemaShowAllComponent,
    CreationDateSideComponent,
    ModDateSideComponent,
    FilterSideComponent,
    FavoriteTileComponent,
    FavoriteCategoryEditModalComponent,
    FavoriteCategoryDeleteComponent,
    UploadEntryComponent,
    AduraMeldungComponent,
    AduraMeldungEditComponent,
    AduraSteckbriefComponent,
    AduraSteckbriefEditComponent,
    AduraTracelogComponent,
    AduraAdminComponent,
    AduraAdminPapierkorbComponent,
    AduraPapierkorbMeldungEditComponent,
    AduraPapierkorbSteckbriefEditComponent,
    AduraAdminStammdatenTreiberComponent,
    AduraAdminStammdatenTreiberEntryComponent,
    AduraAdminStammdatenTreiberNewComponent,
    AduraAdminStammdatenGefahrComponent,
    AduraAdminStammdatenGefahrEntryComponent,
    AduraAdminStammdatenGefahrNewComponent,
    AduraAdminStammdatenPublikationComponent,
    AduraAdminStammdatenPublikationEntryComponent,
    AduraAdminStammdatenPublikationNewComponent,
    AduraGefahrCreateComponent,
    AduraTreiberCreateComponent,
    AduraMatrixEditComponent,
    AduraMatrixEntryComponent,
    AduraPublikationAddComponent,
    AduraPublikationEntryComponent,
    AduraSearchComponent,
    AduraMeldungItemComponent,
    AduraSteckbriefItemComponent,
    AduraGefahrSideComponent,
    AduraMatrixSideComponent,
    AduraTreiberSideComponent,
    AduraBereichSideComponent,
    AduraStatusSideComponent,
    AduraPrioritySideComponent,
    AduraDateSideComponent,
    AduraAktivitaetSideComponent,
    AduraBeurteilungSideComponent,
    AduraSeismoInfoSideComponent,
    AduraIsPublicSideComponent,
    AduraFilterSideComponent,
    AduraMeldungWeitereInfoComponent,
    AduraDocumentEntryComponent,
    AduraMeldungWeitereInfoComponent,
    AduraBeurteilungEditComponent,
    AduraBeurteilungEntryComponent,
    AduraAktivitaetEntryComponent,
    AduraAktivitaetEditComponent,
    AduraMassenfreigabeComponent,
    AduraMassenfreigabeEntryComponent,
    AduraMassenfreigabeDateFilterComponent,
    AduraMassenfreigabeStatusFilterComponent,
    AduraMassenfreigabeSeismoInfoFilterComponent,
    AduraMassenfreigabeTypeFilterComponent,
    AduraReportKennzahlenComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DpDatePickerModule,
    DropzoneModule,
    NgSelectModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    DynamicHooksComponent,
    NgbModule,
    RatingConfigModule,
    EditorModule,
    QRCodeModule
  ],
  providers: [
    AppInitService,
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AccessTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptor, multi: true },
    UrlService,
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    provideHttpClient(withInterceptorsFromDi()),
    provideDynamicHooks({
      parsers: [
        { component: TabsComponent, enclosing: true },
        { component: TabComponent },
        { component: ThemaLinkComponent },
        { component: ThemaImgComponent },
        { component: ThemaShowAllComponent }
      ]
    })
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
