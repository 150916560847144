export interface UserJSON {
  guid?: string;
  eiamId: string;
  givenName: string;
  familyName: string;
  email: string;
  language: string;
}

export class User {
  guid?: string;
  eiamId: string;
  givenName: string;
  familyName: string;
  email: string;
  language: string;
  disclaimerOk?: boolean;
  activeDisclaimerDate?: string;
  activeDisclaimerText?: string;
  roles?: string[];
  previousTokenExpired?: boolean;

  static fromJSON(json: string | object): User {
    if (!json) {
      return null;
    }
    const user = Object.create(User.prototype);
    return Object.assign(user, json);
  }
}

export class AuthResponse {
  user: User;
  roles: string[];
}
