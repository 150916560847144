<div class="row admin">
  <p><b>Neue Publikation</b></p>
</div>
<div class="row admin">
    <div class="col-md-4 clear-padding-left"><p class="admin clear-margin-top"><b>Publikationstitel</b></p></div>
    <div class="col-md-4"><p class="admin clear-margin-top"><b>Publikationsabkürzung</b></p></div>
    <div class="col-md-2"><p class="admin clear-margin-top"><b>Deaktiviert</b></p></div>
</div>
<div class="row admin">
  <form [formGroup]="newPublikationForm" (ngSubmit)="onSubmit()">
    <div class="col-md-4 clear-padding-left">
      <input id="titel_new" class="admin-settings" formControlName="titel_new" maxlength="255">
      <span class="length-counter">
        {{ (255 - newPublikationForm.get('titel_new').value?.length) + ' / 255' }}
      </span>
    </div>

    <div class="col-md-4">
      <input id="abkuerzung_new" class="admin-settings" formControlName="abkuerzung_new" maxlength="30">
      <span class="length-counter">
        {{ (30 - newPublikationForm.get('abkuerzung_new').value?.length) + ' / 30' }}
      </span>
    </div>

    <div class="col-md-2">
      <input type="checkbox" id="deaktiviert_new" formControlName="deaktiviert_new">
    </div>

    <div class="col-md-2">
      <button class="btn btn-sm admin-settings" type="submit"
              [ngStyle]="{ 'visibility': newPublikationForm.dirty ? '' : 'hidden'}"
              [disabled]="newPublikationForm.invalid">
        <i class="fa fa-check"></i>
      </button>

      <button class="btn btn-sm admin-settings" type="button"
              [ngStyle]="{ 'visibility': newPublikationForm.dirty ? '' : 'hidden'}"
              title="Abbrechen" (click)="onReset()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </form>
</div>
