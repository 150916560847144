import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { ConfigurationService } from '@app/core/config/configuration.service';
import { Favorite, FavoriteFrequency, FavoriteSave } from '@app/shared/classes/favorite';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { User } from '@app/core/auth/user';

const FavoriteFormValidator: ValidatorFn = (form: UntypedFormGroup) => {
  let categoryLengthExceeded = false;

  form.value.categories?.some((category) => {
    if (category.length > 10) {
      categoryLengthExceeded = true;
    }
  });

  if (categoryLengthExceeded) {
    form.controls.categories.setErrors({invalid: true});
  }

  return null;
};

@Component({
  selector: 'app-favorite-create',
  templateUrl: './favorite-create.component.html'
})
export class FavoriteCreateComponent implements AfterViewInit {
  private user: User;
  @ViewChild('favoriteCreateModal', {static: false}) favoriteCreateModal: ElementRef;
  private apiBase: string;
  favorite: Favorite;
  favName: string;
  favNotification: FavoriteFrequency;
  notificationList = Object.keys(FavoriteFrequency);
  private elm: HTMLElement;
  category: string;
  favoriteCreateForm: UntypedFormGroup;
  categories: string [] = [];

  isSending = false;
  error = false;
  errorMessage: string;

  constructor(private authenticationService: AuthenticationService,
              private awisaFoService: AwisaFoService,
              private translateService: TranslateService,
              private router: Router,
              private formBuilder: UntypedFormBuilder,
              configurationService: ConfigurationService) {
    this.apiBase = configurationService.getConfig().rootUrl;
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.elm = this.favoriteCreateModal.nativeElement as HTMLElement;
  }

  private initForm() {
    this.favoriteCreateForm = this.formBuilder.group({
      name: [''],
      categories: [[]],
      notification: FavoriteFrequency.NO
    }, {validators: FavoriteFormValidator});
  }

  onSubmit() {
    this.isSending = true;
    const category = this.favoriteCreateForm.value.categories?.join(';');
    const daten: FavoriteSave = {
      userGuid: this.authenticationService.getUser().guid,
      title: this.favoriteCreateForm.value.name,
      url: this.router.url,
      notification: this.favoriteCreateForm.value.notification,
      category: category ? category : ''
    };
    console.log(daten);

    this.awisaFoService.createFavorite(daten)
      .subscribe({
        next: () => {
          this.close();
          this.showSuccessAlert('favorite.stored');
          this.isSending = false;
        },
        error: error => {
          this.error = true;
          if (error.error === undefined || error.statusText === 'Unknown Error') {
            this.errorMessage = 'global.error';
          } else {
            this.errorMessage = error.error;
          }
          this.isSending = false;
        }
      });
  }

  close() {
    this.favoriteCreateForm.reset({
      name: '',
      notification: FavoriteFrequency.NO
    }, {emitEvent: false});
    this.elm.classList.remove('show');
    setTimeout(() => {
      this.elm.style.width = '0';
    }, 75);
    this.error = false;
  }

  open(): void {
    this.user = this.authenticationService.getUser();
    this.awisaFoService.getFavoritCategoriesByUser(this.user.guid).subscribe(
      (result) => {
        console.log(result);
        this.categories = result;
      });
    this.elm.classList.add('show');
    this.elm.style.width = '100vw';
  }

  showSuccessAlert(translateString: string) {
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          });
        });
  }
}
