import { HttpParams } from '@angular/common/http';
import { type Dayjs } from 'dayjs';

interface IAduraKennzahlenParams {
  from?: Dayjs;
  to?: Dayjs;
}

export class AduraKennzahlenParams implements IAduraKennzahlenParams {
  constructor(public from?: Dayjs,
              public to?: Dayjs) {
  }

  static fromObject(obj: IAduraKennzahlenParams): AduraKennzahlenParams {
    return Object.assign(new AduraKennzahlenParams(), obj);
  }

  getHttpParams(): HttpParams {
    let params = new HttpParams();

    if (this.from !== undefined) {
      params = params.append('from', this.from?.format('YYYY-MM-DD'));
    }
    if (this.to !== undefined) {
      params = params.append('to', this.to?.format('YYYY-MM-DD'));
    }

    // Timestamp wird angehängt, damit nicht auf den Cache zurückgegriffen wird
    params = params.append('time', Date.now().toString());

    return params;
  }
}
