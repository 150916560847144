import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { Favorite, FavoriteEdit, FavoriteFrequency } from '@app/shared/classes/favorite';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { User } from '@app/core/auth/user';

const FavoriteFormValidator: ValidatorFn = (form: UntypedFormGroup) => {
  let categoryLengthExceeded = false;

  form.value.categories?.some((category) => {
    if (category.length > 80) {
      categoryLengthExceeded = true;
    }
  });

  if (categoryLengthExceeded) {
    form.controls.categories.setErrors({invalid: true});
  }

  return null;
};

@Component({
  selector: 'app-favorite-edit-modal',
  templateUrl: './favorite-edit-modal.component.html'
})
export class FavoriteEditModalComponent implements AfterViewInit {
  private user: User;
  @ViewChild('favoriteEditModal', {static: false}) favoriteEditModal: ElementRef;
  @Output()
  updateEditFavorite = new EventEmitter();
  favorite: Favorite;
  favName: string;
  favNotification: FavoriteFrequency;
  notificationList = Object.keys(FavoriteFrequency);
  private elm: HTMLElement;
  categories: string [] = [];
  favoriteEditForm: UntypedFormGroup;

  isSending = false;
  error = false;
  errorMessage: string;

  constructor(private authenticationService: AuthenticationService,
              private awisaFoService: AwisaFoService,
              private formBuilder: UntypedFormBuilder,
              private translateService: TranslateService) {
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.elm = this.favoriteEditModal.nativeElement as HTMLElement;
  }

  private initForm() {
    this.favoriteEditForm = this.formBuilder.group({
      name: [''],
      categories: [[]],
      notification: FavoriteFrequency.NO
    }, {validators: FavoriteFormValidator});
  }

  onSubmit() {
    this.isSending = true;
    const daten: FavoriteEdit = {
      id: this.favorite.id,
      title: this.favoriteEditForm.value.name,
      notification: this.favoriteEditForm.value.notification,
      category: this.favoriteEditForm.value.categories?.join(';')
    };
    console.log(daten);

    this.awisaFoService.editFavorite(daten)
      .subscribe({
        next: (result) => {
          this.close();
          this.refreshCurrentPage(result);
          this.showSuccessAlert('favorite.stored');
          this.isSending = false;
        },
        error: error => {
          this.error = true;
          if (error.error === undefined || error.statusText === 'Unknown Error') {
            this.errorMessage = 'global.error';
          } else {
            this.errorMessage = error.error;
          }
          this.isSending = false;
        }
      });
  }

  open(favorite: Favorite): void {
    this.user = this.authenticationService.getUser();
    this.awisaFoService.getFavoritCategoriesByUser(this.user.guid).subscribe(
      (result) => {
        console.log(result);
        this.categories = result;
      });
    const favoriteCategories = favorite.category?.split(';');
    this.elm.classList.add('show');
    this.elm.style.width = '100vw';
    this.favorite = favorite;
    this.favoriteEditForm.controls.name.setValue(favorite.title);
    this.favoriteEditForm.controls.categories.setValue(favoriteCategories);
    this.favNotification = this.favorite.notification;
  }

  close() {
    this.favoriteEditForm.reset({
      name: '',
      notification: this.favorite.notification
    }, {emitEvent: false});
    this.elm.classList.remove('show');
    setTimeout(() => {
      this.elm.style.width = '0';
    }, 75);
    this.error = false;
    this.favorite = null;
  }

  refreshCurrentPage(favoriteArr: Favorite[]) {
    this.updateEditFavorite.emit(favoriteArr);
  }

  showSuccessAlert(translateString: string) {
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          });
        });
  }
}
