import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { Favorite, FavoriteEdit } from '@app/shared/classes/favorite';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-favorite-delete',
  templateUrl: './favorite-delete.component.html'
})
export class FavoriteDeleteComponent implements AfterViewInit {
  isSending = false;
  favorite: Favorite;
  private elm: HTMLElement;

  @ViewChild('favoriteDeleteModal', {static: false}) favoriteDeleteModal: ElementRef;
  error = false;
  errorMessage: string;
  @Output()
  updateDeleteFavorite = new EventEmitter();

  constructor(private authenticationService: AuthenticationService,
              private translateService: TranslateService,
              private awisaFoService: AwisaFoService) {
  }

  ngAfterViewInit(): void {
    this.elm = this.favoriteDeleteModal.nativeElement as HTMLElement;
  }

  deleteFavorite() {
    this.isSending = true;
    const daten: FavoriteEdit = {
      id: this.favorite.id,
      title: '',
      notification: null,
      category: null
    };

    this.awisaFoService.deleteFavorite(daten)
      .subscribe({
        next: (result) => {
          this.close();
          this.refreshCurrentPage(result);
          this.showSuccessAlert('favorite.deleted');
          this.isSending = false;
        },
        error: error => {
          this.error = true;
          if (error.error === undefined || error.statusText === 'Unknown Error') {
            this.errorMessage = 'global.error';
          } else {
            this.errorMessage = error.error;
          }
          this.isSending = false;
        }
      });
  }

  open(favorite: Favorite): void {
    this.elm.classList.add('show');
    this.elm.style.width = '100vw';
    this.favorite = favorite;
  }

  close() {
    this.elm.classList.remove('show');
    setTimeout(() => {
      this.elm.style.width = '0';
    }, 75);
    this.error = false;
  }

  refreshCurrentPage(favoriteArr: Favorite[]) {
    this.updateDeleteFavorite.emit(favoriteArr);
  }

  showSuccessAlert(translateString: string) {
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          });
        });
  }
}
