<section class="container information" xmlns="http://www.w3.org/1999/html">
  <h1 *ngIf="id; else newSteckbrief" [innerHTML]="titel"></h1>
  <ng-template #newSteckbrief>
    <h1>{{ 'adura.steckbrief.new' | translate }}</h1>
  </ng-template>
  <form [formGroup]="steckbriefForm" (ngSubmit)="saveSteckbrief()">
    <div class="adura-formular">
      <ng-container *ngIf="id">
        <div class="row form-element">
          <label class="col-sm-2">
            {{ 'adura.steckbrief.id' | translate }}
          </label>
          <div class="col-sm-10">
            {{ this.code }}
          </div>
        </div>
        <div class="row form-element">
          <label class="col-sm-2">{{ 'adura.erfDate' | translate }} / {{ 'adura.mutDate' | translate }}</label>
          <div class="col-sm-10">
            {{ steckbrief.erfDate | date: 'dd.MM.yyyy' }} / {{ steckbrief.mutDate | date: 'dd.MM.yyyy' }}
          </div>
        </div>
      </ng-container>
      <div class="row form-element" [ngClass]="hasError('titel')?'alert alert-danger':''">
        <p *ngIf="hasError('titel')" class="col-sm-12 adura-error">{{ getError('titel') | translate }}</p>
        <label class="col-sm-2">{{ 'adura.title' | translate }}:</label>
        <div class="col-sm-10">
          <div class="tiny-input">
            <editor formControlName="titel"
                    [inline]="true"
                    [init]="tinyConfigTitel"
                    (onInit)="updateRemainingCharacters('titel', $event)"
                    (onKeyUp)="updateRemainingCharacters('titel', $event)"
                    (onChange)="updateRemainingCharacters('titel', $event)">
            </editor>
          </div>
          <span class="length-counter">
            {{ remainingCharactersMap['titel'] + ' / ' + maxCharsMap['titel'] }}
          </span>
        </div>
      </div>
      <div class="row form-element" [ngClass]="hasError('kurzinfo')?'alert alert-danger':''">
        <p *ngIf="hasError('kurzinfo')" class="col-sm-12 adura-error">{{ getError('kurzinfo') | translate }}</p>
        <label class="col-sm-12">{{ 'adura.shortInfo' | translate }}:</label>
        <div class="col-sm-12">
          <div class="tiny-textarea">
            <editor formControlName="kurzinfo"
                    [inline]="true"
                    [init]="tinyConfigEnterAndLinksAllowed">
            </editor>
            <span class="length-counter">∞</span>
          </div>
        </div>
      </div>
      <div class="row form-element">
        <label class="col-sm-12">{{ 'adura.kommentar' | translate }}:</label>
        <div class="col-sm-12 form-element">
          <div class="tiny-input">
            <editor formControlName="comment"
                    [inline]="true"
                    [init]="tinyConfigEnterAndLinksAllowed"
                    (onInit)="updateRemainingCharacters('comment', $event)"
                    (onKeyUp)="updateRemainingCharacters('comment', $event)"
                    (onChange)="updateRemainingCharacters('comment', $event)">
            </editor>
          </div>
          <span class="length-counter">
            {{ remainingCharactersMap['comment'] + ' / ' + maxCharsMap['comment'] }}
          </span>
        </div>
      </div>

      <div class="row form-element" [ngClass]="hasError('nextEvaluation')?'alert alert-danger':''">
        <p *ngIf="hasError('nextEvaluation')" class="col-sm-12 adura-error">{{ getError('nextEvaluation') | translate }}</p>
        <label class="col-sm-2" for="nextEvaluation">{{ 'adura.steckbrief.nextEvaluation' | translate }}</label>
        <div class="col-sm-10">
          <dp-date-picker aria-hidden="true" placeholder="{{datePickerConfig.format}}" theme="dp-material"
                          class="form-control event-datepicker"
                          [ngClass]="(nextEvaluationForm.invalid && nextEvaluationForm.touched) ? 'errors-input':''"
                          formControlName="nextEvaluation"
                          [config]="datePickerConfig"
                          (onSelect)="getNextEvaluation()">
          </dp-date-picker>
          <input (keyup.enter)="getnextEvaluationFromDatePicker($event)"
                 id="nextEvaluation"
                 placeholder="{{datePickerConfig.format}}" type="text"
                 tabindex="-1" class="sr-only" [attr.aria-label]="'date.event' | translate">
          <p role="alert" class="errors-text" *ngIf="(nextEvaluationForm.invalid && nextEvaluationForm.touched)">
            {{ 'global.validation.date' | translate }}
          </p>
        </div>
      </div>

      <div class="row form-element">
        <label class="col-sm-2">{{ 'adura.steckbrief.priority' | translate }}</label>
        <div class="col-sm-10">
          <ng-select formControlName="priority"
                     placeholder="{{ 'adura.priority.choose' | translate }}"
                     [items]="priorityList"
                     [clearable]="false"
                     [readonly]="disableInputByRole"
                     dropdownPosition="bottom">
            <ng-template ng-label-tmp let-item="item">
              <span>{{ item.messageProperty | translate }}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
              <span>{{ item.messageProperty | translate }}</span>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div class="row form-element">
        <label class="col-sm-2">{{ 'adura.signal.label' | translate }}</label>
        <div class="col-sm-10">
          <input type="checkbox"
                 role="checkbox"
                 formControlName="signal"
                 [checked]="steckbrief?.signal"/>
        </div>
      </div>

      <hr>

      <h3>{{ 'adura.steckbrief.beurteilungUndAktivitaet' | translate }}</h3>
      <div class="row form-element">
        <label class="col-sm-2">{{ 'adura.beurteilung.plural' | translate }}</label>
        <div class="col-sm-10 clear-padding-left clear-padding-right">
          <div class="col-sm-12 clear-padding-left clear-padding-right">
            <div class="col-sm-2"><b>{{ 'global.type' | translate }}</b></div>
            <div class="col-sm-5"><b>{{ 'global.description.label' | translate }}</b></div>
          </div>
          <div class="col-sm-12 clear-padding-left clear-padding-right beurteilung-aktivitaet-list" *ngFor="let beurteilung of steckbriefForm.get('beurteilungen').value">
            <app-adura-beurteilung-entry [beurteilung]="beurteilung"></app-adura-beurteilung-entry>
            <ng-template [ngIf]="!disableInputByRole">
              <div class="col-sm-1 text-right">
                <button type="button" class="favorite-icon" title="{{ 'global.edit' | translate }}"
                        (click)="openEditBeurteilung(beurteilung)" [attr.aria-label]="'global.edit' | translate">
                  <i class="fa fa-pencil"></i>
                </button>
                <button type="button" class="favorite-icon" title="{{ 'global.delete' | translate }}"
                        (click)="confirmRemoveBeurteilung(beurteilung)" [attr.aria-label]="'global.delete' | translate">
                  <i class="fa fa-times"></i>
                </button>
              </div>
            </ng-template>
          </div>
          <ng-template [ngIf]="!disableInputByRole">
            <div class="col-sm-12 beurteilung-aktivitaet-list">
              <button type="button" class="favorite-icon" title="{{ 'adura.beurteilung.add' | translate }}"
                      (click)="openAddBeurteilung()" [attr.aria-label]="'adura.beurteilung.add' | translate">
                <i class="fa fa-plus"></i>
              </button>
            </div>
            <app-adura-beurteilung-edit [steckbrief]="steckbrief" (updateAddBeurteilung)="updateBeurteilungList($event)"></app-adura-beurteilung-edit>
          </ng-template>
        </div>
      </div>
      <div class="row form-element">
        <label class="col-sm-2">{{ 'adura.aktivitaet.label' | translate }}</label>
        <div class="col-sm-10 clear-padding-left clear-padding-right">
          <div class="col-sm-2"><b>{{ 'adura.aktivitaet.who' | translate }}</b></div>
          <div class="col-sm-2"><b>{{ 'adura.aktivitaet.till' | translate }}</b></div>
          <div class="col-sm-6"><b>{{ 'adura.aktivitaet.text' | translate }}</b></div>
          <div class="col-sm-2"><b>{{ 'adura.aktivitaet.status.label' | translate }}</b></div>
          <div class="col-sm-12 clear-padding-left clear-padding-right beurteilung-aktivitaet-list" *ngFor="let aktivitaet of steckbriefForm.get('aktivitaeten').value">
            <app-adura-aktivitaet-entry [aktivitaet]="aktivitaet"></app-adura-aktivitaet-entry>
            <ng-template [ngIf]="!disableInputByRole">
              <div class="col-sm-1 text-right">
                <button type="button" class="favorite-icon" title="{{ 'global.edit' | translate }}"
                        (click)="openEditAktivitaet(aktivitaet)" [attr.aria-label]="'global.edit' | translate">
                  <i class="fa fa-pencil"></i>
                </button>
                <button type="button" class="favorite-icon" title="{{ 'global.delete' | translate }}"
                        (click)="confirmRemoveAktivitaet(aktivitaet)" [attr.aria-label]="'global.delete' | translate">
                  <i class="fa fa-times"></i>
                </button>
              </div>
            </ng-template>
          </div>
          <ng-template [ngIf]="!disableInputByRole">
            <div class="col-sm-12 beurteilung-aktivitaet-list">
              <button type="button" class="favorite-icon" title="{{ 'adura.aktivitaet.add' | translate }}"
                      (click)="openAddAktivitaet()" [attr.aria-label]="'adura.aktivitaet.add' | translate">
                <i class="fa fa-plus"></i>
              </button>
            </div>
            <app-adura-aktivitaet-edit [steckbrief]="steckbrief" (updateAddAktivitaet)="updateAktivitaetList($event)"></app-adura-aktivitaet-edit>
          </ng-template>
        </div>
      </div>

      <hr>

      <h3>{{ 'adura.steckbrief.documents' | translate }}</h3>
      <div class="row form-element">
        <label class="col-sm-2">{{ 'adura.reference.title' | translate }}:</label>
        <div class="col-sm-12">
          <div class="tiny-input">
            <editor formControlName="referenzen"
                    [inline]="true"
                    [init]="tinyConfigEnterAndLinksAllowed">
            </editor>
            <span class="length-counter">∞</span>
          </div>
        </div>
      </div>

      <div class="row form-element">
        <label class="col-sm-3">{{ 'adura.uploads.title' | translate }}:</label>
        <ng-container *ngIf="sortedDocuments?.length > 0">
          <div class="col-sm-12 clear-padding-left">
            <div class="row steckbrief-document">
              <div class="col-sm-2"><b>{{ 'adura.autorisierung.label' | translate }}</b></div>
              <div class="col-sm-6"><b>{{ 'adura.steckbrief.documentName' | translate }}</b></div>
            </div>
          </div>

          <div class="col-sm-12 clear-padding-left">
            <ng-container *ngFor="let document of sortedDocuments">
              <app-adura-document-entry [document]="document"
                                        (changedDocument)="markFormDirty()"></app-adura-document-entry>
            </ng-container>
          </div>
        </ng-container>

        <div class="col-sm-12" *ngIf="dropzoneConfig">
          <dropzone class="dropzone-element"
                    [config]="dropzoneConfig"
                    (sending)="dropzoneFileUploading($event)"
                    (success)="dropzoneFileAdded($event)"
                    (error)="dropzoneFileError($event)">
          </dropzone>
          <p role="alert" class="errors-text" *ngFor="let dzErr of dropzoneError" [innerText]="dzErr"></p>
        </div>
      </div>

      <div *ngIf="!disableInputByRole" class="row form-element">
        <label class="col-sm-2">{{ 'adura.bcgDocument.title' | translate }}:</label>
        <div class="col-sm-12">
          <div class="tiny-input">
            <editor formControlName="bcgDokumente"
                    [inline]="true"
                    [init]="tinyConfigEnterAndLinksAllowed">
            </editor>
            <span class="length-counter">∞</span>
          </div>
        </div>
      </div>

      <hr>

      <h3>{{ 'adura.links' | translate }}</h3>
      <div class="row form-element">
        <label class="col-sm-2">{{ 'adura.danger.label' | translate }}:
          <a role="button" title="{{ 'adura.danger.create' | translate }}" (click)="openCreateGefahr()"
             [attr.aria-label]="'adura.danger.create' | translate"><i class="fa fa-plus" aria-hidden="true"></i></a>
        </label>
        <app-adura-gefahr-create (updateCreateGefahr)="reloadGefahrList()"></app-adura-gefahr-create>
        <div class="col-sm-10">
          <ng-select formControlName="gefahren"
                     placeholder="{{ 'adura.danger.choose' | translate }}"
                     [items]="gefahrList"
                     [multiple]="true"
                     dropdownPosition="bottom"
                     bindLabel="bezeichnungRaw">
            <ng-template ng-label-tmp let-item="item">
              <span aria-hidden="true" class="ng-value-icon left" (click)="removeGefahr(item)">×</span>
              <span class="ng-value-label" [innerHTML]="item.bezeichnung"></span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
              <span [innerHTML]="item.bezeichnung"></span>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div class="row form-element">
        <label class="col-sm-2">{{ 'adura.matrix.label' | translate }}:</label>
        <div class="col-sm-10">
          <ul class="adura-form" *ngIf="steckbriefForm.get('matrix')?.value?.length > 0">
            <li *ngFor="let matrixEntry of steckbriefForm.get('matrix').value">
              <app-adura-matrix-entry [matrixDetail]="matrixEntry"></app-adura-matrix-entry>
              <button type="button" class="favorite-icon" title="{{ 'global.edit' | translate }}"
                      (click)="openEditMatrix(matrixEntry)" [attr.aria-label]="'global.edit' | translate">
                <i class="fa fa-pencil"></i>
              </button>
              <button type="button" class="favorite-icon" title="{{ 'global.delete' | translate }}"
                      (click)="removeMatrix(matrixEntry)" [attr.aria-label]="'global.delete' | translate">
                <i class="fa fa-times"></i>
              </button>
            </li>
          </ul>
          <button type="button" class="favorite-icon" title="{{ 'adura.matrix.add' | translate }}"
                  (click)="openAddMatrix()" [attr.aria-label]="'adura.matrix.add' | translate">
            <i class="fa fa-plus"></i>
          </button>
          <app-adura-matrix-edit [steckbrief]="steckbrief" (updateAddMatrix)="updateMatrixList($event)"></app-adura-matrix-edit>
        </div>
      </div>

      <div class="row form-element" [ngClass]="hasError('bereiche')?'alert alert-danger':''">
        <p *ngIf="hasError('bereiche')" class="col-sm-12 adura-error">{{ getError('bereiche') | translate }}</p>
        <label class="col-sm-12">{{ 'adura.domain.label' | translate }}</label>
        <ng-template ngFor let-i="index" let-bereich [ngForOf]="bereichList">
          <div class="col-sm-12 clear-padding-left">
            <label for="bereich{{ i }}_prim" class="col-sm-2 bereiche">{{ bereich.bezeichnung }}</label>
            <div class="col-sm-10">
              <input type="checkbox"
                     class="primary-domain-checkbox"
                     [value]="bereich.id"
                     [checked]="isBereichActive(bereich.id)"
                     (click)="selectBereich($event)"/>
            </div>
          </div>
        </ng-template>
      </div>

      <div class="row form-element" [ngClass]="hasError('meldungen')?'alert alert-danger':''">
        <p *ngIf="hasError('meldungen')" class="col-sm-12 adura-error">{{ getError('meldungen') | translate }}</p>
        <label class="col-sm-2">{{ 'adura.steckbrief.meldungen' | translate }}:</label>
        <div class="col-sm-10">
          <ng-select formControlName="meldungen"
                     placeholder="{{ 'adura.meldung.choose' | translate }}"
                     [items]="meldungList"
                     [multiple]="true"
                     dropdownPosition="bottom"
                     bindLabel="codeTitelRaw">
            <ng-template ng-label-tmp let-item="item">
              <span aria-hidden="true" class="ng-value-icon left" (click)="removeMeldung(item)">×</span>
              <span class="ng-value-label" [innerHTML]="item.code + ' - ' + item.titel"></span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
              <span [innerHTML]="item.code + ' - ' + item.titel"></span>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <hr>

      <ng-container *ngIf="id">
        <h3>{{ 'adura.status.processControl' | translate }}</h3>
        <div class="row form-element">
          <label class="col-sm-2">{{ 'adura.status.label' | translate }}:</label>
          <div class="col-sm-10">
            <ng-select formControlName="status"
                       placeholder="{{ 'adura.status.choose' | translate }}"
                       [items]="statusList"
                       [clearable]="false"
                       [readonly]="disableInputByRole"
                       dropdownPosition="bottom">
              <ng-template ng-label-tmp let-item="item">
                <span>{{ item.messageProperty | translate }}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                <span>{{ item.messageProperty | translate }}</span>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </ng-container>

      <div class="row">
        <label class="col-sm-2">{{ 'adura.meldung.publicVisible' | translate }}</label>
        <div class="col-sm-10 adura-form">
          <input type="checkbox"
                 role="checkbox"
                 formControlName="isPublic"
                 [checked]="steckbrief?.isPublic"/>
        </div>
      </div>

      <ng-container *ngIf="id">
        <div *ngIf="id" class="row form-element">
          <label class="col-sm-12">{{ 'adura.log.comment' | translate }}</label>
          <div class="col-sm-12">
            <input class="form-control adura-form"
                   formControlName="logComment"
                   id="logComment"
                   maxlength="255"
                   autocomplete="off">
            <span class="length-counter">
              {{ (255 - steckbriefForm.get('logComment').value?.length) + ' / 255' }}
            </span>
          </div>
        </div>
        <app-adura-tracelog [code]="steckbrief.code"></app-adura-tracelog>
      </ng-container>
    </div>
    <div class="row col-sm-12">
      <p *ngIf="error" class="alert alert-danger" role="alert">{{ 'adura.steckbrief.errorSave' | translate}}</p>
      <p *ngIf="deleteError" class="alert alert-danger" role="alert">{{ 'adura.steckbrief.errorSave' | translate}}</p>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <button type="button" class="btn btn-sm btn-space btn-primary"
                [disabled]="steckbriefForm.invalid || this.isSending" (click)="saveSteckbrief()">
          <i *ngIf="isSending" class="fa fa-spinner fa-pulse fa-fw"></i>{{ 'global.save' | translate }}
        </button>
        <ng-template [ngIf]="!disableInputByRole">
          <button type="button" class="btn btn-sm btn-space btn-danger pull-right" *ngIf="steckbrief?.id"
                  [disabled]="this.isSending" (click)="deleteSteckbrief()">
            <i *ngIf="isSending" class="fa fa-spinner fa-pulse fa-fw"></i>{{ 'global.delete' | translate }}
          </button>
        </ng-template>
        <button type="button" [routerLink]="cancelLink" class="btn btn-sm btn-space btn-secondary pull-right">
          {{ 'global.cancel' | translate }}
        </button>
      </div>
    </div>
  </form>
</section>
