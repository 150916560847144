import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpStatusCode } from '@angular/common/http';
import { AduraService } from '@app/shared/services/adura.service';
import { PublikationAdmin } from '@app/shared/classes/adura/publikation';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-adura-admin-stammdaten-publikation-entry]',
  templateUrl: './adura-admin-stammdaten-publikation-entry.component.html'
})
export class AduraAdminStammdatenPublikationEntryComponent {
  @Output() deleted = new EventEmitter();
  @Output() reloadEntries: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  set set(publikation: PublikationAdmin) {
    this.publikation = publikation;
    this.initForm(publikation);
  }

  publikationForm: UntypedFormGroup;
  delChosen = false;
  publikation: PublikationAdmin;

  constructor(private aduraService: AduraService,
              private formBuilder: UntypedFormBuilder) {
  }

  private initForm(publikation: PublikationAdmin) {
    this.publikationForm = this.formBuilder.group({
      id: [''],
      titel: ['', Validators.required],
      abkuerzung: ['', Validators.required],
      deaktiviert: ['']
    });
    this.updateForm(publikation);
  }

  private updateForm(publikation: PublikationAdmin) {
    this.publikationForm.reset({
      id: publikation.id,
      titel: publikation.titel,
      abkuerzung: publikation.abkuerzung,
      deaktiviert: publikation.deaktiviert
    }, {emitEvent: false});
  }

  onSubmit() {
    const formValues = this.publikationForm.value;
    const newPub = new PublikationAdmin();
    newPub.id = formValues.id;
    newPub.titel = formValues.titel;
    newPub.abkuerzung = formValues.abkuerzung;
    newPub.deaktiviert = formValues.deaktiviert;
    this.aduraService.editPublikation(newPub)
      .subscribe({
        next: result => {
          this.publikation = result;
          this.reloadEntries.emit();
        },
        error: error => {
          if (error.status === HttpStatusCode.BadRequest) {
            this.showAlert(error.error);
          } else {
            this.showAlert(null);
          }
          console.error(error);
        }
      });
  }

  onReset() {
    this.updateForm(this.publikation);
  }

  deletePublikation(publikationId: number) {
    this.delChosen = true;
    Swal.fire({
      text: 'Wollen Sie diese Publikation wirklich löschen?',
      icon: 'warning',
      customClass: {htmlContainer: 'content-text-center'},
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: 'Ja, Publikation löschen',
      cancelButtonText: 'Abbrechen'
    }).then((result) => {
      if (result.value) {
        this.continueDeletePublikation(publikationId);
      } else {
        this.delChosen = false;
      }
    });
  }

  continueDeletePublikation(publikationId: number) {
    this.aduraService.deletePublikation(publikationId)
      .subscribe({
        next: () => {
          this.showAlertDelete(true);
          this.deleted.emit();
        },
        error: error => {
          this.showAlertDelete(false);
          this.delChosen = false;
          console.error(error.message);
        }
      });
  }

  showAlertDelete(ok: boolean) {
    let message = 'Publikation wurde gelöscht!';
    let apiResult: SweetAlertIcon = 'success';
    if (!ok) {
      message = 'Die Publikation konnte nicht gelöscht werden!';
      apiResult = 'error';
    }
    Swal.fire({
      text: message,
      icon: apiResult,
      customClass: {htmlContainer: 'content-text-center'},
      buttonsStyling: true,
      confirmButtonText: 'OK'
    });
  }

  showAlert(errors: any[]) {
    let errorString = '';
    if (errors === null) {
      errorString = 'Die Publikation konnte nicht gespeichert werden!';
    } else {
      errors.forEach(entry => {
        errorString += entry.message + '<br/>';
      });
    }
    Swal.fire({
      html: errorString,
      icon: 'error',
      customClass: {htmlContainer: 'content-text-center'},
      buttonsStyling: true,
      confirmButtonText: 'OK'
    });
  }
}
