<!-- //NOSONAR UL-Element in äusserem Component --><li class="dataset-item clear-margin-bottom">
  <div class="dataset-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 item-content-name">
          <h2>
            <a class="item-content-name"
               routerLink="/{{ this.content.type }}/{{ this.content.guid }}" [innerHTML]="getMarkedContent(this.content.name)"></a>
            <ng-container *ngIf="this.content.format; then showFormat else showContenttype"></ng-container>
            <ng-template #showContenttype>
                <span class="doc-item-format">
                  <h3 class="sr-only">{{ "content.type" | translate }}: </h3>
                    <label attr.aria-label="{{ this.content.type + '.label' | translate }}"
                           title="{{ this.content.type + '.label' | translate }}">
                      ({{ this.content.type + '.label' | translate }})
                    </label>
                </span>
            </ng-template>
            <ng-template #showFormat>
                <span class="doc-item-format">
                  <h3 class="sr-only">{{ "format.label" | translate }}:</h3>
                  <label>({{ getFormat() }})</label>
                </span>
            </ng-template>

            <span *ngIf="content.termInContent">
                  <i class="fa fa-bullseye document-search-term-in-content"
                     title="{{ 'search.content.match' | translate }}">
                  </i>
            </span>
            <span (click)="getDocument(this.content.guid)" *ngIf="content.type === documentType">
                  <i class="fa fa-arrow-circle-down document-preview-icon"
                     title="{{ 'global.download' | translate }}">
                  </i>
            </span>
            <span (click)="getDocumentPreview(this.content.guid)" *ngIf="content.canPreview">
                  <i [ngClass]="loadingPreview?'fa fa-spinner fa-pulse document-preview-icon':'fa fa-eye document-preview-icon'"
                     title="{{ 'document.preview.show' | translate }}">
                  </i>
            </span>
          </h2>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-3 clear-padding-left">
      <p [ngClass]="!this.content.description ? 'clear-margin-bottom':''"
         [attr.aria-label]="('global.changed' | translate) + ': ' + (content.modificationDate | date: 'dd.MM.yyyy')">
        {{ 'global.changed' | translate }}: {{ content.modificationDate | date: 'dd.MM.yyyy' }}</p>
    </div>
    <ul class="col-xs-12 col-md-9 dataset-resources list-inline tags clear-padding-left">
      <ng-container *ngFor="let tag of content.tags">
        <li class="nav-item filter-tag" [ngClass]="(isTagActive(tag))?'active':''">
          <a (click)="changeTag(tag)" role="button" class="filter-tag-label"
             [attr.aria-pressed]="(isTagActive(tag))?'true':'false'"
             [attr.aria-label]="('tag.label' | translate) + ': ' + tag"
             [innerHTML]="getMarkedContent(tag)"></a>
        </li>
      </ng-container>
    </ul>
    <div class="col-xs-12 col-md-12 clear-padding-left">
      <p *ngIf="!isProd()">Faktor: {{ content.relevanzFaktor }}</p>
    </div>
  </div>
  <div class="doc-item-description">
    <h3 class="sr-only">{{ 'global.description.label' | translate }}</h3>
    <p class="clear-margin-bottom" [innerHTML]="getMarkedContent(this.content.description)"></p>
  </div>
</li>
<app-document-preview-modal></app-document-preview-modal>
