import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Settings } from '@app/core/config/settings';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-admin-settings-entry]',
  templateUrl: './admin-settings-entry.component.html'
})
export class AdminSettingsEntryComponent implements AfterViewInit {
  @Output() deleted = new EventEmitter();

  settingsForm: UntypedFormGroup;
  isLoading = false;
  delChosen = false;

  setting: Settings;

  constructor(private awisaFoService: AwisaFoService,
              private formBuilder: UntypedFormBuilder) {
  }

  @Input()
  set set(setting: Settings) {
    this.setting = setting;
    this.initForm(setting);
  }

  ngAfterViewInit() {
    this.autogrow(this.setting.id);
  }

  private initForm(setting: Settings) {
    this.settingsForm = this.formBuilder.group({
      key: ['', Validators.required],
      value: ['', Validators.required]
    });
    this.updateForm(setting);
  }

  private updateForm(setting: Settings) {
    this.settingsForm.reset({
      key: setting.key,
      value: setting.value,
      description: setting.description
    }, {emitEvent: false});
  }

  onSubmit() {
    const formValues = this.settingsForm.value;
    this.setting.key = formValues.key;
    this.setting.value = btoa(formValues.value);
    this.awisaFoService.editSetting(this.setting)
      .subscribe({
        next: result => {
          this.setting = result;
          this.initForm(this.setting);
        },
        error: error => console.log(error)
      });
  }

  onReset() {
    this.updateForm(this.setting);
  }

  deleteSetting(setId: number) {
    this.delChosen = true;
    Swal.fire({
      text: 'Wollen Sie diese Einstellung wirklich löschen?',
      icon: 'warning',
      customClass: {htmlContainer: 'content-text-center'},
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: 'Ja, Einstellung löschen',
      cancelButtonText: 'Abbrechen'
    }).then((result) => {
      if (result.value) {
        this.continueDeleteSetting(setId);
      } else {
        this.delChosen = false;
      }
    });
  }

  continueDeleteSetting(setId: number) {
    this.awisaFoService.deleteSetting(setId)
      .subscribe({
        next: () => {
          this.showAlert(true);
          this.deleted.emit();
        },
        error: error => {
          this.showAlert(false);
          console.log('Error' + error.message);
        }
      });
  }

  autogrow(id: number) {
    const textArea = document.getElementById('value_' + id);
    textArea.style.overflowY = 'auto';
    textArea.style.height = '0px';
    if (textArea.scrollHeight > 91) {
      textArea.style.height = '91px';
    } else {
      textArea.style.height = textArea.scrollHeight + 'px';
    }
  }

  showAlert(ok: boolean) {
    let message = 'Einstellung wurde gelöscht!';
    let apiResult: SweetAlertIcon = 'success';
    if (!ok) {
      message = 'Die Einstellung konnte nicht gelöscht werden!';
      apiResult = 'error';
    }
    Swal.fire({
      text: message,
      icon: apiResult,
      customClass: {htmlContainer: 'content-text-center'},
      buttonsStyling: true,
      confirmButtonText: 'OK'
    });
  }
}
