import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { User } from '@app/core/auth/user';
import { Comment } from '@app/shared/classes/comment';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-comment-create',
  templateUrl: './comment-create.component.html'
})
export class CommentCreateComponent implements OnInit, AfterViewInit {
  @Input()
  refGuid: string;
  @ViewChild('commentCreateModal', {static: false}) commentCreateModal: ElementRef;
  @ViewChild('commentCreateForm') commentForm: NgForm;
  private user: User;
  private elm: HTMLElement;
  @Output()
  updateCreateComment = new EventEmitter();

  activateSpinner = false;
  error = false;
  errorMessage: string;

  constructor(private authenticationService: AuthenticationService,
              private awisaFoService: AwisaFoService,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
  }

  ngAfterViewInit(): void {
    this.elm = this.commentCreateModal.nativeElement as HTMLElement;
  }

  close(): void {
    this.commentForm.reset();
    this.elm.classList.remove('show');
    setTimeout(() => {
      this.elm.style.width = '0';
    }, 75);
    this.error = false;
  }

  open(): void {
    this.elm.classList.add('show');
    this.elm.style.width = '100vw';
  }

  createComment() {
    if ('commentTextarea' in this.commentForm.value) {
      const comment = new Comment();
      comment.authorGuid = this.user.guid;
      comment.text = this.commentForm.value.commentTextarea;
      comment.refGuid = this.refGuid;
      this.activateSpinner = true;
      this.awisaFoService.createComment(comment)
        .subscribe({
          next: (result) => {
            this.close();
            this.refreshCurrentPage(result);
            this.showSuccessAlert('comment.stored');
            this.activateSpinner = false;
          },
          error: error => {
            this.error = true;
            if (error.error === undefined || error.statusText === 'Unknown Error') {
              this.errorMessage = 'global.error';
            } else {
              this.errorMessage = error.error;
            }
            this.activateSpinner = false;
          }
        });
    }
  }

  refreshCurrentPage(commentArr: Comment[]) {
    this.updateCreateComment.emit(commentArr);
  }

  showSuccessAlert(translateString: string) {
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          });
        });
  }
}
