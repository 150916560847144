import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-thema-content-item',
  templateUrl: './thema-content-item.component.html'
})
export class ThemaContentItemComponent {
  @Input()
  themaContent: string;
}
