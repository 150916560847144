<section class="facet-filter">
  <a role="button" class="facet-language_de-collapse-button facet-toggle collapsed"
     (click)="changeLanguageCollapseForTablet()"
     [attr.aria-expanded]="activeLanguageCollapseTablet" aria-controls="facet-language_de-collapse">
    <h2>{{ 'language.documents' | translate }}<i class="fa fa-angle-down"></i></h2>
  </a>
  <h2 class="facet-header"
      [attr.aria-label]="'language.documents' | translate">{{ 'language.documents' | translate }}</h2>
  <div id="facet-language_de-collapse" class="collapse facet-collapse"
       [ngClass]="(activeLanguageCollapseTablet)?'in':''">
    <ng-container *ngIf="!this.languages || this.languages.length === 0">
      <p><small>{{ 'search.noFilter' | translate }}</small></p>
    </ng-container>
    <nav [attr.aria-label]="'language.list' | translate">
      <div class="list-inline tags">
        <ng-container *ngFor="let language of languages">
          <div class="flex-container">
            <input type="checkbox" class="filter-checkbox sr-only" id="{{language.guid}}"
                   (click)="changeLanguage(language.guid)"
                   [checked]="isLanguageActive(language.guid)">
            <label class="filter-checkbox-label" [ngClass]="(language.count === 0)?'filter-element-background':''"
                   for="{{language.guid}}"
                   role="checkbox"
                   [attr.aria-label]="('language.label' | translate) + ': ' + language.name + ', ' +
                   ('global.total' | translate) + ': ' + language.count"
                   [attr.aria-checked]="(isLanguageActive(language.guid))?'true':'false'">
              {{ language.name }}
            </label>
            <span class="filter-checkbox-number" aria-hidden="true">{{ language.count}}</span>
          </div>
        </ng-container>
      </div>
    </nav>
  </div>
</section>
