import { Component } from '@angular/core';
import { ConfigurationService } from '@app/core/config/configuration.service';

@Component({
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent {
  private apiBase: string;

  constructor(private configurationService: ConfigurationService) {
    this.apiBase = configurationService.getConfig().awisaFOUrl;
  }

}
