<section class="facet-filter">
  <h3 class="facet-subtitle" [attr.aria-label]="'adura.driver.searchTitle' | translate">{{ 'adura.driver.searchTitle' | translate }}</h3>
  <div>
    <div class="form-group input-reset-button" *ngIf="this.treiber && this.treiber?.length > 0">
      <input class="form-control input-sm" spellcheck="false" [(ngModel)]="searchTerm"
             (ngModelChange)="searchTreiber()" placeholder="{{ 'adura.driver.search' | translate }}"/>
      <button type="reset">
        <i class="fa fa-times" aria-hidden="true" (click)="clearInput()"></i>
      </button>
    </div>
    <ng-container *ngIf="!this.treiberListToShow() || this.treiberListToShow().length === 0">
      <p><small>{{ 'search.noFilter' | translate }}</small></p>
    </ng-container>
    <nav [attr.aria-label]="'adura.driver.list' | translate">
      <ul class="list-inline tags">
        <ng-container *ngFor="let treiber of this.treiberListToShow()">
          <li class="nav-item filter-tag"  [ngClass]="(isTreiberActive(treiber.id))?'active':''">
            <a (click)="changeTreiber(treiber.id)" role="button" class="filter-tag-label"
               [attr.aria-pressed]="(isTreiberActive(treiber.id))?'true':'false'"
               [attr.aria-label]="('adura.driver.label' | translate) + ': ' + treiber.name + ', ' +
             ('global.total' | translate) + ': ' + treiber.count">{{ treiber.name }}
              <small [attr.aria-label]="treiber.count" aria-hidden="true">{{ treiber.count }}</small></a></li>
        </ng-container>
      </ul>
    </nav>

    <ng-container *ngIf="(top10Length > 9)">
      <p><a (click)="changeShowMoreTreiber()" class="btn btn-xs">
        <ng-container *ngIf="isShowMoreTreiber(); then showmore else showless"></ng-container>
        <ng-template #showmore>
          <i class="fa filter-more-less fa-angle-up"></i>{{ 'global.show.less' | translate }}
        </ng-template>
        <ng-template #showless>
          <i class="fa filter-more-less fa-angle-down"></i>{{ 'global.show.more' | translate }}
        </ng-template>
      </a>
      </p>
    </ng-container>
  </div>
</section>
