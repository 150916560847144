import { Status } from './status';
import { Bereich } from './bereich';
import { Gefahr } from './gefahr';
import { Meldung } from './meldung';
import { Matrix } from '@app/shared/classes/adura/matrix';
import { AduraDocument } from '@app/shared/classes/adura/adura-document';
import { Beurteilung } from '@app/shared/classes/adura/beurteilung';
import { Priority } from '@app/shared/classes/adura/priority';
import { Aktivitaet } from '@app/shared/classes/adura/aktivitaet';

export interface SteckbriefJSON {
  id?: number;
  isPublic?: boolean;
  code?: string;
  titel: string;
  titelRaw: string;
  kurzinfo?: string;
  comment?: string;
  creationDate?: Date;
  nextEvaluation?: Date;
  priority?: Priority;
  signal?: boolean;
  beurteilungen?: Beurteilung[];
  aktivitaeten?: Aktivitaet[];
  status?: Status;
  logComment?: string;

  referenzen: string;
  bcgDokumente: string;

  bereiche: Bereich[];
  gefahren: Gefahr[];
  matrix: Matrix[];
  documents: AduraDocument[];

  meldungen: Meldung[];

  erfDate?: Date;
  mutDate?: Date;

  disabled: boolean;
}

export class Steckbrief {
  id?: number;
  isPublic?: boolean;
  code?: string;
  titel: string;
  titelRaw: string;
  kurzinfo: string;
  comment?: string;
  creationDate?: Date;
  nextEvaluation?: Date;
  priority?: Priority;
  signal?: boolean;
  beurteilungen?: Beurteilung[];
  aktivitaeten?: Aktivitaet[];
  status?: Status;
  logComment?: string;

  referenzen: string;
  bcgDokumente: string;

  bereiche: Bereich[];
  gefahren: Gefahr[];
  matrix: Matrix[];
  documents: AduraDocument[];

  meldungen: Meldung[];

  erfDate?: Date;
  mutDate?: Date;

  // Id aus dem alten Adura
  aduraId?: string;

  disabled: boolean; // Damit Elemente des Dropdowns disabled gesetzt werden können; für das Backend irrelevant

  get codeTitelRaw() {
    return this.code + ' ' + this.titelRaw;
  }

  public constructor(init?: Partial<Steckbrief>) {
    Object.assign(this, init);
  }

  static fromJSON(json: string | object): Steckbrief {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const steckbrief = Object.create(Steckbrief.prototype);
    Object.assign(steckbrief, object);
    steckbrief.documents = steckbrief.documents?.map(AduraDocument.fromJSON);
    return steckbrief;
  }

  toJSON(): SteckbriefJSON {
    return Object.assign({}, this);
  }
}

