export interface BereichJSON {
  id: number;
  bezeichnung: string;
  primaer: boolean;
}

export class Bereich {
  id: number;
  bezeichnung: string;
  primaer: boolean;

  static fromJSON(json: string | object): Bereich {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const land = Object.create(Bereich.prototype);
    return Object.assign(land, object);
  }

  toJSON(): BereichJSON {
    return Object.assign({}, this);
  }
}

export interface BereichAdminJSON {
  id?: number;
  bezeichnungDe: string;
  bezeichnungEn: string;
  bezeichnungFr: string;
  bezeichnungIt: string;
  mutDate?: Date;
  mutUser?: string;
}

export class BereichAdmin {
  id?: number;
  bezeichnungDe: string;
  bezeichnungEn: string;
  bezeichnungFr: string;
  bezeichnungIt: string;
  mutDate?: Date;
  mutUser?: string;

  static fromJSON(json: string | object): BereichAdmin {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const land = Object.create(BereichAdmin.prototype);
    return Object.assign(land, object);
  }

  toJSON(): BereichAdminJSON {
    return Object.assign({}, this);
  }
}
