import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthResponse, User } from '@app/core/auth/user';
import { ConfigurationService } from '@app/core/config/configuration.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PersonService {

  private apiBase: string;

  constructor(private http: HttpClient,
              private configurationService: ConfigurationService) {
  }

  get(user: User): Observable<AuthResponse> {
    this.apiBase = this.configurationService.getConfig().awisaFOUrl;
    return this.http.put<AuthResponse>(`${this.apiBase}/user`, user);
  }

  changeLanguage(user: User): Observable<User> {
    // Text muss gelöscht werden, da HTML -> Dies wird vom Bundes-Proxy abgefangen
    user.activeDisclaimerText = '';
    const body = user;
    return this.http.put(`${this.apiBase}/user/${user.guid}/changeLanguage`, body)
      .pipe(
        map((data) => User.fromJSON(data))
      );
  }

  setDisclaimerDate(guid): Observable<any> {
    return this.http.put(`${this.apiBase}/user/${guid}/setDisclaimerDate`, null)
      .pipe(
        map((data) => User.fromJSON(data))
      );
  }

  checkLoginStatus(): Observable<boolean> {
    return this.http.get(`${this.apiBase}/user/ping`, {observe: 'response', headers: {ignoreLoadingBar: ''}})
      .pipe(
        map((resp: HttpResponse<any>) => resp.status),
        catchError((error) => error instanceof HttpErrorResponse ? of(error.status) : of(-1)),
        map((code: number) => code !== 401)
      );
  }
}
