import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { AduraService } from '@app/shared/services/adura.service';
import { TranslateService } from '@ngx-translate/core';
import { AduraGefahrCreateComponent } from '@app/adura/shared/gefahr-create/adura-gefahr-create.component';
import { AduraTreiberCreateComponent } from '@app/adura/shared/treiber-create/adura-treiber-create.component';
import { AduraPublikationAddComponent } from '@app/adura/shared/publikation/publikation-add/adura-publikation-add.component';
import { AduraMatrixEditComponent } from '@app/adura/shared/matrix/matrix-edit/adura-matrix-edit.component';
import { Gefahr } from '@app/shared/classes/adura/gefahr';
import { Bereich } from '@app/shared/classes/adura/bereich';
import { Matrix } from '@app/shared/classes/adura/matrix';
import { Treiber } from '@app/shared/classes/adura/treiber';
import { Meldung } from '@app/shared/classes/adura/meldung';
import { PublikationDetail } from '@app/shared/classes/adura/publikation';
import { Status } from '@app/shared/classes/adura/status';
import { Steckbrief } from '@app/shared/classes/adura/steckbrief';
import { SeismoInfo } from '@app/shared/classes/adura/seismoInfo';
import Swal from 'sweetalert2';
import { AduraMeldungWeitereInfoComponent } from '@app/adura/meldung/meldung-weitere-info/adura-meldung-weitere-info.component';
import { SteckbriefService } from '@app/shared/services/steckbrief.service';

@Component({
  selector: 'app-adura-meldung-edit',
  templateUrl: './adura-meldung-edit.component.html'
})
export class AduraMeldungEditComponent implements OnInit {
  meldung: Meldung;
  hasSteckbrief = false;

  meldungForm: UntypedFormGroup;

  // Stammdaten
  bereichList: Bereich[];
  gefahrList: Gefahr[];
  treiberList: Treiber[];
  statusList: Status[];
  steckbriefList: Steckbrief[];
  seismoInfoList: SeismoInfo[];

  isSending = false;
  error = false;
  delChosen = false;
  deleteError = false;
  errorMessages = [];

  tinyConfigTitel: any;
  tinyConfigEnterAndLinksAllowed: any;

  remainingCharactersMap = {
    titel: '',
    kurzinfo: '',
    kommentar: ''
  };

  maxCharsMap = {
    titel: 255,
    kurzinfo: 4000,
    kommentar: 4000
  };

  @ViewChild(AduraGefahrCreateComponent) gefahrCreate: AduraGefahrCreateComponent;
  @ViewChild(AduraTreiberCreateComponent) treiberCreate: AduraTreiberCreateComponent;
  @ViewChild(AduraPublikationAddComponent) publikationAdd: AduraPublikationAddComponent;
  @ViewChild(AduraMatrixEditComponent) matrixEdit: AduraMatrixEditComponent;
  @ViewChild(AduraMeldungWeitereInfoComponent) weitereInfoEdit: AduraMeldungWeitereInfoComponent;

  constructor(private aduraService: AduraService,
              private translateService: TranslateService,
              private authenticationService: AuthenticationService,
              private steckbriefService: SteckbriefService,
              private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    const meldungCode = this.route.snapshot.paramMap.get('code');

    console.log('Meldungscode: ');
    console.log(meldungCode);

    if (meldungCode === null) {
      this.meldung = new Meldung();
      this.loadSeismoInfoList();
      this.setHasSteckbrief();
    } else {
      this.aduraService.getMeldung(meldungCode)
        .subscribe(meldung => {
          this.meldung = meldung;
          this.loadSeismoInfoList();
          this.setHasSteckbrief();
          this.updateForm();
          this.disableInputsByRole();
        });
    }

    this.aduraService.listBereich()
      .subscribe(
        (result) => {
          this.bereichList = result;
        });
    this.aduraService.listGefahr()
      .subscribe(
        (result) => {
          this.gefahrList = result;
        });
    this.aduraService.listMeldungStatus(meldungCode)
      .subscribe(
        (result) => {
          this.statusList = result;
        });
    this.aduraService.listSteckbriefMinimal()
      .subscribe(
        (result) => {
          this.steckbriefList = result;
          this.meldung?.steckbriefe?.forEach(linkedSteckbrief =>
            this.steckbriefList.find(steckbrief => steckbrief.id === linkedSteckbrief.id).disabled = true
          );
        });
    this.reloadTreiberList();
    this.initForm();
    this.setupTinyMce();
  }

  private setupTinyMce(): void {
    this.tinyConfigTitel = {
      menubar: false,
      toolbar: 'undo bold italic',
      valid_elements: 'strong,em',
      entity_encoding: 'raw',
      setup: this.preventEnterInTinyMceEditor
    };

    this.tinyConfigEnterAndLinksAllowed = {
      menubar: false,
      plugins: 'lists link',
      toolbar: 'undo bold italic bullist link',
      valid_elements: 'strong,em,br,a[href|target=_blank],ul,li',
      default_link_target: '_blank',
      target_list: false,
      entity_encoding: 'raw',
      convert_urls: false
    };
  }

  private initForm() {
    this.meldungForm = this.formBuilder.group({
      isPublic: [false],
      sterne: [''],
      titel: [''],
      kurzinfo: [''],
      kommentar: [''],
      gefahren: [[]],
      matrix: new UntypedFormArray([]),
      treiber: [[]],
      bereiche: new UntypedFormArray([], Validators.minLength(1)),
      steckbriefe: [[]],
      basisQuelle: [],
      originalPublikationen: new UntypedFormArray([]),
      weitereInfos: new UntypedFormArray([]),
      seismoPublikation: [false],
      seismoInfoAusgabe: [''],
      status: [null],
      logComment: [null]
    });
  }

  private updateForm() {
    this.meldungForm.reset({
      isPublic: this.meldung.isPublic,
      titel: this.meldung.titel,
      kurzinfo: this.meldung.kurzinfo,
      kommentar: this.meldung.kommentar,
      gefahren: this.meldung.gefahren,
      treiber: this.meldung.treiber,
      steckbriefe: this.meldung.steckbriefe,
      basisQuelle: this.meldung.publikationen.find(it => it.type === 10),
      seismoPublikation: this.meldung.seismoPublikation,
      seismoInfoAusgabe: this.meldung.seismoInfoAusgabe,
      status: this.meldung.status,
      logComment: this.meldung.logComment
    });

    const bereicheFormArray = this.meldungForm.get('bereiche') as UntypedFormArray;
    this.setFormArrayValues(bereicheFormArray, this.meldung.bereiche);

    const matrixFormArray = this.meldungForm.get('matrix') as UntypedFormArray;
    this.setFormArrayValues(matrixFormArray, this.meldung.matrix);

    const originalPublikationenFormArray = this.meldungForm.get('originalPublikationen') as UntypedFormArray;
    this.setFormArrayValues(originalPublikationenFormArray, this.meldung.publikationen.filter(it => it.type === 20));

    const weitereInfoFormArray = this.meldungForm.get('weitereInfos') as UntypedFormArray;
    this.setFormArrayValues(weitereInfoFormArray, this.meldung.publikationen.filter(it => it.type === 30));

    this.meldungForm.markAsPristine();
  }

  private setFormArrayValues(formArray: UntypedFormArray, objectArray: any[]) {
    objectArray?.forEach(weitereInfo =>
      formArray.push(new UntypedFormControl(weitereInfo))
    );
  }

  saveMeldung() {
    this.meldungForm.markAsPristine();
    this.aduraService.saveMeldung(this.prepareMeldungForSaving())
      .subscribe({
        next: (result) => {
          this.showSuccessAlert('adura.meldung.saved');
          this.router.navigate(['/adura/notification/', result.code]);
        },
        error: error => {
          this.isSending = false;
          this.error = true;
          this.errorMessages = error.error;
          console.log('isSending');
          console.log(this.isSending);
          console.log(this.errorMessages);
        }
      });
  }

  saveMeldungCreateSteckbrief() {
    this.meldungForm.markAsPristine();

    const meldung = this.prepareMeldungForSaving();
    meldung.createSteckbrief = true;

    this.aduraService.saveMeldungCreateSteckbrief(meldung)
      .subscribe({
        next: (result) => {
          this.showSuccessAlert('adura.meldung.saved');
          this.steckbriefService.steckbrief = result;
          this.router.navigate(['/adura/factsheet/new']);
        },
        error: error => {
          this.isSending = false;
          this.error = true;
          this.errorMessages = error.error;
          console.log('isSending');
          console.log(this.isSending);
          console.log(this.errorMessages);
        }
      });
  }

  private prepareMeldungForSaving(): Meldung {
    this.isSending = true;
    const formValues = this.meldungForm.value;
    const meldung = new Meldung();
    meldung.id = this.meldung.id;
    meldung.code = this.meldung.code;
    meldung.isPublic = formValues.isPublic;
    meldung.sterne = formValues.sterne == null || isNaN(formValues.sterne) ? this.meldung.sterne : (formValues.sterne || 0);
    meldung.titel = formValues.titel;
    meldung.kurzinfo = formValues.kurzinfo;
    meldung.kommentar = formValues.kommentar;
    meldung.status = formValues.status;
    meldung.seismoPublikation = formValues.seismoPublikation;
    meldung.seismoInfoAusgabe = formValues.seismoInfoAusgabe;
    meldung.bereiche = this.meldungForm.get('bereiche').value;
    meldung.matrix = this.meldungForm.get('matrix').value;
    meldung.gefahren = this.meldungForm.get('gefahren').value;
    meldung.treiber = this.meldungForm.get('treiber').value;
    meldung.steckbriefe = this.meldungForm.get('steckbriefe').value;
    meldung.publikationen = [];
    if (this.meldungForm.get('basisQuelle').value !== null) {
      meldung.publikationen.push(this.meldungForm.get('basisQuelle').value);
    }
    if (this.meldungForm.get('originalPublikationen').value !== null) {
      meldung.publikationen.push(...this.meldungForm.get('originalPublikationen').value);
    }
    if (this.meldungForm.get('weitereInfos').value !== null) {
      meldung.publikationen.push(...this.meldungForm.get('weitereInfos').value);
    }
    meldung.logComment = formValues.logComment;
    return meldung;
  }

  deleteMeldung() {
    this.delChosen = true;
    Swal.fire({
      text: this.translateService.instant('adura.meldung.delete.title'),
      icon: 'warning',
      customClass: { htmlContainer: 'content-text-center' },
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('adura.meldung.delete.confirm'),
      cancelButtonText: this.translateService.instant('global.cancel')
    }).then((result) => {
      if (result.value) {
        this.continueDeleteMeldung();
      } else {
        this.delChosen = false;
      }
    });
  }

  private continueDeleteMeldung() {
    this.isSending = true;

    this.aduraService.deleteMeldung(this.meldung.id)
      .subscribe({
        next: () => location.href = '/adura/search',
        error: error => {
          this.isSending = false;
          this.deleteError = true;
          this.errorMessages = error.error;
          console.log('isSending');
          console.log(this.isSending);
          console.log(this.errorMessages);
        }
      });
  }

  onPrimaryDomainChange(event) {
    const target = event.target;
    const selectedBereichId = +target.value;
    const selectedBereich = this.bereichList.find(it => it.id === selectedBereichId);

    const bereicheFormArray = this.meldungForm.get('bereiche') as UntypedFormArray;
    const formControlIndex = bereicheFormArray.controls.findIndex(it => it.value.id === selectedBereichId);

    // Bereich dem Form hinzufügen und primär = true / false setzen
    if (target.checked && formControlIndex === -1) {
      selectedBereich.primaer = target.checked;
      bereicheFormArray.push(new UntypedFormControl(selectedBereich));
    } else if (target.checked && formControlIndex > -1) {
      bereicheFormArray.at(formControlIndex).value.primaer = true;
    } else if (!target.checked && formControlIndex > -1) {
      bereicheFormArray.at(formControlIndex).value.primaer = false;
    }

    // Andere Primärer-Bereich Checkboxes disabled toggeln
    const primaryCheckboxes = document.querySelectorAll('.primary-domain-checkbox') as NodeListOf<HTMLInputElement>;
    primaryCheckboxes.forEach( primaryCheckbox => {
      if (primaryCheckbox !== target) {
        primaryCheckbox.disabled = target.checked;
      }
    });

    // Haken beim Sekundären-Bereich setzen / entfernen
    const secondaryCheckboxes = document.querySelectorAll('.secondary-domain-checkbox') as NodeListOf<HTMLInputElement>;
    secondaryCheckboxes.forEach( secondaryCheckbox => {
      if (!secondaryCheckbox.checked && (+secondaryCheckbox.value === selectedBereichId)) {
        secondaryCheckbox.checked = true;
      }
    });

    this.meldungForm.markAsDirty();
  }

  onSecondaryDomainChange(event) {
    const target = event.target;
    const selectedBereichId = +target.value;
    const selectedBereich = this.bereichList.find(it => it.id === selectedBereichId);

    const bereicheFormArray = this.meldungForm.get('bereiche') as UntypedFormArray;
    const formControlIndex = bereicheFormArray.controls.findIndex(it => it.value.id === selectedBereichId);

    // Bereich dem FormArray hinzufügen oder entfernen
    if (target.checked && formControlIndex === -1) {
      bereicheFormArray.push(new UntypedFormControl(selectedBereich));
    } else if (!target.checked && formControlIndex > -1) {
      bereicheFormArray.removeAt(formControlIndex);
    }

    if (!target.checked) {
      // Primärer-Bereich Checkbox haken entfern, wo nötig
      const primaryCheckboxes = document.querySelectorAll('.primary-domain-checkbox') as NodeListOf<HTMLInputElement>;
      primaryCheckboxes.forEach( primaryCheckbox => {
        if (primaryCheckbox.checked && (+primaryCheckbox.value === selectedBereichId)) {
          primaryCheckbox.click();
        }
      });

      // Bugfix: Checked explizit = false setzen, ansonsten muss man zweimal clicken damit den Haken entfernt wird.
      target.checked = false;
    }

    this.meldungForm.markAsDirty();
  }

  isPrimaryBereichSelected() {
    const primaryBereich = this.meldung?.bereiche?.find(
      (bereich) => bereich.primaer === true
    );
    return primaryBereich !== undefined;
  }

  isBereichActiveAndPrimary(bereichId: number): boolean {
    const selectedBereich = this.meldung?.bereiche?.find(
      (bereich) => bereich.id === bereichId
    );
    return selectedBereich?.primaer;
  }

  bereichActive(bereichId: number): boolean {
    const selectedBereich = this.meldung?.bereiche?.find(
      (bereich) => bereich.id === bereichId
    );
    return selectedBereich !== undefined;
  }

  removeGefahr(gefahr: Gefahr): void {
    const gefahrenFormArray = this.meldungForm.get('gefahren') as UntypedFormArray;
    const gefahrenArray: Gefahr [] = gefahrenFormArray.value?.filter(gef => gef.id !== gefahr.id);
    gefahrenFormArray.patchValue(gefahrenArray);
  }

  get cancelLink() {
    if (this.meldung?.code) {
      return '/adura/notification/' + this.meldung.code;
    } else {
      // Gehe zur Suchseite
      return '/adura/search/';
    }
  }

  showSuccessAlert(translateString: string) {
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          });
        });
  }

  get id() {
    return (this.meldung && this.meldung.id) ? this.meldung.id : null;
  }

  get code() {
    return (this.meldung && this.meldung.code) ? this.meldung.code : null;
  }

  get titel() {
    return '<i class="fa fa-bullhorn"></i>&nbsp;&nbsp;' + this.meldung.titel;
  }

  get basisQuelle() {
    return (this.meldung && this.meldungForm.get('basisQuelle') && this.meldungForm.get('basisQuelle').value) ? this.meldungForm.get('basisQuelle').value : null;
  }

  get originalPublikationen(): PublikationDetail[] {
    return (this.meldung && this.meldungForm.get('originalPublikationen') && this.meldungForm.get('originalPublikationen').value)
      ? this.meldungForm.get('originalPublikationen').value : null;
  }

  get matrix() {
    return (this.meldung && this.meldung.matrix) ? this.meldung.matrix : null;
  }

  get bereiche() {
    return (this.meldung && this.meldung.bereiche) ? this.meldung.bereiche : null;
  }

  publishSeismoInfo(): boolean {
    return this.meldungForm.controls.seismoPublikation.value;
  }

  loadSeismoInfoList(setIsPublic: boolean = false) {
    this.aduraService.getSeismoInfoAusgabeList(this.meldung.code).subscribe(seismoInfo => {
      this.seismoInfoList = seismoInfo;
      const thisInfo = this.seismoInfoList.find(it => it.selected === true);
      this.meldungForm.patchValue({
        seismoInfoAusgabe: thisInfo.month,
      });
      if (thisInfo.gesperrt) {
        this.meldungForm.get('seismoInfoAusgabe').disable();
      }
    });
    if (setIsPublic && !this.meldungForm.get('isPublic').value) {
      this.meldungForm.get('isPublic')?.setValue(true);
    }
  }

  getRating(): number {
    return this.meldung?.sterne;
  }

  setRating(rating: number): void {
    this.meldungForm.get('sterne')?.setValue(rating);
    this.meldungForm.markAsDirty();
  }

  openCreateGefahr() {
    this.gefahrCreate.open();
  }

  reloadGefahrList() {
    this.aduraService.listGefahr()
      .subscribe(
        (result) => {
          this.gefahrList = result;
        });
  }

  openCreateTreiber() {
    this.treiberCreate.open();
  }

  reloadTreiberList() {
    this.aduraService.listTreiber()
      .subscribe(
        (result) => {
          this.treiberList = result;
        });
  }

  openAddPublikation(pubDetailType: number, publikationDetail?: PublikationDetail) {
    this.publikationAdd.open(publikationDetail || new PublikationDetail(), pubDetailType);
  }

  reloadPublikationBasisquelle(pub: PublikationDetail) {
    this.meldungForm.get('basisQuelle')?.setValue(pub);
    this.meldungForm.markAsDirty();
  }

  removeBasisQuelle() {
    this.meldungForm.get('basisQuelle')?.setValue(null);
    this.meldungForm.markAsDirty();
  }

  updateOriginalPublikationenList(publikationDetail: PublikationDetail) {
    const originalPublikationenFormArray = this.meldungForm.get('originalPublikationen') as UntypedFormArray;
    const originalPublikationen = originalPublikationenFormArray.value.find(pub => pub === publikationDetail);

    if (originalPublikationen === undefined) {
      originalPublikationenFormArray.value?.push(publikationDetail);
      this.meldungForm.markAsDirty();
    }
  }

  removeOriginalPublikation(publikationDetail: PublikationDetail) {
    const originalPublikationenFormArray = this.meldungForm.get('originalPublikationen') as UntypedFormArray;
    const originalPublikationenArray: PublikationDetail [] = originalPublikationenFormArray.value?.filter(pub => pub !== publikationDetail);
    originalPublikationenFormArray.clear();
    this.meldungForm.markAsDirty();

    if (originalPublikationenArray.length === 0) {
      this.meldungForm.get('originalPublikationen').setValue([]);
    } else {
      this.setFormArrayValues(originalPublikationenFormArray, originalPublikationenArray);
    }
  }

  openAddWeitereInfo(weitereInfo?: PublikationDetail) {
    this.weitereInfoEdit.open(weitereInfo || new PublikationDetail());
  }

  updateWeitereInfoList(weitereInfoToUpdate: PublikationDetail) {
    const weitereInfoFormArray = this.meldungForm.get('weitereInfos') as UntypedFormArray;
    const weitereInfo = weitereInfoFormArray.value.find(wi => wi === weitereInfoToUpdate);

    if (weitereInfo === undefined) {
      weitereInfoFormArray.value?.push(weitereInfoToUpdate);
      this.meldungForm.markAsDirty();
    }
  }

  removeWeitereInfo(weitereInfo: PublikationDetail) {
    const weitereInfoFormArray = this.meldungForm.get('weitereInfos') as UntypedFormArray;
    const weitereInfoArray: PublikationDetail[] = weitereInfoFormArray.value?.filter(wi => wi !== weitereInfo);
    weitereInfoFormArray.clear();
    this.meldungForm.markAsDirty();

    if (weitereInfoArray.length === 0) {
      this.meldungForm.get('weitereInfos').setValue([]);
    } else {
      this.setFormArrayValues(weitereInfoFormArray, weitereInfoArray);
    }
  }

  openAddMatrix() {
    this.matrixEdit.open();
  }

  openEditMatrix(matrix: Matrix) {
    this.matrixEdit.open(matrix);
  }

  updateMatrixList(matrix: Matrix) {
    const matrixFormArray = this.meldungForm.get('matrix') as UntypedFormArray;

    if (!matrixFormArray.value?.includes(matrix)) {
      matrixFormArray.value?.push(matrix);
      this.meldungForm.markAsDirty();
    }
  }

  removeMatrix(matrix: Matrix) {
    const matrixFormArray = this.meldungForm.get('matrix') as UntypedFormArray;
    const matrixArray: Matrix [] = matrixFormArray.value?.filter(mtx => mtx !== matrix);
    matrixFormArray.clear();

    if (matrixArray.length === 0) {
      this.meldungForm.get('matrix').setValue([]);
    } else {
      this.setFormArrayValues(matrixFormArray, matrixArray);
    }
  }

  setHasSteckbrief(steckbrief?: Steckbrief) {
    this.hasSteckbrief = steckbrief !== undefined || this.meldung.steckbriefe?.length > 0;
  }

  removeSteckbrief(steckbrief: Steckbrief): void {
    const steckbriefFormArray = this.meldungForm.get('steckbriefe') as UntypedFormArray;
    const steckbriefArray: Steckbrief [] = steckbriefFormArray.value?.filter(sb => sb.id !== steckbrief.id);
    steckbriefFormArray.patchValue(steckbriefArray);
    this.hasSteckbrief = steckbriefArray.length > 0;
  }

  preventEnterInTinyMceEditor(editor) {
    editor.on('keydown', e => (e.keyCode !== 13));
  }

  hasError(field: string) {
    if (Array.isArray(this.errorMessages)) {
      return this.errorMessages.find(it => it.path === field);
    } else {
      return false;
    }
  }

  getError(field: string) {
    if (Array.isArray(this.errorMessages)) {
      const error = this.errorMessages.find(it => it.path === field);
      return error.message;
    }
    return '';
  }

  disableInputsByRole() {
    if (!this.authenticationService.hasRole('AWISA_FRESIL')) {
      this.meldungForm.get('seismoPublikation').disable();
      this.meldungForm.get('seismoInfoAusgabe').disable();
      this.meldungForm.get('status').disable();

      if (this.meldung.status.id === 20) {
        this.router.navigate(['/adura/notification/', this.meldung.code]);
      }
    }

    if (this.meldung.status.id === 90) {
      this.router.navigate(['/adura/notification/', this.meldung.code]);
    }
  }

  updateRemainingCharacters(fieldName: string, event: any) {
    const editor = event.editor;
    const maxChars = this.maxCharsMap[fieldName];
    let editorText = this.meldungForm.get(fieldName).value;

    if (maxChars < editorText?.length) {
      editorText = editorText.slice(0, maxChars);
      this.meldungForm.get(fieldName).setValue(editorText);
      // https://stackoverflow.com/a/19836226
      editor.selection.select(editor.getBody(), true);
      editor.selection.collapse(false);
    }

    this.remainingCharactersMap[fieldName] = maxChars - (editorText?.length || 0);
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return !this.meldungForm.dirty;
  }
}
