import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { UrlService } from '@app/core/routing/navigation/url.service';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * Passes on the KeyCloak token
 */
@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {

  private url: string;

  constructor(private authenticationService: AuthenticationService,
              private route: ActivatedRoute,
              private urlService: UrlService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.url = previousUrl;
    });

    return from(this.authenticationService.getAccessToken())
      .pipe(
        switchMap(authToken => {
          if (!authToken) {
            return next.handle(request);
          }

          let referrerHeader = {};
          // Falls der Referrer vom Download her bereits gesetzt wurde, bleibt der drin
          if (request.headers.get('X-UserReferrer') == null) {
            referrerHeader = this.url ? {'X-UserReferrer': this.url} : {};
          }

          const clone = request.clone({
            setHeaders: {
              Authorization: 'Bearer ' + authToken,
              ...referrerHeader
            }
          });
          return next.handle(clone);
        })
      );
  }
}
