import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ConfigurationService} from '../../core/config/configuration.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Task} from '../classes/task';

@Injectable({ providedIn: 'root' })
export class MailerService {

  private apiBase: string;

  constructor(private http: HttpClient,
              configurationService: ConfigurationService) {
    this.apiBase = configurationService.getConfig().mailerUrl;
  }

  processSubscriptions(): Observable<Task> {
    return this.http.get(`${this.apiBase}/job/processSubscriptions`)
      .pipe(
        map((data) => Task.fromJSON(data))
      );
  }

  lastSuccessOfAllJobs(): Observable<Task[]> {
    return this.http.get(`${this.apiBase}/job/success`)
      .pipe(
        map((data: Task[]) => data.map(Task.fromJSON))
      );
  }

  testmail(): Observable<any> {
    return this.http.get(`${this.apiBase}/job/test`, {responseType: 'text'})
      .pipe();
  }

  getVersion(): Observable<any> {
    return this.http.get(`${this.apiBase}/admin/version`, {responseType: 'text'})
      .pipe();
  }
}
