<section class="inverted-container">
  <div class="container">
    <div class="row">
      <div class="changes-list col-md-12 col-xs-12">
        <div class="changes-title">
          <h2>{{ 'global.lastChanges' | translate }}</h2>
          <span class="changes-list">(<a class="item-content-name" routerLink="/search/content"
                                               [queryParams]="{sort: 'lastModified'}">{{ 'global.more' | translate }}</a>)</span>
       </div>
        <ul *ngFor="let top10Item of top10Items" class="change-item clear-padding-left">
          <h3 class="top10-changes">{{ top10Item.date | date: 'dd.MM.yyyy'  }}</h3>
          <li class="change-item dataset-item clear-margin-bottom clear" *ngFor="let content of top10Item.content">
            <div class="dataset-content">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-xs-12 change-item-name">
                    <a href="{{ content.url }}">{{ content.name }} </a>
                    <span class="doc-item-format">
                      <h4 class="sr-only">{{ "content.type" | translate }}: </h4>
                        <label attr.aria-label="{{ content.type | translate }}" title="{{ content.type | translate }}">
                          ({{ content.type | translate }})
                        </label>
                    </span>
                  </div>
                  <div *ngIf="content.type === 'comment.label'" class="col-xs-12 clear-padding-left">
                    <p class="clear-margin-bottom change-item-date"
                       [attr.aria-label]="content.author + ': ' + content.description">
                      {{ content.author }}: {{ content.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
