<ng-container *ngIf="newEventEnabled; else disabledNewEvent">
  <header class="page-header">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <button appBackButton type="button" class="btn btn-sm btn-primary back-button"
                  title="{{ 'global.back' | translate }}"
                  [attr.aria-label]="'global.back' | translate">
            <i class="fa fa-arrow-left"></i>
          </button>
        </div>
      </div>
    </div>
  </header>

  <section class="container information">
    <h1>{{ 'event.new' | translate }}</h1>
    <form [formGroup]="eventForm" (ngSubmit)="createTermin()">
      <p *ngIf="error" class="alert alert-danger" role="alert"> {{ 'global.error' | translate}}</p>
      <div class="form-group">
        <div class="form-element">
          <label for="name">{{ 'global.name' | translate }}*:</label>
          <input
            type="text"
            class="form-control"
            id="name"
            [ngClass]="(eventForm.controls.name.invalid && eventForm.controls.name.dirty) ? 'errors-input':''"
            formControlName="name"
            maxlength="400"
            autocomplete="off">
          <p role="alert" class="errors-text"
             *ngIf="(eventForm.controls.name.invalid && eventForm.controls.name.dirty)">
            {{ 'global.validation.name' | translate }}
          </p>
        </div>
        <div class="col-md-4 col-xs-12 clear-padding-left">
          <label for="dateFrom">{{ 'global.from' | translate }}*:</label><br/>
          <div class="col-md-6 col-xs-6 clear-padding-left form-element">
            <dp-date-picker aria-hidden="true" placeholder="{{datePickerConfig.format}}" theme="dp-material"
                            name="dateFrom"
                            class="form-control event-datepicker"
                            formControlName="dateFrom"
                            [ngClass]="(eventForm.controls.dateFrom.invalid && eventForm.controls.dateFrom.dirty) ? 'errors-input':''"
                            [(ngModel)]="selectedDateFrom" [config]="datePickerConfig"
                            (onSelect)="getDateFrom()">
            </dp-date-picker>
            <input (keyup.enter)="getDateFromFromEvent($event)"
                   id="dateFrom"
                   placeholder="{{datePickerConfig.format}}" type="text"
                   tabindex="-1" class="sr-only" [attr.aria-label]="'date.from' | translate">
          </div>
          <div class="col-md-4 col-xs-6 clear-padding-left form-element">
            <input id="timeFrom"
                   placeholder="HH:MM"
                   class="form-control"
                   [ngClass]="(eventForm.controls.timeFrom.invalid && eventForm.controls.timeFrom.dirty) ? 'errors-input':''"
                   autocomplete="off"
                   formControlName="timeFrom"
                   type="text"
                   [attr.aria-label]="'time.from' | translate">
          </div>
        </div>
        <div class="col-md-8 col-xs-12 clear-padding-left">
          <label for="dateTo">{{ 'global.to' | translate }}*:</label><br/>
          <div class="col-md-3 col-xs-6 clear-padding-left form-element">
            <dp-date-picker aria-hidden="true" placeholder="{{datePickerConfig.format}}" theme="dp-material"
                            name="dateTo"
                            class="form-control event-datepicker"
                            formControlName="dateTo"
                            [ngClass]="(eventForm.controls.dateTo.invalid && eventForm.controls.dateTo.dirty) ? 'errors-input':''"
                            [(ngModel)]="selectedDateTo" [config]="datePickerConfig"
                            (onSelect)="getDateTo()">
            </dp-date-picker>
            <input (keyup.enter)="getDateToFromEvent($event)"
                   name="dateTo"
                   id="dateTo"
                   placeholder="{{datePickerConfig.format}}" type="text"
                   tabindex="-1" class="sr-only" [attr.aria-label]="'global.to' | translate">
          </div>
          <div class="col-md-2 col-xs-6 clear-padding-left form-element">
            <input id="timeTo"
                   placeholder="HH:MM"
                   class="form-control"
                   [ngClass]="(eventForm.controls.timeTo.invalid && eventForm.controls.timeTo.dirty) ? 'errors-input':''"
                   autocomplete="off"
                   formControlName="timeTo"
                   type="text"
                   [attr.aria-label]="'time.to' | translate">
          </div>
        </div>
        <div class="form-element">
          <div class="col-md-4 col-xs-12 clear-padding-left">
            <p role="alert" class="errors-text"
               *ngIf="(eventForm.controls.dateFrom.invalid && eventForm.controls.dateFrom.dirty)">
              {{ 'global.validation.dateFrom' | translate }}
            </p>
            <p role="alert" class="errors-text"
               *ngIf="(eventForm.controls.timeFrom.invalid && eventForm.controls.timeFrom.dirty)">
              {{ 'global.validation.timeFrom' | translate }}
            </p>
            <p role="alert" class="errors-text" *ngIf="invalidDateRange()">
              {{ 'global.validation.dateRange' | translate }}
            </p>
          </div>
          <div class="col-md-4 col-xs-12 clear-padding-left">
            <p role="alert" class="errors-text"
               *ngIf="(eventForm.controls.dateTo.invalid && eventForm.controls.dateTo.dirty)">
              {{ 'global.validation.dateTo' | translate }}
            </p>
            <p role="alert" class="errors-text"
               *ngIf="(eventForm.controls.timeTo.invalid && eventForm.controls.timeTo.dirty)">
              {{ 'global.validation.timeTo' | translate }}
            </p>
          </div>
          <div class="clearfix">
          </div>
        </div>
        <div class="form-element">
          <label>{{ 'tag.plural' | translate }}*:</label><br/>
          <ng-select appendTo="body"
                     [multiple]="true"
                     dropdownPosition="bottom"
                     [ngClass]="(eventForm.controls.tags.invalid && eventForm.controls.tags.dirty) ? 'errors-input':''"
                     [readonly]="false"
                     formControlName="tags">
            <ng-option *ngFor="let tag of tags" [value]="tag.guid">{{tag.name}}</ng-option>
          </ng-select>
          <p role="alert" class="errors-text"
             *ngIf="(eventForm.controls.tags.invalid && eventForm.controls.tags.dirty)">
            {{ 'global.validation.tags' | translate }}
          </p>
        </div>
        <div class="form-element">
          <label>{{ 'event.shortDescription' | translate }}:</label>
          <app-tabs class="event-desc-tabs">
            <app-tab *ngFor="let language of languages" role="tab"
                     tabId="content" tabTitle="{{ language }}"
                     contentClass="tabmenu-content">
        <textarea
          class="form-control form-element"
          id="desc_{{ language }}"
          formControlName="desc_{{ language }}"
          maxlength="1530"
          autocomplete="off"
          rows="3"></textarea>
            </app-tab>
          </app-tabs>
        </div>
        <div class="form-element">
          <label>{{ 'event.subevent.plural' | translate }}:</label><br/>
          <ng-template #subevents></ng-template>
          <div class="col-md-12 clear-padding-left">
            <i class="fa fa-plus-circle subevent-add-button"
               (click)="addSubEvent()"></i>
          </div>
        </div>
        <div class="form-element">
          <label>{{ 'global.permission' | translate }}*:
            <div class="tooltip-awisa">
              <i class="fa fa-info-circle"></i>
              <span class="tooltiptext-awisa"><div [innerHTML]="html"></div></span>
            </div>
          </label><br/>
          <ng-container *ngFor="let role of roles">
            <input type="checkbox" class="filter-checkbox sr-only" id="{{role.name}}"
                   name="{{role.identifier}}"
                   (change)="chooseRole($event)"
                   role="checkbox" [checked]="isRoleActive(role.identifier)">
            <label class="filter-checkbox-label" for="{{role.name}}"
                   role="checkbox"
                   [attr.aria-checked]="(isRoleActive(role.identifier))?'true':'false'">
              {{ role.name }}
            </label>
          </ng-container>
          <p role="alert" class="errors-text" *ngIf="(rolesForm.invalid)">
            {{ 'global.validation.role' | translate }}
          </p>
        </div>
      </div>
      <div class="form-element">
        <ng-container *ngIf="isSending; then showspinner else hidespinner"></ng-container>

        <ng-template #hidespinner>
          <button type="submit" class="btn btn-primary">
            {{ 'global.create' | translate }}
          </button>
        </ng-template>

        <ng-template #showspinner>
          <button type="submit" class="btn btn-primary" [disabled]="isSending">
            <i class="fa fa-spinner fa-pulse fa-fw"></i>
          </button>
        </ng-template>
      </div>
    </form>
  </section>
</ng-container>
<ng-template #disabledNewEvent>
  <div class="row">
    <div class="errors">
      <h1>{{ 'settings.disabledNewEvent' | translate }}</h1>
    </div>
  </div>
</ng-template>
