<section class="facet-filter">
  <h3 title="{{ 'adura.date.created' | translate }}" [attr.aria-label]="'adura.date.created' | translate">{{ 'adura.date.created' | translate }}</h3>
  <div>
    <nav [attr.aria-label]="'date.selection' | translate">
      <dp-date-picker class="search-doc-date" aria-hidden="true" placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                      [(ngModel)]="selectedCreatedDateFrom" [config]="datePickerConfig"
                      (onSelect)="getCreatedDateFrom()" (keyup.enter)="updateEmptyCreatedDateData()">
      </dp-date-picker>
      <input (keyup.enter)="getCreatedDateFromEvent($event)" [ngModel]="selectedCreatedDateFrom | date: 'dd.MM.yyyy'"
             placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" type="text"
             tabindex="-1" class="sr-only" [attr.aria-label]="'date.from' | translate">
      <br/>
      <dp-date-picker aria-hidden="true" placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                      [(ngModel)]="selectedCreatedDateTo" [config]="datePickerConfig"
                      (onSelect)="getCreatedDateTo()" (keyup.enter)="updateEmptyCreatedDateData()">
      </dp-date-picker>
      <input (keyup.enter)="getCreatedDateToEvent($event)" [ngModel]="selectedCreatedDateTo | date: 'dd.MM.yyyy'"
             placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" type="text"
             tabindex="-1" class="sr-only" [attr.aria-label]="'global.to' | translate">
    </nav>
  </div>
  <h3 title="{{ 'adura.date.changed' | translate }}" [attr.aria-label]="'adura.date.changed' | translate">{{ 'adura.date.changed' | translate }}</h3>
  <div>
    <nav [attr.aria-label]="'date.selection' | translate">
      <dp-date-picker class="search-doc-date" aria-hidden="true" placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                      [(ngModel)]="selectedChangedDateFrom" [config]="datePickerConfig"
                      (onSelect)="getChangedDateFrom()" (keyup.enter)="updateEmptyChangedDateData()">
      </dp-date-picker>
      <input (keyup.enter)="getChangedDateFromEvent($event)" [ngModel]="selectedChangedDateFrom | date: 'dd.MM.yyyy'"
             placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" type="text"
             tabindex="-1" class="sr-only" [attr.aria-label]="'date.from' | translate">
      <br/>
      <dp-date-picker aria-hidden="true" placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                      [(ngModel)]="selectedChangedDateTo" [config]="datePickerConfig"
                      (onSelect)="getChangedDateTo()" (keyup.enter)="updateEmptyChangedDateData()">
      </dp-date-picker>
      <input (keyup.enter)="getChangedDateToEvent($event)" [ngModel]="selectedChangedDateTo | date: 'dd.MM.yyyy'"
             placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" type="text"
             tabindex="-1" class="sr-only" [attr.aria-label]="'global.to' | translate">
    </nav>
  </div>
</section>
