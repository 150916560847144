import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Document } from '../shared/classes/document';
import { AwisaFoService } from '../shared/services/awisa-fo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})

export class HomeComponent implements OnInit {
  loading = false;
  docs: Document[];
  sucheForm: UntypedFormGroup;
  term = '';

  constructor(private awisaFoService: AwisaFoService,
              private formBuilder: UntypedFormBuilder,
              private router: Router) {
  }

  ngOnInit() {
    this.sucheForm = this.formBuilder.group({
      term: this.term
    });
  }

  onSubmitSuche() {
    const sucheFormValues = this.sucheForm.value;
    console.log(sucheFormValues.term);

    this.search(sucheFormValues.term);
  }

  search(term: string) {
    this.router.navigate(['/search/content'], {
      queryParams: {
        term,
        status: ['10', '20']
      }
    });
  }

  resetSearch() {
    this.sucheForm.reset();

    this.router.navigate([], {
      queryParams: {},
    });
  }
}
