import { Component, Input } from '@angular/core';
import { ConfigurationService } from '@app/core/config/configuration.service';
import { AduraResultItem } from '@app/shared/classes/adura/adura-result-item';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/auth/authentication.service';

@Component({
  selector: 'app-adura-steckbrief-item',
  templateUrl: './adura-steckbrief-item.component.html'
})
export class AduraSteckbriefItemComponent {
  @Input() steckbrief: AduraResultItem;
  kurzInfoHidden = true;

  constructor(private configurationService: ConfigurationService,
              private authenticationService: AuthenticationService,
              private router: Router) {}

  isProd() {
    return this.configurationService.getStage() === 'PR';
  }

  isEditable(): boolean {
    return (this.authenticationService.hasRole('AWISA_FRESIL')
            || (this.steckbrief?.status === '10' || this.steckbrief?.status === '20'));
  }

  openSteckbrief(code: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/adura/factsheet/${code}/edit`])
    );

    window.open(url, '_blank');
  }

  toggleKurzInfoHidden() {
    this.kurzInfoHidden = !this.kurzInfoHidden;
  }
}
