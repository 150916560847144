import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-link-clipboard',
  template: `
    <button [class]="buttonClass" role="button" title="{{ 'link.copy' | translate }}" [attr.aria-label]="'link.copy' | translate"
       (click)="copyLinkClipboard()"><i class="fa fa-link link" aria-hidden="true"></i></button>
  `
})
export class LinkClipboardComponent {
  @Input() title = '';
  @Input() type = '';
  @Input() buttonClass = 'btn btn-sm btn-primary title-icon';

  constructor(private router: Router,
              private translateService: TranslateService) {
  }

  copyLinkClipboard() {
    const url = window.location.origin + this.router.url;
    const name = this.type ? `${this.title} (${this.type})` : this.title;
    const link = `<a href="${url}">${name}</a>`;
    navigator.clipboard.write([
      new ClipboardItem({
        'text/html'  : new Blob([link], {type: 'text/html'}),
        'text/plain' : new Blob([url],  {type: 'text/plain'})
      })
    ]);
  }

  showInfoAlert(translateString: string) {
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'info',
            showConfirmButton: false,
            timer: 2000
          });
        });
  }
}
