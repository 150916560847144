import { Component } from '@angular/core';
import { ConfigurationService } from '@app/core/config/configuration.service';

@Component({
  templateUrl: './unauthorized.component.html'
})
export class UnauthorizedComponent {
  private apiBase: string;

  constructor(private configurationService: ConfigurationService) {
    this.apiBase = configurationService.getConfig().awisaFOUrl;
  }
}
