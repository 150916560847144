import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { SettingsService } from '@app/core/config/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  html = '';

  constructor(private settingsService: SettingsService,
              private translateService: TranslateService,
              private router: Router,
              private renderer2: Renderer2,
              @Inject(DOCUMENT) private doc: Document) {
  }

  ngOnInit(): void {
    const lang = this.translateService.currentLang;
    const set = this.settingsService.settings().find(el => el.key === 'gui.footer.' + lang);
    this.html = set.value;

    const stagesList = ['localhost',
      'www.awisa-in.blv.admin.ch',
      'www.awisa-tr.blv.admin.ch',
      'www.awisa-vp.blv.admin.ch'];

    // Auf der Produktion wird das Feedbacktool nicht angezeigt
    if (stagesList.includes(window.location.hostname)) {
      const script = this.renderer2.createElement('script');
      script.type = `text/javascript`;
      script.text = `
              Userback = window.Userback || {};
              Userback.access_token = '5929|14981|pEn2E6VEVaqgeV0Co8UgWqTUFOmopxv6b5q771M4xkltFb8cGA';
              (function(id)
                { var s = document.createElement('script'); s.async = 1;s.src = 'https://static.userback.io/widget/v1.js'; var parent_node = document.head || document.body;parent_node.appendChild(s); }
              )('userback-sdk');
        `;

      this.renderer2.appendChild(this.doc.body, script);
    }
  }

  isAwisa(): boolean {
    return !this.router.url.includes('/adura/');
  }
}
