import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DocumentPreviewModalComponent } from '@app/document/document-preview-modal/document-preview-modal.component';
import { Facet } from '@app/shared/classes/facet';
import { ResultItem } from '@app/shared/classes/result-item';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';
import { saveAs } from 'file-saver';
import { ConfigurationService } from '@app/core/config/configuration.service';

@Component({
  selector: 'app-doc-item',
  templateUrl: './doc-item.component.html'
})
export class DocItemComponent {

  @Input() searchTerm: string;
  @Input() content: ResultItem;
  @Input() activeTags: string[] = [];
  @Input() allTags: Facet[] = [];
  @Output() changedTag: EventEmitter<string> = new EventEmitter();
  @ViewChild(DocumentPreviewModalComponent) documentPreviewModalComponent: DocumentPreviewModalComponent;
  loading = false;
  loadingPreview = false;
  documentType = 'document';

  constructor(private awisaFoService: AwisaFoService,
              private domSanitizer: DomSanitizer,
              private configurationService: ConfigurationService) {}

  isProd() {
    return this.configurationService.getStage() === 'PR';
  }

  getMarkedContent(contentString: string): SafeHtml {
    if (this.searchTerm === '' || this.searchTerm === undefined || this.searchTerm === null || this.searchTerm.length < 3) {
      return contentString;
    }

    const fullSearchString = this.searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '');
    const searchTerms: string[] = fullSearchString.split(/\s+/).filter(term =>  term.trim() !== '');
    searchTerms.sort((a: string, b: string) => b.length - a.length);
    console.log(searchTerms);

    searchTerms.forEach(searchTerm => {
      // Nur ausserhalb HTML Tags suchen, da wir Treffer mit <mark></mark> markieren, und wir nur einmal markieren möchten
      const regExp = new RegExp('(' + searchTerm + ')(?![^<]*>|[^<>]*<\/)', 'i');
      let matchArray = contentString.match(regExp);
      const holderArray = [];

      while (matchArray !== null) {
        const contentStringUntilTermToMark = contentString.substring(0, matchArray.index);
        const markedContentStringPart = contentStringUntilTermToMark + '<mark>' + matchArray[0] + '</mark>';
        holderArray.push(markedContentStringPart);
        contentString = contentString.substring(matchArray.index + matchArray[0].length);
        matchArray = contentString.match(regExp);
      }

      if (contentString.length > 0) {
        holderArray.push(contentString);
      }

      if (holderArray.length > 0) {
        contentString = holderArray.filter(str => str.trim() !== '').join('');
      }
    });

    return contentString;
  }

  getFormat() {
    return this.content.format.join(', ');
  }

  changeTag(tag: string) {
    this.changedTag.emit(tag);
  }

  isTagActive(tag: any) {
    return this.activeTags.find(res => res === this.getTagGuid(tag));
  }

  getTagGuid(tagName: string): string {
    return this.allTags.find(tag => tag.name === tagName).guid;
  }

  getDocumentPreview(guid: string) {
    this.loadingPreview = true;

    this.awisaFoService.getDocumentPreview(guid)
      .subscribe({
        next: (response) => {
          this.documentPreviewModalComponent.open(
            response, this.content.name
          );
          this.loadingPreview = false;
        },
        error: e => {
          console.log(e);
          this.loadingPreview = false;
        }
      });
  }

  getDocument(guid: string) {

    this.loading = true;

    this.awisaFoService.getDocumentFromSearch(guid)
      .subscribe({
        next: (response: HttpResponse<Blob>) => {
          const blob = new Blob([response.body], {type: response.headers.get('Content-Type')});
          const contentDisposition: string = response.headers.get('content-disposition');
          const r = /(?:filename=")(.+)(?:")/;
          const filename = r.exec(contentDisposition)[1];
          console.log(filename);
          saveAs(blob, filename);
          this.loading = false;
        },
        error: e => {
          console.log(e);
          this.loading = false;
        }
      });
  }
}
