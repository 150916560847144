export interface PublikationJSON {
  id: number;
  titel: string;
  abkuerzung: string;
}

export class Publikation {
  id: number;
  titel: string;
  abkuerzung: string;

  get titelAbkuerzung() {
    return this.titel + ' ' + this.abkuerzung;
  }

  static fromJSON(json: string | object): Publikation {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const land = Object.create(Publikation.prototype);
    return Object.assign(land, object);
  }

  toJSON(): PublikationJSON {
    return Object.assign({}, this);
  }
}

export interface PublikationAdminJSON {
  id?: number;
  titel: string;
  abkuerzung: string;
  deaktiviert: boolean;
  mutDate?: Date;
  mutUser?: string;
}

export class PublikationAdmin {
  id?: number;
  titel: string;
  abkuerzung: string;
  deaktiviert: boolean;
  mutDate?: Date;
  mutUser?: string;

  static fromJSON(json: string | object): PublikationAdmin {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const land = Object.create(PublikationAdmin.prototype);
    return Object.assign(land, object);
  }

  toJSON(): PublikationAdminJSON {
    return Object.assign({}, this);
  }
}

export interface PublikationDetailJSON {
  id?: number;
  type: number;
  date: string;
  pageCount?: number;
  link: string;
  publikation: Publikation;
}

export class PublikationDetail {
  id?: number;
  type: number;
  date: string;
  pageCount?: number;
  link: string;
  info: string;
  publikation: Publikation;

  static fromJSON(json: string | object): PublikationDetail {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const pubDetail = Object.create(PublikationDetail.prototype);
    return Object.assign(pubDetail, object);
  }

  toJSON(): PublikationDetailJSON {
    return Object.assign({}, this);
  }
}
