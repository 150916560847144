<section class="container information">
  <div class="row description">
    <div class="col-xs-12 thema-test">
      <h1>{{ 'subject.word.label' | translate }}</h1>
      <p [innerHTML]="'subject.word.disclaimer' | translate"></p>
      <label class="custom-file-upload">
        <input #fileUploader name="excelInput" id="excelInput" type="file" (change)="selectFile($event)"
               placeholder="Word-Datei auswählen" accept=".doc,.docx"/>
      </label>
      <br/>
      <button type="button" class="btn btn-sm btn-primary"
              [disabled]="!this.chosenFile || this.loading" (click)="sendThemenword()">{{ 'subject.word.submitButton' | translate }}
      </button><p *ngIf="error" style="padding-top: 10px; color:red">{{ 'global.error' | translate }}</p>
    </div>
  </div>
</section>

<section class="container information">
  <div class="row description thema-preview">
    <div class="col-xs-12">
      <ng-template [ngIf]="this.thema">
        <ul role="tablist" class="tabmenu">
          <li role="tab" class="active" aria-selected="true">
            <a title="{{ 'subject.word.contentPreview' | translate }}">{{ 'subject.word.contentPreview' | translate }}</a>
          </li>
          <li role="tab" aria-selected="false" disabled="true">
            <a title="{{ 'comment.plural' | translate }}">{{ 'comment.plural' | translate }}</a>
          </li>
          <li role="tab" aria-selected="false" disabled="true">
            <a title="{{ 'global.furtherInformation' | translate }}">{{ 'global.furtherInformation' | translate }}</a>
          </li>
        </ul>
        <app-thema-content-item [themaContent]="thema.html"></app-thema-content-item>
      </ng-template>
    </div>
  </div>
</section>

