import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {ConfigurationService} from './configuration.service';
import {Settings} from './settings';
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private appSettings: Settings[];

  constructor(private http: HttpClient,
              private configurationService: ConfigurationService) {
  }

  public settings(): Settings[] {
    return this.appSettings;
  }

  public initSettings(): Promise<Settings[]> {
    const config = this.configurationService.getConfig();
    return firstValueFrom(
      this.http.get<Settings[]>(`${config.awisaFOUrl}/settings`)
        .pipe(tap(result => {
          this.appSettings = result;
          console.log(this.appSettings);
        }))
      );
  }
}
