<div *ngIf="showSpinner" class="spinner">
  <div class="splash-screen-spinner">
    <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
  </div>
</div>
<div class="col-sm-12 adura-report">
  <h2 (click)="collapse = !collapse" attr.aria-label="{{ 'global.date' | translate }}">
    {{ 'adura.reports.kennzahlen.label' | translate }}
    <i class="fa" [ngClass]="collapse? 'fa-angle-right': 'fa-angle-down'"></i></h2>
  <div *ngIf="!collapse">
    <section>
      <div class="row">
        <div class="col-sm-3">
          <h3 [innerText]="'global.date' | translate"></h3>
        </div>
        <div class="col-sm-3">
          <dp-date-picker class="search-doc-date" aria-hidden="true" placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                          [(ngModel)]="selectedFrom" [config]="datePickerConfig">
          </dp-date-picker>
          <input [ngModel]="selectedFrom | date: 'dd.MM.yyyy'"
                 placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" type="text"
                 tabindex="-1" class="sr-only" [attr.aria-label]="'date.from' | translate">
          <br/>
        </div>
        <div class="col-sm-3">
          <dp-date-picker aria-hidden="true" placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                          [(ngModel)]="selectedTo" [config]="datePickerConfig">
          </dp-date-picker>
          <input [ngModel]="selectedTo | date: 'dd.MM.yyyy'"
                 placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" type="text"
                 tabindex="-1" class="sr-only" [attr.aria-label]="'global.to' | translate">
        </div>
        <div class="col-sm-3">
          <button class="btn btn-sm btn-default" role="button" (click)="downloadKennzahlen()">
            <small>
              <i class="fa fa-download"></i>
              {{ 'adura.reports.kennzahlen.download' | translate }}
            </small>
          </button>
        </div>
      </div>
    </section>
    <section *ngIf="result">
      <div class="row">
        <div class="col-sm-6">
          <div class="col-sm-6" [innerText]="'adura.reports.kennzahlen.erfasst.meldung' | translate"></div>
          <div class="col-sm-6" [innerText]="result.meldungenErfasst"></div>
        </div>
        <div class="col-sm-6">
          <div class="col-sm-6" [innerText]="'adura.reports.kennzahlen.freigegeben.meldung' | translate"></div>
          <div class="col-sm-6" [innerText]="result.meldungenFreigegeben"></div>
        </div>
        <div class="col-sm-6">
          <div class="col-sm-6" [innerText]="'adura.reports.kennzahlen.erfasst.steckbrief' | translate"></div>
          <div class="col-sm-6" [innerText]="result.steckbriefeErfasst"></div>
        </div>
        <div class="col-sm-6">
          <div class="col-sm-6" [innerText]="'adura.reports.kennzahlen.freigegeben.steckbrief' | translate"></div>
          <div class="col-sm-6" [innerText]="result.steckbriefeFreigegeben"></div>
        </div>
        <div class="col-sm-6">
          <div class="col-sm-6" [innerText]="'adura.reports.kennzahlen.erfasst.signal' | translate"></div>
          <div class="col-sm-6" [innerText]="result.signaleErfasst"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="col-sm-6" [innerText]="'adura.reports.kennzahlen.seismo.ausgabe' | translate"></div>
          <div class="col-sm-6" [innerText]="result.seismoAusgaben"></div>
        </div>
        <div class="col-sm-6">
          <div class="col-sm-6" [innerText]="'adura.reports.kennzahlen.seismo.eintrag' | translate"></div>
          <div class="col-sm-6" [innerText]="result.seismoEintraege"></div>
        </div>
      </div>
    </section>
  </div>
</div>
