import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FavoriteCreateComponent } from '@app/search/favorite-create/favorite-create.component';
import { Thema } from '@app/shared/classes/thema';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-document',
  templateUrl: './thema.component.html',
  styleUrls: ['./print/thema.component.print.css'],
  encapsulation: ViewEncapsulation.None
})
export class ThemaComponent implements OnInit {

  loading = false;
  guid: string;
  thema: Thema;

  @ViewChild(FavoriteCreateComponent) child: FavoriteCreateComponent;

  constructor(private route: ActivatedRoute,
              private awisaFoService: AwisaFoService,
              private platformLocation: PlatformLocation,
              private element: ElementRef) { }

  ngOnInit(): void {
    this.guid = this.route.snapshot.paramMap.get('id');
    this.awisaFoService.getThema(this.guid)
      .subscribe(
        (result) => {
          this.thema = result;
        });
  }

  get comments() { return (this.thema && this.thema.comments) ? this.thema.comments : null; }

  openCreateFavorite() {
    this.child.open();
  }

  get themaLink() {
    return this.platformLocation.href;
  }

  print() {
    const details = this.element.nativeElement.querySelectorAll('details');
    details.forEach(detail => detail.setAttribute('open', ''));
    window.print();
    details.forEach(detail => detail.removeAttribute('open'));
  }
}

