<section class="facet-filter">
  <a role="button" class="facet-subject_de-collapse-button facet-toggle collapsed"
     (click)="changeOberthemenCollapseForTablet()"
     [attr.aria-expanded]="activeOberthemaCollapseTablet" aria-controls="facet-subject_de-collapse">
    <h2>{{ 'oberthema.plural' | translate }}<i class="fa fa-angle-down"></i></h2>
  </a>
  <h2 class="facet-header"
      [attr.aria-label]="'oberthema.plural' | translate">{{ 'oberthema.plural' | translate }}</h2>
  <div id="facet-subject-collapse" [attr.aria-label]="'oberthema.subjectCollapse' | translate" class="collapse facet-collapse"
       [ngClass]="(activeOberthemaCollapseTablet)?'in':''">
    <ng-container *ngIf="!this.oberthemenListToShow() || this.oberthemenListToShow().length === 0">
      <p><small>{{ 'search.noFilter' | translate }}</small></p>
    </ng-container>
    <nav [attr.aria-label]="'oberthema.list' | translate">
      <div class="nav nav-pills nav-stacked">
        <ng-container *ngFor="let ot of this.oberthemenListToShow()">
          <div class="flex-container">
            <input type="checkbox" class="filter-checkbox sr-only" id="{{ot.guid}}"
                   (click)="changeOberthema(ot.guid)"
                   [checked]="isOberthemaActive(ot.guid)">
            <label class="filter-checkbox-label" [ngClass]="(ot.count === 0)?'filter-element-background':''"
                   for="{{ot.guid}}" title="{{ot.name}}"
                   role="checkbox"
                   [attr.aria-label]="('oberthema.label' | translate) + ': ' + ot.name + ', ' +
                   ('global.total' | translate) + ': ' + ot.count"
                   [attr.aria-checked]="(isOberthemaActive(ot.guid))?'true':'false'">
              {{ ot.name }}
            </label>
            <span class="filter-checkbox-number" aria-hidden="true">{{ ot.count}}</span>
          </div>
        </ng-container>
      </div>
    </nav>

    <ng-container *ngIf="(top10Length > 9)">
      <p><a (click)="changeShowMoreOberthemen()" class="btn btn-xs">
        <ng-container *ngIf="isShowMoreOberthemen(); then showmore else showless"></ng-container>
        <ng-template #showmore>
          <i class="fa filter-more-less fa-angle-up"></i>{{ 'global.show.less' | translate }}
        </ng-template>
        <ng-template #showless>
          <i class="fa filter-more-less fa-angle-down"></i>{{ 'global.show.more' | translate }}
        </ng-template>
      </a>
      </p>
    </ng-container>
  </div>
</section>
