<div *ngIf="showSpinner" class="spinner">
  <div class="splash-screen-spinner">
    <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
  </div>
</div>
<div>
  <header class="page-header front-header">
    <div class="container">
      <div class="row">
        <form [formGroup]="sucheForm" (ngSubmit)="onSubmitSuche()">
          <div class="col-xs-9 col-md-11 clear-padding-right">
            <input formControlName="term" (keydown.enter)="onSubmitSuche()" id="ogdch_search"
                   class="form-control input-lg" name="term" autocomplete="off"
                   placeholder="{{ 'search.placeholder' | translate }}"
                   [attr.aria-label]="'search.placeholder' | translate"
                   [(ngModel)]="term">
            <button class="form-control-feedback search-button " (click)="onSubmitSuche()" [attr.aria-label]="'search.label.activity' | translate"
                    title="{{ 'search.label.activity' | translate }}" [disabled]="!term"><i class="fa fa-search" aria-hidden="true"></i></button>
          </div>
          <div class="col-xs-3 col-md-1 text-md-right" *ngIf="term">
            <button type="button" class="btn search-reset" (click)="resetSearch()" [attr.aria-label]="'search.reset' | translate">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </header>

  <form id="dataset-search-form" class="search-form" method="get" data-module="select-switch">
    <section class="container">
      <div class="row">
        <div class="col-md-3 facet-filters" role="search">
          <h2 class="sr-only">{{ 'search.filter' | translate }}</h2>
            <div class="flex-container" style="margin-top: 27px;">
              <button class="btn-sm btn-primary title-icon" role="button"
                 title="{{ 'search.resetFilter' | translate }}" [attr.aria-label]="'search.resetFilter' | translate" (click)="resetFilter()"><i class="fa fa-times"></i></button><br/>
              <button class="btn-sm btn-primary title-icon" role="button" title="{{ 'favorite.save' | translate }}" [attr.aria-label]="'favorite.save' | translate"
                 (click)="openCreateFavorite()"><i class="fa fa-star-o" aria-hidden="true"></i></button>
              <app-favorite-create></app-favorite-create>
              <app-link-clipboard title="{{ 'search.clipboard' | translate }}"></app-link-clipboard>
            </div>
          <app-filter-side [filter]="filterFacets" [activeFilter]="filter" (filterChanged)="onFilterChanged($event)"></app-filter-side>
          <app-domain-side [domains]="domainFacets" [activeDomain]="domains" (domainsChanged)="onDomainsChanged($event)"></app-domain-side>
          <app-subject-side [oberthemen]="oberthemaFacets" [activeOberthemen]="topics" (oberthemenChanged)="onOberthemenChanged($event)"></app-subject-side>
          <app-tag-side [tags]="tagFacets" [activeTags]="tags" [searchTerm]="tagsSearchTerm" (tagsChanged)="onTagsChanged($event)" (searchTermChanged)="setTagsSearchTerm($event)"></app-tag-side>
          <app-author-side [authors]="authorFacets" [activeAuthors]="authors" [searchTerm]="authorsSearchTerm" (authorsChanged)="onAuthorsChanged($event)" (searchTermChanged)="setAuthorsSearchTerm($event)"></app-author-side>
          <app-content-type-side [contentTypes]="typeFacets" [activeContentTypes]="contentTypes" (contentTypesChanged)="onContentTypesChanged($event)"></app-content-type-side>
          <app-date-side [activeDateFrom]="dateFrom" [activeDateTo]="dateTo" (dateChanged)="onDateChanged($event)"></app-date-side>
          <app-creation-date-side [activeCreationDateFrom]="createdDateFrom" [activeCreationDateTo]="createdDateTo" (creationDateChanged)="onCreationDateChanged($event)"></app-creation-date-side>
          <app-mod-date-side [activeModDateFrom]="changedDateFrom" [activeModDateTo]="changedDateTo" (modDateChanged)="onModDateChanged($event)"></app-mod-date-side>
          <app-language-side [languages]="languageFacets" [activeLanguages]="languages" (languagesChanged)="onLanguagesChanged($event)"></app-language-side>
          <app-format-side [formats]="formatFacets" [activeFormats]="formats" (formatsChanged)="onFormatsChanged($event)"></app-format-side>
          <app-status-side [status]="statusFacets" [activeStatus]="status" (statusChanged)="onStatusChanged($event)"></app-status-side>
        </div>

        <main class="col-md-9">
          <div class="search-result-title clearfix">
            <div class="col-md-6 clear-padding-left">
              <h2>{{ totalCount }} {{ 'search.count' | translate }}</h2>
              <h2 class="sr-only">
                {{ 'global.ofWhich' | translate }} {{ contentLength }} {{ 'content.plural' | translate }},
                {{ themenLength }} {{ 'subject.plural' | translate }}
                {{ 'global.and' | translate }} {{ commentLength }} {{ 'comment.plural' | translate }}
              </h2>
            </div>
            <div class="clear-padding-left text-md-right">
              <div class="form-group form-inline header-md-vertical-center sortby">
                <label for="field-order-by" class="search-order-by">{{ 'search.orderBy.label' | translate }}</label>
                <select #sortSelect class="form-control" id="field-order-by" name="sort" (change)="this.sortSearchResult(sortSelect.value)">
                  <ng-container *ngFor="let sortItem of this.sortList">
                    <option [selected]="sortItem == sort" value="{{ sortItem }}"
                            [attr.aria-label]="'search.orderBy.' + sortItem | translate">{{ 'search.orderBy.' + sortItem | translate }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
          </div>
          <div class="row search-result-box">
            <app-tabs (tabChanged)="onCategoryChange($event)" [selectedId]="currentCategory">
              <app-tab tabId="content" tabTitle="{{ 'content.plural' | translate }}" contentLength="{{ contentLength }}" contentClass="tabmenu-content">
                <p *ngIf="contentLength == 0">{{ 'search.noresults' | translate }}</p>
                <div class="list-unstyled">
                  <app-doc-item role="tabpanel" [allTags]="tagFacets"
                                [activeTags]="tags"
                                *ngFor="let content of contents"
                                [content]="content"
                                (changedTag)="appendTagToSidebarTags($event)"
                                [searchTerm]="highlightTerm">
                  </app-doc-item>
                  <button *ngIf="hasMoreContent" type="button" class="button-loadmore btn btn-sm" (click)="nextTranche('content')">
                    {{ 'global.loadMore' | translate }}
                  </button>
                </div>
              </app-tab>
              <app-tab tabId="subject" tabTitle="{{ 'subject.plural' | translate }}" contentLength="{{ themenLength }}" contentClass="tabmenu-content">
                <p *ngIf="themenLength == 0">{{ 'search.noresults' | translate }}</p>
                <ul class="list-unstyled">
                  <app-thema-item role="tabpanel" *ngFor="let thema of themen" [thema]="thema"></app-thema-item>
                  <button *ngIf="hasMoreThemen" type="button" class="button-loadmore btn btn-sm" (click)="nextTranche('thema')">
                    {{ 'global.loadMore' | translate }}
                  </button>
                </ul>
              </app-tab>
              <app-tab tabId="comment" tabTitle="{{ 'comment.plural' | translate }}" contentLength="{{ commentLength }}" contentClass="tabmenu-content">
                <p *ngIf="commentLength == 0">{{ 'search.noresults' | translate }}</p>
                <ul class="list-unstyled">
                  <app-comment-item role="tabpanel" *ngFor="let comment of comments" [comment]="comment"></app-comment-item>
                  <button *ngIf="hasMoreComment" type="button" class="button-loadmore btn btn-sm" (click)="nextTranche('comment')">
                    {{ 'global.loadMore' | translate }}
                  </button>
                </ul>
              </app-tab>
            </app-tabs>
          </div>
        </main>
      </div>
    </section>
  </form>
</div>
