import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Facet } from '@app/shared/classes/facet';

@Component({
  selector: 'app-language-side',
  templateUrl: './language-side.component.html'
})
export class LanguageSideComponent {
  @Input() languages: Facet[] = [];
  @Input() activeLanguages: string[] = [];
  @Output() languagesChanged = new EventEmitter<string[]>();

  activeLanguageCollapseTablet = false;

  isLanguageActive(name: string) {
    return this.activeLanguages.find(res => res === name);
  }

  changeLanguage(name: string) {
    const newActiveLanguages = this.activeLanguages.slice();

    const index = newActiveLanguages.indexOf(name);
    if (index > -1) {
      newActiveLanguages.splice(index, 1);
    } else {
      newActiveLanguages.push(name);
    }

    this.languagesChanged.emit(newActiveLanguages);
  }

  changeLanguageCollapseForTablet() {
    this.activeLanguageCollapseTablet = !this.activeLanguageCollapseTablet;
  }
}
