import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { RatingConfigComponent } from './rating-config';

@NgModule({
  imports: [BrowserModule, NgbModule],
  declarations: [RatingConfigComponent],
  exports: [RatingConfigComponent],
  bootstrap: [RatingConfigComponent]
})
export class RatingConfigModule {}
