import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Favorite, FavoriteEdit, FavoriteFrequency } from '@app/shared/classes/favorite';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { User } from '@app/core/auth/user';
import { AuthenticationService } from '@app/core/auth/authentication.service';

const FavoriteFormValidator: ValidatorFn = (form: UntypedFormGroup) => {
  let categoryLengthExceeded = false;

  form.value.categories?.some((category) => {
    if (category.length > 80) {
      categoryLengthExceeded = true;
    }
  });

  if (categoryLengthExceeded) {
    form.controls.categories.setErrors({invalid: true});
  }

  return null;
};

@Component({
  selector: 'app-favorite-edit',
  templateUrl: './favorite-edit.component.html'
})
export class FavoriteEditComponent implements OnInit {
  private user: User;
  favoriteEditForm: UntypedFormGroup;
  favorite: Favorite;
  notificationList = Object.keys(FavoriteFrequency);
  categories: string [] = [];

  isSending = false;
  error = false;
  errorMessage: string;

  constructor(private authenticationService: AuthenticationService,
              private awisaFoService: AwisaFoService,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.user = this.authenticationService.getUser();
    this.awisaFoService.getFavoritCategoriesByUser(this.user.guid).subscribe(
      (result) => {
        console.log(result);
        this.categories = result;
      });
    const favId = this.route.snapshot.paramMap.get('id');
    this.awisaFoService.getFavorite(favId)
      .subscribe(
        (result) => {
          console.log(result);
          this.favorite = result;
          this.updateForm(result);
        });
  }

  private initForm() {
    this.favoriteEditForm = this.formBuilder.group({
      favName: [{value: ''}, Validators.required],
      categories: [[]],
      favNotification: [FavoriteFrequency.NO, Validators.required]
    }, {validators: FavoriteFormValidator});
  }

  private updateForm(favorite: Favorite) {
    const favoriteCategories = favorite.category?.split(';');
    this.favoriteEditForm.reset({
      favName: favorite.title,
      favNotification: favorite.notification,
      categories: favoriteCategories
    }, {emitEvent: false});
  }

  onSubmit() {
    this.isSending = true;
    console.log(this.favoriteEditForm);
    const daten: FavoriteEdit = {
      id: this.favorite.id,
      title: this.favoriteEditForm.value.favName,
      notification: this.favoriteEditForm.value.favNotification,
      category: this.favoriteEditForm.value.categories?.join(';')
    };
    console.log(daten);
    this.awisaFoService.editFavorite(daten)
      .subscribe({
        next: () => {
          this.isSending = false;
          this.showAlertOk();
          this.error = false;
        },
        error: error => {
          this.error = true;
          if (error.error === undefined || error.statusText === 'Unknown Error') {
            this.errorMessage = 'global.error';
          } else {
            this.errorMessage = error.error;
          }
          this.isSending = false;
        }
      });
  }

  onReset() {
    this.updateForm(this.favorite);
  }

  showAlertOk() {
    Swal.fire({
      text: this.translateService.instant('favorite.stored'),
      icon: 'success',
      customClass: {htmlContainer: 'content-text-center'},
      buttonsStyling: true,
      confirmButtonText: 'OK'
    });
  }
}
