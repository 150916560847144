import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@app/core/auth/user';
import { ConfigurationService } from '@app/core/config/configuration.service';
import { PersonService } from '@app/shared/services/person.service';
import Keycloak, { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private readonly previousToken: string;
  private keycloak: Keycloak;
  private tokenTimer: NodeJS.Timeout;
  private authenticatedSubject = new BehaviorSubject<boolean>(false);
  private user: User;
  public onTokenExpired: () => void;

  constructor(private http: HttpClient,
              private configurationService: ConfigurationService,
              private personService: PersonService,
              private router: Router) {
    this.previousToken = localStorage.getItem('access_token');
  }

  get authenticated$(): Observable<boolean> {
    return this.authenticatedSubject;
  }

  isAuthenticated(): boolean {
    return this.authenticatedSubject.getValue();
  }

  async init(): Promise<void> {
    const config = this.configurationService.getConfig();

    const keycloakConfig: KeycloakConfig = {
      url: config.ssoUrl,
      realm: config.ssoRealm,
      clientId: config.ssoClient
    };
    const initOptions: KeycloakInitOptions = {
      pkceMethod       : 'S256',
      onLoad           : 'login-required',
      checkLoginIframe : false,
      token            : localStorage.getItem('access_token'),
      refreshToken     : localStorage.getItem('refresh_token'),
      idToken          : localStorage.getItem('id_token')
    };

    this.keycloak = new Keycloak(keycloakConfig);
    this.keycloak.onAuthSuccess = () => this.storeAuthorization();
    this.keycloak.onAuthRefreshSuccess = () => this.startTokenTimer();
    this.keycloak.onTokenExpired = () => this.tokenExpired();
    this.keycloak.onAuthLogout = () => {
      this.clearAuthorization();
      this.authenticatedSubject.next(false);
    };

    const authenticated = await this.keycloak.init(initOptions);
    if (authenticated) {
      await this.initUser();
    }
  }

  private async initUser(): Promise<void> {
    const token = this.keycloak.idTokenParsed as any;
    const previousExpiration = this.extractExpirationDate(this.previousToken) * 1000;
    const user: User = {
      eiamId               : token.sub_id,
      givenName            : token.given_name,
      familyName           : token.family_name,
      email                : token.email,
      language             : token.lang,
      previousTokenExpired : previousExpiration && previousExpiration < new Date().getTime()
    };

    const response = await firstValueFrom(this.personService.get(user));

    // Wenn der Benutzer undefined ist oder die Inhaltsrolle (und Adura-Rolle) nicht erhält,
    // wird er auf die Zugriffsantragsseite weitergeleitet
    if (response.user === undefined || (!response.roles.includes('ROLE_INHALT') && !response.roles.includes('ROLE_ADURA'))) {
      console.log('no user');
      const config = this.configurationService.getConfig();
      const returnUrl: string = config.rootUrl;
      const ssoClient: string = config.ssoClient;
      window.location.href = config.ssoAccessReqUrl.replace('${ssoClient}', ssoClient).replace('${returnURL}', returnUrl);
    } else {
      this.user = response.user;
      this.user.roles = response.roles;
      this.authenticatedSubject.next(true);
    }
  }

  checkLoginState(): Promise<boolean> {
    if (!this.keycloak.authenticated) {
      return Promise.resolve(false);
    }
    return this.refreshToken()
      .then(() => this.keycloak.authenticated);
  }

  getAccessToken(): Promise<string> {
    return this.refreshToken()
      .then(() => this.keycloak.token);
  }

  refreshToken(minValidity = 10): Promise<boolean> {
    return this.keycloak.updateToken(minValidity);
  }

  getUser() {
    return this.user;
  }

  hasRole(role: string) {
    return this.user.roles.includes(role);
  }

  onlyAduraUser() {
    return this.hasRole('ROLE_ADURA') && !this.hasRole('ROLE_INHALT');
  }

  onlyAwisaUser() {
    return this.hasRole('ROLE_INHALT') && !this.hasRole('ROLE_ADURA');
  }

  getProfile(): Keycloak.KeycloakProfile {
    return this.keycloak.profile;
  }

  logout(): Promise<void> {
    this.clearAuthorization();
    return this.keycloak.logout();
  }

  goToMyAccount(): void {
    const config = this.configurationService.getConfig();
    const url = config.ssoAccountUrl.replace('${returnURL}', encodeURIComponent(location.href));
    window.open(url, '_blank');
  }

  private storeAuthorization() {
    console.log(this.keycloak);

    localStorage.setItem('access_token', this.keycloak.token);
    localStorage.setItem('refresh_token', this.keycloak.refreshToken);
    localStorage.setItem('id_token', this.keycloak.idToken);

    this.startTokenTimer();
  }

  private clearAuthorization() {
    this.user = null;
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('id_token');
    this.keycloak.clearToken();
    this.clearTokenTimer();
  }

  private startTokenTimer(): void {
    this.clearTokenTimer();

    const issuedAt = this.keycloak.tokenParsed.iat * 1000;
    const expiresAt = this.keycloak.tokenParsed.exp * 1000;
    const expirationTimeout = expiresAt - issuedAt;
    const timeout = Math.max(0, expirationTimeout - 20000);

    this.tokenTimer = setTimeout(() => this.refreshToken(20), timeout);
  }

  private clearTokenTimer(): void {
    if (this.tokenTimer) {
      clearTimeout(this.tokenTimer);
    }
    this.tokenTimer = null;
  }

  private tokenExpired(): void {
    if (this.onTokenExpired) {
      this.onTokenExpired();
    }
  }

  private extractExpirationDate(token: string): number {
    const base64 = token?.split(/\./);
    if (base64?.length > 1) {
      const obj = JSON.parse(atob(base64[1]));
      return obj?.exp;
    }
    return undefined;
  }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    return this.checkLoginState()
      .then( authenticated => authenticated || this.generateUrlTree(route, '/unauthorized') );
  }

  checkDisclaimer(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    return this.checkLoginState().then(
      () => this.onlyAduraUser()
         || this.user.disclaimerOk
         || this.generateUrlTree(route, '/disclaimer')
    );
  }

  generateUrlTree(route: ActivatedRouteSnapshot, url: string): UrlTree {
    return this.router.createUrlTree([url], {
      queryParams: {
        url: this.router.serializeUrl(
          this.router.createUrlTree(
            route.pathFromRoot
              .reduce((segments, current) => segments.concat(current.url), [])
              .map(value => value.toString()),
            {
              queryParams: route.queryParams,
              fragment: route.fragment
            }
          )
        )
      }
    });
  }

  redirectAduraUser(): boolean | UrlTree {
    return this.hasRole('ROLE_INHALT')
      || !this.hasRole('ROLE_ADURA')
      || this.router.createUrlTree(['/adura']);
  }
}
