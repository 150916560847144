import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AduraFacet } from '@app/shared/classes/adura/adura-facet';
import { AduraService } from '@app/shared/services/adura.service';

@Component({
  selector: 'app-adura-matrix-side',
  templateUrl: './adura-matrix-side.component.html'
})
export class AduraMatrixSideComponent implements OnChanges {
  @Input() matrix: AduraFacet[] = [];
  @Input() activeMatrix: string[] = [];
  @Output() matrixChanged = new EventEmitter<string[]>();
  @Output() searchTermChanged = new EventEmitter<string>();

  searchTerm: string;
  matrixCopy: AduraFacet[];
  matrixTop10: AduraFacet[];
  isShowMoreVal = false;

  constructor(private aduraService: AduraService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.copyMatrix();
    this.calcTopMatrix();
    this.clearInput();
  }

  changeMatrix(id: string) {
    if (this.searchTerm) {
      this.searchTermChanged.emit(this.searchTerm);
    }
    this.clearInput();

    const newActiveMatrix = this.activeMatrix.slice();

    const index = newActiveMatrix.indexOf(id);
    if (index > -1) {
      newActiveMatrix.splice(index, 1);
    } else {
      newActiveMatrix.push(id);
    }

    this.matrixChanged.emit(newActiveMatrix);
  }

  isMatrixActive(matrix: string) {
    return this.activeMatrix.find(res => res === matrix);
  }

  calcTopMatrix() {
    if (this.matrixCopy !== undefined && this.matrixCopy !== null) {
      this.matrixTop10 = this.matrixCopy.slice(0, 10);
    } else {
      this.matrixTop10 = [];
    }
  }

  get top10Length() {
    return (this.matrixTop10) ? this.matrixTop10.length : null;
  }

  isShowMoreMatrix() {
    return this.isShowMoreVal;
  }

  changeShowMoreMatrix() {
    this.isShowMoreVal = !this.isShowMoreVal;
  }

  matrixListToShow() {
    if (this.isShowMoreVal) {
      return this.matrixCopy;
    } else {
      return this.matrixTop10;
    }
  }

  copyMatrix() {
    this.matrixCopy = this.matrix;
  }

  searchMatrix(): void {
    if (this.searchTerm) {
      this.aduraService.filterFacetsBySearchTerm(this.searchTerm.toLowerCase(), this.matrix)
        .subscribe({
          next: result => this.matrixCopy = result.length > 0 ? result : [],
          error: error => console.log(error)
        });
    } else {
      this.copyMatrix();
    }
    this.calcTopMatrix();
  }

  clearInput(): void {
    this.searchTerm = null;
    this.searchMatrix();
  }
}
