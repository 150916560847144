import {Tag} from './tag';
import {Comment} from './comment';

export interface EventJSON {
  guid: string;
  name: string;
  description: string;
  descriptionDe: string;
  descriptionEn: string;
  descriptionFr: string;
  descriptionIt: string;
  dateFrom: string;
  dateTo: string;
  dossierLink: string;
  creationDate: string;
  modificationDate: string;
  subtermine: Subevent[];
  tags: Tag[];
  roles: string[];
  comments: Comment[];
}

export class Event {
  guid: string;
  name: string;
  description: string;
  descriptionDe: string;
  descriptionEn: string;
  descriptionFr: string;
  descriptionIt: string;
  dateFrom: string;
  dateTo: string;
  tags: Tag[];
  dossierLink: string;
  creationDate: string;
  modificationDate: string;
  subtermine: Subevent[];
  roles: string[];
  comments: Comment[];

  static fromJSON(json: string | object): Event {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const termin = Object.create(Event.prototype);
    return Object.assign(termin, json);
  }

  toJSON(): EventJSON {
    return Object.assign({}, this);
  }
}

export interface SubeventJSON {
  description: string;
  dateFrom: string;
  dateTo: string;
}

export class Subevent {
  description: string;
  dateFrom: string;
  dateTo: string;

  constructor(description: string, dateFrom: string, dateTo: string) {
    this.description = description;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
  }

  static fromJSON(json: string | object): Subevent {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const subtermin = Object.create(Subevent.prototype);
    return Object.assign(subtermin, json);
  }

  toJSON(): SubeventJSON {
    return Object.assign({}, this);
  }
}

export interface EventSave {
  name: string;
  dateFrom: string;
  dateTo: string;
  descriptionDe: string;
  descriptionEn: string;
  descriptionFr: string;
  descriptionIt: string;
  tags: string[];
  subtermine: SubeventSave[];
  roles: string[];
  einbringerGuid: string;
}

export interface SubeventSave {
  dateFrom: string;
  dateTo: string;
  descriptionDe: string;
  descriptionEn: string;
  descriptionFr: string;
  descriptionIt: string;
}
