import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild
} from '@angular/core';
import { AduraService } from '@app/shared/services/adura.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { Status } from '@app/shared/classes/adura/status';
import { Steckbrief } from '@app/shared/classes/adura/steckbrief';
import { AduraSteckbriefComponent } from '@app/adura/steckbrief/adura-steckbrief-component';

@Component({
  selector: 'app-adura-papierkorb-steckbrief-edit',
  templateUrl: './adura-papierkorb-steckbrief-edit.component.html'
})
export class AduraPapierkorbSteckbriefEditComponent implements AfterViewInit {
  @ViewChild('steckbriefEditModal', {static: false}) steckbriefEditModal: ElementRef;
  @ViewChild(AduraSteckbriefComponent) steckbriefComponent: AduraSteckbriefComponent;
  @Output() updateRestoreSteckbrief = new EventEmitter<Steckbrief>();
  steckbrief: Steckbrief;

  private elm: HTMLElement;

  isSending = false;
  error = false;
  errorMessages = [];
  success = false;

  constructor(private aduraService: AduraService,
              private translateService: TranslateService) {
  }

  ngAfterViewInit(): void {
    this.elm = this.steckbriefEditModal.nativeElement as HTMLElement;
  }

  close() {
    this.elm.classList.remove('show');
    setTimeout(() => {
      this.elm.style.width = '0';
    }, 75);
  }

  open(steckbrief: Steckbrief): void {
    this.steckbrief = steckbrief;
    this.steckbriefComponent.initPapierkorbView(this.steckbrief);
    this.elm.classList.add('show');
    this.elm.style.width = '100vw';
  }

  restore(): void {
    const eingereichtStatus = new Status();
    eingereichtStatus.id = 10;
    this.steckbrief.status = eingereichtStatus;
    this.aduraService.saveSteckbrief(this.steckbrief)
      .subscribe({
        next: () => {
          this.close();
          this.showSuccessAlert('adura.steckbrief.restored');
          this.updateRestoreSteckbrief.emit(this.steckbrief);
        },
        error: error => {
          this.isSending = false;
          this.success = false;
          this.error = true;
          this.errorMessages = error.error;
          console.log('isSending');
          console.log(this.isSending);
          console.log(this.errorMessages);
        }
      });
  }

  showSuccessAlert(translateString: string) {
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000
          });
        });
  }
}
