<div #favoriteCategoryEditModal class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button *ngIf="!isSending" type="button" class="close" (click)="close()" aria-label="Close"><span
          aria-hidden="true">&times;</span>
        </button>
        <h2 class="modal-title">{{ 'favorite.categories.edit' | translate }}</h2>
      </div>
      <form [formGroup]="favoriteCategoryEditForm" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form-group">
            <p *ngIf="error" role="alert" class="alert alert-danger"> {{ errorMessage | translate }}</p>
            <label for="favoriteName">{{ 'global.name' | translate }}:</label>
            <input
              type="text"
              class="form-control"
              formControlName="name"
              id="favoriteName"
              autocomplete="off"
              required>
            <p role="alert" class="errors-text" *ngIf="(this.favoriteCategoryEditForm.controls.name.errors?.required)">
              {{ 'global.validation.name' | translate }}
            </p>
            <p role="alert" class="errors-text" *ngIf="(this.favoriteCategoryEditForm.controls.name.errors?.maxlength)">
              {{ 'favorite.categories.maxLength' | translate }}
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <ng-container *ngIf="isSending; then showspinner else hidespinner"></ng-container>

          <ng-template #hidespinner>
            <button type="button" class="btn btn-sm btn-secondary" (click)="close()">{{ 'global.cancel' | translate }}
            </button>
            <button type="submit" class="btn btn-sm btn-primary" [disabled]="favoriteCategoryEditForm.controls.name.invalid">
              {{ 'global.save' | translate }}
            </button>
          </ng-template>

          <ng-template #showspinner>
            <button type="submit" class="btn btn-sm btn-primary" [disabled]="favoriteCategoryEditForm.pristine || this.isSending">
              <i class="fa fa-spinner fa-pulse fa-fw"></i>
            </button>
          </ng-template>
        </div>
      </form>
    </div>
  </div>
</div>
