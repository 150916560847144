import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AduraFacet } from '@app/shared/classes/adura/adura-facet';

@Component({
  selector: 'app-adura-massenfreigabe-status-filter',
  templateUrl: './adura-massenfreigabe-status-filter.component.html'
})
export class AduraMassenfreigabeStatusFilterComponent {
  @Input() status: AduraFacet[] = [];
  @Input() activeStatus: string[] = [];
  @Output() statusChanged = new EventEmitter<string[]>();

  isStatusActive(id: string) {
    return this.activeStatus.find(res => res === id);
  }

  changeStatus(id: string) {
    id = id.replace('status_', '');
    const newActiveStatus = this.activeStatus.slice();
    const index = newActiveStatus.indexOf(id);
    if (index > -1) {
      newActiveStatus.splice(index, 1);
    } else {
      newActiveStatus.push(id);
    }
    this.statusChanged.emit(newActiveStatus);
  }
}
