<section class="facet-filter">
  <h2 (click)="collapseFilter()" class="facet-header" attr.aria-label="{{ 'adura.beurteilung.plural' | translate }}">{{ 'adura.beurteilung.plural' | translate }}
    <i class="fa" [ngClass]="collapse? 'fa-angle-right': 'fa-angle-down'" style="float: right"></i></h2>
  <div *ngIf="!collapse">
    <h3 class="facet-subtitle" attr.aria-label="{{ 'adura.beurteilung.type.label' | translate }}">{{ 'adura.beurteilung.typ.label' | translate }}</h3>
    <div>
      <ng-container *ngIf="!this.beurteilungType || this.beurteilungType.length === 0">
        <p><small>{{ 'search.noFilter' | translate }}</small></p>
      </ng-container>
      <nav [attr.aria-label]="'adura.beurteilung.typ.list' | translate">
        <div class="list-inline tags">
          <ng-container *ngFor="let state of beurteilungType">
            <div class="flex-container">
              <input type="checkbox" class="filter-checkbox sr-only" id="beurteilung_type_{{state.id}}"
                     (click)="changeBeurteilungType('beurteilung_type_' + state.id)"
                     [checked]="isBeurteilungTypeActive(state.id)">
              <label class="filter-checkbox-label" [ngClass]="(state.count === 0)?'filter-element-background':''"
                     for="beurteilung_type_{{state.id}}"
                     role="checkbox"
                     [attr.aria-label]="('adura.beurteilung.typ.label' | translate) + ': ' + (state.name | translate) + ', ' +
                   ('global.total' | translate) + ': ' + state.count"
                     [attr.aria-checked]="(isBeurteilungTypeActive(state.id))?'true':'false'">
                {{ state.name | translate }}
              </label>
              <span class="filter-checkbox-number" aria-hidden="true">{{ state.count}}</span>
            </div>
          </ng-container>
        </div>
      </nav>
    </div>
    <h3 title="{{ 'adura.beurteilung.date' | translate }}" class="facet-subtitle" [attr.aria-label]="'adura.beurteilung.date' | translate">{{ 'adura.beurteilung.date' | translate }}</h3>
    <div>
      <nav [attr.aria-label]="'date.selection' | translate">
        <dp-date-picker class="search-doc-date" aria-hidden="true" placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                        [(ngModel)]="selectedBeurteilungDateFrom" [config]="datePickerConfig"
                        (onSelect)="getBeurteilungDateFrom()" (keyup.enter)="updateEmptyBeurteilungDateData()">
        </dp-date-picker>
        <input (keyup.enter)="getBeurteilungDateFromEvent($event)" [ngModel]="selectedBeurteilungDateFrom | date: 'dd.MM.yyyy'"
               placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" type="text"
               tabindex="-1" class="sr-only" [attr.aria-label]="'date.from' | translate">

        <dp-date-picker aria-hidden="true" placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                        [(ngModel)]="selectedBeurteilungDateTo" [config]="datePickerConfig"
                        (onSelect)="getBeurteilungDateTo()" (keyup.enter)="updateEmptyBeurteilungDateData()">
        </dp-date-picker>
        <input (keyup.enter)="getBeurteilungDateToEvent($event)" [ngModel]="selectedBeurteilungDateTo | date: 'dd.MM.yyyy'"
               placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" type="text"
               tabindex="-1" class="sr-only" [attr.aria-label]="'global.to' | translate">
      </nav>
    </div>
  </div>
</section>
