<div class="row admin">
  <div class="col-md-6">
    <p class="admin">Dies ist der Check, der auch von CheckMK vom ISCeco aufgerufen wird</p>
  </div>
  <div class="col-md-2">
    <button (click)="checkShort()" class="btn btn-sm btn-default">
      <i class="fa fa-play fa-fw" [ngClass]="checkRunning?'fa-spinner fa-pulse':'fa-play'"></i>
    </button>
  </div>
  <div class="col-md-1">
    <i *ngIf="checkOk" class="fa fa-check job-ok"></i>
    <i *ngIf="!checkOk" class="fa fa-times job-nok"></i>
  </div>
</div>
<hr/>
<div class="row admin">
  <div class="col-md-1">
    <p class="admin-checks title">Status</p>
  </div>
  <div class="col-md-3">
    <p class="admin-checks title">JobName</p>
  </div>
  <div class="col-md-4">
    <p class="admin-checks title">FinishedAt</p>
  </div>
  <div class="col-md-4">
    <p class="admin-checks title">Exception</p>
  </div>
</div>
<div *ngFor="let task of checkResult" class="row admin" [ngClass]="task.status === 'NotOK'?'job-nok':''">
  <div class="col-md-1">
    <p class="admin-checks job">
      <i *ngIf="task.inProgress; else statusText">running</i>
      <ng-template #statusText>{{ task.status }}</ng-template>
    </p>
  </div>
  <div class="col-md-3">
    <p class="admin-checks job">{{ task.job }}</p>
  </div>
  <div class="col-md-2">
    <p class="admin-checks job">{{ task.lastSuccess | date: 'dd.MM.yyyy  HH:mm:ss' }}</p>
  </div>
  <div class="col-md-2">
    <p class="admin-checks job">{{ task.lastSuccess | dateAgo }}</p>
  </div>
  <div class="col-md-4">
    <p class="admin-checks job">{{ task.exception?task.exception:'-' }}</p>
  </div>
</div>


