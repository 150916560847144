import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationService } from '@app/core/config/configuration.service';
import { Task } from '../classes/task';

@Injectable({ providedIn: 'root' })
export class DataExchangeService {

  private apiBase: string;

  constructor(private http: HttpClient,
              configurationService: ConfigurationService) {
    this.apiBase = configurationService.getConfig().dataexchangeUrl;
  }

  runAllJobs(): Observable<Task[]> {
    return this.http.get(`${this.apiBase}/job/runAll`)
      .pipe(
        map((data: Task[]) => data.map(Task.fromJSON))
      );
  }

  makeStructure(): Observable<Task> {
    return this.http.get(`${this.apiBase}/job/makeStructure`)
      .pipe(
        map((data) => Task.fromJSON(data))
      );
  }

  fetchAwisaChanges(): Observable<Task> {
    return this.http.get(`${this.apiBase}/job/fetchChanges`)
      .pipe(
        map((data) => Task.fromJSON(data))
      );
  }

  fetchDocuments(): Observable<Task> {
    return this.http.get(`${this.apiBase}/job/fetchDocuments`)
      .pipe(
        map((data) => Task.fromJSON(data))
      );
  }

  fetchTranslations(): Observable<Task> {
    return this.http.get(`${this.apiBase}/job/fetchTranslations`)
      .pipe(
        map((data) => Task.fromJSON(data))
      );
  }

  fetchProcessThemaWordLinks(): Observable<Task> {
    return this.http.get(`${this.apiBase}/job/fetchProcessThemaWordLinks`)
      .pipe(
        map((data) => Task.fromJSON(data))
      );
  }

  updateEiamUsers(): Observable<Task> {
    return this.http.get(`${this.apiBase}/job/updateEiamUser`)
      .pipe(
        map((data) => Task.fromJSON(data))
      );
  }

  truncateDatabase(includePerson: boolean): Observable<any> {
    const param = includePerson ? '?full=true' : '';
    return this.http.put(`${this.apiBase}/job/truncateDatabase${param}`, null, {responseType: 'text'})
      .pipe();
  }

  lastSuccessOfAllJobs(): Observable<Task[]> {
    return this.http.get(`${this.apiBase}/job/success`)
      .pipe(
        map((data: Task[]) => data.map(Task.fromJSON))
      );
  }

  getVersion(): Observable<any> {
    return this.http.get(`${this.apiBase}/admin/version`, {responseType: 'text'})
      .pipe();
  }

  public uploadThemenword(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', new File([file], 'ignore', { type: file.type }));
    formData.append('fileName', file.name);
    return this.http.post<any>(`${this.apiBase}/thema/transformWord`, formData)
      .pipe();
  }

  checkBackend(): Observable<any> {
    return this.http.get(`${this.apiBase}/monitoring/checkDetails`)
      .pipe();
  }
}
