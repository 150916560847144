<section class="facet-filter">
  <h2 class="facet-header" attr.aria-label="{{ 'search.filter.title' | translate }}">{{ 'search.filter.title' | translate }}</h2>
  <div>
    <nav [attr.aria-label]="'search.filter.list' | translate">
      <div class="list-inline tags">
        <ng-container *ngFor="let state of filter">
          <div class="flex-container">
            <input type="checkbox" class="filter-checkbox sr-only" id="{{state.guid}}"
                   (click)="changeFilter(state.guid)"
                   [checked]="isFilterActive(state.guid)">
            <label class="filter-checkbox-label"
                   for="{{state.guid}}"
                   role="checkbox"
                   [attr.aria-label]="('search.filter.label' | translate) + ': ' + (state.name | translate)"
                   [attr.aria-checked]="(isFilterActive(state.guid))?'true':'false'">
              {{ state.name | translate }}
            </label>
          </div>
        </ng-container>
      </div>
    </nav>
  </div>
</section>
