<div #aktivitaetEditModal class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="close()" aria-label="Close"><span
          aria-hidden="true">&times;</span>
        </button>
        <h3 class="modal-title">{{ 'adura.aktivitaet.add' | translate }}</h3>
      </div>
      <form [formGroup]="aktivitaetEditForm">
        <div class="modal-body">
          <div class="form-group">
            <label>{{ 'adura.aktivitaet.who' | translate }}:</label>
            <span class="length-counter-modal">
              {{ (255 - aktivitaetEditForm.get('who').value?.length) + ' / 255' }}
            </span>
            <input
              type="text"
              class="form-control"
              formControlName="who"
              id="who"
              maxlength="100"
              autocomplete="off">
          </div>
          <div class="form-group">
            <label>{{ 'adura.aktivitaet.till' | translate }}:</label>
            <dp-date-picker aria-hidden="true" placeholder="{{datePickerConfig.format}}" theme="dp-material"
                            class="form-control event-datepicker"
                            [ngClass]="(dateForm.invalid && dateForm.touched) ? 'errors-input':''"
                            formControlName="till"
                            [config]="datePickerConfig"
                            (onSelect)="getDate()">
            </dp-date-picker>
          </div>
          <div class="form-group">
            <label>{{ 'adura.aktivitaet.text' | translate }}*:</label>
            <span class="length-counter-modal">
              {{ remainingCharactersMap['text'] + ' / ' + maxCharsMap['text'] }}
            </span>
            <div class="tiny-input">
              <editor formControlName="text"
                      [inline]="true"
                      [init]="tinyConfigEnterAndLinksAllowed"
                      (onInit)="updateRemainingCharacters('text', $event)"
                      (onKeyUp)="updateRemainingCharacters('text', $event)"
                      (onChange)="updateRemainingCharacters('text', $event)">
              </editor>
            </div>
          </div>
          <div class="form-group">
            <label>{{ 'adura.aktivitaet.status.label' | translate }}*:</label>
            <ng-select formControlName="status"
                       placeholder="{{ 'adura.aktivitaet.status.choose' | translate }}"
                       [items]="statusList"
                       [clearable]="false"
                       bindLabel="status"
                       dropdownPosition="bottom">
              <ng-template ng-label-tmp let-item="item">
                <div>{{ 'adura.aktivitaet.status.' + item | translate }}</div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                <div>{{ 'adura.aktivitaet.status.' + item | translate }}</div>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" (click)="close()">
            {{ 'global.cancel' | translate }}
          </button>
          <button type="button" class="btn btn-sm btn-space btn-primary" (click)="onSave()"
                  [disabled]="aktivitaetEditForm.invalid" *ngIf="aktivitaetToEdit">
            {{ 'global.save' | translate }}
          </button>
          <button type="submit" class="btn btn-sm btn-space btn-primary"
                  [disabled]="aktivitaetEditForm.invalid" *ngIf="!aktivitaetToEdit">
            {{ 'global.add' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
