import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AduraFacet } from '@app/shared/classes/adura/adura-facet';

@Component({
  selector: 'app-adura-filter-side',
  templateUrl: './adura-filter-side.component.html'
})
export class AduraFilterSideComponent implements OnInit {
  @Input() steckbriefFilter: AduraFacet[] = [];
  @Input() meldungFilter: AduraFacet[] = [];
  @Input() activeSteckbriefFilter: string[] = [];
  @Input() activeMeldungFilter: string[] = [];
  @Output() steckbriefFilterChanged = new EventEmitter<string[]>();
  @Output() meldungFilterChanged = new EventEmitter<string[]>();

  collapseSteckbrief = true;
  collapseMeldung = true;

  ngOnInit() {
    // Filter sind eingeblendet, wenn einer gesetzt ist
    if (this.activeSteckbriefFilter.length > 0) {
      this.collapseSteckbrief = false;
    }
    if (this.activeMeldungFilter.length > 0) {
      this.collapseMeldung = false;
    }
  }

  isSteckbriefFilterActive(id: string) {
    return this.activeSteckbriefFilter.find(res => res === id);
  }

  changeSteckbriefFilter(id: string) {
    const newActiveSteckbriefFilter = this.activeSteckbriefFilter.slice();
    const index = newActiveSteckbriefFilter.indexOf(id);
    if (index > -1) {
      newActiveSteckbriefFilter.splice(index, 1);
    } else {
      newActiveSteckbriefFilter.push(id);
    }
    this.steckbriefFilterChanged.emit(newActiveSteckbriefFilter);
  }

  isMeldungFilterActive(id: string) {
    return this.activeMeldungFilter.find(res => res === id);
  }

  changeMeldungFilter(id: string) {
    const newActiveMeldungFilter = this.activeMeldungFilter.slice();
    const index = newActiveMeldungFilter.indexOf(id);
    if (index > -1) {
      newActiveMeldungFilter.splice(index, 1);
    } else {
      newActiveMeldungFilter.push(id);
    }
    this.meldungFilterChanged.emit(newActiveMeldungFilter);
  }

  collapseSteckbriefFilter() {
    this.collapseSteckbrief = !this.collapseSteckbrief;
  }

  collapseMeldungFilter() {
    this.collapseMeldung = !this.collapseMeldung;
  }
}
