
export interface AduraKennzahlenResultJSON {
  meldungenErfasst: number;
  meldungenFreigegeben: number;
  steckbriefeErfasst: number;
  steckbriefeFreigegeben: number;
  signaleErfasst: number;
  seismoAusgaben: number;
  seismoEintraege: number;
}

export class AduraKennzahlenResult {
  meldungenErfasst: number;
  meldungenFreigegeben: number;
  steckbriefeErfasst: number;
  steckbriefeFreigegeben: number;
  signaleErfasst: number;
  seismoAusgaben: number;
  seismoEintraege: number;

  static fromJSON(json: string | object): AduraKennzahlenResult {
    if (!json) {
      return null;
    }
    const result = Object.create(AduraKennzahlenResult.prototype);
    return Object.assign(result, json);
  }

  toJSON(): AduraKennzahlenResultJSON {
    return Object.assign({}, this);
  }
}
