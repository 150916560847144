<section class="facet-filter">
  <h2 class="facet-header" [attr.aria-label]="'adura.search.aktivitaet.label' | translate">{{ 'adura.search.aktivitaet.label' | translate }}</h2>
  <h3 class="facet-subtitle" [attr.aria-label]="'adura.search.aktivitaet.status' | translate">{{ 'adura.search.aktivitaet.status' | translate }}</h3>
  <div>
    <ng-container *ngIf="!this.aktivitaetStatus || this.aktivitaetStatus.length === 0">
      <p><small>{{ 'search.noFilter' | translate }}</small></p>
    </ng-container>
    <nav [attr.aria-label]="'adura.aktivitaet.status.list' | translate">
      <div class="list-inline tags">
        <ng-container *ngFor="let entry of aktivitaetStatus">
          <div class="flex-container">
            <input type="checkbox" class="filter-checkbox sr-only" id="aktivitaet_status_{{entry.id}}"
                   (click)="changeAktivitaetStatus('aktivitaet_status_' + entry.id)"
                   [checked]="aktivitaetStatusActive(entry.id)">
            <label class="filter-checkbox-label" [ngClass]="(entry.count === 0)?'filter-element-background':''"
                   for="aktivitaet_status_{{entry.id}}"
                   role="checkbox"
                   [attr.aria-label]="('adura.aktivitaet.status.label' | translate) + ': ' + (entry.name | translate) + ', ' +
                   ('global.total' | translate) + ': ' + entry.count"
                   [attr.aria-checked]="(aktivitaetStatusActive(entry.id))?'true':'false'">
              {{ entry.name | translate }}
            </label>
            <span class="filter-checkbox-number" aria-hidden="true">{{ entry.count}}</span>
          </div>
        </ng-container>
      </div>
    </nav>
  </div>
  <h3 title="{{ 'adura.date.aktivitaet' | translate }}" class="facet-subtitle"
      [attr.aria-label]="'adura.date.aktivitaet' | translate">{{ 'adura.date.aktivitaet' | translate }}
    <input class="filter-checkbox-date" type="checkbox" id="next_eval_null" title="{{ 'adura.date.empty' | translate }}"
           (click)="changeAktivitaetDateNullValue($event)"
           [checked]="aktivitaetDateNullActive()"/>
  </h3>
  <div>
    <nav [attr.aria-label]="'date.selection' | translate">
      <dp-date-picker class="search-doc-date" aria-hidden="true" placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                      [(ngModel)]="selectedAktivitaetDateFrom" [config]="datePickerConfig"
                      (onSelect)="getAktivitaetDateFrom()" (keyup.enter)="updateEmptyAktivitaetDateData()">
      </dp-date-picker>
      <input (keyup.enter)="getAktivitaetDateFromEvent($event)" [ngModel]="selectedAktivitaetDateFrom | date: 'dd.MM.yyyy'"
             placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" type="text"
             tabindex="-1" class="sr-only" [attr.aria-label]="'date.from' | translate">

      <dp-date-picker aria-hidden="true" placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                      [(ngModel)]="selectedAktivitaetDateTo" [config]="datePickerConfig"
                      (onSelect)="getAktivitaetDateTo()" (keyup.enter)="updateEmptyAktivitaetDateData()">
      </dp-date-picker>
      <input (keyup.enter)="getAktivitaetDateToEvent($event)" [ngModel]="selectedAktivitaetDateTo | date: 'dd.MM.yyyy'"
             placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" type="text"
             tabindex="-1" class="sr-only" [attr.aria-label]="'global.to' | translate">
    </nav>
  </div>
</section>
