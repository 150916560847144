<div class="row form-group">
  <div class="col-sm-10 form-control-static" style="overflow: hidden">
    {{ filename }}
  </div>
  <div class="col-sm-2 form-control-static text-right">
    <button class="btn btn-sm label-primary-gray" title="{{ 'global.delete' | translate }}" (click)="onDelete()">
      <i class="fa fa-trash-o"></i>
    </button>
  </div>
</div>
