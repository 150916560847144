<div class="row admin">
  <p><b>Neue Gefahr</b></p>
</div>
<div class="row admin">
    <div class="col-md-2 clear-padding-left"><p class="admin clear-margin-top"><b>Bezeichnung EN</b></p></div>
    <div class="col-md-2"><p class="admin clear-margin-top"><b>Bezeichnung DE</b></p></div>
    <div class="col-md-2"><p class="admin clear-margin-top"><b>Bezeichnung FR</b></p></div>
    <div class="col-md-2"><p class="admin clear-margin-top"><b>Bezeichnung IT</b></p></div>
    <div class="col-md-1"><p class="admin clear-margin-top"><b>Deaktiviert</b></p></div>
</div>
<div class="row admin">
  <form [formGroup]="newGefahrForm" (ngSubmit)="onSubmit()">
    <div class="col-md-2 clear-padding-left">
      <div class="tiny-input-settings">
        <editor formControlName="bezeichnungEn_new"
                maxlength="255"
                [inline]="true"
                [init]="tinyConfig"
                (onInit)="updateRemainingCharacters('bezeichnungEn_new', $event)"
                (onKeyUp)="updateRemainingCharacters('bezeichnungEn_new', $event)"
                (onChange)="updateRemainingCharacters('bezeichnungEn_new', $event)">
        </editor>
        <span class="length-counter">
          {{ remainingCharactersMap['bezeichnungEn_new'] + ' / ' + maxCharsMap['bezeichnungEn_new'] }}
        </span>
      </div>
    </div>

    <div class="col-md-2">
      <div class="tiny-input-settings">
        <editor formControlName="bezeichnungDe_new"
                maxlength="255"
                [inline]="true"
                [init]="tinyConfig"
                (onInit)="updateRemainingCharacters('bezeichnungDe_new', $event)"
                (onKeyUp)="updateRemainingCharacters('bezeichnungDe_new', $event)"
                (onChange)="updateRemainingCharacters('bezeichnungDe_new', $event)">
        </editor>
        <span class="length-counter">
          {{ remainingCharactersMap['bezeichnungDe_new'] + ' / ' + maxCharsMap['bezeichnungDe_new'] }}
        </span>
      </div>
    </div>

    <div class="col-md-2">
      <div class="tiny-input-settings">
        <editor formControlName="bezeichnungFr_new"
                maxlength="255"
                [inline]="true"
                [init]="tinyConfig"
                (onInit)="updateRemainingCharacters('bezeichnungFr_new', $event)"
                (onKeyUp)="updateRemainingCharacters('bezeichnungFr_new', $event)"
                (onChange)="updateRemainingCharacters('bezeichnungFr_new', $event)">
        </editor>
        <span class="length-counter">
          {{ remainingCharactersMap['bezeichnungFr_new'] + ' / ' + maxCharsMap['bezeichnungFr_new'] }}
        </span>
      </div>
    </div>

    <div class="col-md-2">
      <div class="tiny-input-settings">
        <editor formControlName="bezeichnungIt_new"
                maxlength="255"
                [inline]="true"
                [init]="tinyConfig"
                (onInit)="updateRemainingCharacters('bezeichnungIt_new', $event)"
                (onKeyUp)="updateRemainingCharacters('bezeichnungIt_new', $event)"
                (onChange)="updateRemainingCharacters('bezeichnungIt_new', $event)">
        </editor>
        <span class="length-counter">
          {{ remainingCharactersMap['bezeichnungIt_new'] + ' / ' + maxCharsMap['bezeichnungIt_new'] }}
        </span>
      </div>
    </div>

    <div class="col-md-1">
      <input type="checkbox" id="deaktiviert_new" formControlName="deaktiviert_new">
    </div>

    <div class="col-md-2">
      <button class="btn btn-sm admin-settings" type="submit"
              [ngStyle]="{ 'visibility': newGefahrForm.dirty ? '' : 'hidden'}"
              [disabled]="newGefahrForm.invalid">
        <i class="fa fa-check"></i>
      </button>

      <button class="btn btn-sm admin-settings" type="button"
              [ngStyle]="{ 'visibility': newGefahrForm.dirty ? '' : 'hidden'}"
              title="Abbrechen" (click)="onReset()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </form>
</div>
