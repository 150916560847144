<section class="facet-filter">
  <a role="button" class="facet-tag-side_de-collapse-button facet-toggle collapsed"
     (click)="changeTagCollapseForTablet()"
     [attr.aria-expanded]="activeTagCollapseTablet" aria-controls="facet-tag-side_de-collapse">
    <h2>{{ 'tag.plural' | translate }}<i class="fa fa-angle-down"></i></h2>
  </a>
  <h2 class="facet-header"
      [attr.aria-label]="'tag.plural' | translate">{{ 'tag.plural' | translate }}</h2>
  <div id="facet-tag-side_de-collapse" class="collapse facet-collapse" [ngClass]="(activeTagCollapseTablet)?'in':''">
    <div class="form-group input-reset-button" *ngIf="this.tags && this.tags?.length > 0">
      <input class="form-control input-sm" spellcheck="false" [(ngModel)]="searchTerm"
             (ngModelChange)="searchTags()" placeholder="{{ 'tag.search' | translate }}"/>
      <button type="reset">
        <i class="fa fa-times" aria-hidden="true" (click)="clearInput()"></i>
      </button>
    </div>
    <ng-container *ngIf="!this.tagListToShow() || this.tagListToShow().length === 0">
      <p><small>{{ 'search.noFilter' | translate }}</small></p>
    </ng-container>
    <nav [attr.aria-label]="'tag.list' | translate">
      <ul class="list-inline tags">
        <ng-container *ngFor="let tag of this.tagListToShow()">
          <li class="nav-item filter-tag"  [ngClass]="(isTagActive(tag.guid))?'active':''">
            <a (click)="changeTag(tag.guid)" role="button" class="filter-tag-label"
               [attr.aria-pressed]="(isTagActive(tag.guid))?'true':'false'"
               [attr.aria-label]="('tag.label' | translate) + ': ' + tag.name + ', ' +
             ('global.total' | translate) + ': ' + tag.count">{{ tag.name }}
              <small [attr.aria-label]="tag.count" aria-hidden="true">{{ tag.count }}</small></a></li>
        </ng-container>
      </ul>
    </nav>

    <ng-container *ngIf="(top10Length > 9)">
      <p><a (click)="changeShowMoreTags()" class="btn btn-xs">
        <ng-container *ngIf="isShowMoreTags(); then showmore else showless"></ng-container>
        <ng-template #showmore>
          <i class="fa filter-more-less fa-angle-up"></i>{{ 'global.show.less' | translate }}
        </ng-template>
        <ng-template #showless>
          <i class="fa filter-more-less fa-angle-down"></i>{{ 'global.show.more' | translate }}
        </ng-template>
      </a>
      </p>
    </ng-container>
  </div>
</section>
