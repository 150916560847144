import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Domain } from '@app/shared/classes/domain';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html'
})
export class DomainComponent implements OnInit {

  domains: Domain[] = [];

  constructor(private awisaFoService: AwisaFoService,
              private router: Router) { }

  ngOnInit(): void {
    this.awisaFoService.getDomains()
      .subscribe(
        (result) => {
          console.log(result);
          this.domains = result;
        });
  }

  goToDomain(domain: string) {
    this.router.navigate(['/search/content'], {
      queryParams: {
        domain,
        status: ['10', '20']
      }
    });
  }
}
