export interface MatrixJSON {
  id: number;
  bezeichnung: string;
  sortierung: string;
  ergaenzung: string;
  disabled: boolean;
}

export class Matrix {
  id: number;
  bezeichnung: string;
  sortierung: string;
  ergaenzung: string;
  disabled: boolean; // Damit Elemente des Dropdowns disabled gesetzt werden können; für das Backend irrelevant

  static fromJSON(json: string | object): Matrix {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const land = Object.create(Matrix.prototype);
    return Object.assign(land, object);
  }

  toJSON(): MatrixJSON {
    return Object.assign({}, this);
  }
}
