import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AduraService } from '@app/shared/services/adura.service';
import { TreiberAdmin } from '@app/shared/classes/adura/treiber';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-adura-admin-stammdaten-treiber-entry]',
  templateUrl: './adura-admin-stammdaten-treiber-entry.component.html'
})
export class AduraAdminStammdatenTreiberEntryComponent {
  @Output() deleted = new EventEmitter();
  @Output() reloadEntries: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  set set(treiber: TreiberAdmin) {
    this.treiber = treiber;
    this.initForm(treiber);
  }

  treiberForm: UntypedFormGroup;
  delChosen = false;
  treiber: TreiberAdmin;

  constructor(private aduraService: AduraService,
              private formBuilder: UntypedFormBuilder) {
  }

  private initForm(treiber: TreiberAdmin) {
    this.treiberForm = this.formBuilder.group({
      id: [''],
      bezeichnungDe: ['', Validators.required],
      bezeichnungEn: ['', Validators.required],
      bezeichnungFr: ['', Validators.required],
      bezeichnungIt: ['', Validators.required],
      deaktiviert: ['']
    });
    this.updateForm(treiber);
  }

  private updateForm(treiber: TreiberAdmin) {
    this.treiberForm.reset({
      id: treiber.id,
      bezeichnungDe: treiber.bezeichnungDe,
      bezeichnungEn: treiber.bezeichnungEn,
      bezeichnungFr: treiber.bezeichnungFr,
      bezeichnungIt: treiber.bezeichnungIt,
      deaktiviert: treiber.deaktiviert
    }, {emitEvent: false});
  }

  onSubmit() {
    const formValues = this.treiberForm.value;
    this.treiber.id = formValues.id;
    this.treiber.bezeichnungDe = formValues.bezeichnungDe;
    this.treiber.bezeichnungEn = formValues.bezeichnungEn;
    this.treiber.bezeichnungFr = formValues.bezeichnungFr;
    this.treiber.bezeichnungIt = formValues.bezeichnungIt;
    this.treiber.deaktiviert = formValues.deaktiviert;
    this.aduraService.editTreiber(this.treiber)
      .subscribe({
        next: result => {
          this.treiber = result;
          this.reloadEntries.emit();
        },
        error: console.error
      });
  }

  onReset() {
    this.updateForm(this.treiber);
  }

  deleteTreiber(treiberId: number) {
    this.delChosen = true;
    Swal.fire({
      text: 'Wollen Sie diesen Treiber wirklich löschen?',
      icon: 'warning',
      customClass: {htmlContainer: 'content-text-center'},
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: 'Ja, Treiber löschen',
      cancelButtonText: 'Abbrechen'
    }).then((result) => {
      if (result.value) {
        this.continueDeleteTreiber(treiberId);
      } else {
        this.delChosen = false;
      }
    });
  }

  continueDeleteTreiber(treiberId: number) {
    this.aduraService.deleteTreiber(treiberId)
      .subscribe({
        next: () => {
          this.showAlert(true);
          this.deleted.emit();
        },
        error: error => {
          this.showAlert(false);
          console.error(error.message);
        }
      });
  }

  showAlert(ok: boolean) {
    let message = 'Treiber wurde gelöscht!';
    let apiResult: SweetAlertIcon = 'success';
    if (!ok) {
      message = 'Der Treiber konnte nicht gelöscht werden!';
      apiResult = 'error';
    }
    Swal.fire({
      text: message,
      icon: apiResult,
      customClass: {htmlContainer: 'content-text-center'},
      buttonsStyling: true,
      confirmButtonText: 'OK'
    });
  }
}
