import { Component, Input } from '@angular/core';
import { Matrix } from '@app/shared/classes/adura/matrix';

@Component({
  selector: 'app-adura-matrix-entry',
  templateUrl: './adura-matrix-entry.component.html'
})
export class AduraMatrixEntryComponent {

  @Input()
  matrixDetail: Matrix;
}
