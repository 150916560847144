<ng-container *ngIf="comments && (comments.length > 0); else nocomments">
  <div class="row">
    <div class="col-xs-12" *ngFor="let comment of comments">
      <ul class="resource-list list-unstyled">
        <li class="resource-item">
          <b>{{ comment.author }}: </b>
          <p class="comment-text">{{ comment.text }}</p>
          <div class="actions pull-right">
            <p>{{ comment.creationDate | date: 'dd.MM.yyyy' }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
<ng-template #nocomments>
  <p>{{ 'comment.none' | translate }}</p>
</ng-template>
<ng-container *ngIf="newCommentEnabled">
  <button type="button" class="btn btn-sm btn-primary new-comment" (click)="openCreateComment()">
    {{ 'comment.add' | translate }}
  </button>
  <app-comment-create [refGuid]="refGuid" (updateCreateComment)="updateCreateComment($event)"></app-comment-create>
</ng-container>
