<header class="page-header">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <button appBackButton type="button" class="btn btn-sm btn-primary back-button"
                title="{{ 'global.back' | translate }}"
                [attr.aria-label]="'global.back' | translate">
          <i class="fa fa-arrow-left"></i>
        </button>
      </div>
    </div>
  </div>
</header>

<section class="container information">
  <div class="row" style="padding: 15px">

    <h1>{{ name }}
      <button type="button" class="btn-sm btn btn-primary title-icon"
              title="{{ 'favorite.save' | translate }}" (click)="openCreateFavorite()"
              [attr.aria-label]="'favorite.save' | translate">
        <i class="fa fa-star-o" aria-hidden="true"></i>
      </button>
      <app-link-clipboard title="{{ name }}" type="{{ 'link.label' | translate }}"></app-link-clipboard>
    </h1>
    <p>{{ description }}</p>
    <app-favorite-create></app-favorite-create>
  </div>
</section>

<section id="dataset-resources" class="container resources">
  <div class="row">
    <div class="col-xs-12">
      <dl class="dl-horizontal">
        <dt class="dataset-label">{{ 'link.label' | translate }}</dt>
        <dd class="dataset-details">
          <a class="actanova-link" href="{{ url }}" target="_blank">{{ url }}</a>
        </dd>
      </dl>
    </div>
  </div>
</section>

<section class="container additional-info">
  <div class="row">
    <div class="col-xs-12">
      <h2>{{ 'global.additionalInfo' | translate }}</h2>
      <dl class="dl-horizontal">
        <dt>{{ 'date.create' | translate }}</dt>
        <dd>{{ creationDate | date: 'dd.MM.yyyy' }}</dd>
        <dt>{{ 'date.mod' | translate }}</dt>
        <dd>{{ modificationDate | date: 'dd.MM.yyyy' }}</dd>
        <dt>{{ 'content.type' | translate }}</dt>
        <dd>{{ 'link.awisa' | translate }}</dd>
        <dt>{{ 'tag.plural' | translate }}</dt>
        <dd>
          <ul class="dataset-resources list-inline tags">
            <li *ngFor="let tag of tags">
              <p class="label label-primary-gray">{{ tag.name }}</p>
            </li>
          </ul>
        </dd>
        <ng-template [appHasRole]="['ROLE_BLV']">
          <dt class="dataset-label">{{ 'actanova.link' | translate }}</dt>
          <dd class="dataset-details">
            <a class="actanova-link" href="{{ dossierLink }}" target="_blank">{{ 'link.awisa' | translate }}</a>
          </dd>
        </ng-template>
      </dl>
    </div>
  </div>
</section>

<section class="container additional-info">
  <div class="row">
    <div class="col-xs-12">
      <h2>{{ 'comment.label' | translate }}</h2>
      <app-comment [refGuid]="getGuid" [comments]="comments" [reference]="'link'"></app-comment>
    </div>
  </div>
</section>
