<section class="contribute inverted-container">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-xs-12">
        <h2>{{ 'disclaimer.title' | translate }}</h2>
        {{ 'global.validFrom' | translate }} {{ getDisclaimerDate | date: 'dd.MM.yyyy' }}<br/>
        {{ 'disclaimer.details' | translate }}
      </div>
      <div class="col-md-12 col-xs-12 clear-padding-left clear-padding-right">
        <div id="disclaimerHtml" #disclaimerHtml class="disclaimer-text"></div>
        <div class="col-md-12 col-xs-12 text-center disclaimer-text">
          <button type="submit" class="btn btn-sm btn-primary"
                  [disabled]="isSending" (click)="updateUser()"><ng-container *ngIf="!isSending">{{ 'global.accept' | translate }}</ng-container>
            <i *ngIf="isSending" class="fa fa-spinner fa-pulse fa-fw"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
