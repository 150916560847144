<section class="facet-filter">
  <h2 class="facet-header" attr.aria-label="{{ 'adura.search.filter.label' | translate }}">{{ 'adura.search.filter.label' | translate }}</h2>
  <div>
    <nav [attr.aria-label]="'adura.steckbrief.filter.list' | translate">
      <div class="list-inline tags">
        <h3 (click)="collapseSteckbriefFilter()" class="facet-subtitle" attr.aria-label="{{ 'adura.steckbrief.label' | translate }}">{{ 'adura.steckbrief.label' | translate }}
          <i class="fa" [ngClass]="collapseSteckbrief? 'fa-angle-right': 'fa-angle-down'" style="float: right"></i></h3>
        <div *ngIf="!collapseSteckbrief">
          <ng-container *ngFor="let state of steckbriefFilter">
            <div class="flex-container">
              <input type="checkbox" class="filter-checkbox sr-only" id="{{state.id}}"
                     (click)="changeSteckbriefFilter(state.id)"
                     [checked]="isSteckbriefFilterActive(state.id)">
              <label class="filter-checkbox-label"
                     for="{{state.id}}"
                     role="checkbox"
                     [attr.aria-label]="('adura.domain.label' | translate) + ': ' + (state.name | translate)"
                     [attr.aria-checked]="(isSteckbriefFilterActive(state.id))?'true':'false'">
                {{ state.name | translate }}
              </label>
            </div>
          </ng-container>
        </div>
        <h3 (click)="collapseMeldungFilter()" class="facet-subtitle" attr.aria-label="{{ 'adura.meldung.label' | translate }}">{{ 'adura.meldung.label' | translate }}
          <i class="fa" [ngClass]="collapseMeldung? 'fa-angle-right': 'fa-angle-down'" style="float: right"></i></h3>
        <div *ngIf="!collapseMeldung">
          <ng-container *ngFor="let state of meldungFilter">
            <div class="flex-container">
              <input type="checkbox" class="filter-checkbox sr-only" id="{{state.id}}"
                     (click)="changeMeldungFilter(state.id)"
                     [checked]="isMeldungFilterActive(state.id)">
              <label class="filter-checkbox-label"
                     for="{{state.id}}"
                     role="checkbox"
                     [attr.aria-label]="('adura.domain.label' | translate) + ': ' + (state.name | translate)"
                     [attr.aria-checked]="(isMeldungFilterActive(state.id))?'true':'false'">
                {{ state.name | translate }}
              </label>
            </div>
          </ng-container>
        </div>
      </div>
    </nav>
  </div>
</section>
