<form [formGroup]="subEventForm">
  <div class="subevent col-md-12 clear-padding-left form-element">
    <div class="col-md-4 col-xs-12 clear-padding-left">
      <label for="dateFrom">{{ 'global.from' | translate }}:</label><br/>
      <div class="col-md-6 col-xs-6 clear-padding-left form-element">
        <dp-date-picker aria-hidden="true" placeholder="{{datePickerConfig.format}}" theme="dp-material"
                        name="dateFrom"
                        formControlName="dateFrom"
                        [ngClass]="(subEventForm.controls.dateFrom.invalid && subEventForm.controls.dateFrom.dirty) ? 'errors-input':''"
                        class="form-control event-datepicker"
                        [config]="datePickerConfig"
                        [(ngModel)]="selectedDateFrom">
        </dp-date-picker>
        <input (keyup.enter)="getDateFromFromEvent($event)" name="dateFrom"
               id="dateFrom"
               placeholder="{{datePickerConfig.format}}" type="text"
               tabindex="-1" class="sr-only" [attr.aria-label]="'date.from' | translate">
      </div>
      <div class="col-md-4 col-xs-6 clear-padding-left form-element">
        <input id="timeFrom"
               placeholder="HH:MM"
               class="form-control"
               [ngClass]="(subEventForm.controls.timeFrom.invalid && subEventForm.controls.timeFrom.dirty) ? 'errors-input':''"
               autocomplete="off"
               formControlName="timeFrom"
               type="text"
               [attr.aria-label]="'time.from' | translate">
      </div>
    </div>
    <div class="col-md-8 col-xs-12 clear-padding-left">
      <label for="dateTo">{{ 'global.to' | translate }}:</label><br/>
      <div class="col-md-3 col-xs-6 clear-padding-left form-element">
        <dp-date-picker aria-hidden="true" placeholder="{{datePickerConfig.format}}" theme="dp-material"
                        name="dateTo"
                        formControlName="dateTo"
                        class="form-control event-datepicker"
                        [ngClass]="(subEventForm.controls.dateTo.invalid && subEventForm.controls.dateTo.dirty) ? 'errors-input':''"
                        [config]="datePickerConfig"
                        [(ngModel)]="selectedDateTo">
        </dp-date-picker>
        <input (keyup.enter)="getDateToFromEvent($event)"
               name="dateTo"
               id="dateTo"
               placeholder="{{datePickerConfig.format}}" type="text"
               tabindex="-1" class="sr-only" [attr.aria-label]="'global.to' | translate">
      </div>
      <div class="col-md-2 col-xs-6 clear-padding-left form-element">
        <input id="timeTo"
               placeholder="HH:MM"
               class="form-control"
               [ngClass]="(subEventForm.controls.timeTo.invalid && subEventForm.controls.timeTo.dirty) ? 'errors-input':''"
               autocomplete="off"
               formControlName="timeTo"
               type="text"
               [attr.aria-label]="'time.to' | translate">
      </div>
    </div>
    <div class="form-element">
      <div class="col-md-4 col-xs-12 clear-padding-left">
        <p role="alert" class="errors-text"
           *ngIf="(subEventForm.controls.dateFrom.invalid && subEventForm.controls.dateFrom.dirty)">
          {{ 'global.validation.dateFrom' | translate }}
        </p>
        <p role="alert" class="errors-text"
           *ngIf="(subEventForm.controls.timeFrom.invalid && subEventForm.controls.timeFrom.dirty)">
          {{ 'global.validation.timeFrom' | translate }}
        </p>
        <p role="alert" class="errors-text" *ngIf="validateTwoDates()">
          {{ 'global.validation.dateRange' | translate }}
        </p>
      </div>
      <div class="col-md-4 col-xs-12 clear-padding-left">
        <p role="alert" class="errors-text"
           *ngIf="(subEventForm.controls.dateTo.invalid && subEventForm.controls.dateTo.dirty)">
          {{ 'global.validation.dateTo' | translate }}
        </p>
        <p role="alert" class="errors-text"
           *ngIf="(subEventForm.controls.timeTo.invalid && subEventForm.controls.timeTo.dirty)">
          {{ 'global.validation.timeTo' | translate }}
        </p>
      </div>
      <div class="clearfix">
      </div>
    </div>
    <div class="form-element">
      <label>{{ 'event.shortDescription' | translate }}:</label>
      <app-tabs class="event-desc-tabs">
        <app-tab *ngFor="let language of languages" role="tab"
                 tabId="content" tabTitle="{{ language }}"
                 contentClass="tabmenu-content">
        <textarea
          class="form-control form-element"
          id="desc_{{ language }}"
          formControlName="desc_{{ language }}"
          maxlength="1530"
          autocomplete="off"
          rows="3"></textarea>
        </app-tab>
      </app-tabs>
    </div>
    <i class="fa fa-times-circle subevent-remove-button" (click)="deleteSubEventComponent()"></i>
  </div>
</form>
