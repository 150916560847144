export interface BeurteilungJSON {
  id: number;
  type: BeurteilungType;
  date: Date;
  text: string;
}

export class Beurteilung {
  id: number;
  type: BeurteilungType;
  date: Date;
  text: string;

  static fromJSON(json: string | object): Beurteilung {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const land = Object.create(Beurteilung.prototype);
    return Object.assign(land, object);
  }

  toJSON(): BeurteilungJSON {
    return Object.assign({}, this);
  }
}

export enum BeurteilungType {
  Intern = '10',
  Seismo = '20',
  Externer = '30',
  GEFA = '40'
}
