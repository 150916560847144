import { HttpParams } from '@angular/common/http';

interface ISearchParams {
  term?: string;
  sort?: string;
  filter?: string[];
  domains?: string[];
  tags?: string[];
  topics?: string[];
  authors?: string[];
  contentTypes?: string[];
  dateFrom?: string;
  dateTo?: string;
  languages?: string[];
  formats?: string[];
  status?: string[];
  createdDateFrom?: string;
  createdDateTo?: string;
  changedDateFrom?: string;
  changedDateTo?: string;
  offset?: number;
  scrollTab?: string;
}

export class SearchParams implements ISearchParams {
  constructor(public term?: string,
              public sort?: string,
              public filter?: string[],
              public domains?: string[],
              public tags?: string[],
              public topics?: string[],
              public authors?: string[],
              public contentTypes?: string[],
              public dateFrom?: string,
              public dateTo?: string,
              public languages?: string[],
              public formats?: string[],
              public status?: string[],
              public createdDateFrom?: string,
              public createdDateTo?: string,
              public changedDateFrom?: string,
              public changedDateTo?: string,
              public offset?: number,
              public scrollTab?: string) {
  }

  static fromObject(obj: ISearchParams): SearchParams {
    return Object.assign(new SearchParams(), obj);
  }

  getHttpParams(): HttpParams {
    let params = new HttpParams();

    if (this.term !== undefined) {
      params = params.append('term', this.term);
    }
    if (this.sort !== undefined) {
      params = params.append('sort', this.sort);
    }
    if (this.filter?.length > 0) {
      this.filter.forEach(entry => {
        params = params.append('filter', entry);
      });
    }
    if (this.domains?.length > 0) {
      this.domains.forEach(entry => {
        params = params.append('domain', entry);
      });
    }
    if (this.tags?.length > 0) {
      this.tags.forEach(entry => {
        params = params.append('tag', entry);
      });
    }
    if (this.topics?.length > 0) {
      this.topics.forEach(entry => {
        params = params.append('oberthema', entry);
      });
    }
    if (this.authors?.length > 0) {
      this.authors.forEach(entry => {
        params = params.append('author', entry);
      });
    }
    if (this.contentTypes?.length > 0) {
      this.contentTypes.forEach(entry => {
        params = params.append('type', entry);
      });
    }
    if (this.dateFrom !== undefined) {
      params = params.append('dateFrom', this.dateFrom);
    }
    if (this.dateTo !== undefined) {
      params = params.append('dateTo', this.dateTo);
    }
    if (this.languages?.length > 0) {
      this.languages.forEach(entry => {
        params = params.append('language', entry);
      });
    }
    if (this.formats?.length > 0) {
      this.formats.forEach(entry => {
        params = params.append('format', entry);
      });
    }
    if (this.status?.length > 0) {
      this.status.forEach(entry => {
        params = params.append('status', entry);
      });
    }
    if (this.createdDateFrom !== undefined) {
      params = params.append('createdDateFrom', this.createdDateFrom);
    }
    if (this.createdDateTo !== undefined) {
      params = params.append('createdDateTo', this.createdDateTo);
    }
    if (this.changedDateFrom !== undefined) {
      params = params.append('changedDateFrom', this.changedDateFrom);
    }
    if (this.changedDateTo !== undefined) {
      params = params.append('changedDateTo', this.changedDateTo);
    }
    if (this.offset !== undefined) {
      params = params.append('offset', this.offset.toString());
    }
    if (this.scrollTab !== undefined) {
      params = params.append('scrollTab', this.scrollTab);
    }

    // Timestamp wird angehängt, damit nicht auf den Cache zurückgegriffen wird
    params = params.append('time', Date.now().toString());

    return params;
  }
}
