import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, filter, map, toArray } from 'rxjs/operators';
import { ConfigurationService } from '@app/core/config/configuration.service';
import { Bereich } from '@app/shared/classes/adura/bereich';
import { Gefahr, GefahrAdmin } from '@app/shared/classes/adura/gefahr';
import { Matrix } from '@app/shared/classes/adura/matrix';
import { Treiber, TreiberAdmin } from '@app/shared/classes/adura/treiber';
import { Meldung } from '@app/shared/classes/adura/meldung';
import { Status } from '@app/shared/classes/adura/status';
import { Log } from '@app/shared/classes/adura/log';
import { SeismoInfo } from '@app/shared/classes/adura/seismoInfo';
import { Steckbrief } from '@app/shared/classes/adura/steckbrief';
import { AduraDocument } from '@app/shared/classes/adura/adura-document';
import { Publikation, PublikationAdmin } from '@app/shared/classes/adura/publikation';
import { AduraSearchResult } from '@app/shared/classes/adura/adura-search-result';
import { AduraFacet } from '@app/shared/classes/adura/adura-facet';
import { Priority } from '@app/shared/classes/adura/priority';
import { DocumentPreview } from '@app/shared/classes/document-preview';
import { Papierkorb } from '@app/shared/classes/adura/papierkorb';
import { AduraResultItem, AduraResultItemJSON } from '@app/shared/classes/adura/adura-result-item';
import { AduraKennzahlenParams } from '@app/shared/classes/adura/adura-kennzahlen-params';
import { AduraKennzahlenResult } from '@app/shared/classes/adura/adura-kennzahlen-result';

@Injectable({ providedIn: 'root' })
export class AduraService {

  private apiBase: string;
  private awisaBase: string;

  constructor(private http: HttpClient,
              private router: Router,
              configurationService: ConfigurationService) {
    this.apiBase = configurationService.getConfig().aduraUrl;
    this.awisaBase = configurationService.getConfig().awisaFOUrl;
  }

  getVersion(): Observable<any> {
    return this.http.get(`${ this.apiBase }/admin/version`, { responseType: 'text' })
      .pipe();
  }

  listBereich(): Observable<Bereich[]> {
    return this.http.get(`${ this.apiBase }/stammdaten/bereich`)
      .pipe(
        map((data: Bereich[]) => data.map(Bereich.fromJSON))
      );
  }

  listGefahr(): Observable<Gefahr[]> {
    return this.http.get(`${ this.apiBase }/stammdaten/gefahr`)
      .pipe(
        map((data: Gefahr[]) => data.map(Gefahr.fromJSON))
      );
  }

  listGefahrAdmin(): Observable<GefahrAdmin[]> {
    return this.http.get(`${ this.apiBase }/stammdaten/gefahr/admin`)
      .pipe(
        map((data: GefahrAdmin[]) => data.map(GefahrAdmin.fromJSON))
      );
  }

  createGefahr(treiber: TreiberAdmin): Observable<GefahrAdmin[]> {
    return this.http.put(`${ this.apiBase }/stammdaten/gefahr/create`, treiber.toJSON())
      .pipe(
        map((data: GefahrAdmin[]) => data.map(GefahrAdmin.fromJSON))
      );
  }

  editGefahr(treiber: GefahrAdmin): Observable<GefahrAdmin> {
    return this.http.put(`${ this.apiBase }/stammdaten/gefahr/edit`, treiber)
      .pipe(
        map((data: GefahrAdmin) => GefahrAdmin.fromJSON(data))
      );
  }

  deleteGefahr(gefahrId: number): Observable<GefahrAdmin[]> {
    return this.http.put(`${ this.apiBase }/stammdaten/gefahr/delete`, gefahrId)
      .pipe(
        map((data: GefahrAdmin[]) => data.map(GefahrAdmin.fromJSON))
      );
  }

  listMatrix(): Observable<Matrix[]> {
    return this.http.get(`${ this.apiBase }/stammdaten/matrix`)
      .pipe(
        map((data: Matrix[]) => data.map(Matrix.fromJSON))
      );
  }

  listTreiber(): Observable<Treiber[]> {
    return this.http.get(`${ this.apiBase }/stammdaten/treiber`)
      .pipe(
        map((data: Treiber[]) => data.map(Treiber.fromJSON))
      );
  }

  listTreiberAdmin(): Observable<TreiberAdmin[]> {
    return this.http.get(`${ this.apiBase }/stammdaten/treiber/admin`)
      .pipe(
        map((data: TreiberAdmin[]) => data.map(TreiberAdmin.fromJSON))
      );
  }

  createTreiber(treiber: TreiberAdmin): Observable<TreiberAdmin[]> {
    return this.http.put(`${ this.apiBase }/stammdaten/treiber/create`, treiber.toJSON())
      .pipe(
        map((data: TreiberAdmin[]) => data.map(TreiberAdmin.fromJSON))
      );
  }

  editTreiber(treiber: TreiberAdmin): Observable<TreiberAdmin> {
    return this.http.put(`${ this.apiBase }/stammdaten/treiber/edit`, treiber)
      .pipe(
        map((data: TreiberAdmin) => TreiberAdmin.fromJSON(data))
      );
  }

  deleteTreiber(treiberId: number): Observable<TreiberAdmin[]> {
    return this.http.put(`${ this.apiBase }/stammdaten/treiber/delete`, treiberId)
      .pipe(
        map((data: TreiberAdmin[]) => data.map(TreiberAdmin.fromJSON))
      );
  }

  listPublikation(): Observable<Publikation[]> {
    return this.http.get(`${ this.apiBase }/stammdaten/publikation`)
      .pipe(
        map((data: Publikation[]) => data.map(Publikation.fromJSON))
      );
  }

  listPublikationAdmin(): Observable<PublikationAdmin[]> {
    return this.http.get(`${ this.apiBase }/stammdaten/publikation/admin`)
      .pipe(
        map((data: PublikationAdmin[]) => data.map(PublikationAdmin.fromJSON))
      );
  }

  createPublikation(publikation: PublikationAdmin): Observable<PublikationAdmin> {
    return this.http.put(`${ this.apiBase }/stammdaten/publikation/create`, publikation.toJSON())
      .pipe(
        map((data: PublikationAdmin) => PublikationAdmin.fromJSON(data))
      );
  }

  editPublikation(publikation: PublikationAdmin): Observable<PublikationAdmin> {
    return this.http.put(`${ this.apiBase }/stammdaten/publikation/edit`, publikation)
      .pipe(
        map((data: PublikationAdmin) => PublikationAdmin.fromJSON(data))
      );
  }

  deletePublikation(publikationId: number): Observable<PublikationAdmin[]> {
    return this.http.put(`${ this.apiBase }/stammdaten/publikation/delete`, publikationId)
      .pipe(
        map((data: PublikationAdmin[]) => data.map(PublikationAdmin.fromJSON))
      );
  }

  listMeldungStatus(code: string): Observable<Status[]> {
    return this.http.get(`${ this.apiBase }/meldung/${ code }/status`)
      .pipe(
        map((data: Status[]) => data.map(Status.fromJSON))
      );
  }

  listMeldung(): Observable<Meldung[]> {
    return this.http.get(`${ this.apiBase }/meldung/`)
      .pipe(
        map((data: Meldung[]) => data.map(Meldung.fromJSON))
      );
  }

  listMeldungMinimal(): Observable<Meldung[]> {
    return this.http.get(`${ this.apiBase }/meldung/minimal`)
      .pipe(
        map((data: Meldung[]) => data.map(Meldung.fromJSON))
      );
  }

  getMeldung(code: string): Observable<Meldung> {
    return this.http.get(`${ this.apiBase }/meldung/${ code }`)
      .pipe(
        map((data: Meldung) => Meldung.fromJSON(data))
      );
  }

  saveMeldung(meldung: Meldung): Observable<Meldung> {
    return this.http.post(`${ this.apiBase }/meldung/save`, meldung)
      .pipe(
        map((data: Meldung) => Meldung.fromJSON(data))
      );
  }

  saveMeldungCreateSteckbrief(meldung: Meldung): Observable<Steckbrief> {
    return this.http.post(`${ this.apiBase }/meldung/save`, meldung)
      .pipe(
        map((data: Steckbrief) => Steckbrief.fromJSON(data))
      );
  }

  // Format ausgabe: yyyy-MM-dd
  setSeismoInfoAusgabe(code: string, ausgabe?: string): Observable<AduraResultItem> {
    return this.http.put(`${ this.apiBase }/meldung/${code}/seismoinfo/${ausgabe}`, null)
      .pipe(
        map((data: AduraResultItemJSON) => AduraResultItem.fromJSON(data))
      );
  }

  setMeldungRating(code: string, rating?: number): Observable<AduraResultItem> {
    return this.http.put(`${ this.apiBase }/meldung/${code}/rating/${rating}`, null)
      .pipe(
        map((data: AduraResultItemJSON) => AduraResultItem.fromJSON(data))
      );
  }

  mehrfachFreigabeMeldung(codes: string[]): Observable<{[code: string]: AduraResultItem | HttpErrorResponse}> {
    const requests = {};
    codes.forEach( (code) => {
      requests[code] = this.freigabeMeldung(code).pipe(
        catchError((err) => of(err))
      );
    });
    return forkJoin(requests);
  }

  freigabeMeldung(code: string): Observable<AduraResultItem> {
    return this.http.put(`${ this.apiBase }/meldung/${code}/freigeben`, null)
      .pipe(
        map((data: AduraResultItemJSON) => AduraResultItem.fromJSON(data))
      );
  }

  deleteMeldung(id: number): Observable<boolean> {
    return this.http.delete(`${ this.apiBase }/meldung/${ id }`)
      .pipe(
        map((data: boolean) => (data))
      );
  }

  getSeismoInfoPreview(seismoInfoAusgabe: string): Observable<DocumentPreview> {
    return this.http.get(`${ this.apiBase }/meldung/seismoInfo/preview/${ seismoInfoAusgabe }`,
      { observe: 'response', responseType: 'json', headers: new HttpHeaders({ 'X-UserReferrer': this.router.url }) })
      .pipe(
        map((data) => DocumentPreview.fromJSON(data))
      );
  }

  getSeismoInfoDocument(seismoInfoAusgabe: string): Observable<any> {
    return this.http.get(`${ this.apiBase }/meldung/seismoInfo/download/${ seismoInfoAusgabe }`,
      { observe: 'response', responseType: 'blob', headers: new HttpHeaders({ 'X-UserReferrer': this.router.url }) })
      .pipe();
  }

  getSeismoInfoAusgabeList(code?: string): Observable<SeismoInfo[]> {
    if (code === undefined) {
      return this.http.get(`${ this.apiBase }/meldung/seismoinfo`)
        .pipe(
          map((data: SeismoInfo[]) => data.map(SeismoInfo.fromJSON))
        );
    } else {
      return this.http.get(`${ this.apiBase }/meldung/seismoinfo/${ code }`)
        .pipe(
          map((data: SeismoInfo[]) => data.map(SeismoInfo.fromJSON))
        );
    }
  }

  getSeismoInfoAusgabeFreigabeList(): Observable<SeismoInfo[]> {
    return this.http.get(`${ this.apiBase }/meldung/seismoinfo/freigabe`)
      .pipe(
        map((data: SeismoInfo[]) => data.map(SeismoInfo.fromJSON))
      );
  }

  getPapierkorbLists(): Observable<Papierkorb> {
    return this.http.get(`${ this.apiBase }/admin/papierkorb`)
      .pipe(
        map((data: Papierkorb) => Papierkorb.fromJSON(data))
      );
  }

  listSteckbrief(): Observable<Steckbrief[]> {
    return this.http.get(`${ this.apiBase }/steckbrief/`)
      .pipe(
        map((data: Steckbrief[]) => data.map(Steckbrief.fromJSON))
      );
  }

  listSteckbriefStatus(code: string): Observable<Status[]> {
    return this.http.get(`${ this.apiBase }/steckbrief/${ code }/status`)
      .pipe(
        map((data: Status[]) => data.map(Status.fromJSON))
      );
  }

  listSteckbriefPriority(): Observable<Priority[]> {
    return this.http.get(`${ this.apiBase }/steckbrief/priority`)
      .pipe(
        map((data: Priority[]) => data.map(Priority.fromJSON))
      );
  }

  listAllowedMimeTypes(): Observable<string> {
    return this.http.get(`${ this.apiBase }/steckbrief/mime`, {responseType: 'text'})
      .pipe(
        map((data: any) => data.toString())
      );
  }

  getSteckbrief(code: string): Observable<Steckbrief> {
    return this.http.get(`${ this.apiBase }/steckbrief/${ code }`)
      .pipe(
        map((data: Steckbrief) => Steckbrief.fromJSON(data))
      );
  }

  saveSteckbrief(steckbrief: Steckbrief): Observable<Steckbrief> {
    return this.http.post(`${ this.apiBase }/steckbrief/save`, steckbrief)
      .pipe(
        map((data: Steckbrief) => Steckbrief.fromJSON(data))
      );
  }

  mehrfachFreigabeSteckbrief(codes: string[]): Observable<{[code: string]: AduraResultItem | HttpErrorResponse}> {
    const requests = {};
    codes.forEach( (code) => {
      requests[code] = this.freigabeSteckbrief(code).pipe(
        catchError((err) => of(err))
      );
    });
    return forkJoin(requests);
  }

  freigabeSteckbrief(code: string): Observable<AduraResultItem> {
    return this.http.put(`${ this.apiBase }/steckbrief/${code}/freigeben`, null)
      .pipe(
        map((data: AduraResultItemJSON) => AduraResultItem.fromJSON(data))
      );
  }

  deleteSteckbrief(id: number): Observable<boolean> {
    return this.http.delete(`${ this.apiBase }/steckbrief/${ id }`)
      .pipe(
        map((data: boolean) => (data))
      );
  }

  saveSteckbriefDocument(steckbrief: Steckbrief, steckbriefDocument: AduraDocument): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('name', steckbriefDocument.name);
    formData.append('autorisierung', String(steckbriefDocument.autorisierung));

    if (steckbriefDocument.id) {
      return this.http.put(`${ this.apiBase }/steckbrief/${ steckbrief.code }/documents/${ steckbriefDocument.id }`, formData);
    } else {
      formData.append('file', new File([steckbriefDocument.file], 'ignore', { type: steckbriefDocument.file.type }));
      return this.http.post(`${ this.apiBase }/steckbrief/${ steckbrief.code }/documents/`, formData);
    }
  }

  get temporaryDocumentUrl(): string {
    return `${ this.apiBase }/steckbrief/documents/`;
  }

  deleteSteckbriefDocument(steckbrief: Steckbrief, document: AduraDocument): Observable<any> {
    return this.http.delete(`${ this.apiBase }/steckbrief/${ steckbrief.code }/documents/${ document.id }`);
  }

  getSteckbriefDocument(id: number): Observable<any> {
    return this.http.get(`${ this.apiBase }/steckbrief/document/${ id }/raw`,
      { observe: 'response', responseType: 'blob', headers: new HttpHeaders({ 'X-UserReferrer': this.router.url }) })
      .pipe();
  }

  getDocumentPreview(id: number): Observable<DocumentPreview> {
    return this.http.get(`${ this.awisaBase }/document/adura/preview/${ id }`,
      { observe: 'response', responseType: 'json', headers: new HttpHeaders({ 'X-UserReferrer': this.router.url }) })
      .pipe(
        map((data) => DocumentPreview.fromJSON(data))
      );
  }

  listSteckbriefMinimal(): Observable<Steckbrief[]> {
    return this.http.get(`${ this.apiBase }/steckbrief/minimal`)
      .pipe(
        map((data: Steckbrief[]) => data.map(Steckbrief.fromJSON))
      );
  }

  listLogOfCode(code: string): Observable<Log[]> {
    return this.http.get(`${ this.apiBase }/log/code/${ code }`)
      .pipe(
        map((data: Log[]) => data.map(Log.fromJSON))
      );
  }

  listPapierkorb(): Observable<Log[]> {
    return this.http.get(`${ this.apiBase }/log/papierkorb/`)
      .pipe(
        map((data: Log[]) => data.map(Log.fromJSON))
      );
  }

  getLog(days: string): Observable<any> {
    return this.http.get(`${ this.apiBase }/log/since/${ days }`, { observe: 'response', responseType: 'blob' })
      .pipe();
  }

  getLogBetween(von: string, bis: string): Observable<any> {
    return this.http.get(`${ this.apiBase }/log/between/${ von }/${ bis }`, { observe: 'response', responseType: 'blob' })
      .pipe();
  }

  createLuceneIndex(): Observable<any> {
    return this.http.get(`${ this.apiBase }/search/createIndex`)
      .pipe();
  }

  search(params: HttpParams): Observable<AduraSearchResult> {
    console.log(params);
    return this.http.get(`${ this.apiBase }/search`, { params })
      .pipe(
        map((data) => AduraSearchResult.fromJSON(data))
      );
  }

  getExcel(params: HttpParams): Observable<any> {
    console.log(params);
    return this.http.get(`${ this.apiBase }/search/export`,
      { params, observe: 'response', responseType: 'blob', headers: new HttpHeaders({ 'X-UserReferrer': this.router.url }) })
      .pipe();
  }

  getKennzahlen(params: AduraKennzahlenParams): Observable<AduraKennzahlenResult> {
    return this.http.get(`${ this.apiBase }/report/kennzahlen`, {params: params.getHttpParams()})
        .pipe(
            map(AduraKennzahlenResult.fromJSON)
        );
  }

  getKennzahlenExport(params: AduraKennzahlenParams): Observable<any> {
    console.log(params);
    return this.http.get(`${ this.apiBase }/report/kennzahlen/export`,
        { params: params.getHttpParams(), observe: 'response', responseType: 'blob', headers: new HttpHeaders({ 'X-UserReferrer': this.router.url }) })
        .pipe();
  }

  filterFacetsBySearchTerm(searchTerm: string, facets: AduraFacet[]): Observable<AduraFacet[]> {
    return from(facets).pipe(
      filter(facet => facet.name?.toLowerCase()?.indexOf(searchTerm) >= 0),
      toArray()
    );
  }
}
