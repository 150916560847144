import {Component, OnInit} from '@angular/core';
import {DataExchangeService} from '@app/shared/services/data-exchange.service';
import {Monitoring} from '@app/shared/classes/task';

@Component({
  selector: 'app-admin-check',
  templateUrl: './check.component.html'
})
export class CheckComponent implements OnInit {

  checkRunning = false;
  checkResult: Monitoring[];
  checkOk = false;

  constructor(private dataexchangeService: DataExchangeService) {
  }

  ngOnInit(): void {
    this.checkShort();
  }

  checkShort() {
    this.checkRunning = true;
    this.dataexchangeService.checkBackend()
      .subscribe({
        next: result => {
          this.checkOk = true;
          this.checkResult = result;
          this.checkRunning = false;
        },
        error: error => {
          console.log(error);
          this.checkOk = false;
          this.checkResult = error.error;
          this.checkRunning = false;
        }
      });
  }
}
