import { Component, ElementRef, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { User } from '@app/core/auth/user';
import { SettingsService } from '@app/core/config/settings.service';
import { Document, DocumentSave } from '@app/shared/classes/document';
import { Role } from '@app/shared/classes/role';
import { SelectItem } from '@app/shared/classes/select-item';
import { Tag } from '@app/shared/classes/tag';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { DropzoneComponent, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-document-new',
  templateUrl: './document-new.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DocumentNewComponent implements OnInit {
  @ViewChild('fileUploader') fileUploader: ElementRef;
  @ViewChild(DropzoneComponent) dropzone: DropzoneComponent;
  html = '';
  documentForm: UntypedFormGroup;
  tags: Tag[];
  roles: Role[];
  statusList = ['gueltig', 'entwurf', 'archiviert'];
  languageList: SelectItem[] = [];
  document: Document;
  user: User;

  datePickerConfig = {
    format: 'DD.MM.YYYY',
    locale: ''
  };
  selectedDocumentDate: dayjs.Dayjs;
  documentDate: string;

  languages: UntypedFormArray = new UntypedFormArray([]);

  newDocumentEnabled = false;

  isSending = false;
  error = false;

  dropzoneConfig: DropzoneConfigInterface;
  dropzoneError: string[] = [];

  constructor(private awisaFoService: AwisaFoService,
              private settingsService: SettingsService,
              private translateService: TranslateService,
              private authenticationService: AuthenticationService,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              private zone: NgZone) {
  }

  ngOnInit(): void {
    const set = this.settingsService.settings().find(el => el.key === 'gui.add.document.enabled');
    if (set.value === '1') {
      this.newDocumentEnabled = true;
    }

    const lang = this.translateService.currentLang;
    const key = 'gui.roles.disclaimer.' + lang;
    const set2 = this.settingsService.settings().find(el => el.key === key);
    this.html = set2.value;

    this.user = this.authenticationService.getUser();
    this.translateService.getLangs().forEach((language) => {
      const langTranslateProp = 'language.' + language;
      this.languageList.push(new SelectItem(language, langTranslateProp, false));
    });
    this.awisaFoService.getTags()
      .subscribe(
        (result) => {
          this.tags = result;
        });
    this.awisaFoService.getRoles()
      .subscribe(
        (result) => {
          console.log(result);
          this.roles = result;
        });
    this.languageList[0].selected = true;
    this.initForm();

    Promise.all([
      this.authenticationService.getAccessToken(),
      firstValueFrom(this.awisaFoService.listAllowedMimeTypes())
    ]).then(([authToken, acceptedFiles]) => {
      const headers: any = {};
      if (authToken) {
        headers.Authorization = 'Bearer ' + authToken;
      }

      this.dropzoneConfig = {
        url: 'dummy', // Wird nicht verwendet
        autoProcessQueue: false,
        maxFilesize: 10,
        acceptedFiles,
        headers
      };
    });
  }

  private initForm() {
    this.documentForm = this.formBuilder.group({
      name: ['', Validators.required],
      language: [this.languageList[0].name, Validators.required],
      file: ['', Validators.required],
      desc_de: [''],
      desc_en: [''],
      desc_fr: [''],
      desc_it: [''],
      changeComment: [''],
      tags: [[], Validators.required],
      author: [this.user.guid],
      fremdAuthor: [''],
      status: ['gueltig'],
      roles: new UntypedFormArray(
        [new UntypedFormControl('BLV_AWISA_ROLLE_Veterinaerdienst'),
          new UntypedFormControl('BLV_AWISA_ROLLE_Lebensmittelbehoerde')]),
      documentDate: ['']
    });
  }

  get nameForm() {
    return this.documentForm.get('name');
  }

  get fileForm() {
    return this.documentForm.get('file');
  }

  get docDateForm() {
    return this.documentForm.get('documentDate');
  }

  get rolesForm() {
    return this.documentForm.get('roles') as UntypedFormArray;
  }

  get tagsForm() {
    return this.documentForm.get('tags');
  }

  createDocument() {
    this.isSending = true;
    const formValues = this.documentForm.value;

    const daten: DocumentSave = {
      name: formValues.name,
      language: formValues.language,
      file: formValues.file,
      descriptionDe: formValues.desc_de,
      descriptionEn: formValues.desc_en,
      descriptionFr: formValues.desc_fr,
      descriptionIt: formValues.desc_it,
      changeComment: formValues.changeComment,
      tags: formValues.tags.join(', '),
      author: formValues.author,
      fremdAuthor: formValues.fremdAuthor,
      status: formValues.status,
      roles: formValues.roles.join(', '),
      documentDate: this.documentDate || ''
    };

    if (!this.isOtherAuthor()) {
      daten.fremdAuthor = '';
      this.documentForm.controls.fremdAuthor.setErrors(null);
    } else if (!this.documentForm.controls.fremdAuthor.value) {
      this.documentForm.controls.fremdAuthor.setErrors({required: true});
    }

    // Maximale Dateigrösse 10 MB
    if (formValues.file.size > 10485760) {
      this.documentForm.controls.file.setErrors({max: true});
    }

    if (this.documentForm.controls.roles.value.includes('BLV_AWISA_ROLLE_Lebensmittelbehoerde')
      || this.documentForm.controls.roles.value.includes('BLV_AWISA_ROLLE_Veterinaerdienst')) {
      this.documentForm.controls.roles.setErrors(null);
    } else {
      this.documentForm.controls.roles.setErrors({min: true});
    }

    if (this.documentForm.valid) {
      this.awisaFoService.createDocument(daten)
        .subscribe({
          next: (result) => {
            this.isSending = false;
            this.onReset();
            this.error = false;
            console.log(result);
            this.showSuccessAlert('document.stored');
            this.router.navigateByUrl('/');
          },
          error: error => {
            this.isSending = false;
            this.error = true;
            console.log(error);
          }
        });
    } else {
      Object.keys(this.documentForm.controls).forEach(field => {
        const control = this.documentForm.get(field);
        control.markAsDirty({onlySelf: true});
      });
      this.isSending = false;
    }
  }

  isOtherAuthor(): boolean {
    if (this.documentForm.controls.author.value === '') {
      return true;
    }
    return false;
  }

  chooseRole(event) {
    /* Selected */
    if (event.target.checked) {
      this.rolesForm.push(new UntypedFormControl(event.target.name));
    }  /* unselected */
    else {
      let i = 0;
      this.rolesForm.controls.forEach((ctrl: UntypedFormControl) => {
        if (ctrl.value === event.target.name) {
          this.rolesForm.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  isRoleActive(role: string) {
    const roleExist = this.rolesForm.controls.find(
      (res: UntypedFormControl) => res.value === role);
    if (roleExist) {
      return roleExist;
    } else {
      return false;
    }
  }

  getDocumentDate() {
    if (this.selectedDocumentDate) {
      this.documentDate = this.selectedDocumentDate.format('YYYY-MM-DD');
    }
  }

  getDocumentDateFromDatePicker(event: any) {
    this.selectedDocumentDate = dayjs(event.target.value.split('.').reverse().join('-'));

  }

  onReset() {
    this.ngOnInit();
  }

  selectFile(event) {
    const [file] = event.target.files;
    this.documentForm.patchValue({
      file
    });
  }

  showSuccessAlert(translateString: string) {
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          });
        });
  }

  dropzoneFileAdded(file): void {
    this.zone.runOutsideAngular(() => {
      const dz = this.dropzone.directiveRef.dropzone();
      dz.removeFile(file);
    });

    this.fileForm.setValue(file);
  }

  dropzoneFileError([file, error]): void {
    this.dropzoneError.push(error);
    this.zone.runOutsideAngular(() => {
      const dz = this.dropzone.directiveRef.dropzone();
      dz.removeFile(file);
    });
  }

  deleteFileUpload() {
    this.fileForm.setValue(null);
  }
}
