<section class="facet-filter">
  <a role="button" class="facet-content-type_de-collapse-button facet-toggle collapsed"
     (click)="changeContentTypeCollapseForTablet()"
     [attr.aria-expanded]="activeContentTypeCollapseTablet" aria-controls="facet-content-type_de-collapse">
    <h2>{{ 'content.type' | translate }}<i class="fa fa-angle-down"></i></h2>
  </a>
  <h2 class="facet-header" [attr.aria-label]="'content.type' | translate">{{ 'content.type' | translate }}</h2>
  <div id="facet-content-type_de-collapse" class="collapse facet-collapse"
       [ngClass]="(activeContentTypeCollapseTablet)?'in':''">
    <ng-container *ngIf="!this.contentTypes || this.contentTypes.length === 0">
      <p><small>{{ 'search.noFilter' | translate }}</small></p>
    </ng-container>
    <nav [attr.aria-label]="'content.list' | translate">
      <div class="list-inline tags">
        <ng-container *ngFor="let contentType of contentTypes">
          <div class="flex-container">
            <input type="checkbox" class="filter-checkbox sr-only" id="{{contentType.name}}"
                   (click)="changeContentType(contentType.name)"
                   [checked]="isContentTypeActive(contentType.name)">
            <label class="filter-checkbox-label" [ngClass]="(contentType.count === 0)?'filter-element-background':''"
                   for="{{contentType.name}}"
                   role="checkbox"
                   [attr.aria-label]="('content.label' | translate) + ': ' + (contentType.name + '.label' | translate) + ', ' +
                   ('global.total' | translate) + ': ' + contentType.count"
                   [attr.aria-checked]="(isContentTypeActive(contentType.name))?'true':'false'">
              {{ contentType.name + '.label' | translate }}
            </label>
            <span class="filter-checkbox-number" aria-hidden="true">{{ contentType.count}}</span>
          </div>
        </ng-container>
      </div>
    </nav>
  </div>
</section>
