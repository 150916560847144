export interface FavoriteJSON {
  id: number;
  title: string;
  url: string;
  notification: FavoriteFrequency;
  category: string;
  type: string;
  iconClass: string;
  messageProperty: string;
}

export class Favorite {
  id: number;
  title: string;
  url: string;
  notification: FavoriteFrequency;
  category: string;
  type: string;
  iconClass: string;
  messageProperty: string;

  static fromJSON(json: string | object): Favorite {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const favorite = Object.create(Favorite.prototype);
    return Object.assign(favorite, json);
  }

  toJSON(): FavoriteJSON {
    return Object.assign({}, this);
  }
}

export enum FavoriteFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  NO = 'NO'
}

export interface FavoriteSave {
  userGuid: string;
  title: string;
  url: string;
  notification: string;
  category: string;
}

export interface FavoriteEdit {
  id: number;
  title: string;
  notification: string;
  category: string;
}

export interface FavoriteCategoryEdit {
  guid: string;
  title: string;
  currentTitle: string;
}
