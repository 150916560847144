<!-- //NOSONAR UL-Element in äusserem Component --><li class="dataset-item clear-margin-bottom">
  <div class="dataset-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 item-content-name">
          <h2>
            <a class="item-content-name"
               routerLink="/adura/factsheet/{{ steckbrief.code }}" [innerHTML]="steckbrief.titel"></a>
            <span class="doc-item-format">
                  <h3 class="sr-only">{{ "format.label" | translate }}:</h3>
                  <label>({{ steckbrief.code }})</label>
            </span>
            <span class="cursor-pointer" (click)="openSteckbrief(steckbrief.code)" *ngIf="isEditable()">
                  <i class="fa fa-edit adura-preview-tooltip" title="{{ 'adura.steckbrief.edit' | translate }}"></i>
            </span>
            <i class="fa fa-eye adura-preview-tooltip"
               (mouseover)="toggleKurzInfoHidden()"
               (mouseleave)="toggleKurzInfoHidden()">
            </i>
            <i *ngIf="steckbrief.signal" class="fa fa-flag adura-signal-flag" title="Signal"></i>
          </h2>
        </div>
        <div class="col-xs-12 clear-padding-left {{ kurzInfoHidden ? 'hidden' : '' }}">
          <p class="kurz-info" [innerHTML]="steckbrief.kurzinfo"></p>
        </div>
        <div class="col-xs-12 col-md-3 clear-padding-left">
          <p [attr.aria-label]="('global.changed' | translate) + ': ' + (steckbrief.mutDate | date: 'dd.MM.yyyy')">
            {{ 'global.changed' | translate }}: {{ steckbrief.mutDate | date: 'dd.MM.yyyy' }}</p>
        </div>
        <ul class="col-xs-12 col-md-9 dataset-resources list-inline tags clear-padding-left">
          <ng-container *ngFor="let bereich of steckbrief.bereiche">
            <li class="nav-item filter-tag">
              <a role="button" class="filter-tag-label">{{ bereich }}</a>
            </li>
          </ng-container>
        </ul>
        <p *ngIf="!isProd()">Faktor: {{ steckbrief.relevanzFaktor }}</p>
      </div>
    </div>
  </div>
</li>
