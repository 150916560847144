<ng-template [ngIf]="this.edit" [ngIfElse]="showDocument">
  <form [formGroup]="documentForm" (ngSubmit)="addDocument()">
    <div class="row steckbrief-document mt-20">
      <div class="col-sm-2">
        <ng-container *ngFor="let auth of autorisierung">
          <label class="adura-radio steckbrief-document-radio">
            <input [value]='auth' type="radio" formControlName="autorisierung">
            {{ 'adura.autorisierung.' + auth | translate }}
          </label>
        </ng-container>
      </div>
      <div class="col-sm-6">
        <input formControlName="name"
               class="form-control adura-form"
               id="name"
               maxlength="255"
               autocomplete="off">
        <span class="length-counter">
          {{ (255 - documentForm.get('name').value?.length) + ' / 255' }}
        </span>
      </div>
      <div class="col-sm-3" style="overflow: hidden">
        {{ document.file?.name }}
      </div>
      <div class="col-sm-1 text-right">
        <button type="submit" class="favorite-icon"
                [disabled]="documentForm.invalid">
          <i class="fa fa-check"></i>
        </button>
        <button type="button" class="favorite-icon" (click)="onReset()">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #showDocument>
  <div class="row steckbrief-document" [ngClass]="this.toDelete?'setting-delete':''">
    <div class="col-sm-2">{{ 'adura.autorisierung.' + this.document.autorisierung | translate }}</div>
    <div class="col-sm-10">
      <div class="col-sm-10 clear-padding-left clear-padding-right text-wrap hide-overflow-x">
        <ng-template [ngIf]="!document.isNew" [ngIfElse]="noDownload"><a class="adura-link" (click)="getDocument(document.id)" target="_blank">{{ fileName }}</a></ng-template>
        <ng-template #noDownload>{{ fileName }}</ng-template>
      </div>
      <div class="col-sm-offset-1 col-sm-1 text-right clear-padding-right">
        <button id="editFileButton"
                class="favorite-icon"
                type="button"
                (click)="this.editDocument()">
          <i class="fa fa-pencil"></i>
        </button>
        <button id="restoreFileButton"
                class="favorite-icon"
                [ngClass]="this.toDelete?'btn-primary':'btn-danger'"
                type="button"
                (click)="this.markDocumentToDelete()">
          <i class="fa" [ngClass]="this.toDelete?'fa-recycle':'fa-trash-o'"></i>
        </button>
      </div>
    </div>
  </div>
</ng-template>
