import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Favorite } from '@app/shared/classes/favorite';
import { Router } from '@angular/router';

@Component({
  selector: 'app-favorite-tile',
  templateUrl: './favorite-tile.component.html'
})
export class FavoriteTileComponent  {
  @Input() favorites: Favorite[] = [];

  @Output() updateEditFavorite = new EventEmitter<Favorite>();
  @Output() updateDeleteFavorite = new EventEmitter<Favorite>();
  @Output() createLinkFavorite = new EventEmitter<Favorite>();

  constructor(private router: Router) { }

  copyLinkClipboard(favorite: Favorite) {
    this.createLinkFavorite.emit(favorite);
  }

  openEditFavorite(favorite: Favorite) {
    this.updateEditFavorite.emit(favorite);
  }

  openDeleteFavorite(favorite: Favorite) {
    this.updateDeleteFavorite.emit(favorite);
  }

  goToUrl(url: string) {
    this.router.navigateByUrl(url);
  }
}
