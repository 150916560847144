import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { Configuration } from '@app/core/config/configuration';
import { ConfigurationService } from '@app/core/config/configuration.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {

  private config: Configuration;
  private handled = false;

  constructor(private authenticationService: AuthenticationService,
              public router: Router,
              private configurationService: ConfigurationService) {
    this.config = configurationService.getConfig();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('/ping')) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error) => {
        console.error(error);
        if (error instanceof HttpErrorResponse) {
          this.handleHttpErrorResponse(error);
        }

        if (this.handled) {
          console.log('error handled');
          return of(error);
        } else {
          console.log('throw error back to to the subscriber');
          return throwError(() => error);
        }
      })
    );
  }

  private handleHttpErrorResponse(error: HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        console.error('Error Event');
      } else {
        console.log(`error status : ${error.status} ${error.statusText}`);
        switch (error.status) {
          case 401:
            this.router.navigateByUrl('/unauthorized');
            console.log('401 - Unauthorized');
            this.handled = true;
            break;
          case 403:
            const user = this.authenticationService.getUser();
            // Wenn der Benutzer undefined ist oder die Inhaltsrolle nicht erhält,
            // wird er auf die Zugriffsantragsseite weitergeleitet
            if (user === undefined || (!user.roles.includes('ROLE_INHALT') && !user.roles.includes('ROLE_ADURA'))) {
              const returnUrl: string = this.config.rootUrl;
              const ssoClient: string = this.config.ssoClient;
              window.location.href = this.config.ssoAccessReqUrl.replace('${ssoClient}', ssoClient).replace('${returnURL}', returnUrl);
            } else {
              this.router.navigateByUrl('/forbidden');
              console.log('403 - Forbidden');
              this.handled = true;
            }
            break;
          case 404:
            this.router.navigateByUrl('/not-found');
            console.log('404 - NotFound');
            this.handled = true;
            break;
        }
      }
    } else {
      console.error('Other Errors');
    }
  }
}
