import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AduraFacet } from '@app/shared/classes/adura/adura-facet';
import { AduraService } from '@app/shared/services/adura.service';

@Component({
  selector: 'app-adura-gefahr-side',
  templateUrl: './adura-gefahr-side.component.html'
})
export class AduraGefahrSideComponent implements OnChanges {
  @Input() gefahr: AduraFacet[] = [];
  @Input() activeGefahr: string[] = [];
  @Output() gefahrChanged = new EventEmitter<string[]>();
  @Output() searchTermChanged = new EventEmitter<string>();

  searchTerm: string;
  gefahrCopy: AduraFacet[];
  gefahrTop10: AduraFacet[];
  isShowMoreVal = false;

  constructor(private aduraService: AduraService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.copyGefahr();
    this.calcTopGefahr();
    this.clearInput();
  }

  changeGefahr(id: string) {
    if (this.searchTerm) {
      this.searchTermChanged.emit(this.searchTerm);
    }
    this.clearInput();

    const newActiveGefahr = this.activeGefahr.slice();

    const index = newActiveGefahr.indexOf(id);
    if (index > -1) {
      newActiveGefahr.splice(index, 1);
    } else {
      newActiveGefahr.push(id);
    }

    this.gefahrChanged.emit(newActiveGefahr);
  }

  isGefahrActive(gefahr: string) {
    return this.activeGefahr.find(res => res === gefahr);
  }

  calcTopGefahr() {
    if (this.gefahrCopy !== undefined && this.gefahrCopy !== null) {
      this.gefahrTop10 = this.gefahrCopy.slice(0, 10);
    } else {
      this.gefahrTop10 = [];
    }
  }

  get top10Length() {
    return (this.gefahrTop10) ? this.gefahrTop10.length : null;
  }

  isShowMoreGefahr() {
    return this.isShowMoreVal;
  }

  changeShowMoreGefahr() {
    this.isShowMoreVal = !this.isShowMoreVal;
  }

  gefahrListToShow() {
    if (this.isShowMoreVal) {
      return this.gefahrCopy;
    } else {
      return this.gefahrTop10;
    }
  }

  copyGefahr() {
    this.gefahrCopy = this.gefahr;
  }

  searchGefahr(): void {
    if (this.searchTerm) {
      this.aduraService.filterFacetsBySearchTerm(this.searchTerm.toLowerCase(), this.gefahr)
        .subscribe({
          next: result => this.gefahrCopy = result.length > 0 ? result : [],
          error: error => console.log(error)
        });
    } else {
      this.copyGefahr();
    }
    this.calcTopGefahr();
  }

  clearInput(): void {
    this.searchTerm = null;
    this.searchGefahr();
  }
}
