<section class="facet-filter">
  <div>
    <nav [attr.aria-label]="'document.status.list' | translate">
      <div class="col-sm-12 clear-padding-left">
        <ng-container *ngFor="let type of typeList">
          <label class="freigabe-type-filter" [ngClass]="isTypeActive(type)?'chosen':''" (click)="changeType(type)">{{ 'adura.freigabe.filter.' + type | translate }}</label>
        </ng-container>
        <div class="freigabe-type-req-fields">
          <input type="checkbox" class="freigabe-filter-checkbox sr-only" id="req_field_filter"
                 (click)="changeReqFieldFilter()"
                 [checked]="isReqFieldsActive()">
          <label class="freigabe-type-filter"
                 for="req_field_filter"
                 role="checkbox">
            {{ 'adura.freigabe.filter.missingReqFields' | translate }}&nbsp;<i class="fa fa-exclamation-triangle freigabe-type-filter warning-icon"></i>
          </label>
        </div>
      </div>
    </nav>
  </div>
</section>
