<ng-container *ngIf="thema">
  <header class="page-header">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <button appBackButton type="button" class="btn btn-sm btn-primary back-button"
                  title="{{ 'global.back' | translate }}"
                  [attr.aria-label]="'global.back' | translate">
            <i class="fa fa-arrow-left"></i>
          </button>
        </div>
      </div>
    </div>
  </header>

  <section class="container information">
    <div class="row description">
      <div class="col-xs-12">
        <h1>{{ thema.name }}
          <button type="button" class="btn-sm btn btn-primary title-icon"
                  title="{{ 'favorite.save' | translate }}" (click)="openCreateFavorite()"
                  [attr.aria-label]="'favorite.save' | translate">
            <i class="fa fa-star-o" aria-hidden="true"></i>
          </button>
          <app-link-clipboard title="{{ thema.name }}" type="{{ 'subject.label' | translate }}"></app-link-clipboard>
          <button type="button" class="btn-sm btn btn-primary title-icon"
                  title="{{ 'subject.print' | translate }}" (click)="print()"
                  [attr.aria-label]="'subject.print' | translate">
            <i class="fa fa-print" aria-hidden="true"></i>
          </button>
          <div class="qrcodeImage">
            <qrcode
              [qrdata]="themaLink"
              [allowEmptyString]="true"
              [cssClass]="'right'"
              [colorDark]="'#000000ff'"
              [colorLight]="'#ffffffff'"
              [elementType]="'canvas'"
              [errorCorrectionLevel]="'M'"
              [margin]="4"
              [scale]="1"
              [width]="100"
            ></qrcode>
          </div>
        </h1>
      </div>
    </div>
    <app-favorite-create></app-favorite-create>
  </section>

  <section class="container">
    <div class="row" style="margin-top: 20px; padding: 15px">
      <app-tabs>
        <app-tab tabTitle="{{ 'content.label' | translate }}" contentClass="tabmenu-content">
          <ul class="list-unstyled">
            <app-thema-content-item role="tabpanel"
                                    [themaContent]="thema.html"></app-thema-content-item>
          </ul>
        </app-tab>
        <app-tab tabTitle="{{ 'comment.plural' | translate }}" contentClass="tabmenu-content" contentLength="{{ comments.length }}">
          <ul class="list-unstyled">
            <app-comment role="tabpanel"
                         [refGuid]="thema.guid" [comments]="comments" [reference]="'subject'"></app-comment>
          </ul>
        </app-tab>
        <app-tab tabTitle="{{ 'global.furtherInformation' | translate }}" contentClass="tabmenu-content">
          <ul class="list-unstyled">
            <app-thema-information-item role="tabpanel"
                                        [thema]="thema"></app-thema-information-item>
          </ul>
        </app-tab>
      </app-tabs>
    </div>
  </section>
</ng-container>

