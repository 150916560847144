import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AduraService } from '@app/shared/services/adura.service';
import { AduraFacet } from '@app/shared/classes/adura/adura-facet';

@Component({
  selector: 'app-adura-seismo-info-side',
  templateUrl: './adura-seismo-info-side.component.html'
})
export class AduraSeismoInfoSideComponent implements OnChanges {
  @Input() seismoPub: AduraFacet[] = [];
  @Input() activeSeismoPub: string[] = [];
  @Output() seismoPubChanged = new EventEmitter<string[]>();

  @Input() seismoInfo: AduraFacet[] = [];
  @Input() activeSeismoInfo: string[] = [];
  @Output() seismoInfoChanged = new EventEmitter<string[]>();
  @Output() searchTermChanged = new EventEmitter<string>();

  searchTerm: string;
  seismoInfoCopy: AduraFacet[];
  seismoInfoTop10: AduraFacet[];
  isShowMoreVal = false;

  constructor(private aduraService: AduraService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.copySeismoInfo();
    this.calcTopSeismoInfo();
    this.clearInput();
  }

  isSeismoPubActive(id: string) {
    return this.activeSeismoPub.find(res => res === id);
  }

  changeSeismoPub(id: string) {
    id = id.replace('seismo_pub_', '');
    const newActiveSeismoPub = this.activeSeismoPub.slice();
    const index = newActiveSeismoPub.indexOf(id);
    if (index > -1) {
      newActiveSeismoPub.splice(index, 1);
    } else {
      newActiveSeismoPub.push(id);
    }
    this.seismoPubChanged.emit(newActiveSeismoPub);
  }

  changeSeismoInfo(id: string) {
    if (this.searchTerm) {
      this.searchTermChanged.emit(this.searchTerm);
    }
    this.clearInput();

    const newActiveSeismoInfo = this.activeSeismoInfo.slice();

    const index = newActiveSeismoInfo.indexOf(id);
    if (index > -1) {
      newActiveSeismoInfo.splice(index, 1);
    } else {
      newActiveSeismoInfo.push(id);
    }

    this.seismoInfoChanged.emit(newActiveSeismoInfo);
  }

  isSeismoInfoActive(seismoInfo: string) {
    return this.activeSeismoInfo.find(res => res === seismoInfo);
  }

  calcTopSeismoInfo() {
    if (this.seismoInfoCopy !== undefined && this.seismoInfoCopy !== null) {
      this.seismoInfoTop10 = this.seismoInfoCopy.slice(0, 10);
    } else {
      this.seismoInfoTop10 = [];
    }
  }

  get top10Length() {
    return (this.seismoInfoTop10) ? this.seismoInfoTop10.length : null;
  }

  isShowMoreSeismoInfo() {
    return this.isShowMoreVal;
  }

  changeShowMoreSeismoInfo() {
    this.isShowMoreVal = !this.isShowMoreVal;
  }

  seismoInfoListToShow() {
    if (this.isShowMoreVal) {
      return this.seismoInfoCopy;
    } else {
      return this.seismoInfoTop10;
    }
  }

  copySeismoInfo() {
    this.seismoInfoCopy = this.seismoInfo;
  }

  searchSeismoInfo(): void {
    if (this.searchTerm) {
      this.aduraService.filterFacetsBySearchTerm(this.searchTerm.toLowerCase(), this.seismoInfo)
        .subscribe({
          next: result => this.seismoInfoCopy = result.length > 0 ? result : [],
          error: error => console.log(error)
        });
    } else {
      this.copySeismoInfo();
    }
    this.calcTopSeismoInfo();
  }

  clearInput(): void {
    this.searchTerm = null;
    this.searchSeismoInfo();
  }
}
