import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AduraService } from '@app/shared/services/adura.service';
import { DocumentPreviewModalComponent } from '@app/document/document-preview-modal/document-preview-modal.component';
import { SeismoInfo } from '@app/shared/classes/adura/seismoInfo';
import { AduraSearchResult } from '@app/shared/classes/adura/adura-search-result';
import { AduraResultItem } from '@app/shared/classes/adura/adura-result-item';
import { AduraSearchParams } from '@app/shared/classes/adura/adura-search-params';
import { ChangedDateChangedEvent, CreatedDateChangedEvent } from '@app/adura/search/date-side/adura-date-side.component';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-adura-massenfreigabe',
  templateUrl: './adura-massenfreigabe.component.html'
})
export class AduraMassenfreigabeComponent implements OnInit {

  @ViewChild(DocumentPreviewModalComponent) documentPreviewModalComponent: DocumentPreviewModalComponent;
  sendingFreigabe = false;
  seismoInfoList: SeismoInfo[];
  seismoInfoAusgabe: SeismoInfo;
  searchResult: AduraSearchResult;
  resultList: AduraResultItem[] = [];

  toggleAllSubject: Subject<boolean> = new Subject<boolean>();
  selectedMeldungen: string[] = [];
  selectedSteckbriefe: string[] = [];
  allSelected = false;
  showSpinner = false;
  collapseFilter = false;

  // Filter
  createdDateFrom: string;
  createdDateTo: string;
  changedDateFrom: string;
  changedDateTo: string;
  seismoPub: string[] = [];
  seismoInfo: string[] = [];
  status: string[] = [];
  missingReqFields: boolean;
  type: string;

  steckbriefeList: AduraResultItem[];
  meldungenList: AduraResultItem[];
  hasMoreSteckbriefe = false;
  hasMoreMeldungen = false;
  offsetEntries = 50;

  typeList: string[] = ['allContent', 'factsheetsOnly', 'notificationsOnly'];
  totalEntries: number;

  errorSubject: Subject<string []> = new Subject<string[]>();
  successSubject: Subject<string []> = new Subject<string []>();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private aduraService: AduraService) {
  }

  ngOnInit(): void {
    this.searchDocuments();
    this.aduraService.getSeismoInfoAusgabeFreigabeList().subscribe(seismoInfo => {
      this.seismoInfoList = seismoInfo;
    });
  }

  reloadList() {
    this.searchDocuments();
  }

  searchDocuments(offset?: number, scrollTab?: string) {
    console.log('search documents');
    this.showSpinner = true;

    const searchParams = AduraSearchParams.fromObject({
      seismoInfo: this.seismoInfo,
      status: this.status,
      createdDateFrom: this.createdDateFrom,
      createdDateTo: this.createdDateTo,
      changedDateFrom: this.changedDateFrom,
      changedDateTo: this.changedDateTo,
      missingReqFields: this.missingReqFields,
      offset,
      scrollTab
    });

    this.aduraService.search(searchParams.getHttpParams()).subscribe({
      next: result => {
        this.searchResult = result;
        this.showSpinner = false;
        if (offset !== undefined) {
          if (scrollTab === 'factsheet' || scrollTab === 'massenfreigabe') {
            this.steckbriefeList = this.steckbriefeList.concat(result.steckbriefe);
            this.hasMoreSteckbriefe = result.hasMoreSteckbriefe;
          }
          if (scrollTab === 'notification' || scrollTab === 'massenfreigabe') {
            this.meldungenList = this.meldungenList.concat(result.meldungen);
            this.hasMoreMeldungen = result.hasMoreMeldungen;
          }
          this.offsetEntries = offset + 50;
        } else {
          this.steckbriefeList = result.steckbriefe;
          this.meldungenList = result.meldungen;
          this.hasMoreSteckbriefe = result.hasMoreSteckbriefe;
          this.hasMoreMeldungen = result.hasMoreMeldungen;
          this.offsetEntries = 50;
        }
        this.fillResultList();
      },
      error: () => this.showSpinner = false
    });
  }

  toggleAllSelected(): void {
    if (this.allSelected) {
      this.selectedMeldungen = [];
      this.selectedSteckbriefe = [];
    } else {
      this.searchResult.meldungen.forEach(meldung => {
        if (meldung.status !== '20' && !this.selectedMeldungen.includes(meldung.code)) {
          this.selectedMeldungen.push(meldung.code);
        }
      });
      this.searchResult.steckbriefe.forEach(steckbrief => {
        if (steckbrief.status !== '20' && !this.selectedSteckbriefe.includes(steckbrief.code)) {
          this.selectedSteckbriefe.push(steckbrief.code);
        }
      });
    }

    this.allSelected = !this.allSelected;
    this.toggleAllSubject.next(this.allSelected);
  }

  toggleMeldungSelected(meldung: AduraResultItem) {
    if (this.selectedMeldungen.includes(meldung.code)) {
      this.selectedMeldungen = this.selectedMeldungen.filter(code => code !== meldung.code);
    } else if (meldung.status !== '20') {
      this.selectedMeldungen.push(meldung.code);
    }
  }

  toggleSteckbriefSelected(steckbrief: AduraResultItem) {
    if (this.selectedSteckbriefe.includes(steckbrief.code)) {
      this.selectedSteckbriefe = this.selectedSteckbriefe.filter(code => code !== steckbrief.code);
    } else if (steckbrief.status !== '20') {
      this.selectedSteckbriefe.push(steckbrief.code);
    }
  }

  auswahlFreigeben(): void {
    const errors = [];
    const success = [];

    this.aduraService.mehrfachFreigabeMeldung(this.selectedMeldungen).subscribe((response) => {
      this.selectedMeldungen.forEach(code => {
        const res = response[code];
        if (res instanceof HttpErrorResponse) {
          errors.push(code);
          console.log(res.error);
        } else if (res instanceof AduraResultItem) {
          success.push(code);
          const resultItem = this.searchResult.meldungen.find(it => it.code === res.code);
          Object.assign(resultItem, res);
        }
      });
    }).add(() => {
      this.aduraService.mehrfachFreigabeSteckbrief(this.selectedSteckbriefe).subscribe((response) => {
        this.selectedSteckbriefe.forEach(code => {
          const res = response[code];
          if (res instanceof HttpErrorResponse) {
            errors.push(code);
            console.log(res.error);
          } else {
            success.push(code);
            const resultItem = this.searchResult.steckbriefe.find(it => it.code === res.code);
            Object.assign(resultItem, res);
          }
        });
      }).add(() => {
        this.errorSubject.next(errors);
        this.successSubject.next(success);
      });
    });
  }

  isSeismoInfoPreviewDisabled(): boolean {
    return !this.seismoInfoAusgabe || this.seismoInfoAusgabe.freigegebenCount === 0;
  }

  getSeismoInfoPreview() {
    this.showSpinner = true;

    this.aduraService.getSeismoInfoPreview(this.seismoInfoAusgabe.month.toString())
      .subscribe({
        next: (response: any) => {
          this.documentPreviewModalComponent.open(
            response, response.body?.name
          );
          this.showSpinner = false;
        },
        error: e => console.log(e)
      });
  }

  // Zum Testen
  getSeismoInfoDocument() {
    this.aduraService.getSeismoInfoDocument(this.seismoInfoAusgabe.month.toString())
      .subscribe({
        next: (response: HttpResponse<Blob>) => {
          const blob = new Blob([response.body], {type: response.headers.get('Content-Type')});
          const contentDisposition: string = response.headers.get('content-disposition');
          const r = /filename="(.+?)"/;
          const filename = r.exec(contentDisposition)[1];
          console.log(filename);
          saveAs(blob, filename);
        },
        error: e => console.log(e)
      });
  }

  fillResultList() {
    this.resultList = [];
    this.totalEntries = 0;
    if (this.type !== 'factsheetsOnly') {
      if (this.meldungenList) {
        this.resultList.push(...this.meldungenList);
        this.totalEntries += this.searchResult.meldungCount;
      }
    }

    if (this.type !== 'notificationsOnly') {
      if (this.steckbriefeList) {
        this.resultList.push(...this.steckbriefeList);
        this.totalEntries += this.searchResult.steckbriefCount;
      }
    }
  }

  get hasMoreEntries() {
    if (this.type === 'factsheetsOnly') {
      return this.hasMoreSteckbriefe;
    }
    if (this.type === 'notificationsOnly') {
      return this.hasMoreMeldungen;
    }
    return this.hasMoreSteckbriefe || this.hasMoreMeldungen;
  }

  get seismoPubFacets() {
    return (this.searchResult && this.searchResult.seismoPubFacets) ? this.searchResult.seismoPubFacets : null;
  }

  get seismoInfoFacets() {
    return (this.searchResult && this.searchResult.seismoInfoFacets) ? this.searchResult.seismoInfoFacets : null;
  }

  get statusFacets() {
    return (this.searchResult && this.searchResult.statusFacets) ? this.searchResult.statusFacets : null;
  }

  // Filter verarbeiten
  onSeismoPubChanged(seismoPub: string[]) {
    this.seismoPub = seismoPub;
    this.searchDocuments();
  }

  onSeismoInfoChanged(seismoInfo: string[]) {
    this.seismoInfo = seismoInfo;
    this.searchDocuments();
  }

  onStatusChanged(status: string[]) {
    this.status = status;
    this.searchDocuments();
  }

  onCreatedDateChanged(event: CreatedDateChangedEvent) {
    this.createdDateFrom = event.createdDateFrom;
    this.createdDateTo = event.createdDateTo;
    this.searchDocuments();
  }

  onChangedDateChanged(event: ChangedDateChangedEvent) {
    this.changedDateFrom = event.changedDateFrom;
    this.changedDateTo = event.changedDateTo;
    this.searchDocuments();
  }

  onMissingReqFieldsChanged(value: boolean) {
    this.missingReqFields = value;
    this.searchDocuments();
  }

  onTypeChanged(type: string) {
    this.type = type;
    this.fillResultList();
  }

  collapseFilterList() {
    this.collapseFilter = !this.collapseFilter;
  }

  nextTranche() {
    // Defaultmässig holen wir eine neue Tranche Steckbriefe UND Meldungen
    let scrollTab = 'massenfreigabe';

    // Wenns keine Meldungen mehr hat, holen wir nur die nächste Tranche Steckbriefe
    if (this.hasMoreSteckbriefe && !this.hasMoreMeldungen) {
      scrollTab = 'factsheet';
    }

    // Wenns keine Steckbriefe mehr hat, holen wir nur die nächste Tranche Meldungen
    if (!this.hasMoreSteckbriefe && this.hasMoreMeldungen) {
      scrollTab = 'notification';
    }
    this.searchDocuments(this.offsetEntries, scrollTab);
  }
}
