import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TreiberAdmin } from '@app/shared/classes/adura/treiber';
import { AduraService } from '@app/shared/services/adura.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-adura-treiber-create',
  templateUrl: './adura-treiber-create.component.html'
})
export class AduraTreiberCreateComponent implements AfterViewInit {
  @ViewChild('treiberCreateModal', { static: false }) treiberCreateModal: ElementRef;
  @Output() updateCreateTreiber = new EventEmitter();
  private elm: HTMLElement;
  treiberCreateForm: UntypedFormGroup;

  isSending = false;
  error = false;
  errorMessage: string;

  constructor(private aduraService: AduraService,
              private translateService: TranslateService,
              private router: Router,
              private formBuilder: UntypedFormBuilder) {
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.elm = this.treiberCreateModal.nativeElement as HTMLElement;
  }

  private initForm() {
    this.treiberCreateForm = this.formBuilder.group({
      bezeichnungDe: ['', Validators.required],
      bezeichnungEn: ['', Validators.required],
      bezeichnungFr: ['', Validators.required],
      bezeichnungIt: ['', Validators.required]
    });
  }

  onSubmit() {
    this.isSending = true;
    const formValues = this.treiberCreateForm.value;
    const daten = new TreiberAdmin();
    daten.bezeichnungDe = formValues.bezeichnungDe;
    daten.bezeichnungEn = formValues.bezeichnungEn;
    daten.bezeichnungFr = formValues.bezeichnungFr;
    daten.bezeichnungIt = formValues.bezeichnungIt;
    daten.deaktiviert = false;
    console.log(daten);

    this.aduraService.createTreiber(daten)
      .subscribe({
        next: () => {
          this.close();
          this.refreshTreiberList();
          this.showSuccessAlert('adura.driver.stored');
          this.isSending = false;
        },
        error: error => {
          this.error = true;
          if (error.error === undefined || error.statusText === 'Unknown Error') {
            this.errorMessage = 'global.error';
          } else {
            this.errorMessage = error.error;
          }
          this.isSending = false;
        }
      });
  }

  close() {
    this.treiberCreateForm.reset({}, { emitEvent: false });
    this.elm.classList.remove('show');
    setTimeout(() => {
      this.elm.style.width = '0';
    }, 75);
    this.error = false;
  }

  open(): void {
    this.elm.classList.add('show');
    this.elm.style.width = '100vw';
  }

  refreshTreiberList() {
    this.updateCreateTreiber.emit();
  }

  showSuccessAlert(translateString: string) {
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          });
        });
  }
}
