import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isDayjs } from 'dayjs';
import { AduraKennzahlenParams } from '@app/shared/classes/adura/adura-kennzahlen-params';
import { HttpResponse } from '@angular/common/http';
import { AduraService } from '@app/shared/services/adura.service';
import { saveAs } from 'file-saver';
import { AduraKennzahlenResult } from '@app/shared/classes/adura/adura-kennzahlen-result';

@Component({
  selector: 'app-adura-report-kennzahlen',
  templateUrl: './adura-report-kennzahlen.component.html'
})
export class AduraReportKennzahlenComponent implements OnInit {

  datePickerConfig = {
    format: 'DD.MM.YYYY',
    locale: 'de'
  };

  from: any;
  to: any;

  result: AduraKennzahlenResult;

  showSpinner = false;
  collapse = false;

  constructor(private translateService: TranslateService,
              private aduraService: AduraService) {
  }

  ngOnInit() {
    this.datePickerConfig.locale = this.translateService.currentLang;
    this.update();
  }

  get selectedFrom(): any {
    return this.from;
  }

  set selectedFrom(from: any) {
    if (from !== this.from) {
      this.from = from;
      this.update();
    }
  }

  get selectedTo(): any {
    return this.to;
  }

  set selectedTo(to: any) {
    if (to !== this.to) {
      this.to = to;
      this.update();
    }
  }

  update(): void {
    if ((this.to === undefined || isDayjs(this.to)) && (this.from === undefined || isDayjs(this.from))) {
      const kennzahlenParams = new AduraKennzahlenParams(this.from, this.to);
      this.aduraService.getKennzahlen(kennzahlenParams).subscribe({
        next: it => this.result = it,
        error: it => console.log(it)
      });
    }
  }

  downloadKennzahlen(): void {
    console.log('download Kennzahlen');
    this.showSpinner = true;

    const kennzahlenParams = new AduraKennzahlenParams(this.from, this.to);

    this.aduraService.getKennzahlenExport(kennzahlenParams).subscribe({
      next: (response: HttpResponse<Blob>) => {
        const blob = new Blob([response.body], { type: response.headers.get('Content-Type') });
        const contentDisposition: string = response.headers.get('content-disposition');
        const r = /(?:filename=")(.+)(?:")/;
        const filename = r.exec(contentDisposition)[1];
        console.log(filename);
        saveAs(blob, filename);
        this.showSpinner = false;
      },
      error: it => console.log(it)
    });
  }
}
