<form [formGroup]="treiberForm" (ngSubmit)="onSubmit()">
  <div class="hidden">
    <input id="id" class="admin-settings" formControlName="id" [ngClass]="delChosen?'setting-delete':''">
  </div>

  <div class="col-md-2 clear-padding-left">
    <input id="bezeichnungEn" class="admin-settings" formControlName="bezeichnungEn" [attr.disabled]="treiber.deaktiviert ? '' : null" [ngClass]="delChosen?'setting-delete':''" maxlength="255">
    <span class="length-counter">
      {{ (255 - treiberForm.get('bezeichnungEn').value?.length) + ' / 255' }}
    </span>
  </div>

  <div class="col-md-2">
    <input id="bezeichnungDe" class="admin-settings" formControlName="bezeichnungDe" [attr.disabled]="treiber.deaktiviert ? '' : null" [ngClass]="delChosen?'setting-delete':''" maxlength="255">
    <span class="length-counter">
      {{ (255 - treiberForm.get('bezeichnungDe').value?.length) + ' / 255' }}
    </span>
  </div>

  <div class="col-md-2">
    <input id="bezeichnungFr" class="admin-settings" formControlName="bezeichnungFr" [attr.disabled]="treiber.deaktiviert ? '' : null" [ngClass]="delChosen?'setting-delete':''" maxlength="255">
    <span class="length-counter">
      {{ (255 - treiberForm.get('bezeichnungFr').value?.length) + ' / 255' }}
    </span>
  </div>

  <div class="col-md-2">
    <input id="bezeichnungIt" class="admin-settings" formControlName="bezeichnungIt" [attr.disabled]="treiber.deaktiviert ? '' : null" [ngClass]="delChosen?'setting-delete':''" maxlength="255">
    <span class="length-counter">
      {{ (255 - treiberForm.get('bezeichnungIt').value?.length) + ' / 255' }}
    </span>
  </div>

  <div class="col-md-1">
    <input type="checkbox" id="deaktiviert" formControlName="deaktiviert" [ngClass]="delChosen?'setting-delete':''">
  </div>

  <div *ngIf="!treiberForm.dirty; else showButtons" class="col-md-2">
    <p class="admin-settings">
      {{ treiber.mutDate | date: 'dd.MM.yyyy HH:mm' }}<br/>
      {{ treiber.mutUser }}</p>
  </div>

  <ng-template #showButtons>
    <div class="col-md-2 text-right">
      <button class="btn btn-sm admin-settings" type="submit">
        <i class="fa fa-check"></i>
      </button>

      <button class="btn btn-sm admin-settings" type="button" title="Abbrechen"
              (click)="onReset()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </ng-template>

  <div class="col-md-1">
    <button class="btn btn-sm admin-settings" type="button" title="Löschen"
            (click)="deleteTreiber(treiber.id)">
      <i class="fa fa-trash-o"></i>
    </button>
  </div>
</form>
