<div class="modal fade" tabindex="-1" role="dialog" #documentPreviewModal>
  <div class="modal-dialog" style="top: 45%">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="close()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h2 class="modal-title">{{ 'document.preview.title' | translate }}{{ name }}</h2>
      </div>
      <div class="modal-body document-preview-modal-body document-preview-width-50" #documentPreviewModalBody>
        <div *ngIf="error" class="alert alert-danger" role="alert">
          <h4>
            {{ 'document.preview.error' | translate }}
          </h4>
          <p>
            {{ 'document.preview.notfound' | translate }}
          </p>
        </div>
        <iframe [src]="htmlString" class="document-preview-html" *ngIf="htmlString"></iframe>
        <ngb-carousel class="document-preview-carousel" [pauseOnHover]="true" *ngIf="pngImageStrings.length > 0">
          <ng-container *ngFor="let imageString of pngImageStrings">
            <ng-template ngbSlide>
              <img [src]="imageString" alt="Document preview" class="document-preview-image" #previewImage>
            </ng-template>
          </ng-container>
        </ngb-carousel>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-primary" (click)="zoomIn()" *ngIf="pngImageStrings.length > 0">
          <i class="fa fa-search-plus"></i>
        </button>
        <button type="button" class="btn btn-sm btn-primary" (click)="zoomOut()" *ngIf="pngImageStrings.length > 0">
          <i class="fa fa-search-minus"></i>
        </button>
        <button type="button" class="btn btn-sm btn-primary" (click)="getDocument()" *ngIf="showDownload()">
          <i class="fa fa-arrow-circle-down"></i>
        </button>
      </div>
    </div>
  </div>
</div>
