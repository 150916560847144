<section class="facet-filter">
  <a role="button" class="facet-date_de-collapse-button facet-toggle collapsed"
     (click)="changeModDateCollapseForTablet()"
     [attr.aria-expanded]="activeModDateCollapseTablet" aria-controls="facet-date_de-collapse">
    <h2>{{ 'date.mod' | translate }}<i class="fa fa-angle-down"></i></h2>
  </a>
  <h2 title="{{ 'date.mod' | translate }}" class="facet-header" [attr.aria-label]="'date.mod' | translate">{{ 'date.mod' | translate }}</h2>
  <div id="facet-date-collapse" class="collapse facet-collapse"
       [ngClass]="(activeModDateCollapseTablet)?'in':''">
    <nav [attr.aria-label]="'date.selection' | translate">
      <dp-date-picker class="search-doc-date" aria-hidden="true" placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                      [(ngModel)]="selectedModDateFrom" [config]="datePickerConfig"
                      (onSelect)="getModDateFrom()" (keyup.enter)="updateEmptyModDateData()">
      </dp-date-picker>
      <input (keyup.enter)="getModDateFromFromEvent($event)" [ngModel]="selectedModDateFrom | date: 'dd.MM.yyyy'"
             placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" type="text"
             tabindex="-1" class="sr-only" [attr.aria-label]="'date.from' | translate">

      <dp-date-picker aria-hidden="true" placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                      [(ngModel)]="selectedModDateTo" [config]="datePickerConfig"
                      (onSelect)="getModDateTo()" (keyup.enter)="updateEmptyModDateData()">
      </dp-date-picker>
      <input (keyup.enter)="getModDateToFromEvent($event)" [ngModel]="selectedModDateTo | date: 'dd.MM.yyyy'"
             placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" type="text"
             tabindex="-1" class="sr-only" [attr.aria-label]="'global.to' | translate">
    </nav>
  </div>
</section>
