<div class="row admin" *ngIf="meldungen">
  <div class="col-sm-12">
    <div class="col-sm-2 h5">{{ 'adura.restore' | translate }}</div>
    <div class="col-sm-3 h5">{{ 'adura.meldung.id' | translate }}</div>
    <div class="col-sm-5 h5">{{ 'adura.title' | translate }}</div>
    <div class="col-sm-2 h5">{{ 'adura.date.deleted' | translate }}</div>
  </div>
  <div class="col-sm-12 dl-horizontal" *ngFor="let meldung of meldungen">
    <div class="col-sm-2">
      <button type="button"
              class="favorite-icon"
              (click)="openEditMeldung(meldung)">
        <i class="fa fa-recycle"></i>
      </button>
    </div>
    <div class="col-sm-3" [innerHTML]="meldung.code"></div>
    <div class="col-sm-5" [innerHTML]="meldung.titel"></div>
    <div class="col-sm-2">{{ meldung.mutDate | date: 'dd.MM.yyyy' }}</div>
  </div>
  <app-adura-papierkorb-meldung-edit (updateRestoreMeldung)="updateMeldungen($event)"></app-adura-papierkorb-meldung-edit>
</div>
<div class="row admin" *ngIf="steckbriefe">
  <div class="col-sm-12">
    <div class="col-sm-2 h5">{{ 'adura.restore' | translate }}</div>
    <div class="col-sm-3 h5">{{ 'adura.steckbrief.id' | translate }}</div>
    <div class="col-sm-5 h5">{{ 'adura.title' | translate }}</div>
    <div class="col-sm-2 h5">{{ 'adura.date.deleted' | translate }}</div>
  </div>
  <div class="col-sm-12 dl-horizontal" *ngFor="let steckbrief of steckbriefe">
    <div class="col-sm-2">
      <button type="button"
              class="favorite-icon"
              (click)="openEditSteckbrief(steckbrief)">
        <i class="fa fa-recycle"></i>
      </button>
    </div>
    <div class="col-sm-3" [innerHTML]="steckbrief.code"></div>
    <div class="col-sm-5" [innerHTML]="steckbrief.titel"></div>
    <div class="col-sm-2">{{ steckbrief.mutDate | date: 'dd.MM.yyyy' }}</div>
    <app-adura-papierkorb-steckbrief-edit (updateRestoreSteckbrief)="updateSteckbriefe($event)"></app-adura-papierkorb-steckbrief-edit>
  </div>
</div>
<div class="row admin">
  <div class="col-sm-12">
    <h3 class="col-sm-12" id="log">{{ 'adura.log.trash.title' | translate }} <i *ngIf="!logOpen" (click)="openLogList()" class="fa fa-caret-right"></i>
      <i *ngIf="logOpen" (click)="openLogList()" class="fa fa-caret-down"></i>
    </h3>
    <div *ngIf="logOpen">
      <div class="col-sm-12">
        <div *ngIf="error" class="errors-text">{{ 'adura.log.error' | translate }}</div>
      </div>
      <ng-container *ngFor="let log of logs">
        <div class="col-sm-2">{{ log.timestamp | date: 'dd.MM.yyyy' }}</div>
        <div class="col-sm-3">
          {{ 'adura.log.trash.label' | translate }}
        </div>
        <div class="col-sm-5">{{ log.comment }}</div>
        <div class="col-sm-2 clear-padding-right">{{ log.person }}</div>
      </ng-container>
    </div>
  </div>
</div>
