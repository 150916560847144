import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AduraFacet } from '@app/shared/classes/adura/adura-facet';

@Component({
  selector: 'app-adura-priority-side',
  templateUrl: './adura-priority-side.component.html'
})
export class AduraPrioritySideComponent {
  @Input() priority: AduraFacet[] = [];
  @Input() activePriority: string[] = [];
  @Output() priorityChanged = new EventEmitter<string[]>();

  isPriorityActive(id: string) {
    return this.activePriority.find(res => res === id);
  }

  changePriority(id: string) {
    id = id.replace('priority_', '');
    const newActivePriority = this.activePriority.slice();
    const index = newActivePriority.indexOf(id);
    if (index > -1) {
      newActivePriority.splice(index, 1);
    } else {
      newActivePriority.push(id);
    }
    this.priorityChanged.emit(newActivePriority);
  }
}
