<div #publikationAddModal class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button *ngIf="!isSending" type="button" class="close" (click)="close()" aria-label="Close"><span
          aria-hidden="true">&times;</span>
        </button>
        <h3 class="modal-title">{{ 'adura.publication.add' | translate }}</h3>
      </div>
      <form [formGroup]="publikationAddForm" (ngSubmit)="onSubmit()" *ngIf="readyToInitForm && !showPublikationCreate">
        <div class="modal-body">
          <p *ngIf="error" class="alert alert-danger" role="alert"> {{ errorMessage | translate }}</p>
          <div class="form-group">
            <label>{{ 'adura.publication.label' | translate }}:
              <a role="button" title="{{ 'adura.publikation.create' | translate }}" (click)="openPublikationCreate()"
                 [attr.aria-label]="'adura.publikation.create' | translate"><i class="fa fa-plus" aria-hidden="true"></i></a>
            </label>
            <ng-select formControlName="publikation"
                       placeholder="{{ 'adura.publication.choose' | translate }}"
                       [items]="publikationList"
                       [clearable]="false"
                       [compareWith]="compareFn"
                       bindLabel="titelAbkuerzung"
                       dropdownPosition="bottom">
              <ng-template ng-option-tmp let-item="item">
                <div>{{ item.abkuerzung }} - {{ item.titel }}</div>
              </ng-template>
            </ng-select>
          </div>
          <div class="form-group">
            <label for="link">{{ 'adura.publication.link' | translate }}:</label>
            <span class="length-counter-modal">
              {{ (255 - publikationAddForm.get('link').value?.length) + ' / 255' }}
            </span>
            <input
              type="text"
              class="form-control"
              formControlName="link"
              id="link"
              maxlength="255"
              autocomplete="off">
          </div>
          <div class="form-group" *ngIf="pubDetailType === 10">
            <label for="pageCount">{{ 'adura.publication.page.count' | translate }}:</label>
            <input
              type="number"
              class="form-control"
              formControlName="pageCount"
              id="pageCount"
              maxlength="255"
              autocomplete="off">
          </div>
          <div class="form-group" *ngIf="pubDetailType === 10">
            <label for="date">{{ 'adura.publication.date' | translate }}:</label><br/>
            {{ 'adura.publication.dateFormat' | translate }}
            <input [ngClass]="hasFieldError('date') ? 'errors-input' : ''"
              type="text"
              class="form-control"
              formControlName="date"
              id="date"
              autocomplete="off">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" (click)="close()">
            {{ 'global.cancel' | translate }}
          </button>
          <button type="submit" class="btn btn-sm btn-space btn-primary"
                  [disabled]="publikationAddForm.invalid || this.isSending">
            <i *ngIf="isSending" class="fa fa-spinner fa-pulse fa-fw"></i>{{ pubDetail ? ('global.save' | translate) : ('global.add' | translate) }}
          </button>
        </div>
      </form>

      <form [formGroup]="publikationCreateForm" *ngIf="showPublikationCreate">
        <div class="modal-body">
          <p *ngIf="error" class="alert alert-danger" role="alert"> {{ errorMessage | translate }}</p>
          <div class="form-group">
            <label for="titel">{{ 'adura.stammdaten.titel' | translate }}:</label>
            <input
              type="text"
              class="form-control"
              formControlName="titel"
              id="titel"
              maxlength="255"
              autocomplete="off">
          </div>
          <div class="form-group">
            <label for="abkuerzung">{{ 'adura.stammdaten.abkuerzung' | translate }}:</label>
            <input
              type="text"
              class="form-control"
              formControlName="abkuerzung"
              id="abkuerzung"
              maxlength="255"
              autocomplete="off">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" (click)="closePublikationCreate()">
            {{ 'global.cancel' | translate }}
          </button>
          <button class="btn btn-sm btn-space btn-primary" (click)="savePublikation()"
                  [disabled]="publikationCreateForm.invalid || this.isSending">
            <i *ngIf="isSending" class="fa fa-spinner fa-pulse fa-fw"></i>{{ 'global.save' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
