<div class="col-sm-12">
  <div class="col-sm-12 entry" [ngClass]="hasError ? 'error' : hasSuccess ? 'success' : ''">
    <div class="col-sm-0.5">
      <input type="checkbox"
             name="freigabeMeldung"
             [value]="entry.code"
             [checked]="isFreigegeben() || isSelected"
             [disabled]="isFreigegeben()"
             (click)="toggleSelected()"/>
    </div>
    <div class="col-sm-1 clear-padding-left clear-padding-right">{{ getTypeLabel() | translate }} &nbsp;<i *ngIf="!entry.readyForFreigabe"
                                                                                                           class="fa fa-exclamation-triangle warning-icon"
                                                                                                           title="{{ 'adura.freigabe.filter.missingReqFields' | translate }}"></i>
    </div>
    <div class="col-sm-1">{{ 'adura.status.' + entry.status | translate }}</div>
    <div class="col-sm-2 text-ellipsis">
      <a routerLink="/adura/{{ entry.type }}/{{ entry.code }}" target="_blank" [innerHTML]="entry.titel"></a>
    </div>
    <div class="col-sm-2 adura-preview-tooltip">
      <div class="text-ellipsis" [innerHTML]="entry.kurzinfo"></div>
      <span class="preview-tooltiptext" [innerHTML]="entry.kurzinfo"></span>
    </div>
    <div class="col-sm-1" *ngIf="entry.code.startsWith('F')">-</div>
    <div class="col-sm-1" *ngIf="!entry.code.startsWith('F')">
      <app-rating-config [initialRating]="entry.sterne" (ratingChanged)="setRating($event)"></app-rating-config>
    </div>
    <div class="col-sm-1">{{ bereich }}</div>
    <div class="col-sm-0.5">{{ 'global.boolean.' + entry.isPublic | translate }}</div>
    <div class="col-sm-1.5">
      <div *ngIf="isMeldung()">
        <ng-template [ngIf]="entry.seismoPublikation && seismoInfoList" [ngIfElse]="noPub">
          <span *ngIf="seismoInfoList.length == 1">
            {{ seismoInfoList[0].month | date: 'MM/yyyy' }} ({{ seismoInfoList[0].count }})
          </span>
          <select *ngIf="seismoInfoList.length > 1" #seismoAusgabe (change)="updateSeismoAusgabe(seismoAusgabe.value)">
            <option *ngIf="!entry.seismoInfo" value="">&nbsp;</option>
            <option *ngFor="let seismoInfo of seismoInfoList" [value]="seismoInfo.month" [selected]="entry.seismoInfo === (seismoInfo.month | date: 'yyyy-MM')">
              {{ seismoInfo.month | date: 'MM/yyyy' }} ({{ seismoInfo.count }})
            </option>
          </select>
        </ng-template>
        <ng-template #noPub>
          <select disabled>
            <option>
              {{ '&nbsp;'.repeat(27) }}
            </option>
          </select>
        </ng-template>
      </div>
    </div>
    <div class="col-sm-1.5 clear-padding-right">{{ entry.erfDate | date: 'dd.MM.yyyy' }} / {{ entry.mutDate | date: 'dd.MM.yyyy' }}</div>
    <div class="col-sm-0.5 clear-padding-right"><i *ngIf="hasSaveSuccess" class="fa fa-check green-icon fadeOut"></i></div>
  </div>
</div>
