import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Facet } from '@app/shared/classes/facet';

@Component({
  selector: 'app-format-side',
  templateUrl: './format-side.component.html'
})
export class FormatSideComponent {
  @Input() formats: Facet[] = [];
  @Input() activeFormats: string[] = [];
  @Output() formatsChanged = new EventEmitter<string[]>();

  activeFormatCollapseTablet = false;

  isFormatActive(name: string) {
    return this.activeFormats.find(res => res === name);
  }

  changeFormat(name: string) {
    const newActiveFormats = this.activeFormats.slice();

    const index = newActiveFormats.indexOf(name);
    if (index > -1) {
      newActiveFormats.splice(index, 1);
    } else {
      newActiveFormats.push(name);
    }

    this.formatsChanged.emit(newActiveFormats);
  }

  changeFormatCollapseForTablet() {
    this.activeFormatCollapseTablet = !this.activeFormatCollapseTablet;
  }
}
