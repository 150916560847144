export interface SeismoInfoJSON {
  month: Date;
  meldungCount: number;
  freigegebenCount: number;
  selected: boolean;
  gesperrt: boolean;
}

export class SeismoInfo {
  month: Date;
  meldungCount: number;
  freigegebenCount: number;
  selected: boolean;
  gesperrt: boolean;

  static fromJSON(json: string | object): SeismoInfo {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const seismoInfo = Object.create(SeismoInfo.prototype);
    return Object.assign(seismoInfo, object);
  }

  toJSON(): SeismoInfoJSON {
    return Object.assign({}, this);
  }

  get count(): string {
    return [
      this.freigegebenCount,
      this.meldungCount
    ].filter(it => it !== undefined).join('/');
  }
}
