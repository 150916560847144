<ng-container *ngIf="editEntry; else noEdit" class="col-md-2">
  <form [formGroup]="gefahrForm" (ngSubmit)="onSubmit()">
    <div class="hidden">
      <input id="id" class="admin-settings" formControlName="id" [ngClass]="delChosen?'setting-delete':''">
    </div>

    <div class="col-md-2 clear-padding-left">
      <div class="tiny-input-settings">
        <editor formControlName="bezeichnungEn"
                maxlength="255"
                [inline]="true"
                [init]="tinyConfig"
                (onInit)="updateRemainingCharacters('bezeichnungEn', $event)"
                (onKeyUp)="updateRemainingCharacters('bezeichnungEn', $event)"
                (onChange)="updateRemainingCharacters('bezeichnungEn', $event)">
        </editor>
        <span class="length-counter">
          {{ remainingCharactersMap['bezeichnungEn'] + ' / ' + maxCharsMap['bezeichnungEn'] }}
        </span>
      </div>
    </div>

    <div class="col-md-2">
      <div class="tiny-input-settings">
        <editor formControlName="bezeichnungDe"
                maxlength="255"
                [inline]="true"
                [init]="tinyConfig"
                (onInit)="updateRemainingCharacters('bezeichnungDe', $event)"
                (onKeyUp)="updateRemainingCharacters('bezeichnungDe', $event)"
                (onChange)="updateRemainingCharacters('bezeichnungDe', $event)">
        </editor>
        <span class="length-counter">
          {{ remainingCharactersMap['bezeichnungDe'] + ' / ' + maxCharsMap['bezeichnungDe'] }}
        </span>
      </div>
    </div>

    <div class="col-md-2">
      <div class="tiny-input-settings">
        <editor formControlName="bezeichnungFr"
                maxlength="255"
                [inline]="true"
                [init]="tinyConfig"
                (onInit)="updateRemainingCharacters('bezeichnungFr', $event)"
                (onKeyUp)="updateRemainingCharacters('bezeichnungFr', $event)"
                (onChange)="updateRemainingCharacters('bezeichnungFr', $event)">
        </editor>
        <span class="length-counter">
          {{ remainingCharactersMap['bezeichnungFr'] + ' / ' + maxCharsMap['bezeichnungFr'] }}
        </span>
      </div>
    </div>

    <div class="col-md-2">
      <div class="tiny-input-settings">
        <editor formControlName="bezeichnungIt"
                maxlength="255"
                [inline]="true"
                [init]="tinyConfig"
                (onInit)="updateRemainingCharacters('bezeichnungIt', $event)"
                (onKeyUp)="updateRemainingCharacters('bezeichnungIt', $event)"
                (onChange)="updateRemainingCharacters('bezeichnungIt', $event)">
        </editor>
        <span class="length-counter">
          {{ remainingCharactersMap['bezeichnungIt'] + ' / ' + maxCharsMap['bezeichnungIt'] }}
        </span>
      </div>
    </div>

    <div class="col-md-1">
      <input type="checkbox" id="deaktiviert" formControlName="deaktiviert" [ngClass]="delChosen?'setting-delete':''">
    </div>

    <div class="col-md-2">
      <p class="admin-settings">
        {{ gefahr.mutDate | date: 'dd.MM.yyyy HH:mm' }}<br/>
        {{ gefahr.mutUser }}</p>
    </div>

    <div class="col-md-1 clear-padding-left">
      <button class="btn btn-sm admin-settings" type="submit"
              [ngStyle]="{ 'visibility': gefahrForm.dirty ? '' : 'hidden'}"
              [disabled]="gefahrForm.invalid">
        <i class="fa fa-check"></i>
      </button>
      <button class="btn btn-sm admin-settings" type="button" title="Abbrechen"
              (click)="onReset()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </form>
</ng-container>

<ng-template #noEdit>
  <div class="col-md-2 clear-padding-left">
    <p class="admin-settings" [ngClass]="delChosen?'setting-delete':''" [innerHTML]="gefahr.bezeichnungEn"></p>
  </div>

  <div class="col-md-2">
    <p class="admin-settings" [ngClass]="delChosen?'setting-delete':''" [innerHTML]="gefahr.bezeichnungDe"></p>
  </div>

  <div class="col-md-2">
    <p class="admin-settings" [ngClass]="delChosen?'setting-delete':''" [innerHTML]="gefahr.bezeichnungFr"></p>
  </div>

  <div class="col-md-2">
    <p class="admin-settings" [ngClass]="delChosen?'setting-delete':''" [innerHTML]="gefahr.bezeichnungIt"></p>
  </div>

  <div class="col-md-1" [ngClass]="delChosen?'setting-delete':''">
    <input type="checkbox" [checked]="gefahr.deaktiviert" disabled>
  </div>

  <div class="col-md-2">
    <p class="admin-settings">
      {{ gefahr.mutDate | date: 'dd.MM.yyyy HH:mm' }}<br/>
      {{ gefahr.mutUser }}</p>
  </div>

  <div class="col-md-1 clear-padding-left">
    <button class="btn btn-sm admin-settings" title="Bearbeiten" (click)="edit()">
      <i class="fa fa-pencil"></i>
    </button>
    <button class="btn btn-sm admin-settings" type="button" title="Löschen"
            (click)="deleteGefahr(gefahr.id)">
      <i class="fa fa-trash-o"></i>
    </button>
  </div>
</ng-template>
