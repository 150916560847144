<header class="page-header front-header">
  <div class="container">
    <div class="row">
      <div class="col-md-12 clear-padding-right">
        <form [formGroup]="sucheForm" (ngSubmit)="onSubmitSuche()">
          <div class="col-xs-9 col-md-11 clear-padding-right clear-padding-left">
            <input formControlName="term" (keydown.enter)="onSubmitSuche()" id="suche" class="form-control input-lg" name="term"
                   placeholder="{{ 'search.placeholder' | translate }}" [attr.aria-label]="'search.placeholder' | translate"
                   [(ngModel)]="term">
            <button class="form-control-feedback search-button " (click)="onSubmitSuche()" [attr.aria-label]="'search.label.activity' | translate"
                    title="{{ 'search.label.activity' | translate }}"><i class="fa fa-search" aria-hidden="true"></i></button>
          </div>
          <div class="col-xs-3 col-md-1 text-md-right" *ngIf="term">
            <button type="button" class="btn search-reset" (click)="resetSearch()" [attr.aria-label]="'search.reset' | translate">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</header>
<app-domain></app-domain>
<app-favorite></app-favorite>
<app-changes></app-changes>
