import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild
} from '@angular/core';
import { AduraService } from '@app/shared/services/adura.service';
import { TranslateService } from '@ngx-translate/core';
import { Meldung } from '@app/shared/classes/adura/meldung';
import Swal from 'sweetalert2';
import { AduraMeldungComponent } from '@app/adura/meldung/adura-meldung-component';
import { Status } from '@app/shared/classes/adura/status';

@Component({
  selector: 'app-adura-papierkorb-meldung-edit',
  templateUrl: './adura-papierkorb-meldung-edit.component.html'
})
export class AduraPapierkorbMeldungEditComponent implements AfterViewInit {
  @ViewChild('meldungEditModal', {static: false}) meldungEditModal: ElementRef;
  @ViewChild(AduraMeldungComponent) meldungComponent: AduraMeldungComponent;
  @Output() updateRestoreMeldung = new EventEmitter<Meldung>();
  meldung: Meldung;

  private elm: HTMLElement;

  isSending = false;
  error = false;
  errorMessages = [];
  success = false;

  constructor(private aduraService: AduraService,
              private translateService: TranslateService) {
  }

  ngAfterViewInit(): void {
    this.elm = this.meldungEditModal.nativeElement as HTMLElement;
  }

  close() {
    this.elm.classList.remove('show');
    setTimeout(() => {
      this.elm.style.width = '0';
    }, 75);
  }

  open(meldung: Meldung): void {
    this.meldung = meldung;
    this.meldungComponent.initPapierkorbView(this.meldung);
    this.elm.classList.add('show');
    this.elm.style.width = '100vw';
  }

  restore(): void {
    const eingereichtStatus = new Status();
    eingereichtStatus.id = 10;
    this.meldung.status = eingereichtStatus;
    this.aduraService.saveMeldung(this.meldung)
      .subscribe({
        next: () => {
          this.close();
          this.showSuccessAlert('adura.meldung.restored');
          this.updateRestoreMeldung.emit(this.meldung);
        },
        error: error => {
          this.isSending = false;
          this.success = false;
          this.error = true;
          this.errorMessages = error.error;
          console.log('isSending');
          console.log(this.isSending);
          console.log(this.errorMessages);
        }
      });
  }

  showSuccessAlert(translateString: string) {
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000
          });
        });
  }
}
