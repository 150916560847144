import { Component, Input } from '@angular/core';
import { AduraService } from '@app/shared/services/adura.service';
import { TranslateService } from '@ngx-translate/core';
import { Log } from '@app/shared/classes/adura/log';

@Component({
  selector: 'app-adura-tracelog',
  templateUrl: './adura-tracelog.component.html'
})
export class AduraTracelogComponent {

  @Input()
  code: string;

  logs: Log[];
  listOpen: boolean;
  error: boolean;

  constructor(private aduraService: AduraService,
              private translateService: TranslateService) {
  }

  openLogList() {
    this.listOpen = !this.listOpen;
    this.error = false;
    if (this.listOpen) {
      if (this.logs === undefined) {
        this.aduraService.listLogOfCode(this.code).subscribe({
          next: logs => this.logs = logs,
          error: error => {
            this.error = true;
            console.error(error);
          }
        });
      }
    }
  }

  getLabel(log: Log) {
    if (log.label.id === 50) {
      return this.translateService.instant(log.label.messageProperty);
    }
    if (log.parentCode?.startsWith('N')) {
      return this.translateService.instant('adura.meldung.label') + ' ' + this.translateService.instant(log.label.messageProperty);
    }
    if (log.parentCode?.startsWith('F')) {
      return this.translateService.instant('adura.steckbrief.label') + ' ' + this.translateService.instant(log.label.messageProperty);
    }
  }
}
