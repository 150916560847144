<div class="navbar navbar-default" id="main-navigation" role="navigation"
     [attr.aria-label]="'global.navigation.main' | translate">
  <div class="container">
    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="navbar-header">
      <button type="button" class="navbar-toggle" [appCollapse]="[menuContainer]" aria-controls="main-navigation-collapse">
        <span class="sr-only">{{ 'global.navigation.fold' | translate }}</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <div class="flex-container">
        <div class="application-brand">
          <a *ngIf="awisaActive()" class="application-brand-logo" style="margin-right: 15px" routerLink="/">
            <img [src]="urlLogo" alt="bund-logo" [attr.aria-label]="'global.home' | translate"/>
          </a>
          <a *ngIf="!awisaActive()" class="application-brand-logo" style="margin-right: 15px" href="/adura">
            <img [src]="urlLogo" alt="bund-logo" [attr.aria-label]="'global.home' | translate"/>
          </a>
          <span class="application-brand-app-title">
          <a *ngIf="awisaActive()" class="application-brand-link" routerLink="/" [attr.aria-label]="'global.home' | translate">awisa</a>
          <a *ngIf="!awisaActive()" class="application-brand-link" href="/adura" [attr.aria-label]="'global.home' | translate">adura</a>
        </span>
        </div>
      </div>
    </div>

    <nav class="navbar-collapse collapse" id="main-navigation-collapse"
         [attr.aria-label]="'global.navigation.main' | translate"
         appCollapseContent #menuContainer="appCollapseContent">
      <div class="menu-mainnavigation-de-container">
        <ul id="menu-mainnavigation-de" class="nav navbar-nav navbar-right">
          <li *ngIf="showLink()" id="adura-link">
            <div class="col-sm-2">
              <a *ngIf="awisaActive()" routerLink="/adura" class="btn adura-button">adura</a>
              <a *ngIf="!awisaActive()" routerLink="/search/content" class="btn adura-button">awisa</a>
            </div>
          </li>
          <li *ngIf="showAdminIcon()" id="admin-dropdown" class="dropdown"
              appDropdown>
            <a (click)="setMenuSelection($event)" title="{{ 'admin.link' | translate }}" class="dropdown-toggle" role="button"
               [attr.aria-expanded]="isMenuSelected(('admin.link' | translate))"
               [attr.aria-label]="'admin.link' | translate"><i class="fa fa-cog" aria-hidden="true"></i><span class="caret"></span></a>
            <ul role="menu" class=" dropdown-menu">
              <ng-template [appHasRole]="['ADMIN_AWISA_ADMIN']">
                <li id="awisa-admin-link">
                  <a routerLink="/admin" [attr.aria-label]="'admin.link' | translate"
                     title="{{ 'admin.link' | translate }}">awisa</a>
                </li>
              </ng-template>
              <ng-template [appHasRole]="['ADMIN_AWISA_ADMIN','AWISA_FRESIL']">
                <li id="adura-admin-link">
                  <a routerLink="/adura/admin/log" [attr.aria-label]="'admin.link' | translate"
                     title="{{ 'admin.link' | translate }}">adura</a>
                </li>
              </ng-template>
            </ul>
          </li>
          <li *ngIf="getContentTypes().length > 0" id="add-dropdown" class="dropdown" appDropdown>
            <a (click)="setMenuSelection($event)" title="{{ 'global.add' | translate }}" class="dropdown-toggle" role="button"
               [attr.aria-expanded]="isMenuSelected(('global.add' | translate))"
               [attr.aria-label]="'global.add' | translate">{{ 'global.add' | translate }}<span class="caret"></span></a>
            <ul role="menu" class=" dropdown-menu">
              <ng-container *ngIf="awisaActive(); else adura">
                <ng-template [appHasRole]="['ROLE_INHALT']">
                  <li *ngFor="let contentType of getContentTypes()" id="header-add-{{ contentType }}"><a
                    role="menuitem" (click)="addContent(contentType)">{{ contentType + '.label' | translate }}</a></li>
                </ng-template>
              </ng-container>
              <ng-template #adura>
                <li><a routerLink="/adura/notification/new">{{ 'adura.meldung.label' | translate }}</a></li>
                <li><a routerLink="/adura/factsheet/new">{{ 'adura.steckbrief.label' | translate }}</a></li>
              </ng-template>
            </ul>
          </li>
          <li id="lang-dropdown" class="dropdown"
              appDropdown>
            <a (click)="setMenuSelection($event)" title="{{ 'language.menu' | translate }}" class="dropdown-toggle" role="button"
               [attr.aria-expanded]="isMenuSelected(('language.menu' | translate))"
               [attr.aria-label]="'language.menu' | translate">{{ 'language.' + currentLanguage | translate }}<span class="caret"></span></a>
            <ul role="menu" class=" dropdown-menu">
              <li *ngFor="let lang of getLanguages()" id="header-lang-{{ lang }}"><a
                role="menuitem" (click)="changeLanguage(lang)">{{ 'language.' + lang | translate }}</a></li>
            </ul>
          </li>
          <li id="user-dropdown" class="dropdown"
              appDropdown *ngIf="currentUser">
            <a (click)="setMenuSelection($event)" title="{{ 'global.userMenu' | translate }}"
               class="dropdown-toggle clear-padding-right" role="button"
               [attr.aria-expanded]="isMenuSelected(('global.userMenu' | translate))"
               [attr.aria-label]="'global.userMenu' | translate">{{ this.currentUser.givenName }} {{ this.currentUser.familyName }}<span
              class="caret"></span></a>
            <ul role="menu" class="dropdown-menu">
              <li id="header-userprofile"><a
                role="menuitem" (click)="userProfile()">{{ 'global.profile' | translate }}</a></li>
              <li id="header-logout"><a role="menuitem" (click)="logout()">{{ 'global.logout' | translate }}</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>

  </div>
</div>
