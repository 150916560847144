<section class="facet-filter">
  <h3 class="facet-subtitle" [attr.aria-label]="'adura.danger.plural' | translate">{{ 'adura.danger.plural' | translate }}</h3>
  <div>
    <div class="form-group input-reset-button" *ngIf="this.gefahr && this.gefahr?.length > 0">
      <input class="form-control input-sm" spellcheck="false" [(ngModel)]="searchTerm"
             (ngModelChange)="searchGefahr()" placeholder="{{ 'adura.danger.search' | translate }}"/>
      <button type="reset">
        <i class="fa fa-times" aria-hidden="true" (click)="clearInput()"></i>
      </button>
    </div>
    <ng-container *ngIf="!this.gefahrListToShow() || this.gefahrListToShow().length === 0">
      <p><small>{{ 'search.noFilter' | translate }}</small></p>
    </ng-container>
    <nav [attr.aria-label]="'adura.danger.list' | translate">
      <ul class="list-inline tags">
        <ng-container *ngFor="let gefahr of this.gefahrListToShow()">
          <li class="nav-item filter-tag"  [ngClass]="(isGefahrActive(gefahr.id))?'active':''">
            <a (click)="changeGefahr(gefahr.id)" role="button" class="filter-tag-label"
               [attr.aria-pressed]="(isGefahrActive(gefahr.id))?'true':'false'"
               [attr.aria-label]="('adura.danger.label' | translate) + ': ' + gefahr.name + ', ' +
             ('global.total' | translate) + ': ' + gefahr.count" [innerHTML]="gefahr.name + ' <small [attr.aria-label]=' + gefahr.count + ' aria-hidden=\'true\'>' + gefahr.count + '</small>'"></a></li>
        </ng-container>
      </ul>
    </nav>

    <ng-container *ngIf="(top10Length > 9)">
      <p><a (click)="changeShowMoreGefahr()" class="btn btn-xs">
        <ng-container *ngIf="isShowMoreGefahr(); then showmore else showless"></ng-container>
        <ng-template #showmore>
          <i class="fa filter-more-less fa-angle-up"></i>{{ 'global.show.less' | translate }}
        </ng-template>
        <ng-template #showless>
          <i class="fa filter-more-less fa-angle-down"></i>{{ 'global.show.more' | translate }}
        </ng-template>
      </a>
      </p>
    </ng-container>
  </div>
</section>
