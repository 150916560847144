import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AduraFacet } from '@app/shared/classes/adura/adura-facet';
import dayjs from 'dayjs';

export interface AktivitaetDateChangedEvent {
  aktivitaetDateFrom: string;
  aktivitaetDateTo: string;
  aktivitaetDateNull: string;
}

@Component({
  selector: 'app-adura-aktivitaet-side',
  templateUrl: './adura-aktivitaet-side.component.html'
})
export class AduraAktivitaetSideComponent implements OnInit, OnChanges {
  @Input() aktivitaetStatus: AduraFacet[] = [];
  @Input() activeAktivitaetStatus: string[] = [];
  @Output() aktivitaetStatusChanged = new EventEmitter<string[]>();

  @Input() activeAktivitaetDateFrom = '';
  @Input() activeAktivitaetDateTo = '';
  @Input() activeAktivitaetDateNull = '';
  @Output() aktivitaetDateChanged = new EventEmitter<AktivitaetDateChangedEvent>();

  datePickerConfig = {
    format: 'DD.MM.YYYY',
    locale: 'de'
  };

  selectedAktivitaetDateFrom: any;
  selectedAktivitaetDateTo: any;
  aktivitaetDateNullChecked: boolean;

  constructor(private translateService: TranslateService) {
  }

  ngOnInit() {
    this.datePickerConfig.locale = this.translateService.currentLang;
  }

  ngOnChanges(changes: SimpleChanges): void {
      // AktivitaetDate
    if (this.activeAktivitaetDateFrom) {
      this.selectedAktivitaetDateFrom = dayjs(this.activeAktivitaetDateFrom);
    } else {
      this.activeAktivitaetDateFrom = '';
      delete this.selectedAktivitaetDateFrom;
    }

    if (this.activeAktivitaetDateTo) {
      this.selectedAktivitaetDateTo = dayjs(this.activeAktivitaetDateTo);
    } else {
      this.activeAktivitaetDateTo = '';
      delete this.selectedAktivitaetDateTo;
    }

    this.aktivitaetDateNullChecked = this.activeAktivitaetDateNull === 'true';
  }

  aktivitaetStatusActive(id: string) {
    return this.activeAktivitaetStatus.find(res => res === id);
  }

  changeAktivitaetStatus(id: string) {
    id = id.replace('aktivitaet_status_', '');
    const newActiveAktivitaetStatus = this.activeAktivitaetStatus.slice();
    const index = newActiveAktivitaetStatus.indexOf(id);
    if (index > -1) {
      newActiveAktivitaetStatus.splice(index, 1);
    } else {
      newActiveAktivitaetStatus.push(id);
    }
    this.aktivitaetStatusChanged.emit(newActiveAktivitaetStatus);
  }
  updateEmptyAktivitaetDateData() {
    this.getAktivitaetDateData();
  }

  getAktivitaetDateFromEvent(event: any) {
    this.selectedAktivitaetDateFrom = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getAktivitaetDateFrom();
  }

  getAktivitaetDateFrom() {
    if (this.selectedAktivitaetDateFrom) {
      this.activeAktivitaetDateFrom = this.selectedAktivitaetDateFrom.format('YYYY-MM-DD');
      // Checkbox zurücksetzen
      this.aktivitaetDateNullChecked = false;
      this.getAktivitaetDateData();
    }
  }

  getAktivitaetDateToEvent(event: any) {
    this.selectedAktivitaetDateTo = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getAktivitaetDateTo();
  }

  getAktivitaetDateTo() {
    if (this.selectedAktivitaetDateTo) {
      this.activeAktivitaetDateTo = this.selectedAktivitaetDateTo.format('YYYY-MM-DD');
      // Checkbox zurücksetzen
      this.aktivitaetDateNullChecked = false;
      this.getAktivitaetDateData();
    }
  }

  getAktivitaetDateData() {
    let params;

    if (this.selectedAktivitaetDateFrom === undefined && this.selectedAktivitaetDateTo === undefined) {
      params = { aktivitaetDateFrom: undefined, aktivitaetDateTo: undefined };
    } else if (this.selectedAktivitaetDateFrom === undefined) {
      params = { aktivitaetDateFrom: undefined, aktivitaetDateTo: this.activeAktivitaetDateTo };
    } else if (this.selectedAktivitaetDateTo === undefined) {
      params = { aktivitaetDateFrom: this.activeAktivitaetDateFrom, aktivitaetDateTo: undefined };
    } else {
      params = { aktivitaetDateFrom: this.activeAktivitaetDateFrom, aktivitaetDateTo: this.activeAktivitaetDateTo };
    }

    // Nur TRUE wird geschickt
    if (this.aktivitaetDateNullChecked) {
      params.aktivitaetDateNull = this.aktivitaetDateNullChecked;
    }
    this.aktivitaetDateChanged.emit(params);
  }

  changeAktivitaetDateNullValue(event) {
    this.aktivitaetDateNullChecked = event.target.checked;

    // Wenn wir alle Steckbriefe ohne Datum gewählt haben, werden die Datumswerte rausgelöscht
    if (this.aktivitaetDateNullChecked) {
      this.selectedAktivitaetDateFrom = undefined;
      this.selectedAktivitaetDateTo = undefined;
    }
    this.getAktivitaetDateData();
  }

  aktivitaetDateNullActive() {
    return this.aktivitaetDateNullChecked;
  }
}
