import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AduraFacet } from '@app/shared/classes/adura/adura-facet';

@Component({
  selector: 'app-adura-is-public-side',
  templateUrl: './adura-is-public-side.component.html'
})
export class AduraIsPublicSideComponent {
  @Input() isPublic: AduraFacet[] = [];
  @Input() activeIsPublic: string[] = [];
  @Output() isPublicChanged = new EventEmitter<string[]>();

  isPublicActive(id: string) {
    return this.activeIsPublic.find(res => res === id);
  }

  changeIsPublic(id: string) {
    id = id.replace('is_public_', '');
    const newActiveIsPublic = this.activeIsPublic.slice();
    const index = newActiveIsPublic.indexOf(id);
    if (index > -1) {
      newActiveIsPublic.splice(index, 1);
    } else {
      newActiveIsPublic.push(id);
    }
    this.isPublicChanged.emit(newActiveIsPublic);
  }
}
