import { Component, Input } from '@angular/core';
import { PublikationDetail } from '@app/shared/classes/adura/publikation';

@Component({
  selector: 'app-adura-publikation-entry',
  templateUrl: './adura-publikation-entry.component.html'
})
export class AduraPublikationEntryComponent {

  @Input()
  pubDetail: PublikationDetail;

  @Input()
  pubDetailType: number;

  get link() {
    if (this.pubDetail.link.includes('http')) {
      return this.pubDetail.link;
    }
    return '//' + this.pubDetail.link;
  }
}
