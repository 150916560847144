import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Facet } from '@app/shared/classes/facet';

@Component({
  selector: 'app-content-type-side',
  templateUrl: './content-type-side.component.html'
})
export class ContentTypeSideComponent {
  @Input() contentTypes: Facet[] = [];
  @Input() activeContentTypes: string[] = [];
  @Output() contentTypesChanged = new EventEmitter<string[]>();

  activeContentTypeCollapseTablet = false;

  isContentTypeActive(name: string) {
    return this.activeContentTypes.find(res => res === name);
  }

  changeContentType(name: string) {
    const newActiveContentTypes = this.activeContentTypes.slice();

    const index = newActiveContentTypes.indexOf(name);
    if (index > -1) {
      newActiveContentTypes.splice(index, 1);
    } else {
      newActiveContentTypes.push(name);
    }

    this.contentTypesChanged.emit(newActiveContentTypes);
  }

  changeContentTypeCollapseForTablet() {
    this.activeContentTypeCollapseTablet = !this.activeContentTypeCollapseTablet;
  }
}
