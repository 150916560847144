<ng-container *ngFor="let fav of this.favorites">
  <div data-role="tile" class="favorite-tile" data-role-tile="true">
    <span class="type-icon">
      <i class="fa {{ fav.iconClass }}" title="{{ 'favorite.type' | translate }} {{ fav.messageProperty + '' | translate }}"></i>
    </span>
    <span class="icons">
      <button type="button" class="favorite-icon" title="{{ 'link.copy' | translate }}"
              (click)="copyLinkClipboard(fav)" [attr.aria-label]="'link.copy' | translate">
        <i class="fa fa-link link"></i>
      </button>
      <button type="button" class="favorite-icon" title="{{ 'global.edit' | translate }}"
              (click)="openEditFavorite(fav)" [attr.aria-label]="'global.edit' | translate">
        <i class="fa fa-pencil"></i>
      </button>
      <button type="button" class="favorite-icon" title="{{ 'global.delete' | translate }}"
              (click)="openDeleteFavorite(fav)" [attr.aria-label]="'global.delete' | translate">
        <i class="fa fa-times"></i>
      </button>
    </span>
    <div class="title" (click)="goToUrl(fav.url)">
      <span class="title">
        <a>{{ fav.title }}</a>
      </span>
    </div>
    <span class="notification">
      <p class="small" [attr.aria-label]="'favorite.notification.frequency.' + fav.notification | translate">
        <i class="fa fa-envelope-o"></i> {{ 'favorite.notification.frequency.' + fav.notification | translate }}
      </p>
    </span>
  </div>
</ng-container>
