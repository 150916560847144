export interface DocumentPreviewJSON {
  id: number;
  guid: string;
  name: string;
  previewData: string[];
}

export class DocumentPreview {
  id: number;
  guid: string;
  name: string;
  previewData: string[];

  static fromJSON(json: string | object): DocumentPreview {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const documentPreview = Object.create(DocumentPreview.prototype);
    return Object.assign(documentPreview, json);
  }

  toJSON(): DocumentPreviewJSON {
    return Object.assign({}, this);
  }
}
