<div #commentCreateModal class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button *ngIf="!activateSpinner" type="button" class="close" (click)="close()" aria-label="Close"><span
          aria-hidden="true">&times;</span>
        </button>
        <h2 class="modal-title">{{ 'comment.new' | translate }}</h2>
      </div>
      <form (ngSubmit)="createComment()" #commentCreateForm="ngForm">
        <div class="modal-body">
          <div class="form-group">
            <div role="alert" *ngIf="error">
              <p class="alert alert-danger"> {{ errorMessage | translate }}</p>
            </div>
            <label for="commentTextarea">{{ 'comment.label' | translate }}:</label>
            <textarea
              class="form-control comment-create-text"
              name="commentTextarea"
              id="commentTextarea"
              autocomplete="off"
              ngModel
              rows="3"
              maxlength="1020"
              required></textarea>
          </div>
          <div class="disclaimer">
            <b>{{ 'comment.policy' | translate }}: </b>
            <br/>
            {{ 'comment.disclaimer' | translate }}
          </div>
        </div>
        <div class="modal-footer">
          <ng-container *ngIf="activateSpinner; then showspinner else hidespinner"></ng-container>

          <ng-template #hidespinner>
            <button type="button" class="btn btn-secondary" (click)="close()">{{ 'global.cancel' | translate }}
            </button>
            <button type="submit" class="btn btn-primary"
                    [disabled]="!commentCreateForm.valid">{{ 'global.create' | translate }}
            </button>
          </ng-template>

          <ng-template #showspinner>
            <button type="submit" class="btn btn-primary" [disabled]="activateSpinner">
              <i class="fa fa-spinner fa-pulse fa-fw"></i>
            </button>
          </ng-template>

        </div>
      </form>
    </div>
  </div>
</div>
