import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AduraDocument, DocumentState } from '@app/shared/classes/adura/adura-document';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { AduraService } from '@app/shared/services/adura.service';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { conditionalValidator } from '@app/shared/validators/conditional.validator';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-adura-document-entry',
  templateUrl: './adura-document-entry.component.html'
})
export class AduraDocumentEntryComponent implements OnInit {
  @Output() updateAddDocument = new EventEmitter<AduraDocument>();
  @Output() changedDocument = new EventEmitter<boolean>();
  @Input() document: AduraDocument;

  documentForm: UntypedFormGroup;
  autorisierung: number[] = [];

  sizeMax = false;
  edit = false;

  constructor(private aduraService: AduraService,
              private authenticationService: AuthenticationService,
              private formBuilder: UntypedFormBuilder) {
    this.autorisierung.push(10); // Public
    this.autorisierung.push(20); // Intern
  }

  ngOnInit() {
    if (!this.document.id) {
      this.edit = true;
    }
    this.initForm();
    this.disableInputsByRole();

    if (this.document.state === DocumentState.Temporary) {
      this.edit = true;
      this.updateForm();
    }
  }

  private initForm() {
    this.documentForm = this.formBuilder.group({
      name: ['', Validators.required],
      autorisierung: [20, Validators.required],
      file: ['', conditionalValidator(() => this.document.state === DocumentState.Prototype || this.document.state === DocumentState.New, Validators.required)]
    });
  }

  private updateForm() {
    this.documentForm.patchValue({
      name: this.document.name,
      autorisierung: this.document.autorisierung,
      file: this.document.file
    });
  }

  addDocument() {
    console.log('addDocument');
    const formValues = this.documentForm.value;
    this.document.name = formValues.name.length > 255 ? formValues.name.slice(0, 254) : formValues.name;
    if (this.authenticationService.hasRole('AWISA_FRESIL')) {
      this.document.autorisierung = formValues.autorisierung;
    } else if (!this.document.autorisierung) {
      this.document.autorisierung = 20;
    }
    this.document.file = formValues.file;
    if (this.document.id) {
      this.document.state = DocumentState.Edited;
    } else {
      this.document.state = DocumentState.New;
    }
    this.updateAddDocument.emit(this.document);
    this.edit = false;
  }

  get fileName() {
    let filename = this.document.name;
    if (this.document.extension) {
      filename += '.' + this.document.extension;
    }
    return filename;
  }

  get toDelete() {
    return this.document.state === DocumentState.Deleted;
  }

  editDocument() {
    this.edit = true;
    this.updateForm();
    this.changedDocument.emit(true);
  }

  markDocumentToDelete() {
    if (this.document.state === DocumentState.Deleted) {
      this.document.state = DocumentState.Edited;
    } else {
      this.document.state = DocumentState.Deleted;
    }

    this.changedDocument.emit(true);
  }

  getDocument(id: number) {
    this.aduraService.getSteckbriefDocument(id)
      .subscribe({
        next: (response: HttpResponse<Blob>) => {
          const blob = new Blob([response.body], { type: response.headers.get('Content-Type') });
          const contentDisposition: string = response.headers.get('content-disposition');
          const r = /filename="(.+)"/;
          const filename = r.exec(contentDisposition)[1];
          console.log(filename);
          saveAs(blob, filename);
        },
        error: e => console.log(e)
      });
  }

  onReset() {
    if (this.document.notPersisted) {
      this.initForm();
    } else {
      this.edit = false;
      this.document.state = this.document.state === DocumentState.Temporary ? DocumentState.Deleted : DocumentState.Persisted;
    }
  }

  disableInputsByRole() {
    if (!this.authenticationService.hasRole('AWISA_FRESIL')) {
      this.documentForm.get('autorisierung').disable();
    }
  }
}
