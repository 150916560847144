import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GefahrAdmin } from '@app/shared/classes/adura/gefahr';
import { AduraService } from '@app/shared/services/adura.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-adura-gefahr-create',
  templateUrl: './adura-gefahr-create.component.html'
})
export class AduraGefahrCreateComponent implements AfterViewInit {
  @ViewChild('gefahrCreateModal', { static: false }) gefahrCreateModal: ElementRef;
  @Output() updateCreateGefahr = new EventEmitter();
  private elm: HTMLElement;
  gefahrCreateForm: UntypedFormGroup;

  isSending = false;
  error = false;
  errorMessage: string;
  tinyConfig: any;

  remainingCharactersMap = {
    bezeichnungEn: '',
    bezeichnungDe: '',
    bezeichnungFr: '',
    bezeichnungIt: ''
  };

  maxCharsMap = {
    bezeichnungEn: 255,
    bezeichnungDe: 255,
    bezeichnungFr: 255,
    bezeichnungIt: 255,
  };

  constructor(private aduraService: AduraService,
              private translateService: TranslateService,
              private router: Router,
              private formBuilder: UntypedFormBuilder) {
    this.initForm();
    this.setupTinyMce();
  }

  ngAfterViewInit(): void {
    this.elm = this.gefahrCreateModal.nativeElement as HTMLElement;
  }

  private setupTinyMce(): void {
    this.tinyConfig = {
      menubar: false,
      toolbar: 'undo bold italic superscript subscript',
      valid_elements: 'strong,em,sup,sub',
      entity_encoding: 'raw',
      setup: this.preventEnterInTinyMceEditor
    };
  }

  private initForm() {
    this.gefahrCreateForm = this.formBuilder.group({
      bezeichnungDe: ['', Validators.required],
      bezeichnungEn: ['', Validators.required],
      bezeichnungFr: ['', Validators.required],
      bezeichnungIt: ['', Validators.required]
    });
  }

  onSubmit() {
    this.isSending = true;
    const formValues = this.gefahrCreateForm.value;
    const daten = new GefahrAdmin();
    daten.bezeichnungDe = this.stripParagraphTags(formValues.bezeichnungDe);
    daten.bezeichnungEn = this.stripParagraphTags(formValues.bezeichnungEn);
    daten.bezeichnungFr = this.stripParagraphTags(formValues.bezeichnungFr);
    daten.bezeichnungIt = this.stripParagraphTags(formValues.bezeichnungIt);
    daten.deaktiviert = false;
    console.log(daten);

    this.aduraService.createGefahr(daten)
      .subscribe({
        next: () => {
          this.close();
          this.refreshGefahrList();
          this.showSuccessAlert('adura.danger.stored');
          this.isSending = false;
        },
        error: error => {
          this.error = true;
          if (error.error === undefined || error.statusText === 'Unknown Error') {
            this.errorMessage = 'global.error';
          } else {
            this.errorMessage = error.error;
          }
          this.isSending = false;
        }
      });
  }

  stripParagraphTags(bezeichnung: string): string {
    bezeichnung = bezeichnung.replace('<p>', '');
    bezeichnung = bezeichnung.replace('</p>', '');
    return bezeichnung;
  }

  close() {
    this.gefahrCreateForm.reset({}, { emitEvent: false });
    this.elm.classList.remove('show');
    setTimeout(() => {
      this.elm.style.width = '0';
    }, 75);
    this.error = false;
  }

  open(): void {
    this.elm.classList.add('show');
    this.elm.style.width = '100vw';
  }

  refreshGefahrList() {
    this.updateCreateGefahr.emit();
  }

  showSuccessAlert(translateString: string) {
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          });
        });
  }

  preventEnterInTinyMceEditor(editor) {

    function restrictTextLength() {
      const value = editor.getContent({ format: 'html' });
      if (value.length > 255) {
        editor.setContent(value.substring(0, 255));
      }
    }

    editor
      .on('keydown', e => (e.keyCode !== 13))
      .on('keyup', e => restrictTextLength())
      .on('Change', e => restrictTextLength());
  }

  updateRemainingCharacters(fieldName: string, event: any) {
    const editor = event.editor;
    const maxChars = this.maxCharsMap[fieldName];
    let editorText = this.gefahrCreateForm.get(fieldName).value;

    if (maxChars < editorText?.length) {
      editorText = editorText.slice(0, maxChars);
      this.gefahrCreateForm.get(fieldName).setValue(editorText);
      // https://stackoverflow.com/a/19836226
      editor.selection.select(editor.getBody(), true);
      editor.selection.collapse(false);
    }

    this.remainingCharactersMap[fieldName] = maxChars - (editorText?.length || 0);
  }
}
