<div #beurteilungEditModal class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="close()" aria-label="Close"><span
          aria-hidden="true">&times;</span>
        </button>
        <h3 class="modal-title">
          {{ this.beurteilungToEdit?.id ? ('adura.beurteilung.edit' | translate) : ('adura.beurteilung.add' | translate) }}
        </h3>
      </div>
      <form [formGroup]="beurteilungEditForm">
        <div class="modal-body">
          <div class="form-group">
            <label>{{ 'global.type' | translate }}:</label>
            <ng-select formControlName="type"
                       placeholder="{{ 'adura.aktivitaet.status.choose' | translate }}"
                       [items]="beurteilungType"
                       [clearable]="false"
                       bindLabel="value"
                       dropdownPosition="bottom">
              <ng-template ng-label-tmp let-item="item">
                <div>{{ 'adura.beurteilung.typ.' + item | translate }}</div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                <div>{{ 'adura.beurteilung.typ.' + item | translate }}</div>
              </ng-template>
            </ng-select>
          </div>
          <div class="form-group">
            <label>{{ 'global.date' | translate }}:</label>
            <dp-date-picker aria-hidden="true" placeholder="{{datePickerConfig.format}}" theme="dp-material"
                            class="form-control event-datepicker"
                            [ngClass]="(dateForm.invalid && dateForm.touched) ? 'errors-input':''"
                            formControlName="date"
                            [config]="datePickerConfig"
                            (onSelect)="getDate()">
            </dp-date-picker>
            <input (keyup.enter)="getDateFromDatePicker($event)"
                   id="date"
                   placeholder="{{datePickerConfig.format}}" type="text"
                   tabindex="-1" class="sr-only" [attr.aria-label]="'date.event' | translate">
            <p role="alert" class="errors-text" *ngIf="(dateForm.invalid && dateForm.touched)">
              {{ 'global.validation.date' | translate }}
            </p>
          </div>
          <div class="form-group">
            <label>{{ 'global.description.label' | translate }}:</label>
            <span class="length-counter-modal">
              {{ remainingCharactersMap['text'] + ' / ' + maxCharsMap['text'] }}
            </span>
            <div class="tiny-textarea">
              <editor formControlName="text"
                      [inline]="true"
                      [init]="tinyConfigEnterAllowed"
                      (onInit)="updateRemainingCharacters('text', $event)"
                      (onKeyUp)="updateRemainingCharacters('text', $event)"
                      (onChange)="updateRemainingCharacters('text', $event)">
              </editor>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" (click)="close()">
            {{ 'global.cancel' | translate }}
          </button>
          <button type="button" class="btn btn-sm btn-space btn-primary" (click)="onSave()"
                  [disabled]="beurteilungEditForm.invalid">
            {{ this.beurteilungToEdit?.id ? ('global.save' | translate) : ('global.add' | translate) }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
