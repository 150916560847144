<section [attr.aria-label]="'search.filter' | translate" class="facet-filter">
  <a role="button" class="facet-domain_de-collapse-button facet-toggle collapsed"
     (click)="changeDomainCollapseForTablet()"
     [attr.aria-expanded]="activeDomainCollapseTablet" aria-controls="facet-domain_de-collapse">
    <h2>{{ 'domain.plural' | translate }}<i class="fa fa-angle-down"></i></h2>
  </a>
  <h2 class="facet-header" attr.aria-label="{{ 'domain.plural' | translate }}">{{ 'domain.plural' | translate }}</h2>
  <div id="facet-domain-collapse" class="collapse facet-collapse"
       [ngClass]="(activeDomainCollapseTablet)?'in':''">
    <ng-container *ngIf="!this.domains || this.domains.length === 0">
      <p><small>{{ 'search.noFilter' | translate }}</small></p>
    </ng-container>
    <ng-container *ngFor="let dom of this.domains">
      <div class="flex-container">
        <input type="checkbox" class="filter-checkbox sr-only" id="{{dom.guid}}"
               (click)="changeDomain(dom.guid)"
               [checked]="isDomainActive(dom.guid)">
        <label class="filter-checkbox-label" [ngClass]="(dom.count === 0)?'filter-element-background':''"
               for="{{dom.guid}}"
               role="checkbox"
               [attr.aria-label]="('domain.label' | translate) + ': ' + dom.name"
               [attr.aria-checked]="(isDomainActive(dom.guid))?'true':'false'">
          {{ dom.name }}
        </label>
      </div>
    </ng-container>
  </div>
</section>
