import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { AduraService } from '@app/shared/services/adura.service';
import { Steckbrief } from '@app/shared/classes/adura/steckbrief';
import { saveAs } from 'file-saver';
import { HttpResponse } from '@angular/common/http';
import { Meldung } from '@app/shared/classes/adura/meldung';
import { DocumentPreviewModalComponent } from '@app/document/document-preview-modal/document-preview-modal.component';

@Component({
  selector: 'app-adura-steckbrief',
  templateUrl: './adura-steckbrief-component.html'
})
export class AduraSteckbriefComponent implements OnInit {
  steckbrief: Steckbrief;
  error = false;

  @ViewChild(DocumentPreviewModalComponent) documentPreviewModalComponent: DocumentPreviewModalComponent;
  loadingPreview = false;

  documents = [];

  @Input() papierkorbView = false;

  constructor(private aduraService: AduraService,
              private authenticationService: AuthenticationService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    if (!this.papierkorbView) {
      const code = this.route.snapshot.paramMap.get('code');
      this.aduraService.getSteckbrief(code)
        .subscribe({
          next: result => {
            this.steckbrief = result;
            this.steckbrief.bereiche?.sort((a, b) =>
              a.bezeichnung.toUpperCase() > b.bezeichnung.toUpperCase() ? 1 : -1
            );
          },
          error: error => {
            this.error = true;
            console.error(error);
          }
        });
    }
  }

  getDocument(id: number) {
    this.aduraService.getSteckbriefDocument(id)
      .subscribe({
        next: (response: HttpResponse<Blob>) => {
          const blob = new Blob([response.body], {type: response.headers.get('Content-Type')});
          const contentDisposition: string = response.headers.get('content-disposition');
          const r = /(?:filename=")(.+)(?:")/;
          const filename = r.exec(contentDisposition)[1];
          console.log(filename);
          saveAs(blob, filename);
        },
        error: e => console.log(e)
      });
  }

  getDocumentPreview(id: number, name: string) {
    this.loadingPreview = true;

    this.aduraService.getDocumentPreview(id)
      .subscribe({
        next: (response) => {
          this.documentPreviewModalComponent.open(
            response, name
          );
          this.loadingPreview = false;
        },
        error: e => {
          console.log(e);
          this.loadingPreview = false;
        }
      });
  }

  isEditable(): boolean {
    return (this.authenticationService.hasRole('AWISA_FRESIL')
            || (this.steckbrief?.status?.id === 10 || this.steckbrief?.status?.id === 20));
  }

  get id() {
    return (this.steckbrief && this.steckbrief.id) ? this.steckbrief.id : null;
  }

  get clipboardType() {
    return this.steckbrief?.signal ? 'adura.signal.label' : 'adura.steckbrief.label';
  }

  get titel() {
    return (this.steckbrief.signal ? '<i class="fa fa-flag"></i>' : '<i class="fa fa-file-text-o"></i>') + '&nbsp;&nbsp;' + this.steckbrief.titel;
  }

  get beurteilungen() {
    return (this.steckbrief && this.steckbrief.beurteilungen) ? this.steckbrief.beurteilungen : null;
  }

  get aktivitaeten() {
    return (this.steckbrief && this.steckbrief.aktivitaeten) ? this.steckbrief.aktivitaeten : null;
  }

  get sortedDocuments() {
    if (this.steckbrief && this.steckbrief.documents) {
      // Sortierung nach Name
      this.steckbrief.documents.sort((a, b) => a.name.localeCompare(b.name));
      // Sortierung nach Autorisierung (Intern/Public)
      this.steckbrief.documents.sort((a, b) => {
        return b.autorisierung - a.autorisierung;
      });
      return this.steckbrief.documents;
    }
    return [];
  }

  editSteckbrief() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/adura/factsheet/' + this.steckbrief.code + '/edit'])
    );

    window.open(url, '_blank');
  }

  meldungHasBasisQuelle(meldung: Meldung) {
    if (meldung && meldung.publikationen) {
      return meldung.publikationen.find(it => it.type === 10) !== undefined;
    }
    return false;
  }

  getMeldungBasisquelleTitel(meldung: Meldung): string {
    return meldung.publikationen.find(it => it.type === 10)?.publikation?.titel;
  }

  getMeldungBasisquelleLink(meldung: Meldung): string {
    const link = meldung.publikationen.find(it => it.type === 10)?.link;

    if (link.includes('http')) {
      return link;
    }
    return '//' + link;
  }

  initPapierkorbView(steckbrief: Steckbrief) {
    this.steckbrief = steckbrief;
  }
}
