import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AduraService } from '@app/shared/services/adura.service';
import { GefahrAdmin } from '@app/shared/classes/adura/gefahr';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-adura-admin-stammdaten-gefahr-entry]',
  templateUrl: './adura-admin-stammdaten-gefahr-entry.component.html'
})
export class AduraAdminStammdatenGefahrEntryComponent {
  @Output() deleted = new EventEmitter();
  @Output() reloadEntries: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  set set(gefahr: GefahrAdmin) {
    this.gefahr = gefahr;
  }

  gefahrForm: UntypedFormGroup;
  delChosen = false;
  gefahr: GefahrAdmin;
  editEntry = false;
  tinyConfig: any;

  maxCharsMap = {
    bezeichnungDe: 255,
    bezeichnungEn: 255,
    bezeichnungFr: 255,
    bezeichnungIt: 255,
  };

  remainingCharactersMap = {
    bezeichnungDe: '',
    bezeichnungEn: '',
    bezeichnungFr: '',
    bezeichnungIt: ''
  };

  constructor(private aduraService: AduraService,
              private formBuilder: UntypedFormBuilder) {
  }

  private setupTinyMce(): void {
    this.tinyConfig = {
      menubar: false,
      toolbar: 'undo bold italic superscript subscript',
      valid_elements: 'strong,em,sup,sub',
      entity_encoding: 'raw',
      setup: this.preventEnterInTinyMceEditor
    };
  }

  private initForm(gefahr: GefahrAdmin) {
    this.gefahrForm = this.formBuilder.group({
      id: [''],
      bezeichnungDe: ['', Validators.required],
      bezeichnungEn: ['', Validators.required],
      bezeichnungFr: ['', Validators.required],
      bezeichnungIt: ['', Validators.required],
      deaktiviert: ['']
    });
    this.updateForm(gefahr);
    this.setupTinyMce();
  }

  private updateForm(gefahr: GefahrAdmin) {
    this.gefahrForm.reset({
      id: gefahr.id,
      bezeichnungDe: gefahr.bezeichnungDe,
      bezeichnungEn: gefahr.bezeichnungEn,
      bezeichnungFr: gefahr.bezeichnungFr,
      bezeichnungIt: gefahr.bezeichnungIt,
      deaktiviert: gefahr.deaktiviert
    }, { emitEvent: false });
  }

  onSubmit() {
    const formValues = this.gefahrForm.value;
    this.gefahr.id = formValues.id;
    this.gefahr.bezeichnungDe = formValues.bezeichnungDe;
    this.gefahr.bezeichnungEn = formValues.bezeichnungEn;
    this.gefahr.bezeichnungFr = formValues.bezeichnungFr;
    this.gefahr.bezeichnungIt = formValues.bezeichnungIt;
    this.gefahr.deaktiviert = formValues.deaktiviert;
    this.aduraService.editGefahr(this.gefahr)
      .subscribe({
        next: result => {
          this.gefahr = result;
          this.reloadEntries.emit();
        },
        error: console.error
      });
  }

  edit() {
    console.log('start editing');
    console.log(this.gefahr);
    this.editEntry = true;
    this.initForm(this.gefahr);
  }

  onReset() {
    this.editEntry = false;
  }

  deleteGefahr(gefahrId: number) {
    this.delChosen = true;
    Swal.fire({
      text: 'Wollen Sie diese Gefahr wirklich löschen?',
      icon: 'warning',
      customClass: { htmlContainer: 'content-text-center' },
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: 'Ja, Gefahr löschen',
      cancelButtonText: 'Abbrechen'
    }).then((result) => {
      if (result.value) {
        this.continueDeleteGefahr(gefahrId);
      } else {
        this.delChosen = false;
      }
    });
  }

  continueDeleteGefahr(gefahrId: number) {
    this.aduraService.deleteGefahr(gefahrId)
      .subscribe({
        next: () => {
          this.showAlert(true);
          this.deleted.emit();
        },
        error: error => {
          this.showAlert(false);
          console.error(error.message);
        }
      });
  }

  showAlert(ok: boolean) {
    let message = 'Gefahr wurde gelöscht!';
    let apiResult: SweetAlertIcon = 'success';
    if (!ok) {
      message = 'Die Gefahr konnte nicht gelöscht werden!';
      apiResult = 'error';
    }
    Swal.fire({
      text: message,
      icon: apiResult,
      customClass: { htmlContainer: 'content-text-center' },
      buttonsStyling: true,
      confirmButtonText: 'OK'
    });
  }

  preventEnterInTinyMceEditor(editor) {
    function restrictTextLength() {
      const value = editor.getContent({ format: 'html' });
      if (value.length > 255) {
        editor.setContent(value.substring(0, 255));
      }
    }

    editor
      .on('keydown', e => (e.keyCode !== 13))
      .on('keyup', e => restrictTextLength())
      .on('Change', e => restrictTextLength());
  }

  updateRemainingCharacters(fieldName: string, event: any) {
    const editor = event.editor;
    const maxChars = this.maxCharsMap[fieldName];
    let editorText = this.gefahrForm.get(fieldName).value;

    if (maxChars < editorText?.length) {
      editorText = editorText.slice(0, maxChars);
      this.gefahrForm.get(fieldName).setValue(editorText);
      // https://stackoverflow.com/a/19836226
      editor.selection.select(editor.getBody(), true);
      editor.selection.collapse(false);
    }

    this.remainingCharactersMap[fieldName] = maxChars - (editorText?.length || 0);
  }
}
