import { ResultItem } from './result-item';

export interface Top10WrapperJSON {
  date: string;
  content: ResultItem[];
}

export class Top10Wrapper {
  date: string;
  content: ResultItem[];

  static fromJSON(json: string | object): Top10Wrapper {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const top10 = Object.create(Top10Wrapper.prototype);
    return Object.assign(top10, json);
  }

  toJSON(): Top10WrapperJSON {
    return Object.assign({}, this);
  }
}
