import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationService } from '@app/core/config/configuration.service';
import { Settings } from '@app/core/config/settings';
import { DocumentPreview } from '@app/shared/classes/document-preview';
import { Facet } from '@app/shared/classes/facet';
import { Role } from '@app/shared/classes/role';
import { Tag } from '@app/shared/classes/tag';
import { Top10Wrapper } from '@app/shared/classes/top10-wrapper';
import { saveAs } from 'file-saver';
import { from, Observable } from 'rxjs';
import { filter, map, toArray } from 'rxjs/operators';
import { Comment } from '../classes/comment';
import { Document, DocumentSave } from '../classes/document';
import { Domain } from '../classes/domain';
import { Event, EventSave } from '../classes/event';
import { Favorite, FavoriteCategoryEdit, FavoriteEdit, FavoriteSave } from '../classes/favorite';
import { Link, LinkSave } from '../classes/link';
import { SearchResult } from '../classes/search-result';
import { Thema } from '../classes/thema';

@Injectable({providedIn: 'root'})
export class AwisaFoService {

  private apiBase: string;

  constructor(private http: HttpClient,
              private router: Router,
              configurationService: ConfigurationService) {
    this.apiBase = configurationService.getConfig().awisaFOUrl;
  }

  list(): Observable<Document[]> {
    return this.http.get(`${this.apiBase}/document/`)
      .pipe(
        map((data: Document[]) => data.map(Document.fromJSON))
      );
  }

  search(params: HttpParams): Observable<SearchResult> {
    console.log(params);
    return this.http.get(`${ this.apiBase }/search`, { params })
      .pipe(
        map((data) => SearchResult.fromJSON(data))
      );
  }

  getDocumentDetails(guid: string): Observable<Document> {
    return this.http.get(`${this.apiBase}/document/${guid}`)
      .pipe(
        map((data) => Document.fromJSON(data))
      );
  }

  getDocument(guid: string): Observable<any> {
    return this.http.get(`${this.apiBase}/document/${guid}/raw`,
      {observe: 'response', responseType: 'blob', headers: new HttpHeaders({ 'X-UserReferrer': this.router.url })})
      .pipe();
  }

  getDocumentFromSearch(guid: string): Observable<any> {
    return this.http.get(`${this.apiBase}/document/search/download/${guid}`,
      {observe: 'response', responseType: 'blob', headers: new HttpHeaders({ 'X-UserReferrer': this.router.url })})
      .pipe();
  }

  getDocumentPreview(guid: string): Observable<DocumentPreview> {
    return this.http.get(`${this.apiBase}/document/preview/${guid}`,
      {observe: 'response', responseType: 'json', headers: new HttpHeaders({ 'X-UserReferrer': this.router.url })})
      .pipe(
        map((data) => DocumentPreview.fromJSON(data))
    );
  }

  getDocumentVersionPreview(guid: string): Observable<DocumentPreview> {
    return this.http.get(`${this.apiBase}/document/preview/version/${guid}`,
      {observe: 'response', responseType: 'json', headers: new HttpHeaders({ 'X-UserReferrer': this.router.url })})
      .pipe(
        map((data) => DocumentPreview.fromJSON(data))
      );
  }

  getLog(days: string): Observable<any> {
    return this.http.get(`${this.apiBase}/log/${days}`, {observe: 'response', responseType: 'blob'})
      .pipe();
  }

  getLogBetween(von: string, bis: string): Observable<any> {
    return this.http.get(`${this.apiBase}/log/${von}/${bis}`, {observe: 'response', responseType: 'blob'})
      .pipe();
  }

  getContentLog(days: string): Observable<any> {
    return this.http.get(`${this.apiBase}/log/content/${days}`, {observe: 'response', responseType: 'blob'})
      .pipe();
  }

  getContentLogBetween(von: string, bis: string): Observable<any> {
    return this.http.get(`${this.apiBase}/log/content/${von}/${bis}`, {observe: 'response', responseType: 'blob'})
      .pipe();
  }

  getBezugReport(guid: string): Observable<any> {
    return this.http.get(`${this.apiBase}/report/bezug/${guid}`, {observe: 'response', responseType: 'blob'})
      .pipe();
  }

  getUnclassifiedDocuments(): Observable<any> {
    return this.http.get(`${this.apiBase}/document/getUnclassifiedDocumentsAsCsv`, {observe: 'response', responseType: 'blob'})
      .pipe();
  }

  getDocumentsForEvaluation(): Observable<any> {
    return this.http.get(`${this.apiBase}/document/getDocumentsForEvaluationAsCsv`, {observe: 'response', responseType: 'blob'})
      .pipe();
  }

  subscribeToDownload(observableDownload: Observable<any>) {
    return observableDownload.subscribe({
      next: (response: HttpResponse<Blob>) => {
        const blob = new Blob([response.body], {type: response.headers.get('Content-Type')});
        const contentDisposition: string = response.headers.get('content-disposition');
        const r = /filename="(.+)"/;
        const filename = r.exec(contentDisposition)[1];
        console.log(filename);
        saveAs(blob, filename);
      },
      error: e => console.log(e)
    });
  }

  getDomains(): Observable<Domain[]> {
    return this.http.get(`${this.apiBase}/domain`)
      .pipe(
        map((data: Domain[]) => data.map(Domain.fromJSON))
      );
  }

  getFavorites(guid: string): Observable<Favorite[]> {
    return this.http.get(`${this.apiBase}/favorite/list/${guid}`)
      .pipe(
        map((data: Favorite[]) => data.map(Favorite.fromJSON))
      );
  }

  getFavorite(id: string): Observable<Favorite> {
    return this.http.get(`${this.apiBase}/favorite/${id}`)
      .pipe(
        map((data) => Favorite.fromJSON(data))
      );
  }

  getThema(guid: string): Observable<Thema> {
    return this.http.get(`${this.apiBase}/thema/${guid}`)
      .pipe(
        map((data) => Thema.fromJSON(data))
      );
  }

  getThemaPicture(id: string): Observable<any> {
    return this.http.get(`${this.apiBase}/thema/picture/${id}`, {responseType: 'text'})
      .pipe();
  }

  getTestThema(guid: string): Observable<any> {
    return this.http.get(`${this.apiBase}/thema/${guid}/test`)
      .pipe();
  }

  getTermin(guid: string): Observable<Event> {
    return this.http.get(`${this.apiBase}/termin/${guid}`)
      .pipe(
        map((data) => Event.fromJSON(data))
      );
  }

  getLink(guid: string): Observable<Link> {
    return this.http.get(`${this.apiBase}/link/${guid}`)
      .pipe(
        map((data) => Link.fromJSON(data))
      );
  }

  getTags(): Observable<any> {
    return this.http.get(`${this.apiBase}/tag/`)
      .pipe(
        map((data: Tag[]) => data.map(Tag.fromJSON))
      );
  }

  listAllowedMimeTypes(): Observable<string> {
    return this.http.get(`${ this.apiBase }/document/mime`, {responseType: 'text'})
      .pipe(
        map((data: any) => data.toString())
      );
  }

  get temporaryDocumentUrl(): string {
    return `${ this.apiBase }/document/upload/`;
  }

  getRoles(): Observable<any> {
    return this.http.get(`${this.apiBase}/role/`)
      .pipe(
        map((data: Role[]) => data.map(Role.fromJSON))
      );
  }

  getTop10(): Observable<any> {
    return this.http.get(`${this.apiBase}/search/top10`)
      .pipe(
        map((data: Top10Wrapper[]) => data.map(Top10Wrapper.fromJSON))
      );
  }

  createLuceneIndex(): Observable<any> {
    return this.http.get(`${this.apiBase}/search/createIndex`)
      .pipe();
  }

  getVersion(): Observable<any> {
    return this.http.get(`${this.apiBase}/admin/version`, {responseType: 'text'})
      .pipe();
  }

  docsInDb(): Observable<any> {
    return this.http.get(`${this.apiBase}/document/docsInDb`, {responseType: 'text'})
      .pipe();
  }

  createComment(comment: Comment): Observable<Comment[]> {
    return this.http.put(`${this.apiBase}/comment/create`, comment.toJSON())
      .pipe(
        map((data: Comment[]) => data.map(Comment.fromJSON))
      );
  }

  createFavorite(favorite: FavoriteSave): Observable<Favorite> {
    return this.http.post(`${this.apiBase}/favorite/create`, favorite)
      .pipe(
        map((data: Favorite) => Favorite.fromJSON(data))
      );
  }

  createLink(linkSave: LinkSave): Observable<Link> {
    return this.http.put(`${this.apiBase}/link/create`, linkSave)
      .pipe(
        map((data: Link) => Link.fromJSON(data))
      );
  }

  createTermin(eventSave: EventSave): Observable<Event> {
    return this.http.put(`${this.apiBase}/termin/create`, eventSave)
      .pipe(
        map((data: Event) => Event.fromJSON(data))
      );
  }

  public createDocument(documentSave: DocumentSave): Observable<Document>{
    const formData: FormData = new FormData();
    formData.append('name', documentSave.name);
    formData.append('language', documentSave.language);
    formData.append('file', new File([documentSave.file], 'ignore', { type: documentSave.file.type }));
    formData.append('descDe', documentSave.descriptionDe);
    formData.append('descEn', documentSave.descriptionEn);
    formData.append('descFr', documentSave.descriptionFr);
    formData.append('descIt', documentSave.descriptionIt);
    formData.append('kommentar', documentSave.changeComment);
    formData.append('tags', documentSave.tags);
    formData.append('author', documentSave.author);
    formData.append('fremdAuthor', documentSave.fremdAuthor);
    formData.append('status', documentSave.status);
    formData.append('roles', documentSave.roles);
    formData.append('docDate', documentSave.documentDate);

    return this.http.post(`${this.apiBase}/document/create`, formData)
      .pipe(
        map((data: Document) => Document.fromJSON(data))
      );
  }

  editFavorite(favorite: FavoriteEdit): Observable<Favorite[]> {
    return this.http.put(`${this.apiBase}/favorite/edit`, favorite)
      .pipe(
        map((data: Favorite[]) => data.map(Favorite.fromJSON))
      );
  }

  deleteFavorite(favorite: FavoriteEdit): Observable<Favorite[]> {
    return this.http.put(`${this.apiBase}/favorite/delete`, favorite)
      .pipe(
        map((data: Favorite[]) => data.map(Favorite.fromJSON))
      );
  }

  getFavoritCategoriesByUser(guid): Observable<string []> {
    return this.http.get(`${this.apiBase}/favorite/list/categories/${guid}`)
      .pipe(
        map((data: string []) => data.map(String))
      );
  }

  editFavoriteCategory(favoriteCategoryEdit: FavoriteCategoryEdit): Observable<Favorite[]> {
    return this.http.put(`${this.apiBase}/favorite/category/edit`, favoriteCategoryEdit)
      .pipe(
        map((data: Favorite[]) => data.map(Favorite.fromJSON))
      );
  }

  deleteFavoriteCategory(favoriteCategoryEdit: FavoriteCategoryEdit): Observable<Favorite[]> {
    return this.http.put(`${this.apiBase}/favorite/category/delete`, favoriteCategoryEdit)
      .pipe(
        map((data: Favorite[]) => data.map(Favorite.fromJSON))
      );
  }

  listSettings(): Observable<Settings[]> {
    return this.http.get(`${this.apiBase}/settings`)
      .pipe(
        map((data: Settings[]) => data.map(Settings.fromJSON))
      );
  }

  createSetting(setting: Settings): Observable<Settings[]> {
    return this.http.put(`${this.apiBase}/settings/create`, setting.toJSON())
      .pipe(
        map((data: Settings[]) => data.map(Settings.fromJSON))
      );
  }

  editSetting(setting: Settings): Observable<Settings> {
    return this.http.put(`${this.apiBase}/settings/edit`, setting)
      .pipe(
        map((data: Settings) => Settings.fromJSON(data))
      );
  }

  deleteSetting(settingId: number): Observable<Settings[]> {
    return this.http.put(`${this.apiBase}/settings/delete`, settingId)
      .pipe(
        map((data: Settings[]) => data.map(Settings.fromJSON))
      );
  }

  filterFacetsBySearchTerm(searchTerm: string, facets: Facet[]): Observable<Facet[]> {
    return from(facets).pipe(
      filter(facet => facet.name?.toLowerCase()?.indexOf(searchTerm) >= 0),
      toArray()
    );
  }
}
