<header class="page-header">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <button appBackButton type="button" class="btn btn-sm btn-primary back-button"
                title="{{ 'global.back' | translate }}"
                [attr.aria-label]="'global.back' | translate">
          <i class="fa fa-arrow-left"></i>
        </button>
      </div>
    </div>
  </div>
</header>

<section class="container information">

  <div class="row description">
    <div class="col-xs-12">
      <h1>{{ doc.name }}
        <button type="button" class="btn-sm btn btn-primary title-icon"
                title="{{ 'favorite.save' | translate }}" (click)="openCreateFavorite()"
                [attr.aria-label]="'favorite.save' | translate">
          <i class="fa fa-star-o" aria-hidden="true"></i>
        </button>
        <app-link-clipboard title="{{ doc.name }}" type="{{ doc.format }}"></app-link-clipboard>
      </h1>
      <p>{{ doc.description }}</p>
    </div>
  </div>

  <app-favorite-create></app-favorite-create>


</section>
<section id="dataset-resources" class="container resources">
  <div class="row" *ngIf="doc.versions && doc.versions.length > 0">
    <div class="col-xs-12">
      <h2>{{ 'document.versions' | translate }}</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <ul class="resource-list list-unstyled">
        <!-- Archivierte Dokumente -->
        <li class="resource-item" *ngFor="let docVersion of doc.versions" [ngClass]="hideArchived && docVersion.status === 'status.archiviert' ? 'hidden' : ''">
          <div class="col-xs-2 col-md-1" style="padding-left:0;">
            <small>
              <span class="label label-default doc-status" [ngClass]="docVersion.status === 'status.gueltig'?'doc-status-gueltig':''">
                {{ 'document.' + docVersion.status | translate }}
              </span>
            </small>
          </div>
          <div class="col-xs-10 col-md-6">
            <div class="row doc-version-name">
              <a (click)="getDocument(docVersion.guid)" class="clear-margin-left"
                 target="_blank">
                {{ docVersion.name }}
              </a>
              <span (click)="getDocumentVersionPreview(docVersion.guid, docVersion.name)" *ngIf="docVersion.canPreview">
                  <i [ngClass]="docVersion.guid == loadingPreviewGuid ? 'fa fa-spinner fa-pulse document-preview-icon' : 'fa fa-eye document-preview-icon'"
                     title="{{ 'document.preview.show' | translate }}">
                  </i>
              </span>
            </div>
            <div class="row">
              <p class="doc-version-aenderungskommentar clear-margin-bottom">{{ docVersion.changeComment }}</p>
            </div>
          </div>

          <div class="col-md-3 col-xs-12 doc-version-author">
            {{ docVersion.author }}
          </div>

          <div class="col-md-2 col-xs-12 doc-version-date">
            {{ docVersion.creationDate | date: 'dd.MM.yyyy' }}
          </div>
        </li>

        <!-- Übersetzte Dokumente -->
        <li class="resource-item" *ngFor="let translation of doc.translations" [ngClass]="hideTranslations ? 'hidden' : ''">
          <div class="col-xs-2 col-md-1" style="padding-left:0;">
            <small>
              <span *ngIf="doc.translations && doc.translations.length > 0" class="label label-primary-gray">
                {{ 'document.translation.label' | translate }} {{ translation.language }}
              </span>
            </small>
          </div>
          <div class="col-xs-10 col-md-6">
            <div class="row doc-version-name">
              <a routerLink="/document/{{ translation.guid }}"
                 class="clear-margin-left"
                 target="_blank">
                {{ translation.name }}
              </a>
            </div>
            <div class="row">
              <p class="doc-version-aenderungskommentar clear-margin-bottom">{{ translation.changeComment }}</p>
            </div>
          </div>
        </li>
      </ul>
      <button *ngIf="hasArchivedDocuments()"
              class="btn btn-xs label-primary-gray"
              (click)="toggleArchivedDocuments()">
        {{ (hideArchived ? 'document.archived.show' : 'document.archived.hide') | translate }}
      </button>
      <button *ngIf="doc.translations && doc.translations.length > 0"
              class="btn btn-xs label-primary-gray"
              (click)="toggleTranslations()">
        {{ (hideTranslations ? 'document.translation.show' : 'document.translation.hide') | translate }}
      </button>
    </div>
  </div>
</section>

<section class="container additional-info">
  <div class="row">
    <div class="col-xs-12">
      <h2>{{ 'global.additionalInfo' | translate }}</h2>
      <dl class="dl-horizontal">
        <dt>{{ 'date.event' | translate }}</dt>
        <dd>
          <a class="search-button" (click)="searchByDocDate(doc.docDate)">
            {{ doc.docDate | date: 'dd.MM.yyyy' }}
          </a>
        </dd>
        <dt>{{ 'date.create' | translate }}</dt>
        <dd>{{ doc.creationDate | date: 'dd.MM.yyyy' }}</dd>
        <dt>{{ 'date.mod' | translate }}</dt>
        <dd>{{ doc.modificationDate | date: 'dd.MM.yyyy' }}</dd>
        <dt>{{ 'language.label' | translate }}</dt>
        <dd>{{ doc.language }}</dd>
        <dt>{{ 'content.type' | translate }}</dt>
        <dd>{{ 'document.awisa' | translate }}</dd>
        <dt>{{ 'tag.plural' | translate }}</dt>
        <dd>
          <ul class="dataset-resources list-inline tags">
            <li *ngFor="let tag of doc.tags">
              <p class="label label-primary-gray search-button"
                 [ngClass]="isSearchTagActive(tag.guid) ? 'filter-tag active' : ''"
                 (click)="toggleSearchTag(tag.guid)">
                {{ tag.name }}
              </p>
            </li>
            <span class="ml-1 cursor-pointer" (click)="searchByTags()">
                  <i class="fa fa-search" title="{{ 'document.tag.search' | translate }}"></i>
            </span>
          </ul>
        </dd>
        <dt>{{ 'subject.plural' | translate }}</dt>
        <dd class="doc-thema">
          <ul>
            <li *ngFor="let thema of doc.themen">
              <a target="_blank" routerLink="/subject/{{ thema.id }}">
                {{ thema.name }}
              </a>
            </li>
          </ul>
        </dd>
        <dt>{{ 'domain.plural' | translate }}</dt>
        <dd>
          <ng-container *ngFor="let dom of this.doc.domains">
            <a class="search-button button-domain-doc btn-sm btn-default" (click)="searchByDomain(dom.id)">{{ dom.name }}</a>
          </ng-container>
        </dd>
        <dt>{{ 'oberthema.plural' | translate }}</dt>
        <dd>
          <ng-container *ngIf="this.doc && this.doc.oberthemen">
            <ng-container *ngFor="let obt of this.doc.oberthemen, let i = index">
              <a class="search-button" (click)="searchByTopic(obt.id)">{{ obt.name }}</a>
              <ng-container *ngIf="i < doc.oberthemen.length - 1">, </ng-container>
            </ng-container>
          </ng-container>
        </dd>
        <ng-template [appHasRole]="['ROLE_BLV']">
          <dt>{{ 'actanova.link' | translate }}</dt>
          <dd>
            <a class="actanova-link" href="{{ doc.dossierLink}}" target="_blank">{{ 'document.awisa' | translate }}</a>
            <a class="actanova-link" href="{{ doc.originalFileLink}}"
               target="_blank">{{ 'document.original' | translate }}</a>
          </dd>
        </ng-template>
      </dl>
    </div>
  </div>
</section>

<section class="container additional-info">
  <div class="row">
    <div class="col-xs-12">
      <h2>{{ 'comment.label' | translate }}</h2>
      <app-comment [refGuid]="doc.originalGuid" [comments]="comments" [reference]="'document'"></app-comment>
    </div>
  </div>
</section>
<app-document-preview-modal></app-document-preview-modal>
