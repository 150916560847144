import { Component, Input } from '@angular/core';
import { Aktivitaet } from '@app/shared/classes/adura/aktivitaet';

@Component({
  selector: 'app-adura-aktivitaet-entry',
  templateUrl: './adura-aktivitaet-entry.component.html'
})
export class AduraAktivitaetEntryComponent {

  @Input()
  aktivitaet: Aktivitaet;
}
