import { Component, OnInit } from '@angular/core';
import { ResultItem } from '@app/shared/classes/result-item';
import { Top10Wrapper } from '@app/shared/classes/top10-wrapper';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';

@Component({
  selector: 'app-changes',
  templateUrl: './changes.component.html'
})
export class ChangesComponent implements OnInit {
  top10Items: Top10Wrapper[];

  constructor(private awisaFoService: AwisaFoService) { }

  ngOnInit(): void {
    this.awisaFoService.getTop10()
      .subscribe(
        (result) => {
          console.log(result);
          this.top10Items = result;
        });
  }

  getFormat(content: ResultItem) {
    return content.format.join(', ');
  }

  getLinkCommentUrl(content: ResultItem) {
    return content.referenceType + '/' + content.referenceGuid;
  }

}
