<ng-container *ngIf="newLinkEnabled; else disabledNewLink">
  <header class="page-header">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <button appBackButton type="button" class="btn btn-sm btn-primary back-button"
                  title="{{ 'global.back' | translate }}"
                  [attr.aria-label]="'global.back' | translate">
            <i class="fa fa-arrow-left"></i>
          </button>
        </div>
      </div>
    </div>
  </header>

  <section class="container information">
    <h1>{{ 'link.new' | translate }}</h1>
    <form [formGroup]="linkForm" (ngSubmit)="createLink()">
      <p *ngIf="error" class="alert alert-danger" role="alert"> {{ 'global.error' | translate}}</p>
      <div class="form-group">
        <div class="form-element">
          <label for="name">{{ 'global.name' | translate }}*:</label>
          <input
            type="text"
            class="form-control"
            id="name"
            [ngClass]="(nameForm.invalid && nameForm.dirty) ? 'errors-input':''"
            formControlName="name"
            maxlength="400"
            autocomplete="off">
          <p role="alert" class="errors-text" *ngIf="(nameForm.invalid && nameForm.dirty)">
            {{ 'global.validation.name' | translate }}
          </p>
        </div>
        <div class="form-element">
          <label for="url">URL*:</label>
          <input
            type="text"
            class="form-control"
            id="url"
            [ngClass]="(urlForm.invalid && urlForm.dirty) ? 'errors-input':''"
            formControlName="url"
            maxlength="255"
            autocomplete="off">
          <p role="alert" class="errors-text" *ngIf="(urlForm.invalid && urlForm.dirty)">
            {{ 'global.validation.url' | translate }}
          </p>
        </div>
        <div class="form-element">
          <label>{{ 'event.shortDescription' | translate }}:</label>
          <app-tabs class="event-desc-tabs">
            <app-tab *ngFor="let language of languageList" role="tab"
                     tabId="content" tabTitle="{{ language.name }}"
                     contentClass="tabmenu-content">
        <textarea
          class="form-control form-element"
          id="desc_{{ language.name }}"
          formControlName="desc_{{ language.name }}"
          maxlength="1530"
          autocomplete="off"
          rows="3"></textarea>
            </app-tab>
          </app-tabs>
        </div>
        <div class="form-element">
          <label>{{ 'tag.plural' | translate }}*:</label><br/>
          <ng-select appendTo="body"
                     [multiple]="true"
                     dropdownPosition="bottom"
                     [ngClass]="(linkForm.controls.tags.invalid && linkForm.controls.tags.dirty) ? 'errors-input':''"
                     [readonly]="false"
                     formControlName="tags">
            <ng-option *ngFor="let tag of tags" [value]="tag.guid">{{tag.name}}</ng-option>
          </ng-select>
          <p role="alert" class="errors-text" *ngIf="(tagsForm.invalid && tagsForm.dirty)">
            {{ 'global.validation.tags' | translate }}
          </p>
        </div>
        <div class="form-element">
          <label>{{ 'global.permission' | translate }}*:
            <div class="tooltip-awisa">
              <i class="fa fa-info-circle"></i>
              <span class="tooltiptext-awisa"><div [innerHTML]="html"></div></span>
            </div>
          </label><br/>
          <ng-container *ngFor="let role of roles">
            <input type="checkbox" class="filter-checkbox sr-only" id="{{role.name}}"
                   name="{{role.identifier}}"
                   (change)="chooseRole($event)"
                   role="checkbox" [checked]="isRoleActive(role.identifier)">
            <label class="filter-checkbox-label" for="{{role.name}}"
                   role="checkbox"
                   [attr.aria-checked]="(isRoleActive(role.identifier))?'true':'false'">
              {{ role.name }}
            </label>
          </ng-container>
          <p role="alert" class="errors-text" *ngIf="(rolesForm.invalid)">
            {{ 'global.validation.role' | translate }}
          </p>
        </div>
        <div class="form-element">
          <ng-container *ngIf="isSending; then showspinner else hidespinner"></ng-container>

          <ng-template #hidespinner>
            <button type="submit" class="btn btn-primary">
              {{ 'global.create' | translate }}
            </button>
          </ng-template>

          <ng-template #showspinner>
            <button type="submit" class="btn btn-primary" [disabled]="isSending">
              <i class="fa fa-spinner fa-pulse fa-fw"></i>
            </button>
          </ng-template>
        </div>
      </div>
    </form>
  </section>
</ng-container>
<ng-template #disabledNewLink>
  <div class="row">
    <div class="errors">
      <h1>{{ 'settings.disabledNewLink' | translate }}</h1>
    </div>
  </div>
</ng-template>
