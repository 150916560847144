import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AduraService } from '@app/shared/services/adura.service';
import { GefahrAdmin } from '@app/shared/classes/adura/gefahr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-adura-admin-stammdaten-gefahr-new',
  templateUrl: './adura-admin-stammdaten-gefahr-new.component.html'
})
export class AduraAdminStammdatenGefahrNewComponent implements OnInit {
  @Output() deleted = new EventEmitter();
  @Output() reloadEntries: EventEmitter<string> = new EventEmitter<string>();

  newGefahrForm: UntypedFormGroup;
  gefahr: GefahrAdmin = new GefahrAdmin();
  tinyConfig: any;

  maxCharsMap = {
    bezeichnungDe_new: 255,
    bezeichnungEn_new: 255,
    bezeichnungFr_new: 255,
    bezeichnungIt_new: 255,
  };

  remainingCharactersMap = {
    bezeichnungDe_new: '',
    bezeichnungEn_new: '',
    bezeichnungFr_new: '',
    bezeichnungIt_new: ''
  };

  constructor(private aduraService: AduraService,
              private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.initForm();
    this.setupTinyMce();
  }

  private setupTinyMce(): void {
    this.tinyConfig = {
      menubar: false,
      toolbar: 'undo bold italic superscript subscript',
      valid_elements: 'strong,em,sup,sub',
      entity_encoding: 'raw',
      setup: this.preventEnterInTinyMceEditor
    };
  }

  private initForm() {
    this.newGefahrForm = this.formBuilder.group({
      bezeichnungDe_new: ['', Validators.required],
      bezeichnungEn_new: ['', Validators.required],
      bezeichnungFr_new: ['', Validators.required],
      bezeichnungIt_new: ['', Validators.required],
      deaktiviert_new: ['']
    });
  }

  onSubmit() {
    const formValues = this.newGefahrForm.value;
    this.gefahr.bezeichnungDe = formValues.bezeichnungDe_new;
    this.gefahr.bezeichnungEn = formValues.bezeichnungEn_new;
    this.gefahr.bezeichnungFr = formValues.bezeichnungFr_new;
    this.gefahr.bezeichnungIt = formValues.bezeichnungIt_new;
    this.gefahr.deaktiviert = formValues.deaktiviert_new;
    this.aduraService.createGefahr(this.gefahr)
      .subscribe({
        next: () => {
          this.initForm();
          this.reloadEntries.emit();
        },
        error: error => {
          this.showAlert();
          console.error(error);
        }
      });
  }

  onReset() {
    this.ngOnInit();
  }

  showAlert() {
    Swal.fire({
      text: 'Die Gefahr konnte nicht gespeichert werden!',
      icon: 'error',
      customClass: {htmlContainer: 'content-text-center'},
      buttonsStyling: true,
      confirmButtonText: 'OK'
    });
  }

  preventEnterInTinyMceEditor(editor) {
    function restrictTextLength() {
      const value = editor.getContent({ format: 'html' });
      if (value.length > 255) {
        editor.setContent(value.substring(0, 255));
      }
    }

    editor
      .on('keydown', e => (e.keyCode !== 13))
      .on('keyup', e => restrictTextLength())
      .on('Change', e => restrictTextLength());
  }

  updateRemainingCharacters(fieldName: string, event: any) {
    const editor = event.editor;
    const maxChars = this.maxCharsMap[fieldName];
    let editorText = this.newGefahrForm.get(fieldName).value;

    if (maxChars < editorText?.length) {
      editorText = editorText.slice(0, maxChars);
      this.newGefahrForm.get(fieldName).setValue(editorText);
      // https://stackoverflow.com/a/19836226
      editor.selection.select(editor.getBody(), true);
      editor.selection.collapse(false);
    }

    this.remainingCharactersMap[fieldName] = maxChars - (editorText?.length || 0);
  }
}
