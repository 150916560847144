<h3 id="tracelog">{{ 'adura.log.title' | translate }} <i *ngIf="!listOpen" (click)="openLogList()" class="fa fa-caret-right"></i>
  <i *ngIf="listOpen" (click)="openLogList()" class="fa fa-caret-down"></i>
</h3>
<div *ngIf="listOpen" class="form-element">
  <div *ngIf="error" class="errors-text">{{ 'adura.log.error' | translate }}</div>
  <div class="row" *ngFor="let log of logs">
    <div class="col-sm-2">{{ log.timestamp | date: 'dd.MM.yyyy' }}</div>
    <div class="col-sm-3">
      {{ getLabel(log) }}
    </div>
    <div class="col-sm-4">{{ log.comment }}</div>
    <div class="col-sm-3 clear-padding-right">{{ log.person }}</div>
  </div>
</div>
