import { Meldung } from './meldung';
import { Steckbrief } from './steckbrief';

export interface PapierkorbJSON {
  meldungen: Meldung[];
  steckbriefe: Steckbrief[];
}

export class Papierkorb {
  meldungen: Meldung[];
  steckbriefe: Steckbrief[];

  static fromJSON(json: string | object): Papierkorb {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const seismoInfo = Object.create(Papierkorb.prototype);
    return Object.assign(seismoInfo, object);
  }

  toJSON(): PapierkorbJSON {
    return Object.assign({}, this);
  }
}
