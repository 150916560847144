<form [formGroup]="publikationForm" (ngSubmit)="onSubmit()">
  <div class="hidden">
    <input id="id" class="admin-settings" formControlName="id" [ngClass]="delChosen?'setting-delete':''">
  </div>

  <div class="col-md-4 clear-padding-left">
    <input id="titel" class="admin-settings" formControlName="titel" [attr.disabled]="publikation.deaktiviert ? '' : null" [ngClass]="delChosen?'setting-delete':''" maxlength="255">
    <span class="length-counter">
      {{ (255 - publikationForm.get('titel').value?.length) + ' / 255' }}
    </span>
  </div>

  <div class="col-md-2">
    <input id="abkuerzung"  class="admin-settings" formControlName="abkuerzung" [attr.disabled]="publikation.deaktiviert ? '' : null" [ngClass]="delChosen?'setting-delete':''" maxlength="30">
    <span class="length-counter">
      {{ (30 - publikationForm.get('abkuerzung').value?.length) + ' / 30' }}
    </span>
  </div>

  <div class="col-md-1">
    <input type="checkbox" id="deaktiviert" formControlName="deaktiviert" [ngClass]="delChosen?'setting-delete':''">
  </div>

  <div class="col-md-3">
    <p class="admin-settings">
      {{ publikation.mutDate | date: 'dd.MM.yyyy HH:mm' }}<br/>
      {{ publikation.mutUser }}</p>
  </div>

  <div class="col-md-2">
    <button class="btn btn-sm admin-settings" type="submit"
            [ngStyle]="{ 'visibility': publikationForm.dirty ? '' : 'hidden'}"
            [disabled]="publikationForm.invalid">
      <i class="fa fa-check"></i>
    </button>

    <button class="btn btn-sm admin-settings" type="button" title="Abbrechen"
            [ngStyle]="{ 'visibility': publikationForm.dirty ? '' : 'hidden'}"
            (click)="onReset()">
      <i class="fa fa-times"></i>
    </button>

    <button class="btn btn-sm admin-settings" type="button" title="Löschen"
            (click)="deletePublikation(publikation.id)">
      <i class="fa fa-trash-o"></i>
    </button>
  </div>
</form>
