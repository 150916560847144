import { Status } from './status';
import { Treiber } from './treiber';
import { Bereich } from './bereich';
import { Gefahr } from './gefahr';
import { Steckbrief } from './steckbrief';
import { PublikationDetail } from './publikation';
import { Matrix } from '@app/shared/classes/adura/matrix';

export interface MeldungJSON {
  id?: number;
  isPublic?: boolean;
  code?: string;
  sterne?: number;
  titel: string;
  titelRaw: string;
  kurzinfo?: string;
  kommentar: string;
  seismoPublikation?: boolean;
  seismoInfoAusgabe?: Date;

  status?: Status;
  logComment?: string;

  createSteckbrief?: boolean;

  publikationen: PublikationDetail[];

  bereiche: Bereich[];
  gefahren: Gefahr[];
  matrix: Matrix[];
  treiber: Treiber[];
  steckbriefe: Steckbrief[];

  erfDate?: Date;
  mutDate?: Date;

  disabled: boolean;
}

export class Meldung {
  id?: number;
  isPublic?: boolean;
  sterne?: number;
  code?: string;
  titel: string;
  titelRaw: string;
  kurzinfo?: string;
  kommentar: string;
  seismoPublikation?: boolean;
  seismoInfoAusgabe?: Date;

  status?: Status;
  logComment?: string;

  createSteckbrief?: boolean;

  publikationen: PublikationDetail[];

  bereiche: Bereich[];
  gefahren: Gefahr[];
  matrix: Matrix[];
  treiber: Treiber[];
  steckbriefe: Steckbrief[];

  erfDate?: Date;
  mutDate?: Date;

  disabled: boolean; // Damit Elemente des Dropdowns disabled gesetzt werden können; für das Backend irrelevant

  get codeTitelRaw() {
    return this.code + ' ' + this.titelRaw;
  }

  public constructor(init?: Partial<Meldung>) {
    Object.assign(this, init);
  }

  static fromJSON(json: string | object): Meldung {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const land = Object.create(Meldung.prototype);
    return Object.assign(land, object);
  }

  toJSON(): MeldungJSON {
    return Object.assign({}, this);
  }
}
