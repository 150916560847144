<div #matrixEditModal class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="close()" aria-label="Close"><span
          aria-hidden="true">&times;</span>
        </button>
        <h3 class="modal-title">{{ 'adura.matrix.add' | translate }}</h3>
      </div>
      <form [formGroup]="matrixEditForm" (ngSubmit)="onSave()">
        <div class="modal-body">
          <div class="form-group">
            <label>{{ 'adura.matrix.label' | translate }}:</label>
            <ng-select formControlName="matrix"
                       placeholder="{{ 'adura.matrix.choose' | translate }}"
                       [items]="matrixList"
                       [clearable]="false"
                       bindLabel="bezeichnung"
                       dropdownPosition="bottom">
              <ng-template ng-option-tmp let-item="item">
                <div>{{ item.sortierung }} - {{ item.bezeichnung }}</div>
              </ng-template>
            </ng-select>
          </div>
          <div class="form-group">
            <label for="ergaenzung">{{ 'adura.matrix.ergaenzung' | translate }}:</label>
            <span class="length-counter-modal">
              {{ (100 - matrixEditForm.get('ergaenzung').value?.length) + ' / 100' }}
            </span>
            <input
              type="text"
              class="form-control"
              formControlName="ergaenzung"
              id="ergaenzung"
              maxlength="100"
              autocomplete="off">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" (click)="close()">
            {{ 'global.cancel' | translate }}
          </button>
          <button type="button" class="btn btn-sm btn-space btn-primary" (click)="onSave()"
                  [disabled]="matrixEditForm.invalid" *ngIf="matrixToEdit?.id">
            {{ 'global.save' | translate }}
          </button>
          <button type="submit" class="btn btn-sm btn-space btn-primary"
                  [disabled]="matrixEditForm.invalid" *ngIf="!matrixToEdit?.id">
            {{ 'global.add' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
