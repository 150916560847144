<section class="facet-filter">
  <a role="button" class="facet-authors_de-collapse-button facet-toggle collapsed"
     (click)="changeAuthorCollapseForTablet()"
     [attr.aria-expanded]="activeAuthorCollapseTablet" aria-controls="facet-authors_de-collapse">
    <h2>{{ 'author.content' | translate }}<i class="fa fa-angle-down"></i></h2>
  </a>
  <h2 class="facet-header" [attr.aria-label]="'author.content' | translate">{{ 'author.content' | translate }}</h2>
  <div id="facet-authors_de-collapse" class="collapse facet-collapse"
       [ngClass]="(activeAuthorCollapseTablet)?'in':''">
    <div class="form-group input-reset-button" *ngIf="this.authors && this.authors?.length > 0">
      <input class="form-control input-sm" spellcheck="false" [(ngModel)]="searchTerm"
             (ngModelChange)="searchAuthors()" placeholder="{{ 'author.search' | translate }}"/>
      <button type="reset">
        <i class="fa fa-times" aria-hidden="true" (click)="clearInput()"></i>
      </button>
    </div>
    <ng-container *ngIf="!this.authorListToShow() || this.authorListToShow().length === 0">
      <p><small>{{ 'search.noFilter' | translate }}</small></p>
    </ng-container>
    <nav [attr.aria-label]="'author.authorList' | translate">
      <div class="list-inline tags">
        <ng-container *ngFor="let author of this.authorListToShow()">
          <div class="flex-container">
            <input type="checkbox" class="filter-checkbox sr-only" id="{{author.guid}}"
                   (click)="changeAuthor(author.guid)"
                   [checked]="isAuthorActive(author.guid)">
            <label class="filter-checkbox-label" [ngClass]="(author.count === 0)?'filter-element-background':''"
                   for="{{author.guid}}" title="{{author.name}}"
                   role="checkbox"
                   [attr.aria-label]="('author.label' | translate) + ': ' + author.name + ', ' +
                   ('global.total' | translate) + ': ' + author.count"
                   [attr.aria-checked]="(isAuthorActive(author.guid))?'true':'false'">
              {{ author.name }}
            </label>
            <span class="filter-checkbox-number" aria-hidden="true">{{ author.count}}</span>
          </div>
        </ng-container>
      </div>
    </nav>

    <ng-container *ngIf="(top10Length > 9)">
      <p><a (click)="changeShowMoreAuthors()" class="btn btn-xs">
        <ng-container *ngIf="isShowMoreAuthors(); then showmore else showless"></ng-container>
        <ng-template #showmore>
          <i class="fa filter-more-less fa-angle-up"></i>{{ 'global.show.less' | translate }}
        </ng-template>
        <ng-template #showless>
          <i class="fa filter-more-less fa-angle-down"></i>{{ 'global.show.more' | translate }}
        </ng-template>
      </a>
      </p>
    </ng-container>
  </div>
</section>
