import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AduraFacet } from '@app/shared/classes/adura/adura-facet';

@Component({
  selector: 'app-adura-bereich-side',
  templateUrl: './adura-bereich-side.component.html'
})
export class AduraBereichSideComponent {
  @Input() bereich: AduraFacet[] = [];
  @Input() activeBereich: string[] = [];
  @Output() bereichChanged = new EventEmitter<string[]>();

  isBereichActive(id: string) {
    return this.activeBereich.find(res => res === id);
  }

  changeBereich(id: string) {
    const newActiveBereich = this.activeBereich.slice();
    const index = newActiveBereich.indexOf(id);
    if (index > -1) {
      newActiveBereich.splice(index, 1);
    } else {
      newActiveBereich.push(id);
    }
    this.bereichChanged.emit(newActiveBereich);
  }
}
