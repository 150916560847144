import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FavoriteCreateComponent } from '@app/search/favorite-create/favorite-create.component';
import { Event } from '@app/shared/classes/event';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html'
})
export class EventComponent implements OnInit {

  loading = false;
  guid: string;
  termin: Event;

  @ViewChild(FavoriteCreateComponent) child: FavoriteCreateComponent;

  constructor(private route: ActivatedRoute,
              private awisaFoService: AwisaFoService) {
  }

  ngOnInit(): void {
    this.guid = this.route.snapshot.paramMap.get('id');
    this.awisaFoService.getTermin(this.guid)
      .subscribe(
        (result) => {
          console.log(result);
          this.termin = result;
        });
  }

  get getGuid() { return (this.termin && this.termin.guid) ? this.termin.guid : null; }
  get name() { return (this.termin && this.termin.name) ? this.termin.name : null; }
  get description() { return (this.termin && this.termin.description) ? this.termin.description : null; }
  get dateFrom() { return (this.termin && this.termin.dateFrom) ? this.termin.dateFrom : null; }
  get dateTo() { return (this.termin && this.termin.dateTo) ? this.termin.dateTo : null; }
  get creationDate() { return (this.termin && this.termin.creationDate) ? this.termin.creationDate : null; }
  get modificationDate() { return (this.termin && this.termin.modificationDate) ? this.termin.modificationDate : null; }
  get dossierLink() { return (this.termin && this.termin.dossierLink) ? this.termin.dossierLink : null; }
  get comments() { return (this.termin && this.termin.comments) ? this.termin.comments : null; }
  get tags() { return (this.termin && this.termin.tags) ? this.termin.tags : null; }
  get subtermine() { return (this.termin && this.termin.subtermine) ? this.termin.subtermine : null; }

  openCreateFavorite() {
    this.child.open();
  }

}

