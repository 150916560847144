import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { SettingsService } from '@app/core/config/settings.service';
import { PersonService } from '@app/shared/services/person.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin',
  templateUrl: './disclaimer.component.html'
})
export class DisclaimerComponent implements OnInit, AfterViewInit {

  @ViewChild('disclaimerHtml') el: ElementRef;
  disclaimerText: string;
  disclaimerDate: string;
  allCheckboxesChecked = false;
  isSending = false;

  constructor(private authenticationService: AuthenticationService,
              private personService: PersonService,
              private settingsService: SettingsService,
              private translateService: TranslateService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const user = this.authenticationService.getUser();
    this.disclaimerText = user.activeDisclaimerText;
    this.disclaimerDate = user.activeDisclaimerDate;
  }

  ngAfterViewInit() {
    this.el.nativeElement.innerHTML = this.disclaimerText;
    }

  allBoxesChecked() {
    const checkboxes = document.querySelectorAll('#disclaimerHtml input[type="checkbox"]');
    const selectedBoxes = Array.prototype.slice.call(checkboxes).filter(ch => ch.checked === true);
    console.log(selectedBoxes.length);

    return checkboxes.length === selectedBoxes.length;
  }

  updateUser() {
    if (this.allBoxesChecked()) {
      this.isSending = true;
      const user = this.authenticationService.getUser();
      let url = '/home';

      this.route.queryParams.subscribe(params => {
        url = this.route.snapshot.queryParams.url;
      });

      this.personService.setDisclaimerDate(user.guid)
        .subscribe(() => {
          window.location.href = url;
        });
    } else {
      this.showAlert();
    }
  }

  showAlert() {
    const text = this.translateService.instant('disclaimer.checkAllBoxes');
    Swal.fire({
      text,
      icon: 'error',
      customClass: {htmlContainer: 'content-text-center'},
      buttonsStyling: true,
      confirmButtonText: 'OK'
    });
  }

  get getDisclaimerText() { return this.disclaimerText ? this.disclaimerText : null; }
  get getDisclaimerDate() { return this.disclaimerDate ? this.disclaimerDate : null; }
}
