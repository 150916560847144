import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@app/core/auth/user';
import { Favorite, FavoriteCategoryEdit } from '@app/shared/classes/favorite';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-favorite-category-edit-modal',
  templateUrl: './favorite-category-edit-modal.component.html'
})
export class FavoriteCategoryEditModalComponent implements AfterViewInit {
  private user: User;
  @ViewChild('favoriteCategoryEditModal', {static: false}) favoriteCategoryEditModal: ElementRef;
  @Output() updateEditFavoriteCategory = new EventEmitter();
  private elm: HTMLElement;
  currentCategoryName: string;
  favoriteCategoryEditForm: UntypedFormGroup;

  isSending = false;
  error = false;
  errorMessage: string;

  constructor(private authenticationService: AuthenticationService,
              private awisaFoService: AwisaFoService,
              private formBuilder: UntypedFormBuilder,
              private translateService: TranslateService) {
    this.initForm();
  }

  private initForm() {
    this.favoriteCategoryEditForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(80)]]
    });
  }

  ngAfterViewInit(): void {
    this.elm = this.favoriteCategoryEditModal.nativeElement as HTMLElement;
    this.user = this.authenticationService.getUser();
  }

  open(category: string): void {
    this.currentCategoryName = category;
    this.elm.classList.add('show');
    this.elm.style.width = '100vw';
    this.favoriteCategoryEditForm.controls.name.setValue(category);
  }

  onSubmit() {
    this.isSending = true;
    const daten: FavoriteCategoryEdit = {
      guid: this.user.guid,
      title: this.favoriteCategoryEditForm.value.name.trim(),
      currentTitle: this.currentCategoryName
    };

    this.awisaFoService.editFavoriteCategory(daten)
      .subscribe({
        next: (result) => {
          this.close();
          this.refreshCurrentPage(result);
          this.showSuccessAlert('favorite.stored');
          this.isSending = false;
        },
        error: error => {
          this.error = true;
          if (error.error === undefined || error.statusText === 'Unknown Error') {
            this.errorMessage = 'global.error';
          } else {
            this.errorMessage = error.error;
          }
          this.isSending = false;
        }
      });
  }

  refreshCurrentPage(favoriteArr: Favorite[]) {
    this.updateEditFavoriteCategory.emit(favoriteArr);
  }

  showSuccessAlert(translateString: string) {
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          });
        });
  }

  close() {
    this.favoriteCategoryEditForm.reset({
      name: ''
    }, {emitEvent: false});
    this.elm.classList.remove('show');
    setTimeout(() => {
      this.elm.style.width = '0';
    }, 75);
    this.error = false;
    this.currentCategoryName = null;
  }
}
