import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { User } from '@app/core/auth/user';
import { SettingsService } from '@app/core/config/settings.service';
import { Link, LinkSave } from '@app/shared/classes/link';
import { Role } from '@app/shared/classes/role';
import { SelectItem } from '@app/shared/classes/select-item';
import { Tag } from '@app/shared/classes/tag';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-link-new',
  templateUrl: './link-new.component.html'
})
export class LinkNewComponent implements OnInit {
  linkForm: UntypedFormGroup;
  tags: Tag[];
  roles: Role[];
  languageList: SelectItem[] = [];
  link: Link;
  user: User;
  html = '';

  newLinkEnabled = false;

  isSending = false;
  error = false;


  constructor(private awisaFoService: AwisaFoService,
              private translateService: TranslateService,
              private authenticationService: AuthenticationService,
              private formBuilder: UntypedFormBuilder,
              private settingsService: SettingsService,
              private router: Router) {
  }

  ngOnInit(): void {
    const set = this.settingsService.settings().find(el => el.key === 'gui.add.link.enabled');
    if (set.value === '1') {
      this.newLinkEnabled = true;
    }
    const lang = this.translateService.currentLang;
    const key = 'gui.roles.disclaimer.' + lang;
    const set2 = this.settingsService.settings().find(el => el.key === key);
    this.html = set2.value;

    this.user = this.authenticationService.getUser();
    this.translateService.getLangs().forEach((language) => {
      const langTranslateProp = 'language.' + language;
      this.languageList.push(new SelectItem(language, langTranslateProp, false));
      console.log(this.languageList);
    });
    this.awisaFoService.getTags()
      .subscribe(
        (result) => {
          this.tags = result;
        });
    this.awisaFoService.getRoles()
      .subscribe(
        (result) => {
          console.log(result);
          this.roles = result;
        });
    this.initForm();
  }

  private initForm() {
    this.linkForm = this.formBuilder.group({
      name: ['', Validators.required],
      url: ['', Validators.required],
      desc_de: [''],
      desc_en: [''],
      desc_fr: [''],
      desc_it: [''],
      tags: [[], Validators.required],
      roles: new UntypedFormArray(
        [new UntypedFormControl('BLV_AWISA_ROLLE_Veterinaerdienst'),
          new UntypedFormControl('BLV_AWISA_ROLLE_Lebensmittelbehoerde')], Validators.required),
    });
  }

  get nameForm() {
    return this.linkForm.get('name');
  }

  get urlForm() {
    return this.linkForm.get('url');
  }

  get tagsForm() {
    return this.linkForm.get('tags');
  }

  get rolesForm() {
    return this.linkForm.get('roles') as UntypedFormArray;
  }

  createLink() {
    this.isSending = true;
    const formValues = this.linkForm.value;
    const daten: LinkSave = {
      name: formValues.name,
      url: formValues.url,
      descriptionDe: formValues.desc_de,
      descriptionEn: formValues.desc_en,
      descriptionFr: formValues.desc_fr,
      descriptionIt: formValues.desc_it,
      tags: formValues.tags,
      roles: formValues.roles,
      einbringerGuid: this.user.guid
    };

    console.log(daten);

    if (this.linkForm.valid) {
      this.awisaFoService.createLink(daten)
        .subscribe({
          next: (result) => {
            this.isSending = false;
            this.onReset();
            this.error = false;
            console.log(result);
            this.showSuccessAlert('link.stored');
            this.router.navigateByUrl('/');
          },
          error: error => {
            this.isSending = false;
            this.error = true;
            console.log(error);
          }
        });
    } else {
      Object.keys(this.linkForm.controls).forEach(field => {
        const control = this.linkForm.get(field);
        control.markAsDirty({onlySelf: true});
      });
      this.isSending = false;
    }
  }

  chooseRole(event) {
    /* Selected */
    if (event.target.checked) {
      this.rolesForm.push(new UntypedFormControl(event.target.name));
    }  /* unselected */
    else {
      let i = 0;
      this.rolesForm.controls.forEach((ctrl: UntypedFormControl) => {
        if (ctrl.value === event.target.name) {
          this.rolesForm.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  isRoleActive(role: string) {
    const roleExist = this.rolesForm.controls.find(
      (res: UntypedFormControl) => res.value === role);
    if (roleExist) {
      return roleExist;
    } else {
      return false;
    }
  }

  onReset() {
    this.linkForm.reset();
    this.linkForm.removeControl('roles');
    this.linkForm.addControl('roles', new UntypedFormArray([new UntypedFormControl('BLV_AWISA_ROLLE_Veterinaerdienst'),
      new UntypedFormControl('BLV_AWISA_ROLLE_Lebensmittelbehoerde')], Validators.required));
  }

  showSuccessAlert(translateString: string){
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          });
        });
  }
}
