import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Injectable()
export class StringDateValidator {

  static date(c: UntypedFormControl) {
    const yearRegex = new RegExp(/^20\d{2}$/);
    const monthRegex = new RegExp(/^((0[1-9])|(1[0-2]))\.20\d{2}$/);
    const dayRegex = new RegExp(/^(0[1-9]|[12]\d|3[01])\.((0[1-9])|(1[0-2]))\.20\d{2}$/);

    if (yearRegex.test(c.value)) {
      console.log('year');
      return null;
    } else if (monthRegex.test(c.value)) {
      console.log('month');
      return null;
    } else if (dayRegex.test(c.value)) {
      console.log('day');
      return null;
    }
    return { date: true };
  }
}
