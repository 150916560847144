import { Injectable } from '@angular/core';
import { Steckbrief } from '../classes/adura/steckbrief';

@Injectable({ providedIn: 'root' })
export class SteckbriefService {

  private _steckbrief: Steckbrief;

  get steckbrief(): Steckbrief {
    return this._steckbrief;
  }

  set steckbrief(steckbrief: Steckbrief) {
    this._steckbrief = steckbrief;
  }
}
