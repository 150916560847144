import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, skip } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private hasHistory = false;

  constructor(private router: Router,
              private location: Location,
              private translateService: TranslateService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      skip(1) // skip initial routing event on page load
    )
      .subscribe((event) => {
        this.hasHistory = true;
      });
  }

  back(): void {
    // Auskommentiert, da damit mehr Fälle abgedeckt werden können / mas 27.01.2021
    // if (this.hasHistory) {
      this.location.back();
    // } else {
      // this.router.navigateByUrl('/');
    // }
  }

  confirmLeave(): Observable<boolean> {
    return from(
      Swal.fire({
        titleText: this.translateService.instant('adura.unsaved.title'),
        text: this.translateService.instant('adura.unsaved.message'),
        icon: 'warning',
        customClass: {htmlContainer: 'content-text-center'},
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: this.translateService.instant('adura.unsaved.confirm'),
        cancelButtonText: this.translateService.instant('global.back')
      }).then( result => !!result.value )
    );
  }
}
