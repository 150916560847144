import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Facet } from '@app/shared/classes/facet';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';

@Component({
  selector: 'app-author-side',
  templateUrl: './author-side.component.html'
})
export class AuthorSideComponent implements OnChanges {
  @Input() authors: Facet[] = [];
  @Input() activeAuthors: string[] = [];
  @Output() authorsChanged = new EventEmitter<string[]>();
  @Output() searchTermChanged = new EventEmitter<string>();
  @Input() searchTerm: string;

  authorsCopy: Facet[];
  authorsTop10: Facet[];
  activeAuthorCollapseTablet = false;
  isShowMoreVal = false;

  constructor(private awisaFoService: AwisaFoService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.copyAuthors();
    this.calcTopAuthors();
  }

  changeAuthor(guid: string) {

    if (this.searchTerm) {
      this.searchTermChanged.emit(this.searchTerm);
    }

    const newActiveAuthors = this.activeAuthors.slice();

    const index = newActiveAuthors.indexOf(guid);
    if (index > -1) {
      newActiveAuthors.splice(index, 1);
    } else {
      newActiveAuthors.push(guid);
    }

    this.authorsChanged.emit(newActiveAuthors);
  }

  isAuthorActive(guid: string) {
    return this.activeAuthors.find(res => res === guid);
  }

  calcTopAuthors() {
    if (this.authorsCopy !== undefined && this.authorsCopy !== null) {
      this.authorsTop10 = this.authorsCopy.slice(0, 10);
    } else {
      this.authorsTop10 = [];
    }
  }

  get top10Length() {
    return (this.authorsTop10) ? this.authorsTop10.length : null;
  }

  isShowMoreAuthors() {
    return this.isShowMoreVal;
  }

  changeShowMoreAuthors() {
    this.isShowMoreVal = !this.isShowMoreVal;
  }

  changeAuthorCollapseForTablet() {
    this.activeAuthorCollapseTablet = !this.activeAuthorCollapseTablet;
  }

  authorListToShow() {
    if (this.isShowMoreVal) {
      return this.authorsCopy;
    } else {
      return this.authorsTop10;
    }
  }

  copyAuthors() {
    this.authorsCopy = this.authors;
  }

  searchAuthors(): void {

    if (this.searchTerm) {
      this.awisaFoService.filterFacetsBySearchTerm(this.searchTerm.toLowerCase(), this.authors)
        .subscribe({
          next: result => this.authorsCopy = result.length > 0 ? result : [],
          error: error => console.log(error)
        });
    } else {
      this.copyAuthors();
    }
    this.calcTopAuthors();
  }

  clearInput(): void {
    this.searchTerm = null;
    this.searchAuthors();
  }
}
