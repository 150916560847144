import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {

  transform(value: any): any {
    if (value === '-' || value === undefined) {
      return '';
    } else {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);

      if (seconds < 10) {
        return 'Gerade jetzt';
      }

      // console.log()

      const intervals = {
        Jahr: 31536000,
        Monat: 2592000,
        Woche: 604800,
        Tag: 86400,
        Stunde: 3600,
        Minute: 60,
        Sekunde: 1
      };
      let counter;
      // tslint:disable-next-line:forin
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0) {
          return this.getText(counter, i);
        }
      }
    }
    return value;
  }

  getText(counter: number, i: string) {
      if (counter === 1) {
        return 'vor ' + counter + ' ' + i; // Einzahl (vor 1 Tag)
      } else {
        if (i === 'Jahr' || i === 'Monat' || i === 'Tag') {
          return 'vor ' + counter + ' ' + i + 'en'; // Mehrzahl (vor 2 Tagen)
        } else {
          return 'vor ' + counter + ' ' + i + 'n'; // Mehrzahl (vor 2 Stunden)
        }
      }
  }
}
