import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';
import { AduraService } from '@app/shared/services/adura.service';
import { TabChangeEvent } from '@app/shared/tabs/tabs.component';

declare let require: any;
const clientVersion = require('../../../../package.json').version;

@Component({
  selector: 'app-adura-admin',
  templateUrl: './adura-admin.component.html'
})
export class AduraAdminComponent implements OnInit, AfterContentChecked {

  datePickerConfig = {
    format: 'DD.MM.YYYY',
    locale: 'de'
  };

  aduraVersion: string;

  selectedPeriod: any = 1;
  selectedFrom: any;
  selectedTo: any;
  currentCategory: string;

  createIndex = false;

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  constructor(private awisaFoService: AwisaFoService,
              private aduraService: AduraService,
              private router: Router,
              private changeDetector: ChangeDetectorRef,
              private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.aduraService.getVersion()
      .subscribe(result => {
        if (/^\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(result)) {
          this.aduraVersion = result;
        } else {
          this.aduraVersion = 'N/A';
        }
      });

    this.route.queryParams.subscribe( () => {
      this.currentCategory = this.route.snapshot.params.category || 'log';
    });
  }

  onCategoryChange(event: TabChangeEvent) {
    this.router.navigate(['/adura/admin', event.tabId], { queryParamsHandling: 'preserve' });
  }

  getLogFile() {
    if (this.selectedPeriod >= 0) {
      const days = this.selectedPeriod;
      this.awisaFoService.subscribeToDownload(this.aduraService.getLog(days));
    } else if (this.selectedFrom && this.selectedTo) {
      this.awisaFoService.subscribeToDownload(this.aduraService.getLogBetween(
        this.selectedFrom.format('YYYYMMDD'),
        this.selectedTo.format('YYYYMMDD')
      ));
    }
  }

  createLuceneIndex() {
    this.createIndex = true;
    this.aduraService.createLuceneIndex()
      .subscribe({
        next: () => this.createIndex = false,
        error: error => {
          console.log(error);
          this.createIndex = false;
        }
      });
  }

  get getFrontendVersion() { return clientVersion; }
  get getAduraVersion() { return (this.aduraVersion) ? this.aduraVersion : '-'; }
}
