<form [formGroup]="settingsForm" (ngSubmit)="onSubmit()">
  <div class="col-md-2 clear-padding-left">
    <input id="key" class="admin-settings" formControlName="key" [ngClass]="delChosen?'setting-delete':''">
  </div>

  <div class="col-md-3">
    <textarea id="value_{{ setting.id }}" class="admin-settings" [ngClass]="delChosen?'setting-delete':''" formControlName="value"
              (keyup)="autogrow(setting.id)"></textarea>
  </div>

  <div class="col-md-3">
    <p class="admin-settings">{{ setting.description }}</p>
  </div>
  <div class="col-md-2">
    <p class="admin-settings">
      {{ setting.mutDate | date: 'dd.MM.yyyy HH:mm' }}<br/>
      {{ setting.mutUserName }}</p>
  </div>

  <div class="col-md-2">
    <button class="btn btn-sm admin-settings" type="submit"
            [ngStyle]="{ 'visibility': settingsForm.dirty ? '' : 'hidden'}"
            [disabled]="settingsForm.invalid">
      <i class="fa fa-check"></i>
    </button>

    <button class="btn btn-sm admin-settings" type="button" title="Abbrechen"
            [ngStyle]="{ 'visibility': settingsForm.dirty ? '' : 'hidden'}"
            (click)="onReset()">
      <i class="fa fa-times"></i>
    </button>

    <button class="btn btn-sm admin-settings" type="button" title="Löschen"
            (click)="deleteSetting(setting.id)">
      <i class="fa fa-trash-o"></i>
    </button>
  </div>
</form>
