export interface RoleJSON {
  identifier: string;
  name: string;
}

export class Role {
  identifier: string;
  name: string;

  static fromJSON(json: string | object): Role {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const role = Object.create(Role.prototype);
    return Object.assign(role, json);
  }

  toJSON(): RoleJSON {
    return Object.assign({}, this);
  }
}
