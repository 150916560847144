import { Component, ElementRef, ViewChild } from '@angular/core';
import { ThemenwordTest } from '@app/shared/classes/thema';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';
import { DataExchangeService } from '../shared/services/data-exchange.service';

@Component({
  selector: 'app-themenword-test',
  templateUrl: './themenword-test.component.html'
})
export class ThemenwordTestComponent {
  @ViewChild('fileUploader') fileUploader: ElementRef;
  thema: ThemenwordTest;
  chosenFile;
  loading = false;
  error = false;

  constructor(private dataExchangeService: DataExchangeService,
              private awisaFO: AwisaFoService) {
  }

  sendThemenword() {
    this.loading = true;
    this.thema = null;
    this.dataExchangeService.uploadThemenword(this.chosenFile)
      .subscribe({
        next: (result1) => {
          this.awisaFO.getTestThema(result1).subscribe({
            next: (result2) => {
              this.thema = result2;
              this.error = false;
              this.onReset();
            },
            error: error => {
              console.log(error);
              this.loading = false;
              this.error = true;
            }
          });
        },
        error: error => {
          console.log(error);
          this.loading = false;
          this.error = true;
        }
      });
  }

  onReset() {
    this.fileUploader.nativeElement.value = null;
    this.loading = false;
  }

  selectFile(event) {
    this.chosenFile = event.target.files[0];
  }
}
