import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-adura-massenfreigabe-type-filter',
  templateUrl: './adura-massenfreigabe-type-filter.component.html'
})
export class AduraMassenfreigabeTypeFilterComponent implements OnInit {
  @Input() typeList: string[] = [];
  @Output() typeChanged = new EventEmitter<string>();
  @Output() reqFieldsChanged = new EventEmitter<boolean>();

  chosenType: string;
  reqFields: boolean;

  ngOnInit() {
    this.chosenType = this.typeList[0];
  }

  isTypeActive(id: string) {
    return this.chosenType === id;
  }

  changeType(id: string) {
    this.chosenType = id;
    this.typeChanged.emit(this.chosenType);
  }

  isReqFieldsActive() {
    return this.reqFields;
  }

  changeReqFieldFilter() {
    this.reqFields = !this.reqFields;
    this.reqFieldsChanged.emit(this.reqFields);
  }
}
