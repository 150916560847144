<!-- //NOSONAR UL-Element in äusserem Component --><li class="dataset-item clear-margin-bottom">
  <div class="dataset-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 item-content-name">
          <h2>
            <a class="item-content-name" routerLink="/subject/{{ this.thema.guid }}">{{ this.thema.name }}</a>
          </h2>
        </div>
        <div class="col-xs-12 col-md-3 clear-padding-left">
          <p>{{ 'global.changed' | translate }}: {{ this.thema.modificationDate | date: 'dd.MM.yyyy' }}</p>
        </div>
        <div class="col-xs-12 col-md-9 clear-padding-left item-content-tags">
          <p class="clear-margin-bottom" *ngIf="(oberthemen.length > 0)">{{ 'oberthema.plural' | translate }}:
            {{ oberthemen }}</p>
        </div>
        <div class="col-xs-12 clear-padding-left">
          <a *ngFor="let dom of this.thema.domains"
             class="button-domain btn-sm btn-default">{{ dom }}</a>
        </div>
        <div class="col-xs-12 col-md-12 clear-padding-left mt-20">
          <p *ngIf="!isProd()">Faktor: {{ thema.relevanzFaktor }}</p>
        </div>
      </div>
    </div>
  </div>
</li>
