import { AduraFacet } from '@app/shared/classes/adura/adura-facet';
import { AduraResultItem } from '@app/shared/classes/adura/adura-result-item';

export interface AduraSearchResultJSON {
  totalCount: number;
  meldungFilterFacets: AduraFacet[];
  steckbriefFilterFacets: AduraFacet[];
  gefahrFacets: AduraFacet[];
  matrixFacets: AduraFacet[];
  treiberFacets: AduraFacet[];
  bereichFacets: AduraFacet[];
  activityStatusFacets: AduraFacet[];
  beurteilungTypeFacets: AduraFacet[];
  seismoPubFacets: AduraFacet[];
  seismoInfoFacets: AduraFacet[];
  statusFacets: AduraFacet[];
  isPublicFacets: AduraFacet[];
  priorityFacets: AduraFacet[];
  meldungen: AduraResultItem[];
  steckbriefe: AduraResultItem[];
  signale: AduraResultItem[];
  meldungCount: number;
  steckbriefCount: number;
  signalCount: number;
  hasMoreMeldungen: boolean;
  hasMoreSteckbriefe: boolean;
  hasMoreSignale: boolean;
}

export class AduraSearchResult {
  totalCount: number;
  meldungFilterFacets: AduraFacet[];
  steckbriefFilterFacets: AduraFacet[];
  gefahrFacets: AduraFacet[];
  matrixFacets: AduraFacet[];
  treiberFacets: AduraFacet[];
  bereichFacets: AduraFacet[];
  activityStatusFacets: AduraFacet[];
  beurteilungTypeFacets: AduraFacet[];
  seismoPubFacets: AduraFacet[];
  seismoInfoFacets: AduraFacet[];
  statusFacets: AduraFacet[];
  isPublicFacets: AduraFacet[];
  priorityFacets: AduraFacet[];
  meldungen: AduraResultItem[];
  steckbriefe: AduraResultItem[];
  signale: AduraResultItem[];
  meldungCount: number;
  steckbriefCount: number;
  signalCount: number;
  hasMoreMeldungen: boolean;
  hasMoreSteckbriefe: boolean;
  hasMoreSignale: boolean;

  static fromJSON(json: string | object): AduraSearchResult {
    if (!json) {
      return null;
    }
    const searchResult = Object.create(AduraSearchResult.prototype);
    return Object.assign(searchResult, json);
  }

  toJSON(): AduraSearchResultJSON {
    return Object.assign({}, this);
  }
}
