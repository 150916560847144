<!-- //NOSONAR UL-Element in äusserem Component --><li class="dataset-item clear-margin-bottom">
  <div class="dataset-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 item-content-name">
          <h2>
            <a routerLink="/{{ this.comment.referenceType }}/{{ this.comment.referenceGuid }}">{{ this.comment.referenceName }}</a>
            <ng-container *ngIf="this.comment.format; then showFormat else showContenttype"></ng-container>
            <ng-template #showContenttype>
                <span class="doc-item-format item-comment-format-type">
                  <h3 class="sr-only">{{ "content.type" | translate }}: </h3>
                    <label attr.aria-label="{{ this.comment.referenceType + '.label' | translate }}"
                           title="{{ this.comment.referenceType + '.label' | translate }}">
                      ({{ this.comment.referenceType + '.label' | translate }})
                    </label>
                </span>
            </ng-template>
            <ng-template #showFormat>
                <span class="doc-item-format item-comment-format-type">
                  <h3 class="sr-only">{{ "format.label" | translate }}:</h3>
                  <label>({{ getFormat() }})</label>
                </span>
            </ng-template>
          </h2>
        </div>
        <div class="col-xs-12 clear-padding-left">
          <p class="clear-margin-bottom">{{ this.comment.author | translate }}, {{ comment.modificationDate | date: 'dd.MM.yyyy' }}:
            {{ this.comment.description | translate }}</p>
        </div>
        <div class="col-xs-12 col-md-12 clear-padding-left mt-20">
          <p *ngIf="!isProd()">Faktor: {{ comment.relevanzFaktor }}</p>
        </div>
      </div>
    </div>
  </div>
</li>
