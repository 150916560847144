import { Component, OnInit, ViewChild } from '@angular/core';
import { AduraService } from '@app/shared/services/adura.service';
import { Meldung } from '@app/shared/classes/adura/meldung';
import { Steckbrief } from '@app/shared/classes/adura/steckbrief';
import { AduraPapierkorbMeldungEditComponent } from '@app/adura/admin/papierkorb/papierkorb-meldung-edit/adura-papierkorb-meldung-edit.component';
import { AduraPapierkorbSteckbriefEditComponent } from '@app/adura/admin/papierkorb/papierkorb-steckbrief-edit/adura-papierkorb-steckbrief-edit.component';
import { Log } from '@app/shared/classes/adura/log';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-adura-admin-papierkorb',
  templateUrl: './adura-admin-papierkorb.component.html'
})
export class AduraAdminPapierkorbComponent implements OnInit {
  @ViewChild(AduraPapierkorbMeldungEditComponent) meldungEdit: AduraPapierkorbMeldungEditComponent;
  @ViewChild(AduraPapierkorbSteckbriefEditComponent) steckbriefEdit: AduraPapierkorbSteckbriefEditComponent;

  meldungen: Meldung[];
  steckbriefe: Steckbrief[];

  logs: Log[];
  logOpen = false;
  error: boolean;

  constructor(private translateService: TranslateService,
              private aduraService: AduraService) {
  }

  ngOnInit(): void {
    this.aduraService.getPapierkorbLists().subscribe(papierkorb => {
      this.meldungen = papierkorb.meldungen;
      this.steckbriefe = papierkorb.steckbriefe;
    });
  }

  openEditMeldung(meldung: Meldung) {
    this.meldungEdit.open(meldung);
  }

  updateMeldungen(restored: Meldung) {
    this.meldungen = this.meldungen.filter((meldung) => {
      return meldung.id !== restored.id;
    });
  }

  openEditSteckbrief(steckbrief: Steckbrief) {
    this.steckbriefEdit.open(steckbrief);
  }

  updateSteckbriefe(restored: Steckbrief) {
    this.steckbriefe = this.steckbriefe.filter((steckbrief) => {
      return steckbrief.id !== restored.id;
    });
  }

  openLog() {
    this.logOpen = !this.logOpen;
  }

  openLogList() {
    this.logOpen = !this.logOpen;
    this.error = false;
    if (this.logOpen) {
      if (this.logs === undefined) {
        this.aduraService.listPapierkorb().subscribe({
          next: logs => this.logs = logs,
          error: (error) => {
            this.error = true;
            console.error(error);
          }
        });
      }
    }
  }
}
