import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AduraService } from '@app/shared/services/adura.service';
import { TreiberAdmin } from '@app/shared/classes/adura/treiber';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-adura-admin-stammdaten-treiber-new',
  templateUrl: './adura-admin-stammdaten-treiber-new.component.html'
})
export class AduraAdminStammdatenTreiberNewComponent implements OnInit {
  @Output() deleted = new EventEmitter();
  @Output() reloadEntries: EventEmitter<string> = new EventEmitter<string>();

  newTreiberForm: UntypedFormGroup;
  treiber: TreiberAdmin = new TreiberAdmin();

  constructor(private aduraService: AduraService,
              private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.newTreiberForm = this.formBuilder.group({
      bezeichnungDe_new: ['', Validators.required],
      bezeichnungEn_new: ['', Validators.required],
      bezeichnungFr_new: ['', Validators.required],
      bezeichnungIt_new: ['', Validators.required],
      deaktiviert_new: ['']
    });
  }

  onSubmit() {
    const formValues = this.newTreiberForm.value;
    this.treiber.bezeichnungDe = formValues.bezeichnungDe_new;
    this.treiber.bezeichnungEn = formValues.bezeichnungEn_new;
    this.treiber.bezeichnungFr = formValues.bezeichnungFr_new;
    this.treiber.bezeichnungIt = formValues.bezeichnungIt_new;
    this.treiber.deaktiviert = formValues.deaktiviert_new;
    this.aduraService.createTreiber(this.treiber)
      .subscribe({
        next: () => {
          this.initForm();
          this.reloadEntries.emit();
        },
        error: error => {
          this.showAlert();
          console.error(error);
        }
      });
  }

  onReset() {
    this.ngOnInit();
  }

  showAlert() {
    Swal.fire({
      text: 'Der Treiber konnte nicht gespeichert werden!',
      icon: 'error',
      customClass: {htmlContainer: 'content-text-center'},
      buttonsStyling: true,
      confirmButtonText: 'OK'
    });
  }
}
