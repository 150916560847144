<div #gefahrCreateModal class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button *ngIf="!isSending" type="button" class="close" (click)="close()" aria-label="Close"><span
          aria-hidden="true">&times;</span>
        </button>
        <h3 class="modal-title">{{ 'adura.danger.new' | translate }}</h3>
      </div>
      <form [formGroup]="gefahrCreateForm" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <p *ngIf="error" class="alert alert-danger" role="alert"> {{ errorMessage | translate }}</p>

          <div class="form-group">
            <label for="bezeichnungEn">{{ 'adura.stammdaten.bezeichnung.en' | translate }}:</label>
            <span class="length-counter-modal">{{ remainingCharactersMap['bezeichnungEn'] + ' / ' + maxCharsMap['bezeichnungEn'] }}</span>
            <div class="tiny-input">
              <editor formControlName="bezeichnungEn"
                      maxlength="255"
                      [inline]="true"
                      [init]="tinyConfig"
                      (onInit)="updateRemainingCharacters('bezeichnungEn', $event)"
                      (onKeyUp)="updateRemainingCharacters('bezeichnungEn', $event)"
                      (onChange)="updateRemainingCharacters('bezeichnungEn', $event)">
              </editor>
            </div>
          </div>
          <div class="form-group">
          <label for="bezeichnungDe">{{ 'adura.stammdaten.bezeichnung.de' | translate }}:</label>
            <span class="length-counter-modal">{{ remainingCharactersMap['bezeichnungDe'] + ' / ' + maxCharsMap['bezeichnungDe'] }}</span>
          <div class="tiny-input">
            <editor formControlName="bezeichnungDe"
                    maxlength="255"
                    [inline]="true"
                    [init]="tinyConfig"
                    (onInit)="updateRemainingCharacters('bezeichnungDe', $event)"
                    (onKeyUp)="updateRemainingCharacters('bezeichnungDe', $event)"
                    (onChange)="updateRemainingCharacters('bezeichnungDe', $event)">
            </editor>
          </div>
        </div>
          <div class="form-group">
            <label for="bezeichnungFr">{{ 'adura.stammdaten.bezeichnung.fr' | translate }}:</label>
            <span class="length-counter-modal">{{ remainingCharactersMap['bezeichnungFr'] + ' / ' + maxCharsMap['bezeichnungFr'] }}</span>
            <div class="tiny-input">
              <editor formControlName="bezeichnungFr"
                      maxlength="255"
                      [inline]="true"
                      [init]="tinyConfig"
                      (onInit)="updateRemainingCharacters('bezeichnungFr', $event)"
                      (onKeyUp)="updateRemainingCharacters('bezeichnungFr', $event)"
                      (onChange)="updateRemainingCharacters('bezeichnungFr', $event)">
              </editor>
            </div>
          </div>
          <div class="form-group">
            <label for="bezeichnungIt">{{ 'adura.stammdaten.bezeichnung.it' | translate }}:</label>
            <span class="length-counter-modal">{{ remainingCharactersMap['bezeichnungIt'] + ' / ' + maxCharsMap['bezeichnungIt'] }}</span>
            <div class="tiny-input">
              <editor formControlName="bezeichnungIt"
                      maxlength="255"
                      [inline]="true"
                      [init]="tinyConfig"
                      (onInit)="updateRemainingCharacters('bezeichnungIt', $event)"
                      (onKeyUp)="updateRemainingCharacters('bezeichnungIt', $event)"
                      (onChange)="updateRemainingCharacters('bezeichnungIt', $event)">
              </editor>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" (click)="close()">
            {{ 'global.cancel' | translate }}
          </button>
          <button type="submit" class="btn btn-sm btn-space btn-primary"
                  [disabled]="gefahrCreateForm.invalid || this.isSending">
            <i *ngIf="isSending" class="fa fa-spinner fa-pulse fa-fw"></i>{{ 'global.save' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
