<section class="facet-filter">
  <h3 attr.aria-label="{{ 'adura.search.seismo.ausgabe.label' | translate }}">{{ 'adura.search.seismo.ausgabe.label' | translate }}</h3>
  <div>
    <div class="form-group input-reset-button" *ngIf="this.seismoInfo && this.seismoInfo?.length > 0">
      <input class="form-control input-sm" spellcheck="false" [(ngModel)]="searchTerm"
             (ngModelChange)="searchSeismoInfo()" placeholder="{{ 'adura.search.seismo.ausgabe.search' | translate }}"/>
      <button type="reset">
        <i class="fa fa-times" aria-hidden="true" (click)="clearInput()"></i>
      </button>
    </div>
    <ng-container *ngIf="!this.seismoInfoListToShow() || this.seismoInfoListToShow().length === 0">
      <p><small>{{ 'search.noFilter' | translate }}</small></p>
    </ng-container>
    <nav [attr.aria-label]="'adura.search.seismo.ausgabe.list' | translate">
      <ul class="list-inline tags">
        <ng-container *ngFor="let seismoInfo of this.seismoInfoListToShow()">
          <li class="nav-item filter-tag" [ngClass]="(isSeismoInfoActive(seismoInfo.id))?'active':''">
            <a (click)="changeSeismoInfo(seismoInfo.id)" role="button" class="filter-tag-label"
               [attr.aria-pressed]="(isSeismoInfoActive(seismoInfo.id))?'true':'false'"
               [attr.aria-label]="('adura.search.seismo.ausgabe.label' | translate) + ': ' + seismoInfo.name + ', ' +
          ('global.total' | translate) + ': ' + seismoInfo.count">{{ seismoInfo.name }}
              <small [attr.aria-label]="seismoInfo.count" aria-hidden="true">{{ seismoInfo.count }}</small></a></li>
        </ng-container>
      </ul>
    </nav>
    <ng-container *ngIf="(top10Length > 9)">
      <p><a (click)="changeShowMoreSeismoInfo()" class="btn btn-xs">
        <ng-container *ngIf="isShowMoreSeismoInfo(); then showmore else showless"></ng-container>
        <ng-template #showmore>
          <i class="fa filter-more-less fa-angle-up"></i>{{ 'global.show.less' | translate }}
        </ng-template>
        <ng-template #showless>
          <i class="fa filter-more-less fa-angle-down"></i>{{ 'global.show.more' | translate }}
        </ng-template>
      </a>
      </p>
    </ng-container>
  </div>
</section>
