export interface AduraResultItemJSON {
  code: string;
  titel: string;
  kurzinfo: string;
  sterne: number;
  status: string;
  type: string;
  basisQuelle: string;
  basisQuelleLink: string;
  signal: boolean;
  seismoPublikation: boolean;
  seismoInfo: string;
  isPublic: boolean;
  erfDate: Date;
  mutDate: Date;
  mutUser: string;
  relevanzFaktor: number;
  bereiche: string[];
  bereichPrimaer: string;
  readyForFreigabe: boolean;
}

export class AduraResultItem {
  code: string;
  titel: string;
  kurzinfo: string;
  sterne: number;
  status: string;
  type: string;
  basisQuelle: string;
  basisQuelleLink: string;
  signal: boolean;
  seismoPublikation: boolean;
  seismoInfo: string;
  isPublic: boolean;
  erfDate: Date;
  mutDate: Date;
  mutUser: string;
  relevanzFaktor: number;
  bereiche: string[];
  bereichPrimaer: string;
  readyForFreigabe: boolean;

  static fromJSON(json: string | object): AduraResultItem {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const resultItem = Object.create(AduraResultItem.prototype);
    return Object.assign(resultItem, json);
  }

  toJSON(): AduraResultItemJSON {
    return Object.assign({}, this);
  }
}
