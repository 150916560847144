import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Facet } from '@app/shared/classes/facet';

@Component({
  selector: 'app-filter-side',
  templateUrl: './filter-side.component.html'
})
export class FilterSideComponent {
  @Input() filter: Facet[] = [];
  @Input() activeFilter: string[] = [];
  @Output() filterChanged = new EventEmitter<string[]>();

  isFilterActive(id: string) {
    return this.activeFilter.find(res => res === id);
  }

  changeFilter(id: string) {
    const newActiveFilter = this.activeFilter.slice();
    const index = newActiveFilter.indexOf(id);
    if (index > -1) {
      newActiveFilter.splice(index, 1);
    } else {
      newActiveFilter.push(id);
    }
    this.filterChanged.emit(newActiveFilter);
  }
}
