import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CreationDateChangedEvent } from '@app/search/creation-date-side/creation-date-side.component';
import { DateChangedEvent } from '@app/search/date-side/date-side.component';
import { FavoriteCreateComponent } from '@app/search/favorite-create/favorite-create.component';
import { ModDateChangedEvent } from '@app/search/mod-date-side/mod-date-side.component';
import { SearchResult } from '@app/shared/classes/search-result';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';
import { TabChangeEvent } from '@app/shared/tabs/tabs.component';
import { TagSideComponent } from '@app/search/tag-side/tag-side.component';
import { SearchParams } from '@app/shared/classes/search-params';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit {

  sucheForm: UntypedFormGroup;
  suchResultat: SearchResult;

  term = '';
  filter: string[];
  domains: string[];
  topics: string[];
  tags: string[];
  authors: string[];
  contentTypes: string[];
  dateFrom = '';
  dateTo = '';
  createdDateFrom = '';
  createdDateTo = '';
  changedDateFrom = '';
  changedDateTo = '';
  languages: string[];
  formats: string[];
  status: string[];
  osContent = 30;
  osThema = 30;
  osComment = 30;
  currentCategory: string;
  showSpinner = false;

  sortList: string[] = [
    'relevance',
    'titleAsc',
    'titleDesc',
    'lastModified',
    'firstModified',
    'lastCreated',
    'firstCreated',
    'docdateDesc',
    'docdateAsc'];
  sort = 'relevance';

  @ViewChild(FavoriteCreateComponent) childFavoriteCreate: FavoriteCreateComponent;
  @ViewChild(TagSideComponent) tagSideComponent: TagSideComponent;
  @Output() searchTermChanged = new EventEmitter<string>();

  tagsSearchTerm: string;
  authorsSearchTerm: string;
  highlightTerm: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private awisaFoService: AwisaFoService,
              private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.term = this.route.snapshot.queryParams.term;
      this.filter = this.getArray(this.route.snapshot.queryParams.filter);
      this.domains = this.getArray(this.route.snapshot.queryParams.domain);
      this.tags = this.getArray(this.route.snapshot.queryParams.tag);
      if (this.route.snapshot.queryParams.sort !== undefined) {
        this.sort = this.route.snapshot.queryParams.sort;
      }
      this.topics = this.getArray(this.route.snapshot.queryParams.topic);
      this.authors = this.getArray(this.route.snapshot.queryParams.author);
      this.contentTypes = this.getArray(this.route.snapshot.queryParams.type);
      this.dateFrom = this.route.snapshot.queryParams.dateFrom;
      this.dateTo = this.route.snapshot.queryParams.dateTo;
      this.createdDateFrom = this.route.snapshot.queryParams.createdDateFrom;
      this.createdDateTo = this.route.snapshot.queryParams.createdDateTo;
      this.changedDateFrom = this.route.snapshot.queryParams.changedDateFrom;
      this.changedDateTo = this.route.snapshot.queryParams.changedDateTo;
      this.languages = this.getArray(this.route.snapshot.queryParams.language);
      this.formats = this.getArray(this.route.snapshot.queryParams.format);
      this.status = this.getArray(this.route.snapshot.queryParams.status);

      this.currentCategory = this.route.snapshot.params.category || 'content';
      this.highlightTerm = this.term;

      this.searchDocuments();
    });

    this.route.params.subscribe(fragment => {
      this.currentCategory = this.route.snapshot.params.category || 'content';
    });

    this.sucheForm = this.formBuilder.group({
      term: this.term
    });

  }

  onSubmitSuche() {
    const sucheFormValues = this.sucheForm.value;
    const term = sucheFormValues.term;
    this.highlightTerm = term;

    console.log(term);

    this.router.navigate(['/search'], {
      queryParams: {
        term
      },
      queryParamsHandling: 'merge',
    });
  }

  resetSearch() {
    this.sucheForm.reset();

    this.router.navigate([], {
      queryParams: {
        term: '',
        filter: undefined
      },
      queryParamsHandling: 'merge',
    });
  }

  searchDocuments() {
    this.showSpinner = true;

    const searchParams = SearchParams.fromObject({
      term: this.term,
      sort: this.sort,
      filter: this.filter,
      domains: this.domains,
      tags: this.tags,
      topics: this.topics,
      authors: this.authors,
      contentTypes: this.contentTypes,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      languages: this.languages,
      formats: this.formats,
      status: this.status,
      createdDateFrom: this.createdDateFrom,
      createdDateTo: this.createdDateTo,
      changedDateFrom: this.changedDateFrom,
      changedDateTo: this.changedDateTo
    });

    this.awisaFoService.search(searchParams.getHttpParams()).subscribe({
      next: result => {
        this.suchResultat = result;
        this.showSpinner = false;
      },
      error: () => this.showSpinner = false
    });
  }

  searchDocumentsOffset(offset: number, scrollTab: string) {
    const searchParams = SearchParams.fromObject({
      term: this.term,
      sort: this.sort,
      filter: this.filter,
      domains: this.domains,
      tags: this.tags,
      topics: this.topics,
      authors: this.authors,
      contentTypes: this.contentTypes,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      languages: this.languages,
      formats: this.formats,
      status: this.status,
      createdDateFrom: this.createdDateFrom,
      createdDateTo: this.createdDateTo,
      changedDateFrom: this.changedDateFrom,
      changedDateTo: this.changedDateTo,
      offset,
      scrollTab
    });

    return this.awisaFoService.search(searchParams.getHttpParams());
  }

  get totalCount() {
    return (this.suchResultat && this.suchResultat.totalCount) ? this.suchResultat.totalCount : null;
  }

  get contents() {
    return (this.suchResultat && this.suchResultat.contents) ? this.suchResultat.contents : null;
  }

  get contentLength() {
    return (this.suchResultat) ? this.suchResultat.contentCount : null;
  }

  get themen() {
    return (this.suchResultat && this.suchResultat.themen) ? this.suchResultat.themen : null;
  }

  get themenLength() {
    return (this.suchResultat) ? this.suchResultat.themenCount : null;
  }

  get comments() {
    return (this.suchResultat && this.suchResultat.comments) ? this.suchResultat.comments : null;
  }

  get commentLength() {
    return (this.suchResultat) ? this.suchResultat.commentCount : null;
  }

  get filterFacets() {
    return (this.suchResultat && this.suchResultat.filterFacets) ? this.suchResultat.filterFacets : null;
  }

  get domainFacets() {
    return (this.suchResultat && this.suchResultat.domainFacets) ? this.suchResultat.domainFacets : null;
  }

  get oberthemaFacets() {
    return (this.suchResultat && this.suchResultat.oberthemaFacets) ? this.suchResultat.oberthemaFacets : null;
  }

  get tagFacets() {
    return (this.suchResultat && this.suchResultat.tagFacets) ? this.suchResultat.tagFacets : null;
  }

  get authorFacets() {
    return (this.suchResultat && this.suchResultat.authorFacets) ? this.suchResultat.authorFacets : null;
  }

  get typeFacets() {
    return (this.suchResultat && this.suchResultat.typeFacets) ? this.suchResultat.typeFacets : null;
  }

  get languageFacets() {
    return (this.suchResultat && this.suchResultat.languageFacets) ? this.suchResultat.languageFacets : null;
  }

  get formatFacets() {
    return (this.suchResultat && this.suchResultat.formatFacets) ? this.suchResultat.formatFacets : null;
  }

  get statusFacets() {
    return (this.suchResultat && this.suchResultat.statusFacets) ? this.suchResultat.statusFacets : null;
  }

  get hasMoreContent() {
    return (this.suchResultat && this.suchResultat.hasMoreContent) ? this.suchResultat.hasMoreContent : false;
  }

  get hasMoreThemen() {
    return (this.suchResultat && this.suchResultat.hasMoreThemen) ? this.suchResultat.hasMoreThemen : false;
  }

  get hasMoreComment() {
    return (this.suchResultat && this.suchResultat.hasMoreComment) ? this.suchResultat.hasMoreComment : false;
  }

  sortSearchResult(sortChoice) {
    this.sort = sortChoice;

    const queryParams = {sort: this.sort};

    this.router.navigate([], {
      queryParams,
      relativeTo: this.route,
      queryParamsHandling: 'merge'
    });
  }

  getArray(suchfilter: string) {
    if (suchfilter !== undefined) {
      return [].concat(suchfilter);
    } else {
      return [];
    }
  }

  resetFilter() {
    const term = this.route.snapshot.queryParams.term;
    this.router.navigate(['/search', this.currentCategory], {
      queryParams: {term, status: ['10', '20']}
    });
  }

  nextTranche(tab: string) {
    switch (tab) {
      case 'content': {
        console.log(this.osContent);
        this.searchDocumentsOffset(this.osContent, tab).subscribe(result => {
          this.suchResultat.contents = this.suchResultat.contents.concat(result.contents);
          this.suchResultat.hasMoreContent = result.hasMoreContent;
          this.osContent = this.osContent + 50;
        });
        break;
      }
      case 'thema': {
        this.searchDocumentsOffset(this.osThema, tab).subscribe(result => {
          this.suchResultat.themen = this.suchResultat.themen.concat(result.themen);
          this.suchResultat.hasMoreThemen = result.hasMoreThemen;
          this.osThema = this.osThema + 50;
        });
        break;
      }
      case 'comment': {
        this.searchDocumentsOffset(this.osComment, tab).subscribe(result => {
          this.suchResultat.comments = this.suchResultat.comments.concat(result.comments);
          this.suchResultat.hasMoreComment = result.hasMoreComment;
          this.osComment = this.osComment + 50;
        });
        break;
      }
    }
  }

  openCreateFavorite() {
    this.childFavoriteCreate.open();
  }

  onCategoryChange(event: TabChangeEvent) {
    this.router.navigate(['/search', event.tabId], {queryParamsHandling: 'preserve'});
  }

  onFilterChanged(filter: string[]) {
    this.applyCriteria({ filter });
  }

  onDomainsChanged(domains: string[]) {
    this.applyCriteria({domain: domains});
  }

  onOberthemenChanged(oberthemen: string[]) {
    this.applyCriteria({topic: oberthemen});
  }

  onTagsChanged(tags: string[]) {
    this.applyCriteria({tag: tags});
  }

  onAuthorsChanged(authors: string[]) {
    this.applyCriteria({author: authors});
  }

  onContentTypesChanged(contentTypes: string[]) {
    this.applyCriteria({type: contentTypes});
  }

  onDateChanged(event: DateChangedEvent) {
    this.applyCriteria({dateFrom: event.dateFrom, dateTo: event.dateTo});
  }

  onCreationDateChanged(event: CreationDateChangedEvent) {
    this.applyCriteria({createdDateFrom: event.createdDateFrom, createdDateTo: event.createdDateTo});
  }

  onModDateChanged(event: ModDateChangedEvent) {
    this.applyCriteria({changedDateFrom: event.changedDateFrom, changedDateTo: event.changedDateTo});
  }

  onLanguagesChanged(languages: string[]) {
    this.applyCriteria({language: languages});
  }

  onFormatsChanged(formats: string[]) {
    this.applyCriteria({format: formats});
  }

  onStatusChanged(status: string[]) {
    this.applyCriteria({status});
  }

  setTagsSearchTerm(searchTerm: string) {
    this.tagsSearchTerm = searchTerm;
  }

  appendTagToSidebarTags(tagString: string) {
    const tagFacet = this.tagSideComponent.tags.find( tag => tag.name === tagString );
    this.tagSideComponent.changeTag(tagFacet.guid);
  }

  setAuthorsSearchTerm(searchTerm: string) {
    this.authorsSearchTerm = searchTerm;
  }

  private applyCriteria(params: object) {
    this.router.navigate(['/search', this.currentCategory], {
      queryParams: params,
      queryParamsHandling: 'merge',

    });
  }
}
