<section class="facet-filter">
  <a role="button" class="facet-status_de-collapse-button facet-toggle collapsed"
     (click)="changeStatusCollapseForTablet()"
     [attr.aria-expanded]="activeStatusCollapseTablet" aria-controls="facet-status_de-collapse">
    <h2>{{ 'document.status.label' | translate }}<i class="fa fa-angle-down"></i></h2>
  </a>
  <h2 class="facet-header" attr.aria-label="{{ 'document.status.label' | translate }}">{{ 'document.status.label' | translate }}</h2>
  <div id="facet-status_de-collapse" class="collapse facet-collapse"
       [ngClass]="(activeStatusCollapseTablet)?'in':''">
    <ng-container *ngIf="!this.status || this.status.length === 0">
      <p><small>{{ 'search.noFilter' | translate }}</small></p>
    </ng-container>
    <nav [attr.aria-label]="'document.status.list' | translate">
      <div class="list-inline tags">
        <ng-container *ngFor="let state of status">
          <div class="flex-container">
            <input type="checkbox" class="filter-checkbox sr-only" id="{{state.guid}}"
                   (click)="changeStatus(state.guid)"
                   [checked]="isStatusActive(state.guid)">
            <label class="filter-checkbox-label" [ngClass]="(state.count === 0)?'filter-element-background':''"
                   for="{{state.guid}}"
                   role="checkbox"
                   [attr.aria-label]="('document.status.label' | translate) + ': ' + ('document.' + state.name | translate) + ', ' +
                   ('global.total' | translate) + ': ' + state.count"
                   [attr.aria-checked]="(isStatusActive(state.guid))?'true':'false'">
              {{ 'document.' + state.name | translate }}
            </label>
            <span class="filter-checkbox-number" aria-hidden="true">{{ state.count}}</span>
          </div>
        </ng-container>
      </div>
    </nav>
  </div>
</section>
