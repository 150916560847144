import { Component, Input } from '@angular/core';
import { Beurteilung } from '@app/shared/classes/adura/beurteilung';

@Component({
  selector: 'app-adura-beurteilung-entry',
  templateUrl: './adura-beurteilung-entry.component.html'
})
export class AduraBeurteilungEntryComponent {
  @Input() beurteilung: Beurteilung;
}
