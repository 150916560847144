import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Facet } from '@app/shared/classes/facet';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';

@Component({
  selector: 'app-tag-side',
  templateUrl: './tag-side.component.html'
})
export class TagSideComponent implements OnChanges {
  @Input() tags: Facet[] = [];
  @Input() activeTags: string[] = [];
  @Output() tagsChanged = new EventEmitter<string[]>();
  @Output() searchTermChanged = new EventEmitter<string>();
  @Input() searchTerm: string;

  tagsCopy: Facet[];
  tagsTop10: Facet[];
  activeTagCollapseTablet = false;
  isShowMoreVal = false;

  constructor(private awisaFoService: AwisaFoService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.copyTags();
    this.calcTopTags();
  }

  changeTag(guid: string) {

    if (this.searchTerm) {
      this.searchTermChanged.emit(this.searchTerm);
    }

    const newActiveTags = this.activeTags.slice();

    const index = newActiveTags.indexOf(guid);
    if (index > -1) {
      newActiveTags.splice(index, 1);
    } else {
      newActiveTags.push(guid);
    }

    this.tagsChanged.emit(newActiveTags);
  }

  isTagActive(tag: string) {
    return this.activeTags.find(res => res === tag);
  }

  calcTopTags() {
    if (this.tagsCopy !== undefined && this.tagsCopy !== null) {
      this.tagsTop10 = this.tagsCopy.slice(0, 10);
    } else {
      this.tagsTop10 = [];
    }
  }

  get top10Length() {
    return (this.tagsTop10) ? this.tagsTop10.length : null;
  }

  isShowMoreTags() {
    return this.isShowMoreVal;
  }

  changeShowMoreTags() {
    this.isShowMoreVal = !this.isShowMoreVal;
  }

  changeTagCollapseForTablet() {
    this.activeTagCollapseTablet = !this.activeTagCollapseTablet;
  }

  tagListToShow() {
    if (this.isShowMoreVal) {
      return this.tagsCopy;
    } else {
      return this.tagsTop10;
    }
  }

  copyTags() {
    this.tagsCopy = this.tags;
  }

  searchTags(): void {

    if (this.searchTerm) {
      this.awisaFoService.filterFacetsBySearchTerm(this.searchTerm.toLowerCase(), this.tags)
        .subscribe({
          next: result => this.tagsCopy = result.length > 0 ? result : [],
          error: error => console.log(error)
        });
    } else {
      this.copyTags();
    }
    this.calcTopTags();
  }

  clearInput(): void {
    this.searchTerm = null;
    this.searchTags();
  }
}
