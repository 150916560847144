<section class="contribute inverted-container">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-xs-12">
        <h2>{{ 'favorite.plural' | translate }}</h2>
      </div>
      <div class="col-md-12 col-xs-12">
        <div class="favorite-tiles">
          <ng-container *ngIf="uncategorizedFavorites && (uncategorizedFavorites.length > 0); else nofavorite">
            <app-favorite-tile class="app-favorite-tile"
                               [favorites]="uncategorizedFavorites"
                               (createLinkFavorite)="copyLinkClipboard($event)"
                               (updateEditFavorite)="openEditFavorite($event)"
                               (updateDeleteFavorite)="openDeleteFavorite($event)">
            </app-favorite-tile>
          </ng-container>
          <ng-template #nofavorite>
            <p>{{ 'favorite.zero' | translate }}</p>
          </ng-template>
          <app-favorite-edit-modal (updateEditFavorite)="updateFavorites($event)"></app-favorite-edit-modal>
          <app-favorite-delete (updateDeleteFavorite)="updateFavorites($event)"></app-favorite-delete>
        </div>
      </div>
      <ng-container *ngFor="let category of this.categories">
        <div class="col-md-12 col-xs-12 favorite-category-container">
          <details>
            <summary class="favorite-category-title">
              <span class="favorite-category-title-bar">
                {{category}}
                <span class="icons">
                  <button type="button" class="favorite-icon" title="{{ 'global.edit' | translate }}"
                          (click)="openEditFavoriteCategory(category)" [attr.aria-label]="'global.edit' | translate">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button type="button" class="favorite-icon" title="{{ 'global.delete' | translate }}"
                          (click)="openDeleteFavoriteCategory(category)" [attr.aria-label]="'global.delete' | translate">
                    <i class="fa fa-times"></i>
                  </button>
                </span>
              </span>
            </summary>
            <app-favorite-tile class="app-favorite-tile"
                               [favorites]="getFavoritesFromCategory(category)"
                               (createLinkFavorite)="copyLinkClipboard($event)"
                               (updateEditFavorite)="openEditFavorite($event)"
                               (updateDeleteFavorite)="openDeleteFavorite($event)">
            </app-favorite-tile>
          </details>
        </div>
        <app-favorite-category-edit-modal (updateEditFavoriteCategory)="updateFavorites($event)"></app-favorite-category-edit-modal>
        <app-favorite-category-delete (input)="category" (updateDeleteFavoriteCategory)="updateFavorites($event)"></app-favorite-category-delete>
      </ng-container>
    </div>
  </div>
</section>
