import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Facet } from '@app/shared/classes/facet';

@Component({
  selector: 'app-domain-side',
  templateUrl: './domain-side.component.html'
})
export class DomainSideComponent {

  @Input() domains: Facet[] = [];
  @Input() activeDomain: string[] = [];
  @Output() domainsChanged = new EventEmitter<string[]>();

  activeDomainCollapseTablet = false;

  isDomainActive(domainGuid: string) {
    return this.activeDomain.find(res => res === domainGuid);
  }

  changeDomain(newDomain: string) {
    const newActiveDomains = this.activeDomain.slice();

    const index = newActiveDomains.indexOf(newDomain);
    if (index > -1) {
      newActiveDomains.splice(index, 1);
    } else {
      newActiveDomains.push(newDomain);
    }

    this.domainsChanged.emit(newActiveDomains);
  }

  changeDomainCollapseForTablet() {
    this.activeDomainCollapseTablet = !this.activeDomainCollapseTablet;
  }
}
