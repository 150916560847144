<section class="facet-filter">
  <h2 (click)="collapseFilter()" class="facet-header" attr.aria-label="{{ 'global.date' | translate }}">{{ 'global.date' | translate }}
    <i class="fa" [ngClass]="collapse? 'fa-angle-right': 'fa-angle-down'" style="float: right"></i></h2>
  <div *ngIf="!collapse">
    <h3 title="{{ 'adura.date.created' | translate }}" class="facet-subtitle" [attr.aria-label]="'adura.date.created' | translate">{{ 'adura.date.created' | translate }}</h3>
    <div>
      <nav [attr.aria-label]="'date.selection' | translate">
        <dp-date-picker class="search-doc-date" aria-hidden="true" placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                        [(ngModel)]="selectedCreatedDateFrom" [config]="datePickerConfig"
                        (onSelect)="getCreatedDateFrom()" (keyup.enter)="updateEmptyCreatedDateData()">
        </dp-date-picker>
        <input (keyup.enter)="getCreatedDateFromEvent($event)" [ngModel]="selectedCreatedDateFrom | date: 'dd.MM.yyyy'"
               placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" type="text"
               tabindex="-1" class="sr-only" [attr.aria-label]="'date.from' | translate">

        <dp-date-picker aria-hidden="true" placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                        [(ngModel)]="selectedCreatedDateTo" [config]="datePickerConfig"
                        (onSelect)="getCreatedDateTo()" (keyup.enter)="updateEmptyCreatedDateData()">
        </dp-date-picker>
        <input (keyup.enter)="getCreatedDateToEvent($event)" [ngModel]="selectedCreatedDateTo | date: 'dd.MM.yyyy'"
               placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" type="text"
               tabindex="-1" class="sr-only" [attr.aria-label]="'global.to' | translate">
      </nav>
    </div>
    <h3 title="{{ 'adura.date.nextEvaluation' | translate }}" class="facet-subtitle"
        [attr.aria-label]="'adura.date.nextEvaluation' | translate">{{ 'adura.date.nextEvaluation' | translate }}
      <input class="filter-checkbox-date" type="checkbox" id="next_eval_null" title="{{ 'adura.date.empty' | translate }}"
             (click)="changeNextEvalNullValue($event)"
             [checked]="nextEvalNullActive()"/>
    </h3>
    <div>
      <nav [attr.aria-label]="'date.selection' | translate">
        <dp-date-picker class="search-doc-date" aria-hidden="true" placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                        [(ngModel)]="selectedNextEvaluationFrom" [config]="datePickerConfig"
                        (onSelect)="getNextEvaluationFrom()" (keyup.enter)="updateEmptyNextEvaluationData()">
        </dp-date-picker>
        <input (keyup.enter)="getNextEvaluationFromEvent($event)" [ngModel]="selectedNextEvaluationFrom | date: 'dd.MM.yyyy'"
               placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" type="text"
               tabindex="-1" class="sr-only" [attr.aria-label]="'date.from' | translate">

        <dp-date-picker aria-hidden="true" placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                        [(ngModel)]="selectedNextEvaluationTo" [config]="datePickerConfig"
                        (onSelect)="getNextEvaluationTo()" (keyup.enter)="updateEmptyNextEvaluationData()">
        </dp-date-picker>
        <input (keyup.enter)="getNextEvaluationToEvent($event)" [ngModel]="selectedNextEvaluationTo | date: 'dd.MM.yyyy'"
               placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" type="text"
               tabindex="-1" class="sr-only" [attr.aria-label]="'global.to' | translate">
      </nav>
    </div>
    <h3 title="{{ 'adura.date.changed' | translate }}" class="facet-subtitle" [attr.aria-label]="'adura.date.changed' | translate">{{ 'adura.date.changed' | translate }}</h3>
    <div>
      <nav [attr.aria-label]="'date.selection' | translate">
        <dp-date-picker class="search-doc-date" aria-hidden="true" placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                        [(ngModel)]="selectedChangedDateFrom" [config]="datePickerConfig"
                        (onSelect)="getChangedDateFrom()" (keyup.enter)="updateEmptyChangedDateData()">
        </dp-date-picker>
        <input (keyup.enter)="getChangedDateFromEvent($event)" [ngModel]="selectedChangedDateFrom | date: 'dd.MM.yyyy'"
               placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" type="text"
               tabindex="-1" class="sr-only" [attr.aria-label]="'date.from' | translate">

        <dp-date-picker aria-hidden="true" placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                        [(ngModel)]="selectedChangedDateTo" [config]="datePickerConfig"
                        (onSelect)="getChangedDateTo()" (keyup.enter)="updateEmptyChangedDateData()">
        </dp-date-picker>
        <input (keyup.enter)="getChangedDateToEvent($event)" [ngModel]="selectedChangedDateTo | date: 'dd.MM.yyyy'"
               placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" type="text"
               tabindex="-1" class="sr-only" [attr.aria-label]="'global.to' | translate">
      </nav>
    </div>
  </div>
</section>
