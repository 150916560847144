<section class="contribute inverted-container">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-xs-12">
        <h2>{{ 'domain.plural' | translate }}</h2>
      </div>
      <div class="col-md-12 col-xs-12">
        <ng-container *ngFor="let dom of this.domains">
          <a class="btn btn-default-inverted" (click)="goToDomain(dom.guid)">{{ dom.name }}</a>
        </ng-container>
      </div>
    </div>
  </div>
</section>
