import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-upload-entry',
  templateUrl: './upload-entry.component.html'
})
export class UploadEntryComponent {
  @Output() deleteFile = new EventEmitter<boolean>();
  @Input() filename: string;

  onDelete() {
    this.deleteFile.emit(true);
  }
}
