<div #steckbriefEditModal class="select modal fade" tabindex="-1" role="dialog" style="min-height: 100vh;">
  <div class="modal-dialog clear-margin-top">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="close()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h3 class="modal-title">{{ 'adura.steckbrief.restore' | translate }}</h3>
        <p *ngIf="error" class="alert alert-danger" role="alert">Fehler beim Speichern</p>
      </div>
      <div class="modal-body scroll-overflow-y max-vh-75">
        <app-adura-steckbrief [papierkorbView]="true"></app-adura-steckbrief>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-sm-12 adura-formular">
            <button type="button" class="btn btn-sm btn-secondary" (click)="close()">
              {{ 'global.cancel' | translate }}
            </button>
            <button type="button" class="btn btn-sm btn-space btn-primary" [disabled]="this.isSending" (click)="restore()">
              <i *ngIf="isSending" class="fa fa-spinner fa-pulse fa-fw"></i>{{ 'global.restore' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
