import { Facet } from './facet';
import { ResultItem } from './result-item';

export interface SearchResultJSON {
  totalCount: number;
  filterFacets: Facet[];
  domainFacets: Facet[];
  oberthemaFacets: Facet[];
  tagFacets: Facet[];
  authorFacets: Facet[];
  languageFacets: Facet[];
  typeFacets: Facet[];
  formatFacets: Facet[];
  statusFacets: Facet[];
  contents: ResultItem[];
  themen: ResultItem[];
  comments: ResultItem[];
  contentCount: number;
  themenCount: number;
  commentCount: number;
  hasMoreContent: boolean;
  hasMoreThemen: boolean;
  hasMoreComment: boolean;
}

export class SearchResult {
  totalCount: number;
  filterFacets: Facet[];
  domainFacets: Facet[];
  oberthemaFacets: Facet[];
  tagFacets: Facet[];
  authorFacets: Facet[];
  languageFacets: Facet[];
  typeFacets: Facet[];
  formatFacets: Facet[];
  statusFacets: Facet[];
  contents: ResultItem[];
  themen: ResultItem[];
  comments: ResultItem[];
  contentCount: number;
  themenCount: number;
  commentCount: number;
  hasMoreContent: boolean;
  hasMoreThemen: boolean;
  hasMoreComment: boolean;

  static fromJSON(json: string | object): SearchResult {
    if (!json) {
      return null;
    }
    const domain = Object.create(SearchResult.prototype);
    return Object.assign(domain, json);
  }

  toJSON(): SearchResultJSON {
    return Object.assign({}, this);
  }
}
