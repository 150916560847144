<div *ngIf="showSpinner" class="spinner">
  <div class="splash-screen-spinner">
    <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
  </div>
</div>
<div>
  <header class="page-header front-header">
    <div class="container">
      <div class="row">
        <form [formGroup]="sucheForm" (ngSubmit)="onSubmitSuche()">
          <div class="col-xs-9 col-md-11 clear-padding-right">
            <input formControlName="term" (keydown.enter)="onSubmitSuche()" id="ogdch_search"
                   class="form-control input-lg" name="term" autocomplete="off"
                   placeholder="{{ 'adura.search.placeholder' | translate }}"
                   [attr.aria-label]="'adura.search.placeholder' | translate">
            <button class="form-control-feedback search-button " (click)="onSubmitSuche()" [attr.aria-label]="'search.label.activity' | translate"
                    title="{{ 'search.label.activity' | translate }}" [disabled]="!term"><i class="fa fa-search" aria-hidden="true"></i></button>
          </div>
          <div class="col-xs-3 col-md-1 text-md-right" *ngIf="term">
            <button type="button" class="btn search-reset" (click)="resetSearch()" [attr.aria-label]="'search.reset' | translate">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </header>

  <form id="dataset-search-form" class="search-form" method="get" data-module="select-switch">
    <section class="container">
      <div class="adura-formular row">
        <div class="col-md-3 facet-filters" role="search">
          <h2 class="sr-only">{{ 'search.filter' | translate }}</h2>
          <div class="flex-container" style="margin-top: 27px;">
            <div class="flex-container col-sm-4 clear-padding-left">
              <button class="btn-sm btn-primary title-icon" role="button"
                      title="{{ 'search.resetFilter' | translate }}" [attr.aria-label]="'search.resetFilter' | translate" (click)="resetFilter()"><i class="fa fa-times"></i></button>
              <br/>
              <app-link-clipboard title="{{ 'adura.search.clipboard' | translate }}"></app-link-clipboard>
            </div>
            <ng-template [appHasRole]="['AWISA_FRESIL']">
              <div class="col-sm-8 clear-padding-right">
                <a class="btn-sm btn-primary title-icon pull-right" (click)="onDowloadExcelExport()" target="_blank">
                  <i class="fa fa-download"></i> {{ 'global.export' | translate }}
                </a>
              </div>
            </ng-template>
          </div>

          <app-adura-filter-side [meldungFilter]="meldungFilterFacets" [activeMeldungFilter]="meldungFilter" (meldungFilterChanged)="onMeldungFilterChanged($event)"
                                 [steckbriefFilter]="steckbriefFilterFacets" [activeSteckbriefFilter]="steckbriefFilter"
                                 (steckbriefFilterChanged)="onSteckbriefFilterChanged($event)"></app-adura-filter-side>

          <h2 class="facet-header" [attr.aria-label]="'adura.links' | translate">{{ 'adura.links' | translate }}</h2>
          <app-adura-gefahr-side [gefahr]="gefahrFacets" [activeGefahr]="gefahr" (gefahrChanged)="onGefahrChanged($event)"></app-adura-gefahr-side>
          <app-adura-matrix-side [matrix]="matrixFacets" [activeMatrix]="matrix" (matrixChanged)="onMatrixChanged($event)"></app-adura-matrix-side>
          <app-adura-treiber-side [treiber]="treiberFacets" [activeTreiber]="treiber" (treiberChanged)="onTreiberChanged($event)"></app-adura-treiber-side>
          <app-adura-bereich-side [bereich]="bereichFacets" [activeBereich]="bereich" (bereichChanged)="onBereichChanged($event)"></app-adura-bereich-side>
          <app-adura-date-side [activeCreatedDateFrom]="createdDateFrom" [activeCreatedDateTo]="createdDateTo" (createdDateChanged)="onCreatedDateChanged($event)"
                               [activeNextEvaluationFrom]="nextEvaluationFrom" [activeNextEvaluationTo]="nextEvaluationTo" (nextEvaluationChanged)="onNextEvaluationChanged($event)"
                               [activeNextEvaluationNull]="nextEvaluationNull" [activeChangedDateFrom]="changedDateFrom" [activeChangedDateTo]="changedDateTo"
                               (changedDateChanged)="onChangedDateChanged($event)"></app-adura-date-side>
          <ng-template [appHasRole]="['AWISA_FRESIL']">
            <h2 class="facet-header">BCG Admin</h2>
            <app-adura-aktivitaet-side [aktivitaetStatus]="aktivitaetStatusFacets" [activeAktivitaetStatus]="aktivitaetStatus"
                                       (aktivitaetStatusChanged)="onAktivitaetStatusChanged($event)"
                                       [activeAktivitaetDateFrom]="aktivitaetDateFrom" [activeAktivitaetDateTo]="aktivitaetDateTo"
                                       (aktivitaetDateChanged)="onAktivitaetDateChanged($event)"
                                       [activeAktivitaetDateNull]="aktivitaetDateNull"></app-adura-aktivitaet-side>
            <app-adura-beurteilung-side [beurteilungType]="beurteilungTypeFacets" [activeBeurteilungType]="beurteilungType"
                                        (beurteilungTypeChanged)="onBeurteilungTypeChanged($event)" [activeBeurteilungDateFrom]="beurteilungDateFrom"
                                        [activeBeurteilungDateTo]="beurteilungDateTo" (beurteilungDateChanged)="onBeurteilungDateChanged($event)"></app-adura-beurteilung-side>
            <app-adura-seismo-info-side [seismoPub]="seismoPubFacets" [activeSeismoPub]="seismoPub" (seismoPubChanged)="onSeismoPubChanged($event)"
                                        [seismoInfo]="seismoInfoFacets" [activeSeismoInfo]="seismoInfo"
                                        (seismoInfoChanged)="onSeismoInfoChanged($event)"></app-adura-seismo-info-side>
            <app-adura-status-side [status]="statusFacets" [activeStatus]="status" (statusChanged)="onStatusChanged($event)"></app-adura-status-side>
            <app-adura-is-public-side [isPublic]="isPublicFacets" [activeIsPublic]="isPublic" (isPublicChanged)="onIsPublicChanged($event)"></app-adura-is-public-side>
            <app-adura-priority-side [priority]="priorityFacets" [activePriority]="priority" (priorityChanged)="onPriorityChanged($event)"></app-adura-priority-side>
          </ng-template>
        </div>

        <main class="col-md-9">
          <div class="search-result-title clearfix">
            <div class="col-md-6 clear-padding-left">
              <h2>{{ totalCount }} {{ 'search.count' | translate }}</h2>
              <h2 class="sr-only">
                {{ 'global.ofWhich' | translate }} {{ meldungLength }} {{ 'content.plural' | translate }},
                {{ steckbriefLength }} {{ 'subject.plural' | translate }}
                {{ 'global.and' | translate }} {{ signalLength }} {{ 'comment.plural' | translate }}
              </h2>
            </div>
            <div class="clear-padding-left text-md-right">
              <div class="form-group form-inline header-md-vertical-center sortby">
                <label for="field-order-by" class="search-order-by">{{ 'search.orderBy.label' | translate }}</label>
                <select #sortSelect class="form-control" id="field-order-by" name="sort" (change)="this.sortSearchResult(sortSelect.value)">
                  <ng-container *ngFor="let sortItem of this.sortList">
                    <option [selected]="sortItem == sort" value="{{ sortItem }}"
                            [attr.aria-label]="'search.orderBy.' + sortItem | translate">{{ 'search.orderBy.' + sortItem | translate }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
          </div>
          <div class="row search-result-box adura-main">
            <app-tabs (tabChanged)="onCategoryChange($event)" [selectedId]="currentCategory">
              <app-tab tabId="notification" tabTitle="{{ 'adura.meldung.plural' | translate }}"
                       contentLength="{{ meldungLength }}" contentClass="tabmenu-content" tabIcon="<i class='fa fa-bullhorn'></i>&nbsp;&nbsp;">
                <p *ngIf="meldungLength == 0">{{ 'search.noresults' | translate }}</p>
                <div class="list-unstyled">
                  <app-adura-meldung-item role="tabpanel"
                                          *ngFor="let meldung of meldungen"
                                          [meldung]="meldung">
                  </app-adura-meldung-item>
                  <button *ngIf="hasMoreMeldungen" type="button" class="button-loadmore btn btn-sm" (click)="nextTranche('notification')">
                    {{ 'global.loadMore' | translate }}
                  </button>
                </div>
              </app-tab>
              <app-tab tabId="factsheet" tabTitle="{{ 'adura.steckbrief.plural' | translate }}"
                       contentLength="{{ steckbriefLength }}" contentClass="tabmenu-content" tabIcon="<i class='fa fa-file-text-o'></i>&nbsp;&nbsp;">
                <p *ngIf="steckbriefLength == 0">{{ 'search.noresults' | translate }}</p>
                <ul class="list-unstyled">
                  <app-adura-steckbrief-item role="tabpanel"
                                             *ngFor="let steckbrief of steckbriefe"
                                             [steckbrief]="steckbrief">
                  </app-adura-steckbrief-item>
                  <button *ngIf="hasMoreSteckbriefe" type="button" class="button-loadmore btn btn-sm" (click)="nextTranche('factsheet')">
                    {{ 'global.loadMore' | translate }}
                  </button>
                </ul>
              </app-tab>
              <app-tab tabId="signal" tabTitle="{{ 'adura.signal.plural' | translate }}"
                       contentLength="{{ signalLength }}" contentClass="tabmenu-content" tabIcon="<i class='fa fa-flag'></i>&nbsp;&nbsp;">
                <p *ngIf="signalLength == 0">{{ 'search.noresults' | translate }}</p>
                <ul class="list-unstyled">
                  <app-adura-steckbrief-item role="tabpanel"
                                             *ngFor="let signal of signale"
                                             [steckbrief]="signal">
                  </app-adura-steckbrief-item>
                  <button *ngIf="hasMoreSignale" type="button" class="button-loadmore btn btn-sm" (click)="nextTranche('signal')">
                    {{ 'global.loadMore' | translate }}
                  </button>
                </ul>
              </app-tab>
            </app-tabs>
          </div>
        </main>
      </div>
    </section>
  </form>
</div>
