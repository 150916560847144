import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { User } from '@app/core/auth/user';
import { FavoriteDeleteComponent } from '@app/home/favorite/favorite-delete/favorite-delete.component';
import { FavoriteEditModalComponent } from '@app/home/favorite/favorite-edit-modal/favorite-edit-modal.component';
import { Favorite } from '@app/shared/classes/favorite';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { FavoriteCategoryEditModalComponent } from '@app/home/favorite/favorite-category-edit-modal/favorite-category-edit-modal.component';
import { FavoriteCategoryDeleteComponent } from '@app/home/favorite/favorite-category-delete/favorite-category-delete.component';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html'
})
export class FavoriteComponent implements OnInit {
  private user: User;
  uncategorizedFavorites: Favorite[] = [];
  favorites: Favorite[] = [];
  categorizedFavorites: Map<string, Favorite[]> = new Map();
  categories: string [] = [];

  @ViewChild(FavoriteEditModalComponent) childFavoriteEdit: FavoriteEditModalComponent;
  @ViewChild(FavoriteDeleteComponent) childFavoriteDelete: FavoriteDeleteComponent;
  @ViewChild(FavoriteCategoryEditModalComponent) childFavoriteCategoryEdit: FavoriteCategoryEditModalComponent;
  @ViewChild(FavoriteCategoryDeleteComponent) childFavoriteCategoryDelete: FavoriteCategoryDeleteComponent;

  constructor(private authenticationService: AuthenticationService,
              private awisaFoService: AwisaFoService,
              private translateService: TranslateService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.user = this.authenticationService.getUser();
    this.awisaFoService.getFavorites(this.user.guid)
      .subscribe(
        (result) => {
          console.log(result);
          this.prepareFavorites(result);
        });
  }

  prepareFavorites(favorites: Favorite[]): void {
    // !fav.category.trim() → sicherstellen, dass Strings nur aus Leerzeichen oder Tabs nicht als Category erkannt werden
    this.uncategorizedFavorites = favorites.filter(
      fav => !fav.category || !fav.category.trim()
    ).sort(
      (a, b) => String(a.title).localeCompare(b.title)
    );
    this.favorites = favorites.filter(
      fav => fav.category !== undefined && fav.category.trim()
    ).sort(
      (a, b) => String(a.title).localeCompare(b.title)
    );

    for (const favorite of this.favorites) {
      const categories = favorite.category.split(';');

      for (const category of categories) {
        const trimmedCategory = category.trim();

        if (!this.categorizedFavorites.get(trimmedCategory)) {
          this.categorizedFavorites.set(trimmedCategory, []);
          this.categories.push(trimmedCategory);
        }

        this.categorizedFavorites.get(trimmedCategory).push(favorite);
      }
    }

    this.categories.sort(
      (a, b) => String(a).localeCompare(b)
    );
  }

  getFavoritesFromCategory(category): Favorite[] {
    return this.categorizedFavorites.get(category);
  }

  openEditFavorite(favorite: Favorite) {
    this.childFavoriteEdit.open(favorite);
  }

  openDeleteFavorite(favorite: Favorite) {
    this.childFavoriteDelete.open(favorite);
  }

  openEditFavoriteCategory(category: string) {
    this.childFavoriteCategoryEdit.open(category);
  }

  openDeleteFavoriteCategory(category: string) {
    this.childFavoriteCategoryDelete.open(category);
  }

  updateFavorites(favorites) {
    this.categorizedFavorites.clear();
    this.favorites = [];
    this.categories = [];
    this.prepareFavorites(favorites);
  }

  copyLinkClipboard(fav: Favorite) {
    const url = window.location.origin + fav.url;
    const link = `<a href="${url}">${fav.title}</a>`;
    navigator.clipboard.write([
      new ClipboardItem({
        'text/html'  : new Blob([link], {type: 'text/html'}),
        'text/plain' : new Blob([url],  {type: 'text/plain'})
      })
    ]);
  }

  showInfoAlert(translateString: string) {
    this.translateService.get(translateString)
      .subscribe(
        (result: string) => {
          Swal.fire({
            title: result,
            position: 'center',
            icon: 'info',
            showConfirmButton: false,
            timer: 2000
          });
        });
  }

  goToUrl(url: string) {
    this.router.navigateByUrl(url);
  }
}
