import {IdName} from './id-name';
import {Comment} from './comment';

export interface ThemaJSON {
  id?: string;
  guid: string;
  name: string;
  html: string;
  dossierLink: string;
  creationDate: string;
  modificationDate: string;
  comments: Comment[];
  domains: IdName[];
  oberthemen: IdName[];
}

export class Thema {
  id?: string;
  guid: string;
  name: string;
  html: string;
  dossierLink: string;
  creationDate: string;
  modificationDate: string;
  comments: Comment[];
  domains: IdName[];
  oberthemen: IdName[];

  static fromJSON(json: string | object): Thema {
    if (!json) {
      return null;
    }
    const thema = Object.create(Thema.prototype);
    return Object.assign(thema, json);
  }

  toJSON(): ThemaJSON {
    return Object.assign({}, this);
  }
}

export interface ThemenwordTestJSON {
  id: number;
  name: string;
  html: string;
}

export class ThemenwordTest {
  id: number;
  name: string;
  html: string;

  static fromJSON(json: string | object): ThemenwordTest {
    if (!json) {
      return null;
    }

    const thema = Object.create(ThemenwordTest.prototype);
    return Object.assign(thema, json);
  }

  toJSON(): ThemenwordTestJSON {
    return Object.assign({}, this);
  }
}
