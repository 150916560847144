import { Component, Input } from '@angular/core';
import { ResultItem } from '@app/shared/classes/result-item';
import { ConfigurationService } from '@app/core/config/configuration.service';

@Component({
  selector: 'app-comment-item',
  templateUrl: './comment-item.component.html'
})
export class CommentItemComponent {

  @Input()
  comment: ResultItem;

  constructor(private configurationService: ConfigurationService) {
  }

  isProd() {
    return this.configurationService.getStage() === 'PR';
  }

  getFormat() {
    return this.comment.format.join(', ');
  }
}
