import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';

export interface CreationDateChangedEvent {
  createdDateFrom: string;
  createdDateTo: string;
}

@Component({
  selector: 'app-creation-date-side',
  templateUrl: './creation-date-side.component.html'
})
export class CreationDateSideComponent implements OnChanges {
  @Input() activeCreationDateFrom = '';
  @Input() activeCreationDateTo = '';
  @Output() creationDateChanged = new EventEmitter<CreationDateChangedEvent>();


  datePickerConfig = {
    format: 'DD.MM.YYYY',
    locale: ''
  };
  selectedCreationDateFrom: any;
  selectedCreationDateTo: any;
  activeCreationDateCollapseTablet = false;
  currentAwisaLanguage: string;


  constructor(private translateService: TranslateService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.activeCreationDateFrom) {
      this.selectedCreationDateFrom = dayjs(this.activeCreationDateFrom);
    } else {
      this.activeCreationDateFrom = '';
      delete this.selectedCreationDateFrom;
    }

    if (this.activeCreationDateTo) {
      this.selectedCreationDateTo = dayjs(this.activeCreationDateTo);
    } else {
      this.activeCreationDateTo = '';
      delete this.selectedCreationDateTo;
    }

    this.currentAwisaLanguage = this.translateService.currentLang;
    this.changeDisplayLanguageCalendar();
  }

  changeDisplayLanguageCalendar() {
    this.datePickerConfig.locale = dayjs.locale(this.currentAwisaLanguage);
  }

  getCreationDateFromFromEvent(event: any) {
    this.selectedCreationDateFrom = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getCreationDateFrom();
  }

  getCreationDateFrom() {
    if (this.selectedCreationDateFrom) {
      this.activeCreationDateFrom = this.selectedCreationDateFrom.format('YYYY-MM-DD');
      this.getDateData();
    }
  }

  getCreationDateToFromEvent(event: any) {
    this.selectedCreationDateTo = dayjs(event.target.value.split('.').reverse().join('-'));
    this.getCreationDateTo();
  }

  getCreationDateTo() {
    if (this.selectedCreationDateTo) {
      this.activeCreationDateTo = this.selectedCreationDateTo.format('YYYY-MM-DD');
      this.getDateData();
    }
  }

  getDateData() {
    let params;

    if (this.selectedCreationDateFrom === undefined && this.selectedCreationDateTo === undefined) {
      params = {createdDateFrom: undefined, createdDateTo: undefined};
    } else if (this.selectedCreationDateFrom === undefined) {
      params = {createdDateFrom: undefined, createdDateTo: this.activeCreationDateTo};
    } else if (this.selectedCreationDateTo === undefined) {
      params = {createdDateFrom: this.activeCreationDateFrom, createdDateTo: undefined};
    } else {
      params = {createdDateFrom: this.activeCreationDateFrom, createdDateTo: this.activeCreationDateTo};
    }

    this.creationDateChanged.emit(params);
  }

  updateEmptyCreationDateData() {
    this.getDateData();
  }

  changeCreationDateCollapseForTablet() {
    this.activeCreationDateCollapseTablet = !this.activeCreationDateCollapseTablet;
  }
}
