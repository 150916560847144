import { AfterViewChecked, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Settings } from '@app/core/config/settings';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-settings-new',
  templateUrl: './admin-settings-new.component.html'
})
export class AdminSettingsNewComponent implements OnInit, AfterViewChecked {
  @Output() deleted = new EventEmitter();

  newSettingForm: UntypedFormGroup;
  isLoading = false;

  setting: Settings = new Settings();

  constructor(private awisaFoService: AwisaFoService,
              private formBuilder: UntypedFormBuilder) {
  }

  @Output() reloadEntries: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewChecked() {
    this.autogrow('value_new');
    this.autogrow('description_new');
  }

  private initForm() {
    this.newSettingForm = this.formBuilder.group({
      key_new: ['', Validators.required],
      value_new: ['', Validators.required],
      description_new: ['']
    });
  }

  onSubmit() {
    const formValues = this.newSettingForm.value;
    this.setting.key = formValues.key_new;
    this.setting.value = btoa(formValues.value_new);
    this.setting.description = formValues.description_new;
    this.awisaFoService.createSetting(this.setting)
      .subscribe({
        next: () => {
          this.initForm();
          this.reloadEntries.emit();
        },
        error: error => {
          this.showAlert();
          console.log(error);
        }
      });
  }

  onReset() {
    this.ngOnInit();
  }

  autogrow(field: string) {
    const textArea = document.getElementById(field);
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }

  showAlert() {
    Swal.fire({
      text: 'Die Einstellung konnte nicht gespeichert werden!',
      icon: 'error',
      customClass: {htmlContainer: 'content-text-center'},
      buttonsStyling: true,
      confirmButtonText: 'OK'
    });
  }
}
