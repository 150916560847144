<section class="facet-filter">
  <h2 class="facet-header" attr.aria-label="{{ 'adura.search.seismo.info' | translate }}">{{ 'adura.search.seismo.info' | translate }}</h2>
  <h3 class="facet-subtitle" attr.aria-label="{{ 'adura.search.seismo.pub' | translate }}">{{ 'adura.search.seismo.pub' | translate }}</h3>
  <div>
    <ng-container *ngIf="!this.seismoPub || this.seismoPub.length === 0">
      <p><small>{{ 'search.noFilter' | translate }}</small></p>
    </ng-container>
    <nav [attr.aria-label]="'document.status.list' | translate">
      <div class="list-inline tags">
        <ng-container *ngFor="let state of seismoPub">
          <div class="flex-container">
            <input type="checkbox" class="filter-checkbox sr-only" id="seismo_pub_{{state.id}}"
                   (click)="changeSeismoPub('seismo_pub_' + state.id)"
                   [checked]="isSeismoPubActive(state.id)">
            <label class="filter-checkbox-label" [ngClass]="(state.count === 0)?'filter-element-background':''"
                   for="seismo_pub_{{state.id}}"
                   role="checkbox"
                   [attr.aria-label]="('adura.status.label' | translate) + ': ' + (state.name | translate) + ', ' +
                   ('global.total' | translate) + ': ' + state.count"
                   [attr.aria-checked]="(isSeismoPubActive(state.id))?'true':'false'">
              {{ 'global.boolean.' + state.name | translate }}
            </label>
            <span class="filter-checkbox-number" aria-hidden="true">{{ state.count}}</span>
          </div>
        </ng-container>
      </div>
    </nav>
  </div>
  <h3 class="facet-subtitle" attr.aria-label="{{ 'adura.search.seismo.ausgabe.label' | translate }}">{{ 'adura.search.seismo.ausgabe.label' | translate }}</h3>
  <div>
    <div class="form-group input-reset-button" *ngIf="this.seismoInfo && this.seismoInfo?.length > 0">
      <input class="form-control input-sm" spellcheck="false" [(ngModel)]="searchTerm"
             (ngModelChange)="searchSeismoInfo()" placeholder="{{ 'adura.search.seismo.ausgabe.search' | translate }}"/>
      <button type="reset">
        <i class="fa fa-times" aria-hidden="true" (click)="clearInput()"></i>
      </button>
    </div>
    <ng-container *ngIf="!this.seismoInfoListToShow() || this.seismoInfoListToShow().length === 0">
      <p><small>{{ 'search.noFilter' | translate }}</small></p>
    </ng-container>
    <nav [attr.aria-label]="'adura.search.seismo.ausgabe.list' | translate">
      <ul class="list-inline tags">
        <ng-container *ngFor="let seismoInfo of this.seismoInfoListToShow()">
          <li class="nav-item filter-tag" [ngClass]="(isSeismoInfoActive(seismoInfo.id))?'active':''">
            <a (click)="changeSeismoInfo(seismoInfo.id)" role="button" class="filter-tag-label"
               [attr.aria-pressed]="(isSeismoInfoActive(seismoInfo.id))?'true':'false'"
               [attr.aria-label]="('adura.search.seismo.ausgabe.label' | translate) + ': ' + seismoInfo.name + ', ' +
          ('global.total' | translate) + ': ' + seismoInfo.count">{{ seismoInfo.name }}
              <small [attr.aria-label]="seismoInfo.count" aria-hidden="true">{{ seismoInfo.count }}</small></a></li>
        </ng-container>
      </ul>
    </nav>
    <ng-container *ngIf="(top10Length > 9)">
      <p><a (click)="changeShowMoreSeismoInfo()" class="btn btn-xs">
        <ng-container *ngIf="isShowMoreSeismoInfo(); then showmore else showless"></ng-container>
        <ng-template #showmore>
          <i class="fa filter-more-less fa-angle-up"></i>{{ 'global.show.less' | translate }}
        </ng-template>
        <ng-template #showless>
          <i class="fa filter-more-less fa-angle-down"></i>{{ 'global.show.more' | translate }}
        </ng-template>
      </a>
      </p>
    </ng-container>
  </div>
</section>
