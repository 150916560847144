<div #favoriteCategoryDeleteModal class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <p *ngIf="error" class="alert alert-danger"> {{ errorMessage | translate }}</p>
      <div class="modal-body" *ngIf="category">
        <label>"{{ category }}" {{ 'global.delete' | translate | lowercase }}?</label>
      </div>

      <div class="modal-footer">
        <ng-container *ngIf="isSending; then showspinner else hidespinner"></ng-container>

        <ng-template #hidespinner>
          <button type="button" class="btn btn-sm btn-secondary" (click)="close()">
            {{ 'global.cancel' | translate }}
          </button>
          <button type="submit" class="btn btn-sm btn-primary" (click)="deleteFavoriteCategory(category)">
            {{ 'global.delete' | translate }}
          </button>
        </ng-template>

        <ng-template #showspinner>
          <button type="submit" class="btn btn-sm btn-primary" (click)="deleteFavoriteCategory(category)"
                  [disabled]="this.isSending">
            <i class="fa fa-spinner fa-pulse fa-fw"></i>
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
