<header class="page-header" *ngIf="!papierkorbView">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <button type="button" class="btn btn-sm btn-space btn-primary" onclick="history.back()">
          <i class="fa fa-backward"></i>
        </button>
        <button type="button" class="btn btn-sm btn-space btn-primary" (click)="editSteckbrief()"
                title="{{ 'adura.steckbrief.edit' | translate }}" *ngIf="isEditable()">
          <i class="fa fa-edit"></i>
        </button>
        <app-link-clipboard title="{{ steckbrief?.titel }}" [type]="clipboardType | translate"
                            buttonClass="btn btn-sm btn-space btn-primary"></app-link-clipboard>
      </div>
    </div>
  </div>
</header>

<section class="container information">
  <ng-container *ngIf="id; else steckbriefNotFound">
    <h1 [innerHTML]="titel"></h1>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.steckbrief.id' | translate }}</label>
      <div class="col-sm-10">
        {{ steckbrief.code }}
      </div>
    </div>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.erfDate' | translate }} / {{ 'adura.mutDate' | translate }}</label>
      <div class="col-sm-10">
        {{ steckbrief.erfDate | date: 'dd.MM.yyyy' }} / {{ steckbrief.mutDate | date: 'dd.MM.yyyy' }}
      </div>
    </div>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.shortInfo' | translate }}</label>
      <div class="col-sm-10 bullets" [innerHTML]="steckbrief.kurzinfo"></div>
    </div>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.kommentar' | translate }}</label>
      <div class="col-sm-10 bullets" [innerHTML]="steckbrief.comment"></div>
    </div>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.steckbrief.nextEvaluation' | translate }}</label>
      <div class="col-sm-10">
        {{ steckbrief.nextEvaluation | date: 'dd.MM.yyyy' }}
      </div>
    </div>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.steckbrief.priority' | translate }}</label>
      <div class="col-sm-10">
        {{ steckbrief.priority.messageProperty | translate }}
      </div>
    </div>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.signal.label' | translate }}</label>
      <div class="col-sm-10">
        <input type="checkbox"
               role="checkbox"
               disabled
               [checked]="steckbrief?.signal"/>
      </div>
    </div>

    <hr>

    <h3>{{ 'adura.steckbrief.beurteilungUndAktivitaet' | translate }}</h3>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.beurteilung.plural' | translate }}</label>
      <div class="col-sm-10 clear-padding-left">
        <div class="col-sm-2"><b>{{ 'global.type' | translate }}</b></div>
        <div class="col-sm-8"><b>{{ 'global.description.label' | translate }}</b></div>
        <div class="col-sm-12 clear-padding-left clear-padding-right beurteilung-aktivitaet-list" *ngFor="let beurteilung of beurteilungen">
          <app-adura-beurteilung-entry [beurteilung]="beurteilung"></app-adura-beurteilung-entry>
        </div>
      </div>
    </div>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.aktivitaet.label' | translate }}</label>
      <div class="col-sm-10 clear-padding-left">
        <div class="col-sm-2"><b>{{ 'adura.aktivitaet.who' | translate }}</b></div>
        <div class="col-sm-2"><b>{{ 'adura.aktivitaet.till' | translate }}</b></div>
        <div class="col-sm-6"><b>{{ 'adura.aktivitaet.text' | translate }}</b></div>
        <div class="col-sm-2"><b>{{ 'adura.aktivitaet.status.label' | translate }}</b></div>
        <div class="col-sm-12 clear-padding-left clear-padding-right beurteilung-aktivitaet-list" *ngFor="let aktivitaet of aktivitaeten">
          <app-adura-aktivitaet-entry [aktivitaet]="aktivitaet"></app-adura-aktivitaet-entry>
        </div>
      </div>
    </div>

    <hr>

    <h3>{{ 'adura.steckbrief.documents' | translate }}</h3>
    <div class="form-element row">
      <label class="col-sm-2">{{ 'adura.reference.title' | translate }}</label>
      <div class="col-sm-10 bullets" [innerHTML]="steckbrief.referenzen"></div>
    </div>

    <div class="form-element row">
      <label class="col-sm-2">{{ 'adura.steckbrief.documents' | translate }}</label>
      <div class="col-sm-10 clear-padding-left">
        <div class="col-sm-12 clear-padding-left clear-padding-right" *ngFor="let document of sortedDocuments">
          <div class="col-sm-2">
            {{ 'adura.autorisierung.' + document.autorisierung | translate }}
          </div>
          <div class="col-sm-8 hide-overflow-x">
            <a class="adura-link" (click)="getDocument(document.id)" target="_blank">
              {{ document.name }}.{{ document.extension }}
            </a>
          </div>
          <div class="col-sm-2" (click)="getDocumentPreview(document.id, document.name)">
            <i class="fa document-preview-icon-left" [ngClass]="loadingPreview?'fa-spinner fa-pulse':'fa-eye'"
               title="{{ 'document.preview.show' | translate }}">
            </i>
          </div>
        </div>
      </div>
    </div>

    <ng-template [appHasRole]="['AWISA_FRESIL']">
      <div class="form-element row">
        <label class="col-sm-2">{{ 'adura.bcgDocument.title' | translate }}</label>
        <div class="col-sm-10 bullets" [innerHTML]="steckbrief.bcgDokumente"></div>
      </div>
    </ng-template>

    <hr>

    <h3>{{ 'adura.links' | translate }}</h3>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.danger.label' | translate }}</label>
      <div class="col-sm-10">
        <ul class="adura-form">
          <li *ngFor="let gefahr of steckbrief.gefahren" [innerHTML]="gefahr.bezeichnung"></li>
        </ul>
      </div>
    </div>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.domain.label' | translate }}</label>
      <div class="col-sm-10">
        <ul class="adura-form">
          <li *ngFor="let bereich of steckbrief.bereiche" [innerHTML]="bereich.bezeichnung"></li>
        </ul>
      </div>
    </div>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.matrix.label' | translate }}</label>
      <div class="col-sm-10">
        <ul class="adura-form">
          <li *ngFor="let matrix of steckbrief.matrix">{{ matrix.sortierung }} - {{ matrix.bezeichnung }}
            <ng-container *ngIf="matrix.ergaenzung"> ({{ matrix.ergaenzung }})</ng-container>
          </li>
        </ul>
      </div>
    </div>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.meldung.label' | translate }}</label>
      <div class="col-sm-8">
        <ul class="adura-form">
          <ng-container *ngFor="let meldung of steckbrief.meldungen">
            <div class="col-sm-10 clear-padding-left">
              <li>
                <a [routerLink]='["/adura/notification/" + meldung.code]' [innerHTML]="meldung.code + ' - ' + meldung.titel" target="_blank"></a> <br>
                <a *ngIf="meldungHasBasisQuelle(meldung)" href="{{ getMeldungBasisquelleLink(meldung) }}"
                   [innerHTML]="getMeldungBasisquelleTitel(meldung)" target="_blank">
                </a>
              </li>
            </div>
            <div class="col-sm-2">
              <i class="fa fa-eye adura-preview-tooltip">
                <span class="preview-tooltiptext" [innerHTML]="meldung.kurzinfo"></span>
              </i>
            </div>
          </ng-container>
        </ul>
      </div>
    </div>

    <hr>

    <h3>{{ 'adura.status.processControl' | translate }}</h3>
    <div class="row form-element">
      <label class="col-sm-2">{{ 'adura.status.label' | translate }}</label>
      <div class="col-sm-10">
        {{ this.steckbrief.status.messageProperty | translate }}
      </div>
    </div>

    <div class="row">
      <label class="col-sm-2">{{ 'adura.steckbrief.publicVisible' | translate }}</label>
      <div class="col-sm-10">
        <input type="checkbox"
               role="checkbox"
               disabled
               [checked]="steckbrief?.isPublic"/>
      </div>
    </div>
    <app-adura-tracelog [code]="steckbrief.code"></app-adura-tracelog>
  </ng-container>
  <ng-template #steckbriefNotFound>
    <h1>{{ 'adura.steckbrief.notFound' | translate }}</h1>
  </ng-template>
</section>
<app-document-preview-modal [awisaDownload]="false"></app-document-preview-modal>
