import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FavoriteCreateComponent } from '@app/search/favorite-create/favorite-create.component';
import { Link } from '@app/shared/classes/link';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html'
})
export class LinkComponent implements OnInit {

  loading = false;
  guid: string;
  link: Link = new Link();

  @ViewChild(FavoriteCreateComponent) child: FavoriteCreateComponent;

  constructor(private route: ActivatedRoute,
              private awisaFoService: AwisaFoService) {
  }

  ngOnInit(): void {
    this.guid = this.route.snapshot.paramMap.get('id');
    this.awisaFoService.getLink(this.guid)
      .subscribe(
        (result) => {
          console.log(result);
          this.link = result;
        });
  }

  get name() { return (this.link && this.link.name) ? this.link.name : null; }
  get description() { return (this.link && this.link.description) ? this.link.description : null; }
  get url() { return (this.link && this.link.url) ? this.link.url : null; }
  get creationDate() { return (this.link && this.link.creationDate) ? this.link.creationDate : null; }
  get modificationDate() { return (this.link && this.link.modificationDate) ? this.link.modificationDate : null; }
  get dossierLink() { return (this.link && this.link.dossierLink) ? this.link.dossierLink : null; }
  get comments() { return (this.link && this.link.comments) ? this.link.comments : null; }
  get getGuid() { return (this.link && this.link.guid) ? this.link.guid : null; }
  get tags() { return (this.link && this.link.tags) ? this.link.tags : null; }

  openCreateFavorite() {
    this.child.open();
  }
}
