export interface AktivitaetJSON {
  id: number;
  who: string;
  till: Date;
  text: string;
  status: number;
}

export class Aktivitaet {
  id: number;
  who: string;
  till: Date;
  text: string;
  status: number;

  static fromJSON(json: string | object): Aktivitaet {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const aktivitaet = Object.create(Aktivitaet.prototype);
    return Object.assign(aktivitaet, object);
  }

  toJSON(): AktivitaetJSON {
    return Object.assign({}, this);
  }
}
