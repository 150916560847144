export interface CommentJSON {
  guid: string;
  text: string;
  author: string;
  authorGuid: string;
  creationDate: string;
  refGuid: string;
  status: string;
  internalComment: string;
}

export class Comment {
  guid: string;
  text: string;
  author: string;
  authorGuid: string;
  creationDate: string;
  refGuid: string;
  status: string;
  internalComment: string;

  static fromJSON(json: string | object): Comment {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const comment = Object.create(Comment.prototype);
    return Object.assign(comment, json);
  }

  toJSON(): CommentJSON {
    return Object.assign({}, this);
  }
}
