<section class="facet-filter">
  <a role="button" class="facet-date_de-collapse-button facet-toggle collapsed"
     (click)="changeDateCollapseForTablet()"
     [attr.aria-expanded]="activeDateCollapseTablet" aria-controls="facet-date_de-collapse">
    <h2>{{ 'date.event' | translate }}<i class="fa fa-angle-down"></i></h2>
  </a>
  <h2 title="{{ 'date.event' | translate }}" class="facet-header" [attr.aria-label]="'date.event' | translate">{{ 'date.event' | translate }}</h2>
  <div id="facet-date-collapse" class="collapse facet-collapse"
       [ngClass]="(activeDateCollapseTablet)?'in':''">
    <nav [attr.aria-label]="'date.selection' | translate">
      <dp-date-picker class="search-doc-date" aria-hidden="true" placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                      [(ngModel)]="selectedDateFrom" [config]="datePickerConfig"
                      (onSelect)="getDateFrom()" (keyup.enter)="updateEmptyDateData()">
      </dp-date-picker>
      <input (keyup.enter)="getDateFromFromEvent($event)" [ngModel]="selectedDateFrom | date: 'dd.MM.yyyy'"
             placeholder="{{ 'global.from' | translate }}: {{datePickerConfig.format}}" type="text"
             tabindex="-1" class="sr-only" [attr.aria-label]="'date.from' | translate">

      <dp-date-picker aria-hidden="true" placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" theme="dp-material"
                      [(ngModel)]="selectedDateTo" [config]="datePickerConfig"
                      (onSelect)="getDateTo()" (keyup.enter)="updateEmptyDateData()">
      </dp-date-picker>
      <input (keyup.enter)="getDateToFromEvent($event)" [ngModel]="selectedDateTo | date: 'dd.MM.yyyy'"
             placeholder="{{ 'global.to' | translate }}: {{datePickerConfig.format}}" type="text"
             tabindex="-1" class="sr-only" [attr.aria-label]="'global.to' | translate">
    </nav>
  </div>
</section>

