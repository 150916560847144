<div class="container admin">
  <!-- Falls die API-Abfrage einen Fehler liefert, wird der Fehlertext angezeigt -->
  <ng-template [ngIf]="setListError" [ngIfElse]="noError">
    <div class="row">
      <p style="font-weight: bold; color: red;">Fehler bei der Datenabfrage!</p>
    </div>
  </ng-template>
  <ng-template #noError>
    <div class="row admin">
      <p class="admin-warning"><b>Änderungen nur vornehmen, wenn sie auch wissen was sie tun!</b></p>
    </div>
    <app-admin-settings-new (reloadEntries)="reloadEntries()"></app-admin-settings-new>
    <div class="row admin">
      <div class="col-md-2 clear-padding-left"><p class="admin"><b>Key</b></p></div>
      <div class="col-md-3"><p class="admin"><b>Value</b></p></div>
      <div class="col-md-3"><p class="admin"><b>Description</b></p></div>
      <div class="col-md-2"><p class="admin"><b>Letzte Änderung</b></p></div>
      <div class="col-md-2"></div>
    </div>
    <div class="row admin" *ngFor="let set of settings; let i = index"
         app-admin-settings-entry
         [set]="set"
         (deleted)="onSettingDeleted(i)">
    </div>
  </ng-template>
</div>
