<a href="{{ link }}" target="_blank">{{ pubDetail.publikation.titel }}</a>
<ng-container *ngIf="pubDetailType === 10 && pubDetail.pageCount">{{ '. ' + pubDetail.pageCount }}
  <ng-template
    *ngIf="pubDetail.pageCount === 1;then singular; else plural">
  </ng-template>
  <ng-template #singular>
    {{ 'adura.publication.page.label'  | translate }}.
  </ng-template>
  <ng-template #plural>
    {{ 'adura.publication.page.plural'  | translate }}.
  </ng-template>
</ng-container><span *ngIf="pubDetailType === 10"> ({{ pubDetail.date }}).</span>
