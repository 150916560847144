import { HttpParams } from '@angular/common/http';

interface IAduraSearchParams {
  term?: string;
  sort?: string;
  steckbriefFilter?: string[];
  meldungFilter?: string[];
  gefahr?: string[];
  matrix?: string[];
  treiber?: string[];
  bereich?: string[];
  assessmentType?: string[];
  assessmentDateFrom?: string;
  assessmentDateTo?: string;
  seismoPub?: string[];
  seismoInfo?: string[];
  status?: string[];
  isPublic?: string[];
  priority?: string[];
  createdDateFrom?: string;
  createdDateTo?: string;
  changedDateFrom?: string;
  changedDateTo?: string;
  nextEvaluationFrom?: string;
  nextEvaluationTo?: string;
  nextEvaluationNull?: string;
  activityStatus?: string[];
  activityDateFrom?: string;
  activityDateTo?: string;
  activityDateNull?: string;
  missingReqFields?: boolean;
  offset?: number;
  scrollTab?: string;
}

export class AduraSearchParams implements IAduraSearchParams {
  constructor(public term?: string,
              public sort?: string,
              public steckbriefFilter?: string[],
              public meldungFilter?: string[],
              public gefahr?: string[],
              public matrix?: string[],
              public treiber?: string[],
              public bereich?: string[],
              public assessmentType?: string[],
              public assessmentDateFrom?: string,
              public assessmentDateTo?: string,
              public seismoPub?: string[],
              public seismoInfo?: string[],
              public status?: string[],
              public isPublic?: string[],
              public priority?: string[],
              public createdDateFrom?: string,
              public createdDateTo?: string,
              public changedDateFrom?: string,
              public changedDateTo?: string,
              public nextEvaluationFrom?: string,
              public nextEvaluationTo?: string,
              public nextEvaluationNull?: string,
              public activityStatus?: string[],
              public activityDateFrom?: string,
              public activityDateTo?: string,
              public activityDateNull?: string,
              public missingReqFields?: boolean,
              public offset?: number,
              public scrollTab?: string) {
  }

  static fromObject(obj: IAduraSearchParams): AduraSearchParams {
    return Object.assign(new AduraSearchParams(), obj);
  }

  getHttpParams(): HttpParams {
    let params = new HttpParams();

    if (this.term !== undefined) {
      params = params.append('term', this.term);
    }
    if (this.sort !== undefined) {
      params = params.append('sort', this.sort);
    }
    if (this.steckbriefFilter?.length > 0) {
      this.steckbriefFilter.forEach(entry => {
        params = params.append('factsheetFilter', entry);
      });
    }
    if (this.meldungFilter?.length > 0) {
      this.meldungFilter.forEach(entry => {
        params = params.append('notificationFilter', entry);
      });
    }
    if (this.gefahr?.length > 0) {
      this.gefahr.forEach(entry => {
        params = params.append('danger', entry);
      });
    }
    if (this.matrix?.length > 0) {
      this.matrix.forEach(entry => {
        params = params.append('matrix', entry);
      });
    }
    if (this.treiber?.length > 0) {
      this.treiber.forEach(entry => {
        params = params.append('driver', entry);
      });
    }
    if (this.bereich?.length > 0) {
      this.bereich.forEach(entry => {
        params = params.append('domain', entry);
      });
    }
    if (this.assessmentType?.length > 0) {
      this.assessmentType.forEach(entry => {
        params = params.append('assessmentType', entry);
      });
    }
    if (this.assessmentDateFrom !== undefined) {
      params = params.append('assessmentDateFrom', this.assessmentDateFrom);
    }
    if (this.assessmentDateTo !== undefined) {
      params = params.append('assessmentDateTo', this.assessmentDateTo);
    }
    if (this.seismoPub?.length > 0) {
      this.seismoPub.forEach(entry => {
        params = params.append('seismoPub', entry);
      });
    }
    if (this.seismoInfo?.length > 0) {
      this.seismoInfo.forEach(entry => {
        params = params.append('seismoInfo', entry);
      });
    }
    if (this.status?.length > 0) {
      this.status.forEach(entry => {
        params = params.append('status', entry);
      });
    }
    if (this.isPublic?.length > 0) {
      this.isPublic.forEach(entry => {
        params = params.append('isPublic', entry);
      });
    }
    if (this.priority?.length > 0) {
      this.priority.forEach(entry => {
        params = params.append('priority', entry);
      });
    }
    if (this.createdDateFrom !== undefined) {
      params = params.append('createdDateFrom', this.createdDateFrom);
    }
    if (this.createdDateTo !== undefined) {
      params = params.append('createdDateTo', this.createdDateTo);
    }
    if (this.changedDateFrom !== undefined) {
      params = params.append('changedDateFrom', this.changedDateFrom);
    }
    if (this.changedDateTo !== undefined) {
      params = params.append('changedDateTo', this.changedDateTo);
    }
    if (this.nextEvaluationFrom !== undefined) {
      params = params.append('nextEvaluationFrom', this.nextEvaluationFrom);
    }
    if (this.nextEvaluationTo !== undefined) {
      params = params.append('nextEvaluationTo', this.nextEvaluationTo);
    }
    if (this.nextEvaluationNull !== undefined) {
      params = params.append('nextEvaluationNull', this.nextEvaluationNull);
    }
    if (this.activityStatus?.length > 0) {
      this.activityStatus.forEach(entry => {
        params = params.append('activityStatus', entry);
      });
    }
    if (this.activityDateFrom !== undefined) {
      params = params.append('activityDateFrom', this.activityDateFrom);
    }
    if (this.activityDateTo !== undefined) {
      params = params.append('activityDateTo', this.activityDateTo);
    }
    if (this.activityDateNull !== undefined) {
      params = params.append('activityDateNull', this.activityDateNull);
    }
    if (this.missingReqFields) {
      params = params.append('readyForFreigabe', !this.missingReqFields);
    }
    if (this.offset !== undefined) {
      params = params.append('offset', this.offset.toString());
    }
    if (this.scrollTab !== undefined) {
      params = params.append('scrollTab', this.scrollTab);
    }

    // Timestamp wird angehängt, damit nicht auf den Cache zurückgegriffen wird
    params = params.append('time', Date.now().toString());

    return params;
  }
}
