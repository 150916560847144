import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tab',
  template: `
    <div [hidden]="!active"
         role="tab"
         [attr.aria-selected]="active"
         [attr.aria-controls]="tabTitle"
         class="{{this.contentClass ? this.contentClass : 'tabmenu-content-framed'}}">
      <ng-content></ng-content>
    </div>
  `
})
export class TabComponent {
  @Input() tabIcon: string;
  @Input() tabTitle: string;
  @Input() plainTabTitle: string;
  @Input() tabId: string;
  @Input() contentLength: string;
  @Input() active = false;
  @Input() autoWidth = false;
  @Input() contentClass = null;

  getTabTitle() {
    return (this.tabIcon || '') + this.tabTitle
      + ' <span class="badge badge-search-tab">' + (this.contentLength === undefined ? '' : this.contentLength) + '</span>';
  }
}
