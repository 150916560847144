<section class="facet-filter">
  <h3 class="facet-subtitle" attr.aria-label="{{ 'adura.priority.label' | translate }}">{{ 'adura.priority.label' | translate }}</h3>
  <div>
    <ng-container *ngIf="!this.priority || this.priority.length === 0">
      <p><small>{{ 'search.noFilter' | translate }}</small></p>
    </ng-container>
    <nav [attr.aria-label]="'adura.priority.list' | translate">
      <div class="list-inline tags">
        <ng-container *ngFor="let state of priority">
          <div class="flex-container">
            <input type="checkbox" class="filter-checkbox sr-only" id="priority_{{state.id}}"
                   (click)="changePriority('priority_'+ state.id)"
                   [checked]="isPriorityActive(state.id)">
            <label class="filter-checkbox-label" [ngClass]="(state.count === 0)?'filter-element-background':''"
                   for="priority_{{state.id}}"
                   role="checkbox"
                   [attr.aria-label]="('adura.priority.label' | translate) + ': ' + (state.name | translate) + ', ' +
                   ('global.total' | translate) + ': ' + state.count"
                   [attr.aria-checked]="(isPriorityActive(state.id))?'true':'false'">
              {{ state.name | translate }}
            </label>
            <span class="filter-checkbox-number" aria-hidden="true">{{ state.count}}</span>
          </div>
        </ng-container>
      </div>
    </nav>
  </div>
</section>
