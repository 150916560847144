<section class="facet-filter">
  <a role="button" class="facet-formats_de-collapse-button facet-toggle collapsed"
     (click)="changeFormatCollapseForTablet()"
     [attr.aria-expanded]="activeFormatCollapseTablet" aria-controls="facet-formats_de-collapse">
    <h2>{{ 'format.document' | translate }}<i class="fa fa-angle-down"></i></h2>
  </a>
  <h2 class="facet-header" attr.aria-label="{{ 'format.document' | translate }}">{{ 'format.document' | translate }}</h2>
  <div id="facet-formats_de-collapse" class="collapse facet-collapse"
       [ngClass]="(activeFormatCollapseTablet)?'in':''">
    <ng-container *ngIf="!this.formats || this.formats.length === 0">
      <p><small>{{ 'search.noFilter' | translate }}</small></p>
    </ng-container>
    <nav [attr.aria-label]="'format.list' | translate">
      <div class="list-inline tags">
        <ng-container *ngFor="let format of formats">
          <div class="flex-container">
            <input type="checkbox" class="filter-checkbox sr-only" id="{{format.name}}"
                   (click)="changeFormat(format.name)"
                   [checked]="isFormatActive(format.name)">
            <label class="filter-checkbox-label" [ngClass]="(format.count === 0)?'filter-element-background':''"
                   for="{{format.name}}"
                   role="checkbox"
                   [attr.aria-label]="('format.label' | translate) + ': ' + format.name + ', ' +
                   ('global.total' | translate) + ': ' + format.count"
                   [attr.aria-checked]="(isFormatActive(format.name))?'true':'false'">
              {{ format.name }}
            </label>
            <span class="filter-checkbox-number" aria-hidden="true">{{format.count}}</span>
          </div>
        </ng-container>
      </div>
    </nav>
  </div>
</section>
