import { Component, OnInit } from '@angular/core';
import { AduraService } from '@app/shared/services/adura.service';
import { TreiberAdmin } from '@app/shared/classes/adura/treiber';

@Component({
  selector: 'app-adura-admin-stammdaten-treiber',
  templateUrl: './adura-admin-stammdaten-treiber.component.html'
})
export class AduraAdminStammdatenTreiberComponent implements OnInit {
  treiber: TreiberAdmin[] = [];
  setListError = false;

  constructor(private aduraService: AduraService) {
  }

  ngOnInit(): void {
    this.aduraService.listTreiberAdmin()
      .subscribe({
        next: result => {
          this.treiber = result;
          this.setListError = false;
        },
        error: error => {
          this.setListError = true;
          console.error(error);
        }
      });
  }

  onTreiberDeleted(index: number) {
    this.treiber.splice(index, 1);
  }

  reloadEntries(): void {
    this.ngOnInit();
  }
}
