import { Component, OnInit } from '@angular/core';
import { Settings } from '@app/core/config/settings';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html'
})
export class AdminSettingsComponent implements OnInit {
  settings: Settings[] = [];
  setListError = false;

  constructor(private awisaFoService: AwisaFoService) {
  }

  ngOnInit(): void {
    this.awisaFoService.listSettings()
      .subscribe({
        next: result => {
          this.settings = result;
          this.setListError = false;
        },
        error: error => {
          this.setListError = true;
          console.log(error);
        }
      });
  }

  onSettingDeleted(index: number) {
    this.settings.splice(index, 1);
  }

  reloadEntries(): void {
    this.ngOnInit();
  }
}
