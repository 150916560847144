import { inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { AdminComponent } from '@app/admin/admin.component';
import { DisclaimerComponent } from '@app/disclaimer/disclaimer.component';
import { DocumentNewComponent } from '@app/document/document-new/document-new.component';
import { DocumentComponent } from '@app/document/document.component';
import { EventNewComponent } from '@app/event/event-new/event-new.component';
import { EventComponent } from '@app/event/event.component';
import { FavoriteEditComponent } from '@app/home/favorite/favorite-edit/favorite-edit.component';
import { ForbiddenComponent } from '@app/layouts/errors/forbidden/forbidden.component';
import { NotFoundComponent } from '@app/layouts/errors/not-found/not-found.component';
import { UnauthorizedComponent } from '@app/layouts/errors/unauthorized/unauthorized.component';
import { LinkNewComponent } from '@app/link/link-new/link-new.component';
import { LinkComponent } from '@app/link/link.component';
import { ThemaComponent } from '@app/thema/thema.component';
import { ThemenwordTestComponent } from '@app/themenword-test/themenword-test.component';
import { HomeComponent } from './home/home.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { SearchComponent } from './search/search.component';
import { AduraMeldungComponent } from '@app/adura/meldung/adura-meldung-component';
import { AduraMeldungEditComponent } from '@app/adura/meldung/adura-meldung-edit/adura-meldung-edit.component';
import { AduraAdminComponent } from '@app/adura/admin/adura-admin.component';
import { AduraSearchComponent } from '@app/adura/search/adura-search.component';
import { AduraSteckbriefEditComponent } from '@app/adura/steckbrief/adura-steckbrief-edit/adura-steckbrief-edit.component';
import { AduraSteckbriefComponent } from '@app/adura/steckbrief/adura-steckbrief-component';
import { AuthorizationService } from '@app/core/auth/authorization.service';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { NavigationService } from '@app/core/routing/navigation/navigation.service';

const routes: Routes = [
  {
    path: '', component: MainLayoutComponent,
    children: [
      // Error routes
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: 'forbidden', component: ForbiddenComponent },
      { path: 'not-found', component: NotFoundComponent },
      { path: 'disclaimer', component: DisclaimerComponent },
      // Awisa
      {
        path: '',
        canActivateChild: [
          ()                              => inject(AuthenticationService).redirectAduraUser(),
          (route: ActivatedRouteSnapshot) => inject(AuthenticationService).canActivate(route),
          (route: ActivatedRouteSnapshot) => inject(AuthorizationService).canActivate(route),
          (route: ActivatedRouteSnapshot) => inject(AuthenticationService).checkDisclaimer(route)
        ],
        children: [
          { path: '', redirectTo: '/home', pathMatch: 'full' },
          { path: 'home', component: HomeComponent},
          { path: 'search', component: SearchComponent, data: { reuseRoute: true, roles: ['ROLE_INHALT'] } },
          { path: 'search/:category', component: SearchComponent, data: { reuseRoute: true, roles: ['ROLE_INHALT'] } },
          { path: 'subject/test', component: ThemenwordTestComponent, data: { roles: ['ROLE_INHALT'] } },
          { path: 'subject/:id', component: ThemaComponent, data: { roles: ['ROLE_INHALT'] } },
          { path: 'document/new', component: DocumentNewComponent, data: { roles: ['ROLE_INHALT'] } },
          { path: 'document/:id', component: DocumentComponent, data: { roles: ['ROLE_INHALT'] } },
          { path: 'event/new', component: EventNewComponent, data: { roles: ['ROLE_INHALT'] } },
          { path: 'event/:id', component: EventComponent, data: { roles: ['ROLE_INHALT'] } },
          { path: 'link/new', component: LinkNewComponent, data: { roles: ['ROLE_INHALT'] } },
          { path: 'link/:id', component: LinkComponent, data: { roles: ['ROLE_INHALT'] } },
          { path: 'favorite/:id', component: FavoriteEditComponent, data: { roles: ['ROLE_INHALT'] } },
          { path: 'admin', component: AdminComponent, data: { roles: ['ADMIN_AWISA_ADMIN'] } },
          { path: 'admin/:category', component: AdminComponent, data: { roles: ['ADMIN_AWISA_ADMIN'] } },
        ]
      },
      // Adura
      {
        path: 'adura',
        canActivateChild: [
          (route: ActivatedRouteSnapshot) => inject(AuthenticationService).canActivate(route),
          (route: ActivatedRouteSnapshot) => inject(AuthorizationService).canActivate(route),
          (route: ActivatedRouteSnapshot) => inject(AuthenticationService).checkDisclaimer(route)
        ],
        children: [
          { path: '', redirectTo: '/adura/search/factsheet', pathMatch: 'full' },
          { path: 'search', component: AduraSearchComponent, data: { reuseRoute: true, roles: ['ROLE_ADURA'] } },
          { path: 'search/:category', component: AduraSearchComponent, data: { reuseRoute: true, roles: ['ROLE_ADURA'] } },
          { path: 'notification/new', component: AduraMeldungEditComponent, data: { roles: ['ROLE_ADURA'] },
            canDeactivate: [(comp: AduraMeldungEditComponent) => comp.canDeactivate() || inject(NavigationService).confirmLeave()] },
          { path: 'notification/:code', component: AduraMeldungComponent, data: { roles: ['ROLE_ADURA'] } },
          { path: 'notification/:code/edit', component: AduraMeldungEditComponent, data: { roles: ['ROLE_ADURA'] },
            canDeactivate: [(comp: AduraMeldungEditComponent) => comp.canDeactivate() || inject(NavigationService).confirmLeave()] },
          { path: 'factsheet/new', component: AduraSteckbriefEditComponent, data: { roles: ['ROLE_ADURA'] },
            canDeactivate: [(comp: AduraSteckbriefEditComponent) => comp.canDeactivate() || inject(NavigationService).confirmLeave()] },
          { path: 'factsheet/:code', component: AduraSteckbriefComponent, data: { roles: ['ROLE_ADURA'] } },
          { path: 'factsheet/:code/edit', component: AduraSteckbriefEditComponent, data: { roles: ['ROLE_ADURA'] },
            canDeactivate: [(comp: AduraSteckbriefEditComponent) => comp.canDeactivate() || inject(NavigationService).confirmLeave()] },
          { path: 'admin', component: AduraAdminComponent, data: { roles: ['AWISA_FRESIL', 'ADMIN_AWISA_ADMIN'] } },
          { path: 'admin/:category', component: AduraAdminComponent, data: { roles: ['AWISA_FRESIL', 'ADMIN_AWISA_ADMIN'] } }
        ]
      },

      // Wildcard route to redirect to not found page
      { path: '**', component: NotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
