import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-thema-link',
  template: '<a [routerLink]="[linkHref]">{{ linkText }}</a>'
})

export class ThemaLinkComponent {

  @Input() linkHref: string;
  @Input() linkText: string;
}
