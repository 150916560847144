import { Component, ElementRef, EventEmitter, Input, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpResponse } from '@angular/common/http';
import { AwisaFoService } from '@app/shared/services/awisa-fo.service';
import { saveAs } from 'file-saver';
import { AduraService } from '@app/shared/services/adura.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-document-preview-modal',
  templateUrl: './document-preview-modal.component.html'
})
export class DocumentPreviewModalComponent {
  @ViewChild('documentPreviewModal', {static: false}) documentPreviewModal: ElementRef;
  @ViewChild('documentPreviewModalBody', {static: false}) documentPreviewModalBody: ElementRef;
  @ViewChildren('previewImage') imageElements!: QueryList<any>;
  @Input() awisaDownload = true;
  @Output() download = new EventEmitter();
  pngImageStrings: string[] = [];
  htmlString: string;
  id: number;
  guid: string;
  name: string;
  error = false;

  constructor(private domSanitizer: DomSanitizer,
              private renderer: Renderer2,
              private awisaFoService: AwisaFoService,
              private aduraService: AduraService) {}

  open(response: any, name: string): void {
    if (response.body?.previewData) {
      response.body.previewData.forEach(data => {
        if (data.startsWith('data:image/png;base64,')) {
          this.htmlString = undefined;
          this.pngImageStrings.push(data);
        } else if (data.startsWith('data:text/html;base64,')) {
          this.htmlString = data;
        }
      });
      this.id = response.body?.id;
      this.guid = response.body?.guid;
    } else {
      this.error = true;
    }
    this.name = name;
    this.renderer.addClass(
      this.documentPreviewModal.nativeElement, 'show'
    );
    this.renderer.setStyle(
      this.documentPreviewModal.nativeElement, 'width', '100vw'
    );
  }

  close() {
    this.renderer.removeClass(
      this.documentPreviewModal.nativeElement, 'show'
    );
    this.pngImageStrings = [];
    this.error = false;
    setTimeout(() => {
      this.renderer.setStyle(
        this.documentPreviewModal.nativeElement, 'width', '0'
      );
    }, 75);
  }

  zoomIn() {
    this.renderer.removeClass(this.documentPreviewModalBody.nativeElement, 'document-preview-width-50');
    this.imageElements.forEach(image => {
        if (image.nativeElement.height > image.nativeElement.width) {
          this.renderer.setStyle(image.nativeElement, 'height', '100%');
        } else {
          this.renderer.setStyle(image.nativeElement, 'height', '100vh');
        }
      }
    );
  }

  zoomOut() {
    this.renderer.addClass(this.documentPreviewModalBody.nativeElement, 'document-preview-width-50');
    this.imageElements.forEach(image =>
      this.renderer.setStyle(image.nativeElement, 'height', '75vh')
    );
  }

  showDownload(): boolean {
    return !!(this.awisaDownload && this.guid || !this.awisaDownload && this.id || this.download.observed);
  }

  getDocument() {
    this.download.emit(this.awisaDownload ? this.guid : this.id);
    if (this.awisaDownload && this.guid) {
      this.handleDownload(this.awisaFoService.getDocument(this.guid));
    }
    if (!this.awisaDownload && this.id) {
      this.handleDownload(this.aduraService.getSteckbriefDocument(this.id));
    }
  }

  private handleDownload(request: Observable<any>): void {
    request.subscribe({
      next: (response: HttpResponse<Blob>) => {
        const blob = new Blob([response.body], {type: response.headers.get('Content-Type')});
        const contentDisposition: string = response.headers.get('content-disposition');
        const r = /filename="(.+)"/;
        const filename = r.exec(contentDisposition)[1];
        console.log(filename);
        saveAs(blob, filename);
        this.close();
      },
      error: e => console.error(e)
    });
  }
}
