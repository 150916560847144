import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Facet } from '@app/shared/classes/facet';

@Component({
  selector: 'app-status-side',
  templateUrl: './status-side.component.html'
})
export class StatusSideComponent {
  @Input() status: Facet[] = [];
  @Input() activeStatus: string[] = [];
  @Output() statusChanged = new EventEmitter<string[]>();

  activeStatusCollapseTablet = false;

  isStatusActive(guid: string) {
    return this.activeStatus.find(res => res === guid);
  }

  changeStatus(guid: string) {
    const newActiveStatus = this.activeStatus.slice();

    const index = newActiveStatus.indexOf(guid);
    if (index > -1) {
      newActiveStatus.splice(index, 1);
    } else {
      newActiveStatus.push(guid);
    }

    this.statusChanged.emit(newActiveStatus);
  }

  changeStatusCollapseForTablet() {
    this.activeStatusCollapseTablet = !this.activeStatusCollapseTablet;
  }
}
