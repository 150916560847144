export interface TagJSON {
  guid: string;
  name: string;
}

export class Tag {
  guid: string;
  name: string;

  static fromJSON(json: string | object): Document {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const tag = Object.create(Tag.prototype);
    return Object.assign(tag, json);
  }

  toJSON(): TagJSON {
    return Object.assign({}, this);
  }
}
