import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { TabChangeEvent } from '@app/shared/tabs/tabs.component';
import { AduraService } from '@app/shared/services/adura.service';
import { AduraSearchResult } from '@app/shared/classes/adura/adura-search-result';
import { AduraSearchParams } from '@app/shared/classes/adura/adura-search-params';
import { ChangedDateChangedEvent, CreatedDateChangedEvent, NextEvaluationChangedEvent } from '@app/adura/search/date-side/adura-date-side.component';
import { BeurteilungDateChangedEvent } from '@app/adura/search/beurteilung-side/adura-beurteilung-side.component';
import { AktivitaetDateChangedEvent } from '@app/adura/search/aktivitaet-date-side/adura-aktivitaet-side.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-adura-search',
  templateUrl: './adura-search.component.html'
})
export class AduraSearchComponent implements OnInit {

  sucheForm: UntypedFormGroup;
  suchResultat: AduraSearchResult;

  term = '';
  meldungFilter: string[];
  steckbriefFilter: string[];
  gefahr: string[];
  matrix: string[];
  treiber: string[];
  bereich: string[];
  beurteilungType: string[];
  seismoPub: string[];
  seismoInfo: string[];
  status: string[];
  isPublic: string[];
  priority: string[];
  beurteilungDateFrom = '';
  beurteilungDateTo = '';
  createdDateFrom = '';
  createdDateTo = '';
  changedDateFrom = '';
  changedDateTo = '';
  nextEvaluationFrom = '';
  nextEvaluationTo = '';
  nextEvaluationNull = '';
  aktivitaetStatus: string[];
  aktivitaetDateFrom = '';
  aktivitaetDateTo = '';
  aktivitaetDateNull = '';
  osMeldung = 30;
  osSteckbrief = 30;
  osSignal = 30;
  currentCategory: string;
  showSpinner = false;

  sortList: string[] = [
    'relevance',
    'titleAsc',
    'titleDesc',
    'lastModified',
    'firstModified',
    'lastCreated',
    'firstCreated'];
  sort = 'relevance';

  @Output() searchTermChanged = new EventEmitter<string>();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private aduraService: AduraService,
              private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(() => {
      this.term = this.route.snapshot.queryParams.term;
      if (this.route.snapshot.queryParams.sort !== undefined) {
        this.sort = this.route.snapshot.queryParams.sort;
      }
      this.meldungFilter = this.getArray(this.route.snapshot.queryParams.notificationFilter);
      this.steckbriefFilter = this.getArray(this.route.snapshot.queryParams.factsheetFilter);
      this.gefahr = this.getArray(this.route.snapshot.queryParams.danger);
      this.matrix = this.getArray(this.route.snapshot.queryParams.matrix);
      this.treiber = this.getArray(this.route.snapshot.queryParams.driver);
      this.bereich = this.getArray(this.route.snapshot.queryParams.domain);
      this.beurteilungType = this.getArray(this.route.snapshot.queryParams.assessmentType);
      this.beurteilungDateFrom = this.route.snapshot.queryParams.assessmentDateFrom;
      this.beurteilungDateTo = this.route.snapshot.queryParams.assessmentDateTo;
      this.seismoPub = this.getArray(this.route.snapshot.queryParams.seismoPub);
      this.seismoInfo = this.getArray(this.route.snapshot.queryParams.seismoInfo);
      this.status = this.getArray(this.route.snapshot.queryParams.status);
      this.isPublic = this.getArray(this.route.snapshot.queryParams.isPublic);
      this.priority = this.getArray(this.route.snapshot.queryParams.priority);
      this.createdDateFrom = this.route.snapshot.queryParams.createdDateFrom;
      this.createdDateTo = this.route.snapshot.queryParams.createdDateTo;
      this.changedDateFrom = this.route.snapshot.queryParams.changedDateFrom;
      this.changedDateTo = this.route.snapshot.queryParams.changedDateTo;
      this.nextEvaluationFrom = this.route.snapshot.queryParams.nextEvaluationFrom;
      this.nextEvaluationTo = this.route.snapshot.queryParams.nextEvaluationTo;
      this.nextEvaluationNull = this.route.snapshot.queryParams.nextEvaluationNull;
      this.aktivitaetStatus = this.getArray(this.route.snapshot.queryParams.activityStatus);
      this.aktivitaetDateFrom = this.route.snapshot.queryParams.activityDateFrom;
      this.aktivitaetDateTo = this.route.snapshot.queryParams.activityDateTo;
      this.aktivitaetDateNull = this.route.snapshot.queryParams.activityDateNull;

      this.currentCategory = this.route.snapshot.params.category || 'factsheet';

      this.searchDocuments();
    });

    this.route.params.subscribe(() => {
      this.currentCategory = this.route.snapshot.params.category || 'factsheet';
    });

    this.sucheForm = this.formBuilder.group({
      term: this.term
    });
  }

  onSubmitSuche() {
    const sucheFormValues = this.sucheForm.value;
    const term = sucheFormValues.term;

    console.log(term);

    this.router.navigate(['/adura/search/' + this.currentCategory], {
      queryParams: {
        term
      },
      queryParamsHandling: 'merge',
    });
  }

  onDowloadExcelExport() {
    const sucheFormValues = this.sucheForm.value;
    const term = sucheFormValues.term;

    console.log(term);
    console.log(sucheFormValues);

    this.showSpinner = true;

    const searchParams = AduraSearchParams.fromObject({
      term: this.term,
      sort: this.sort,
      steckbriefFilter: this.steckbriefFilter,
      meldungFilter: this.meldungFilter,
      gefahr: this.gefahr,
      matrix: this.matrix,
      treiber: this.treiber,
      bereich: this.bereich,
      assessmentType: this.beurteilungType,
      assessmentDateFrom: this.beurteilungDateFrom,
      assessmentDateTo: this.beurteilungDateTo,
      seismoPub: this.seismoPub,
      seismoInfo: this.seismoInfo,
      status: this.status,
      isPublic: this.isPublic,
      priority: this.priority,
      createdDateFrom: this.createdDateFrom,
      createdDateTo: this.createdDateTo,
      changedDateFrom: this.changedDateFrom,
      changedDateTo: this.changedDateTo,
      nextEvaluationFrom: this.nextEvaluationFrom,
      nextEvaluationTo: this.nextEvaluationTo,
      nextEvaluationNull: this.nextEvaluationNull,
      activityStatus: this.aktivitaetStatus,
      activityDateFrom: this.aktivitaetDateFrom,
      activityDateTo: this.aktivitaetDateTo,
      activityDateNull: this.aktivitaetDateNull
    });

    this.aduraService.getExcel(searchParams.getHttpParams()).subscribe({
      next: (response: HttpResponse<Blob>) => {
        const blob = new Blob([response.body], { type: response.headers.get('Content-Type') });
        const contentDisposition: string = response.headers.get('content-disposition');
        const r = /(?:filename=")(.+)(?:")/;
        const filename = r.exec(contentDisposition)[1];
        console.log(filename);
        saveAs(blob, filename);
        this.showSpinner = false;
      },
      error: e => console.log(e)
    });
  }

  resetSearch() {
    this.sucheForm.reset();

    this.router.navigate([], {
      queryParams: {
        term: '',
        factsheetFilter: undefined,
        notificationFilter: undefined
      },
      queryParamsHandling: 'merge',
    });
  }

  searchDocuments() {
    this.showSpinner = true;

    const searchParams = AduraSearchParams.fromObject({
      term: this.term,
      sort: this.sort,
      steckbriefFilter: this.steckbriefFilter,
      meldungFilter: this.meldungFilter,
      gefahr: this.gefahr,
      matrix: this.matrix,
      treiber: this.treiber,
      bereich: this.bereich,
      assessmentType: this.beurteilungType,
      assessmentDateFrom: this.beurteilungDateFrom,
      assessmentDateTo: this.beurteilungDateTo,
      seismoPub: this.seismoPub,
      seismoInfo: this.seismoInfo,
      status: this.status,
      isPublic: this.isPublic,
      priority: this.priority,
      createdDateFrom: this.createdDateFrom,
      createdDateTo: this.createdDateTo,
      changedDateFrom: this.changedDateFrom,
      changedDateTo: this.changedDateTo,
      nextEvaluationFrom: this.nextEvaluationFrom,
      nextEvaluationTo: this.nextEvaluationTo,
      nextEvaluationNull: this.nextEvaluationNull,
      activityStatus: this.aktivitaetStatus,
      activityDateFrom: this.aktivitaetDateFrom,
      activityDateTo: this.aktivitaetDateTo,
      activityDateNull: this.aktivitaetDateNull,
    });

    this.aduraService.search(searchParams.getHttpParams()).subscribe({
      next: result => {
        this.suchResultat = result;
        this.showSpinner = false;
      },
      error: () => this.showSpinner = false
    });
  }

  searchDocumentsOffset(offset: number, scrollTab: string) {
    const searchParams = AduraSearchParams.fromObject({
      term: this.term,
      sort: this.sort,
      steckbriefFilter: this.steckbriefFilter,
      meldungFilter: this.meldungFilter,
      gefahr: this.gefahr,
      matrix: this.matrix,
      treiber: this.treiber,
      bereich: this.bereich,
      assessmentType: this.beurteilungType,
      assessmentDateFrom: this.beurteilungDateFrom,
      assessmentDateTo: this.beurteilungDateTo,
      seismoPub: this.seismoPub,
      seismoInfo: this.seismoInfo,
      status: this.status,
      isPublic: this.isPublic,
      priority: this.priority,
      createdDateFrom: this.createdDateFrom,
      createdDateTo: this.createdDateTo,
      changedDateFrom: this.changedDateFrom,
      changedDateTo: this.changedDateTo,
      nextEvaluationFrom: this.nextEvaluationFrom,
      nextEvaluationTo: this.nextEvaluationTo,
      nextEvaluationNull: this.nextEvaluationNull,
      activityStatus: this.aktivitaetStatus,
      activityDateFrom: this.aktivitaetDateFrom,
      activityDateTo: this.aktivitaetDateTo,
      activityDateNull: this.aktivitaetDateNull,
      offset,
      scrollTab
    });

    return this.aduraService.search(searchParams.getHttpParams());
  }

  get totalCount() {
    return (this.suchResultat && this.suchResultat.totalCount) ? this.suchResultat.totalCount : null;
  }

  get meldungen() {
    return (this.suchResultat && this.suchResultat.meldungen) ? this.suchResultat.meldungen : null;
  }

  get meldungLength() {
    return (this.suchResultat) ? this.suchResultat.meldungCount : null;
  }

  get steckbriefe() {
    return (this.suchResultat && this.suchResultat.steckbriefe) ? this.suchResultat.steckbriefe : null;
  }

  get steckbriefLength() {
    return (this.suchResultat) ? this.suchResultat.steckbriefCount : null;
  }

  get signale() {
    return (this.suchResultat && this.suchResultat.signale) ? this.suchResultat.signale : null;
  }

  get signalLength() {
    return (this.suchResultat) ? this.suchResultat.signalCount : null;
  }

  get meldungFilterFacets() {
    return (this.suchResultat && this.suchResultat.meldungFilterFacets) ? this.suchResultat.meldungFilterFacets : null;
  }

  get steckbriefFilterFacets() {
    return (this.suchResultat && this.suchResultat.steckbriefFilterFacets) ? this.suchResultat.steckbriefFilterFacets : null;
  }

  get gefahrFacets() {
    return (this.suchResultat && this.suchResultat.gefahrFacets) ? this.suchResultat.gefahrFacets : null;
  }

  get matrixFacets() {
    return (this.suchResultat && this.suchResultat.matrixFacets) ? this.suchResultat.matrixFacets : null;
  }

  get treiberFacets() {
    return (this.suchResultat && this.suchResultat.treiberFacets) ? this.suchResultat.treiberFacets : null;
  }

  get bereichFacets() {
    return (this.suchResultat && this.suchResultat.bereichFacets) ? this.suchResultat.bereichFacets : null;
  }

  get aktivitaetStatusFacets() {
    return (this.suchResultat && this.suchResultat.activityStatusFacets) ? this.suchResultat.activityStatusFacets : null;
  }

  get beurteilungTypeFacets() {
    return (this.suchResultat && this.suchResultat.beurteilungTypeFacets) ? this.suchResultat.beurteilungTypeFacets : null;
  }

  get seismoPubFacets() {
    return (this.suchResultat && this.suchResultat.seismoPubFacets) ? this.suchResultat.seismoPubFacets : null;
  }

  get seismoInfoFacets() {
    return (this.suchResultat && this.suchResultat.seismoInfoFacets) ? this.suchResultat.seismoInfoFacets : null;
  }

  get statusFacets() {
    return (this.suchResultat && this.suchResultat.statusFacets) ? this.suchResultat.statusFacets : null;
  }

  get isPublicFacets() {
    return (this.suchResultat && this.suchResultat.isPublicFacets) ? this.suchResultat.isPublicFacets : null;
  }

  get priorityFacets() {
    return (this.suchResultat && this.suchResultat.priorityFacets) ? this.suchResultat.priorityFacets : null;
  }

  get hasMoreMeldungen() {
    return (this.suchResultat && this.suchResultat.hasMoreMeldungen) ? this.suchResultat.hasMoreMeldungen : false;
  }

  get hasMoreSteckbriefe() {
    return (this.suchResultat && this.suchResultat.hasMoreSteckbriefe) ? this.suchResultat.hasMoreSteckbriefe : false;
  }

  get hasMoreSignale() {
    return (this.suchResultat && this.suchResultat.hasMoreSignale) ? this.suchResultat.hasMoreSignale : false;
  }

  sortSearchResult(sortChoice) {
    this.sort = sortChoice;
    const queryParams = { sort: this.sort };

    this.router.navigate([], {
      queryParams,
      relativeTo: this.route,
      queryParamsHandling: 'merge'
    });
  }

  getArray(suchfilter: string) {
    if (suchfilter !== undefined) {
      return [].concat(suchfilter);
    } else {
      return [];
    }
  }

  resetFilter() {
    console.log('volltext zurücksetzen');
    const term = this.route.snapshot.queryParams.term;
    this.router.navigate(['/adura/search', this.currentCategory], {
      queryParams: { term }
    });
  }

  nextTranche(tab: string) {
    switch (tab) {
      case 'notification': {
        this.searchDocumentsOffset(this.osMeldung, tab).subscribe(result => {
          this.suchResultat.meldungen = this.suchResultat.meldungen.concat(result.meldungen);
          this.suchResultat.hasMoreMeldungen = result.hasMoreMeldungen;
          this.osMeldung = this.osMeldung + 50;
        });
        break;
      }
      case 'factsheet': {
        this.searchDocumentsOffset(this.osSteckbrief, tab).subscribe(result => {
          this.suchResultat.steckbriefe = this.suchResultat.steckbriefe.concat(result.steckbriefe);
          this.suchResultat.hasMoreSteckbriefe = result.hasMoreSteckbriefe;
          this.osSteckbrief = this.osSteckbrief + 50;
        });
        break;
      }
      case 'signal': {
        this.searchDocumentsOffset(this.osSignal, tab).subscribe(result => {
          this.suchResultat.signale = this.suchResultat.signale.concat(result.signale);
          this.suchResultat.hasMoreSignale = result.hasMoreSignale;
          this.osSignal = this.osSignal + 50;
        });
        break;
      }
    }
  }

  onCategoryChange(event: TabChangeEvent) {
    this.router.navigate(['/adura/search', event.tabId], { queryParamsHandling: 'preserve' });
  }

  onMeldungFilterChanged(notificationFilter: string[]) {
    this.applyCriteria({ notificationFilter });
  }

  onSteckbriefFilterChanged(factsheetFilter: string[]) {
    this.applyCriteria({ factsheetFilter });
  }

  onGefahrChanged(danger: string[]) {
    this.applyCriteria({ danger });
  }

  onMatrixChanged(matrix: string[]) {
    this.applyCriteria({ matrix });
  }

  onTreiberChanged(driver: string[]) {
    this.applyCriteria({ driver });
  }

  onBereichChanged(domain: string[]) {
    this.applyCriteria({ domain });
  }

  onBeurteilungTypeChanged(assessmentType: string[]) {
    this.applyCriteria({ assessmentType });
  }

  onBeurteilungDateChanged(event: BeurteilungDateChangedEvent) {
    this.applyCriteria({assessmentDateFrom: event.beurteilungDateFrom, assessmentDateTo: event.beurteilungDateTo});
  }

  onSeismoPubChanged(seismoPub: string[]) {
    this.applyCriteria({ seismoPub });
  }

  onSeismoInfoChanged(seismoInfo: string[]) {
    this.applyCriteria({ seismoInfo });
  }

  onStatusChanged(status: string[]) {
    this.applyCriteria({ status });
  }

  onIsPublicChanged(isPublic: string[]) {
    this.applyCriteria({ isPublic });
  }

  onPriorityChanged(priority: string[]) {
    this.applyCriteria({ priority });
  }

  onCreatedDateChanged(event: CreatedDateChangedEvent) {
    this.applyCriteria({createdDateFrom: event.createdDateFrom, createdDateTo: event.createdDateTo});
  }

  onChangedDateChanged(event: ChangedDateChangedEvent) {
    this.applyCriteria({changedDateFrom: event.changedDateFrom, changedDateTo: event.changedDateTo});
  }

  onNextEvaluationChanged(event: NextEvaluationChangedEvent) {
    this.applyCriteria({nextEvaluationFrom: event.nextEvaluationFrom, nextEvaluationTo: event.nextEvaluationTo,
      nextEvaluationNull: event.nextEvaluationNull});
  }

  onAktivitaetStatusChanged(activityStatus: string[]) {
    this.applyCriteria({ activityStatus });
  }

  onAktivitaetDateChanged(event: AktivitaetDateChangedEvent) {
    this.applyCriteria({activityDateFrom: event.aktivitaetDateFrom, activityDateTo: event.aktivitaetDateTo,
      activityDateNull: event.aktivitaetDateNull});
  }

  private applyCriteria(params: object) {
    this.router.navigate(['/adura/search', this.currentCategory], {
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }
}
