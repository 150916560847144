<section class="contribute container information">
  <h1>{{ 'favorite.edit' | translate }}</h1>
  <form [formGroup]="favoriteEditForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <div class="form-element">
        <p *ngIf="error" role="alert" class="alert alert-danger">{{ errorMessage | translate }}</p>
        <div class="form-element">
          <label for="favoriteName">{{ 'global.name' | translate }}:</label>
          <input id="favoriteName" class="form-control form-control-xs" type="text" formControlName="favName">
        </div>
        <div class="form-element">
          <label>{{ 'favorite.categories.label' | translate }}</label><br/>
          <ng-select appendTo="body"
                     [multiple]="true"
                     [maxSelectedItems]="3"
                     dropdownPosition="bottom"
                     [readonly]="false"
                     [addTag]="true"
                     addTagText="{{'favorite.categories.add' | translate}}"
                     notFoundText="{{'favorite.categories.none' | translate}}"
                     placeholder="{{'favorite.categories.choose' | translate}}"
                     [items]="categories"
                     class="favorite-category-select"
                     formControlName="categories">
          </ng-select>
          <p role="alert" class="errors-text" *ngIf="(favoriteEditForm.controls.categories?.invalid)">
            {{ 'favorite.categories.maxLength' | translate }}
          </p>
        </div>
        <div class="form-element">
          <label for="notification">{{ 'favorite.notification.label' | translate }}:</label>
          <select class="form-control" id="notification" name="notification" formControlName="favNotification">
            <option *ngFor="let notification of notificationList" [ngValue]="notification"
                    [attr.aria-label]="'favorite.notification.frequency.' + notification | translate">{{ 'favorite.notification.frequency.' + notification | translate }}
            </option>
          </select>
        </div>
        <div class="form-element">
          <ng-container *ngIf="isSending; then showspinner else hidespinner"></ng-container>

          <ng-template #hidespinner>
            <button type="button" class="btn btn-sm btn-secondary" (click)="onReset()">{{ 'global.cancel' | translate }}
            </button>
            <button type="submit" class="btn btn-sm btn-primary" [disabled]="favoriteEditForm.controls.categories?.invalid">
              {{ 'global.save' | translate }}
            </button>
          </ng-template>

          <ng-template #showspinner>
            <button type="submit" class="btn btn-sm btn-primary"
                    [disabled]="favoriteEditForm.pristine || this.isSending">
              <i class="fa fa-spinner fa-pulse fa-fw"></i>
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </form>
</section>

