export interface PriorityJSON {
  id: number;
  messageProperty: string;
}

export class Priority {
  id: number;
  messageProperty: string;

  static fromJSON(json: string | object): Priority {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const priority = Object.create(Priority.prototype);
    return Object.assign(priority, object);
  }

  toJSON(): PriorityJSON {
    return Object.assign({}, this);
  }
}
