import { Component, Input, OnInit } from '@angular/core';
import { ResultItem } from '@app/shared/classes/result-item';
import { ConfigurationService } from '@app/core/config/configuration.service';

@Component({
  selector: 'app-thema-item',
  templateUrl: './thema-item.component.html'
})
export class ThemaItemComponent implements OnInit {

  @Input()
  thema: ResultItem;
  oberthemen: string;

  constructor(private configurationService: ConfigurationService) {
  }

  isProd() {
    return this.configurationService.getStage() === 'PR';
  }

  ngOnInit(): void {
    this.oberthemen = this.thema.oberthemen.join(', ');
  }
}
