export interface GefahrJSON {
  id: number;
  bezeichnung: string;
  bezeichnungRaw: string;
}

export class Gefahr {
  id: number;
  bezeichnung: string;
  bezeichnungRaw: string;

  static fromJSON(json: string | object): Gefahr {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const land = Object.create(Gefahr.prototype);
    return Object.assign(land, object);
  }

  toJSON(): GefahrJSON {
    return Object.assign({}, this);
  }
}

export interface GefahrAdminJSON {
  id?: number;
  bezeichnungDe: string;
  bezeichnungEn: string;
  bezeichnungFr: string;
  bezeichnungIt: string;
  deaktiviert: boolean;
  mutDate?: Date;
  mutUser?: string;
}

export class GefahrAdmin {
  id?: number;
  bezeichnungDe: string;
  bezeichnungEn: string;
  bezeichnungFr: string;
  bezeichnungIt: string;
  deaktiviert: boolean;
  mutDate?: Date;
  mutUser?: string;

  static fromJSON(json: string | object): GefahrAdmin {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const land = Object.create(GefahrAdmin.prototype);
    return Object.assign(land, object);
  }

  toJSON(): GefahrAdminJSON {
    return Object.assign({}, this);
  }
}
