import { Component, Input } from '@angular/core';
import { ConfigurationService } from '@app/core/config/configuration.service';
import { AduraResultItem } from '@app/shared/classes/adura/adura-result-item';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/auth/authentication.service';

@Component({
  selector: 'app-adura-meldung-item',
  templateUrl: './adura-meldung-item.component.html'
})
export class AduraMeldungItemComponent {
  @Input() meldung: AduraResultItem;
  kurzInfoHidden = true;

  constructor(private configurationService: ConfigurationService,
              private authenticationService: AuthenticationService,
              private router: Router) {}

  isProd() {
    return this.configurationService.getStage() === 'PR';
  }

  isBereichPrimaer(bereich: any) {
    return this.meldung.bereichPrimaer === bereich;
  }

  get basisQuelleLink() {
    if (this.meldung && this.meldung.basisQuelleLink) {
      if (this.meldung.basisQuelleLink.includes('http')) {
        return this.meldung.basisQuelleLink;
      }
      return '//' + this.meldung.basisQuelleLink;
    }
    return null;
  }

  isEditable(): boolean {
    return (this.authenticationService.hasRole('AWISA_FRESIL') || this.meldung?.status === '10');
  }

  openMeldung(code: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/adura/notification/${code}/edit`])
    );

    window.open(url, '_blank');
  }

  toggleKurzInfoHidden() {
    this.kurzInfoHidden = !this.kurzInfoHidden;
  }
}
