import { Component, OnInit } from '@angular/core';
import { AduraService } from '@app/shared/services/adura.service';
import { PublikationAdmin } from '@app/shared/classes/adura/publikation';

@Component({
  selector: 'app-adura-admin-stammdaten-publikation',
  templateUrl: './adura-admin-stammdaten-publikation.component.html'
})
export class AduraAdminStammdatenPublikationComponent implements OnInit {
  publikation: PublikationAdmin[] = [];
  setListError = false;

  constructor(private aduraService: AduraService) {
  }

  ngOnInit(): void {
    this.aduraService.listPublikationAdmin()
      .subscribe({
        next: result => {
          this.publikation = result;
          this.setListError = false;
        },
        error: error => {
          this.setListError = true;
          console.error(error);
        }
      });
  }

  onPublikationDeleted(index: number) {
    this.publikation.splice(index, 1);
  }

  reloadEntries(): void {
    this.ngOnInit();
  }
}
