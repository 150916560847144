import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpStatusCode } from '@angular/common/http';
import { AduraService } from '@app/shared/services/adura.service';
import { PublikationAdmin } from '@app/shared/classes/adura/publikation';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-adura-admin-stammdaten-publikation-new',
  templateUrl: './adura-admin-stammdaten-publikation-new.component.html'
})
export class AduraAdminStammdatenPublikationNewComponent implements OnInit {
  @Output() deleted = new EventEmitter();
  @Output() reloadEntries: EventEmitter<string> = new EventEmitter<string>();

  newPublikationForm: UntypedFormGroup;
  publikation: PublikationAdmin = new PublikationAdmin();

  constructor(private aduraService: AduraService,
              private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.newPublikationForm = this.formBuilder.group({
      titel_new: ['', Validators.required],
      abkuerzung_new: ['', Validators.required],
      deaktiviert_new: ['']
    });
  }

  onSubmit() {
    const formValues = this.newPublikationForm.value;
    this.publikation.titel = formValues.titel_new;
    this.publikation.abkuerzung = formValues.abkuerzung_new;
    this.publikation.deaktiviert = formValues.deaktiviert_new;
    this.aduraService.createPublikation(this.publikation)
      .subscribe({
        next: () => {
          this.initForm();
          this.reloadEntries.emit();
        },
        error: error => {
          if (error.status === HttpStatusCode.BadRequest) {
            this.showAlert(error.error);
          } else {
            this.showAlert(null);
          }
          console.error(error);
        }
      });
  }

  onReset() {
    this.ngOnInit();
  }

  showAlert(errors: any[]) {
    let errorString = '';
    if (errors === null) {
      errorString = 'Die Publikation konnte nicht gespeichert werden!';
    } else {
      errors.forEach(entry => {
        errorString += entry.message + '<br/>';
      });
    }
    Swal.fire({
      html: errorString,
      icon: 'error',
      customClass: {htmlContainer: 'content-text-center'},
      buttonsStyling: true,
      confirmButtonText: 'OK'
    });
  }
}
