<div class="row admin">
  <p><b>Neuer Treiber</b></p>
</div>
<div class="row admin">
    <div class="col-md-2 clear-padding-left"><p class="admin clear-margin-top"><b>Bezeichnung EN</b></p></div>
    <div class="col-md-2"><p class="admin clear-margin-top"><b>Bezeichnung DE</b></p></div>
    <div class="col-md-2"><p class="admin clear-margin-top"><b>Bezeichnung FR</b></p></div>
    <div class="col-md-2"><p class="admin clear-margin-top"><b>Bezeichnung IT</b></p></div>
    <div class="col-md-1"><p class="admin clear-margin-top"><b>Deaktiviert</b></p></div>
</div>
<div class="row admin">
  <form [formGroup]="newTreiberForm" (ngSubmit)="onSubmit()">

    <div class="col-md-2 clear-padding-left">
      <input id="bezeichnungEn_new" class="admin-settings" formControlName="bezeichnungEn_new" maxlength="255">
      <span class="length-counter">
        {{ (255 - newTreiberForm.get('bezeichnungEn_new').value?.length) + ' / 255' }}
      </span>
    </div>

    <div class="col-md-2">
      <input id="bezeichnungDe_new" class="admin-settings" formControlName="bezeichnungDe_new" maxlength="255">
      <span class="length-counter">
        {{ (255 - newTreiberForm.get('bezeichnungDe_new').value?.length) + ' / 255' }}
      </span>
    </div>

    <div class="col-md-2">
      <input id="bezeichnungFr_new" class="admin-settings" formControlName="bezeichnungFr_new" maxlength="255">
      <span class="length-counter">
        {{ (255 - newTreiberForm.get('bezeichnungFr_new').value?.length) + ' / 255' }}
      </span>
    </div>

    <div class="col-md-2">
      <input id="bezeichnungIt_new" class="admin-settings" formControlName="bezeichnungIt_new" maxlength="255">
      <span class="length-counter">
        {{ (255 - newTreiberForm.get('bezeichnungIt_new').value?.length) + ' / 255' }}
      </span>
    </div>

    <div class="col-md-1">
      <input type="checkbox" id="deaktiviert_new" formControlName="deaktiviert_new">
    </div>

    <div class="col-md-2">
      <button class="btn btn-sm admin-settings" type="submit"
              [ngStyle]="{ 'visibility': newTreiberForm.dirty ? '' : 'hidden'}"
              [disabled]="newTreiberForm.invalid">
        <i class="fa fa-check"></i>
      </button>

      <button class="btn btn-sm admin-settings" type="button"
              [ngStyle]="{ 'visibility': newTreiberForm.dirty ? '' : 'hidden'}"
              title="Abbrechen" (click)="onReset()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </form>
</div>
