export class SelectItem {
  name: string;
  messageProperty: string;
  selected: boolean;

  constructor(name, messageProperty, selected) {
    this.name = name;
    this.messageProperty = messageProperty;
    this.selected = selected;
  }
}
